import styled from "styled-components";

export const FormInputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const FormInput = styled.input<{
  $focused?: boolean;
  $errorCard?: boolean;
  $inputBlocked?: boolean;
  $isDropdown?: boolean;
}>`
  width: 100%;
  border-radius: ${({ theme }) => theme?.elementBorderRadius};
  font-size: 16px;
  border: ${({ theme }) => `1px solid ${theme?.inputBackgroundColor}`};
  color: ${({ theme }) => theme?.inputColor};
  background-color: ${({ theme, $focused }) =>
    $focused ? theme?.inputFocusedBackground : theme?.inputBackgroundColor};
  font-family: var(--boldFont);
  border: ${({ $focused, $errorCard, theme }) => {
    if ($focused) {
      return `1px solid  ${theme.mainColor}`;
    } else if ($errorCard) {
      return `1px solid ${theme?.errorTitleColor}`;
    }

    return "";
  }};
  padding: 12px 16px;
  margin-top: 10px;
  margin-bottom: 18px;
  box-shadow: ${({ $errorCard }) =>
    $errorCard ? "0 0 5px rgba(235, 0, 117, 0.5)" : ""};
  pointer-events: ${({ $inputBlocked }) => ($inputBlocked ? "none" : "auto")};
  cursor: ${({ $inputBlocked }) => ($inputBlocked ? "default" : "text")};
  user-select: ${({ $inputBlocked }) => ($inputBlocked ? "none" : "auto")};
  height: ${(props) => props.theme?.inputHeight};
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    background-color: white;
  }

  ${({ $isDropdown }) => {
    if ($isDropdown) {
      return `
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 28px;
      `;
    }

    return ``;
  }}
`;

export const InvalidCardErrorTitle = styled.p`
  position: absolute;
  top: ${({ theme }) => `${57 + parseInt(theme.bottomError, 10)}px`};
  left: 12px;
  color: ${({ theme }) => theme?.errorTitleColor};
  font-size: 11px;
  font-family: var(--regularFont);
  width: calc(100% - 24px);
  min-width: 230px;
`;

export const CvvTooltipMainTitle = styled.p`
  span {
    font-family: var(--boldFont);
    color: #fff;
    font-size: 14px;
    line-height: 16.03px;
  }
`;

export const TooltipContentWrapper = styled.div`
  position: relative;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 42px;
`;
