import styled from "styled-components";

export const SavedCardPopUpTitle = styled.p`
  font-size: 20px;
  font-family: var(--regularFont);
  color: ${({ theme }) => theme?.popupTextTitleColor};
  margin-right: 16px;
  text-align: center;
`;

export const ManageCardWrapper = styled.div`
  background-color: ${({ theme }) => theme?.managePopupBackgroundColor};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
