import Trans from "../../Trans";
import { COLORS } from "../../../constants/themeConstants";
import { PopupActionTitle } from "../../FormPopup/style";
import {
  ActionButtonCardEditWrapper,
  ActionButtonCardList,
} from "../../../themes/commons/styles/ManageCardStyles";

interface ClearFormModalsProps {
  resetCardForm?: () => void;
  handleCloseModal?: () => void;
}

const ClearFormModalKombine: React.FC<ClearFormModalsProps> = ({
  resetCardForm,
  handleCloseModal,
}) => {
  return (
    <>
      <PopupActionTitle $color={COLORS.WHITE} $fw="400">
        <Trans message="clearCardInfoConfirmation" />
      </PopupActionTitle>
      <ActionButtonCardEditWrapper>
        <ActionButtonCardList
          $background="#10366B"
          type="button"
          $color="#fff"
          $maxWidth="147px"
          $width="100%"
          $fontSize="16px"
          $textTransform="uppercase"
          $fontWeight="700"
          onClick={handleCloseModal}
        >
          <Trans message="cancel" />
        </ActionButtonCardList>

        <ActionButtonCardList
          $background="linear-gradient(#C71432, #FF5975)"
          type="button"
          $color="#fff"
          $width="100%"
          $maxWidth="147px"
          $fontSize="16px"
          $textTransform="uppercase"
          $fontWeight="700"
          onClick={resetCardForm}
        >
          <Trans message="delete" />
        </ActionButtonCardList>
      </ActionButtonCardEditWrapper>
    </>
  );
};

export default ClearFormModalKombine;
