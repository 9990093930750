import { Bold } from "../../../../ui/GeneralStyles/style";
import { IPaymentInstructions } from "./interfaces";
import { PM_COLORS } from "../../enums";

const expirationDate: IPaymentInstructions = {
  getCustomInfoBlocks: () => [
    {
      position: 1,
      type: "warning",
      warningMessage: "paymentTimeLimit",
      iconColor: PM_COLORS.ERROR,
      titleColor: "#000000F0",
      backgroundColor: PM_COLORS.ERROR_BACKGROUND,
      condition: () => true,
      content: () => ({
        message: "transferNotCompleted",
        components: [Bold],
      }),
    },
  ],
};

export default expirationDate;
