import { useThemeComponent } from "../../hooks/useThemeComponent";
import ChallengeQrDefault from "../../themes/default/components/ChallengeQr";
import ChallengeQrPM from "../../themes/PM_brand/components/ChallengeQr";
import ExpirationDateInfoDefault from "../../themes/default/components/ExpirationDate/expirationDateInfo";
import ExpirationDateInfoPm from "../../themes/PM_brand/components/ExpirationDate/expirationDateInfo";
import { useExpirationStatus } from "../../services/hooks";

const ChallengeQr = ({
  currentState,
  cancelUrl,
  selectedMethod,
  expiredProviders,
}) => {
  const { challenge } = currentState;
  const themeForComponent = useThemeComponent("ChallengeQr");

  const ChallengeQrComponent = {
    default: ChallengeQrDefault,
    PM_brand: ChallengeQrPM,
  }[themeForComponent];

  const infoBlockTitle = {
    pix: "scanQrTitlePix",
    wallet_opay: "scanQrTitleOpay",
    wallet_opay_qr: "scanQrTitleOpay",
  };

  const ExpirationDateInfoComponent = {
    default: ExpirationDateInfoDefault,
    PM_brand: ExpirationDateInfoPm,
  }[themeForComponent];

  const isExpired = useExpirationStatus(challenge?.qr?.expiration_date);

  return isExpired && expiredProviders ? (
    <ExpirationDateInfoComponent cancelUrl={cancelUrl} />
  ) : (
    <ChallengeQrComponent
      challenge={challenge}
      cancelUrl={cancelUrl}
      infoBlockTitle={infoBlockTitle}
      selectedMethod={selectedMethod}
    />
  );
};

export default ChallengeQr;
