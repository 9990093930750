import styled from "styled-components";

export const CustomCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme?.rememberMeCheckboxMargin};
  padding: ${({ theme }) => theme?.rememberMeCheckboxPadding};
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCheckbox = styled.div<{
  $checked?: boolean;
}>`
  display: inline-block;
  width: ${({ theme }) => theme?.rememberMeCheckboxSize};
  height: ${({ theme }) => theme?.rememberMeCheckboxSize};
  background: ${({ $checked, theme }) =>
    $checked ? theme?.rememberMeCheckboxBackground : "transparent"};
  border-radius: ${({ theme }) => theme?.rememberMeCheckboxBorderRadius};
  transition: all 150ms;
  border: 1px solid
    ${({ $checked, theme }) =>
      $checked
        ? theme?.rememberMeCheckboxBorderChecked
        : theme?.rememberMeCheckboxBorderNotChecked};
  cursor: pointer;
  position: relative;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #0e76a8;
  }
  svg {
    position: absolute;
    top: ${({ theme }) => theme?.rememberMeCheckboxCheckedIconTop};
    left: 0px;
    fill: none;
    stroke: black;
    stroke-width: 3px;
    visibility: ${({ $checked }) => ($checked ? "visible" : "hidden")};
  }
`;

export const CheckboxLabel = styled.label`
  margin-left: 8px;
  color: ${({ theme }) => theme?.rememberMeCheckboxTitleColor};
  cursor: pointer;
`;
