import { FunctionComponent } from "preact";
import PMArrow from "../../../../assets/icons/brand/pm/ui/Arrow";
import Trans from "../../../../components/Trans";
import {
  PMLabelSavedCard,
  PMSavedCardContainer,
  PMItemContent,
  PMItemDetails,
  PMItemIcon,
  PMItemIconAction,
  PMItemName,
  PMItemDescription,
} from "./style";
import { Card } from "../../../commons/interfaces/CardInterfaces";
import { CARD_BRANDS } from "../../../../constants/cardBrand";
import defaultCard from "../../../../assets/icons/anotherCardPb.svg";

interface IPropsSavedCard {
  card: Card;
  handleOpen: () => void;
}

const SavedCardPM: FunctionComponent<IPropsSavedCard> = ({
  card,
  handleOpen,
}) => {
  return (
    <PMSavedCardContainer>
      <PMLabelSavedCard>
        <Trans message="savedCard" />
      </PMLabelSavedCard>
      <PMItemContent onClick={handleOpen} $hasImage>
        <PMItemIcon>
          <img src={CARD_BRANDS[card.brand] || defaultCard} alt="brand" />
        </PMItemIcon>
        <PMItemDetails>
          <PMItemName>Master card</PMItemName>
          <PMItemDescription>{`${card?.bin?.toString()?.slice(0, 4)} ${card?.bin
            ?.toString()
            ?.slice(4, 6)}** **** ${card?.last4}`}</PMItemDescription>
        </PMItemDetails>
        <PMItemIconAction>
          <PMArrow />
        </PMItemIconAction>
      </PMItemContent>
    </PMSavedCardContainer>
  );
};

export default SavedCardPM;
