import { useState, useEffect } from "preact/hooks";
import ExpirationDateDefault from "../../themes/default/components/ExpirationDate";
import ExpirationDatePM from "../../themes/PM_brand/components/ExpirationDate";
import { useThemeComponent } from "../../hooks/useThemeComponent";

const ExpirationDate = ({ expiration_date }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(expiration_date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      const hours = Math.floor((difference / 1000 / 60 / 60) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      timeLeft = { hours, minutes, seconds, difference };
    } else {
      timeLeft = { hours: 0, minutes: 0, seconds: 0, difference };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    if (timeLeft.difference <= 0) {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const themeForComponent = useThemeComponent("ExpirationDate");

  const ExpirationDateComponent = {
    default: ExpirationDateDefault,
    PM_brand: ExpirationDatePM,
  }[themeForComponent];

  if (!ExpirationDateComponent) return null;

  return <ExpirationDateComponent timeLeft={timeLeft} />;
};

export default ExpirationDate;
