import { i18n } from "../../services";
import { useContext } from "preact/hooks";
import { ContextUI } from "../../providers/UIProvider";
import LanguageModal from "../Modals/LanguageModal";
import { StyledCurrentLanguageIcon, StyledLanguageButton } from "./style";

const FormHeaderLanguageAction = () => {
  const { setModalData } = useContext(ContextUI);

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      content: <LanguageModal onChange={undefined} />,
      title: "chooseLanguage",
      isFullView: i18n.getLanguageConfig()?.length > 4,
    });
  };

  return (
    <StyledLanguageButton>
      <StyledCurrentLanguageIcon
        onClick={handleOpen}
        src={`/flags/${i18n.getCountryCode()?.toString()?.toLowerCase()}.svg`}
      />
    </StyledLanguageButton>
  );
};

export default FormHeaderLanguageAction;
