import { FunctionComponent } from "preact";
import { SavedCardMobileWrapper } from "../../../commons/styles/FormStyles";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import Trans from "../../../../components/Trans";

interface InputSavedCardProps {
  openModal: () => void;
}

const InputSavedCard: FunctionComponent<InputSavedCardProps> = ({
  openModal,
}) => {
  return (
    <>
      <SavedCardMobileWrapper width="116px" onClick={() => openModal()}>
        <Tooltip
          background="#10366B"
          width="220px"
          textAlign="start"
          fs="12px"
          bottom="-520%"
          top="-12%"
          left="-25%"
          leftArrow="80%"
          rotate="180deg"
          text="We only store the card number and expiration date for bulk orders on our website"
        >
          <Trans message="savedCard" />
        </Tooltip>
      </SavedCardMobileWrapper>
    </>
  );
};

export default InputSavedCard;
