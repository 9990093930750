import styled from "styled-components";

export const KombineTooltipWrapper = styled.div`
  display: flex;
  align-items: center;

  & > span,
  & > span > span {
    display: inline-flex;
    align-items: center;
  }
`;
