import styled from "styled-components";

export const IconColorWrapper = styled.div<{
  $ml?: string;
  $mr?: string;
  $color?: string;
}>`
  position: relative;
  margin-left: ${({ $ml }) => $ml || ""};
  margin-right: ${({ $mr }) => $mr || ""};

  svg {
    path {
      fill: ${({ theme, $color }) =>
        theme?.iconCopyColorIcon || $color || theme.mainColor};
    }
  }
`;
