const InfoWarningIcon = ({ color = "#FF3333" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.7199 18.85L13.8099 3.06003C13.6285 2.73973 13.3654 2.47331 13.0474 2.28793C12.7294 2.10256 12.368 2.00488 11.9999 2.00488C11.6318 2.00488 11.2703 2.10256 10.9523 2.28793C10.6343 2.47331 10.3712 2.73973 10.1899 3.06003L1.27988 18.85C1.09593 19.1686 0.998918 19.53 0.998536 19.8979C0.998155 20.2658 1.09442 20.6273 1.27771 20.9463C1.461 21.2653 1.72487 21.5305 2.04291 21.7155C2.36095 21.9004 2.72198 21.9985 3.08988 22H20.9099C21.2778 21.9985 21.6388 21.9004 21.9569 21.7155C22.2749 21.5305 22.5388 21.2653 22.722 20.9463C22.9053 20.6273 23.0016 20.2658 23.0012 19.8979C23.0008 19.53 22.9038 19.1686 22.7199 18.85ZM11.0999 8.75003C11.0999 8.51134 11.1947 8.28243 11.3635 8.11365C11.5323 7.94486 11.7612 7.85004 11.9999 7.85004C12.2386 7.85004 12.4675 7.94486 12.6363 8.11365C12.805 8.28243 12.8999 8.51134 12.8999 8.75003V13.25C12.8999 13.4887 12.805 13.7176 12.6363 13.8864C12.4675 14.0552 12.2386 14.15 11.9999 14.15C11.7612 14.15 11.5323 14.0552 11.3635 13.8864C11.1947 13.7176 11.0999 13.4887 11.0999 13.25V8.75003ZM11.9999 18.2C11.7625 18.2 11.5305 18.1297 11.3332 17.9978C11.1359 17.8659 10.9821 17.6785 10.8912 17.4593C10.8004 17.24 10.7766 16.9987 10.8229 16.7659C10.8692 16.5332 10.9835 16.3193 11.1513 16.1515C11.3192 15.9837 11.533 15.8694 11.7658 15.8231C11.9986 15.7768 12.2398 15.8006 12.4591 15.8914C12.6784 15.9822 12.8658 16.136 12.9977 16.3333C13.1295 16.5307 13.1999 16.7627 13.1999 17C13.1999 17.3183 13.0735 17.6235 12.8484 17.8486C12.6234 18.0736 12.3181 18.2 11.9999 18.2Z"
      fill={color}
    />
  </svg>
);

export default InfoWarningIcon;
