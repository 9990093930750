const MIN_NAME_LENGTH = 1;
const MAX_LENGTH = 50; // first name + last name

const nameRegex = /^[\p{L}'-]{1,}( [\p{L}'-]{1,})*$/u;

const preprocessValue = (value) => value?.trim()?.replace(/\s+/g, " ");

const validateNameForKombine = (value) => {
  const wordPattern = /^[A-Za-z]+(?:['-][A-Za-z]+)*$/;
  const MIN_WORD_LENGTH_KOMBINE = 2;

  const trimmedValue = preprocessValue(value);
  const parts = trimmedValue?.split(" ");

  if (!parts || parts.length !== 2) {
    return false;
  }

  const [firstName, lastName] = parts;

  return (
    wordPattern.test(firstName) &&
    wordPattern.test(lastName) &&
    firstName.length >= MIN_WORD_LENGTH_KOMBINE &&
    lastName.length >= MIN_WORD_LENGTH_KOMBINE &&
    trimmedValue.length <= MAX_LENGTH
  );
};

const validateDefault = (value) => {
  const trimmedValue = preprocessValue(value);

  return (
    nameRegex.test(trimmedValue) &&
    trimmedValue.length >= MIN_NAME_LENGTH &&
    trimmedValue.length <= MAX_LENGTH
  );
};

const validationConfig = {
  default: validateDefault,
  kombine: validateNameForKombine,
};

export const validateCardHolder = (validationType, value) => {
  const validate = validationConfig[validationType];

  if (validate) {
    return validate(value);
  }
};
