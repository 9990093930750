import { FunctionComponent } from "preact";
import { state } from "../../../../services";
import { HelperForChooseMethod } from "../../../commons/styles/FormStyles";
import Trans from "../../../../components/Trans";

interface FormHeaderProps {
  titleError?: string;
  selectedMethod?: string | null;
}

const FormHeader: FunctionComponent<FormHeaderProps> = ({
  titleError,
  selectedMethod,
}) => {
  const canReturnToMethods = state.hasPaymentMethodsToShow();

  const hasError = !canReturnToMethods && !!titleError;

  return (
    <>
      <div style={{ height: "24px" }} />

      {!selectedMethod && !hasError ? (
        <HelperForChooseMethod>
          <Trans message="chooseMethod" />
        </HelperForChooseMethod>
      ) : null}
    </>
  );
};

export default FormHeader;
