import { formatCryptoCurrency } from "../../../../constants/CryptoCurrencyIcon";
import { Bold, Regular } from "../../../../ui/GeneralStyles/style";
import { IPaymentInstructions } from "./interfaces";

const crypto: IPaymentInstructions = {
  getCustomInfoBlocks: () => [
    {
      type: "info",
      position: 2,
      condition: (data) => data?.isCryptoCalculated,
      content: () => ({
        message: "cryptoCalculatedInfoTitle",
      }),
    },
    {
      type: "info",
      position: 3,
      condition: (data) =>
        ["on_customer"].includes(data?.networkFeeField || ""),
      content: ({ data = {} }) => ({
        message: "cryptoFeeTitle",
        params: {
          currency: formatCryptoCurrency(data?.currency, true) || "",
          cryptoCurrency: formatCryptoCurrency(data?.pay_currency, true) || "",
        },
        components: [Regular],
      }),
    },
    {
      position: 1,
      type: "warning",
      condition: (data) => data?.hasMinValue && data?.isMinNotificationEnabled,
      content: ({ data = {} }) => ({
        message: "cryptoMinAttentionTitle",
        params: {
          min: data.sumMinValue || "",
          currency: formatCryptoCurrency(data?.pay_currency, true) || "",
        },
        components: [Bold],
      }),
    },
  ],
  getTitle: ({ data = {} }) => ({
    message: "cryptoDisclaimer",
    params: {
      value: formatCryptoCurrency(data?.pay_currency, true) || "",
    },
    components: [Bold],
  }),
  isOrderedList: false,
  instructionList: [
    {
      dataTitle: "walletAddress",
      getPaymentData: ({ data = {} }) => data?.address,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.address,
        text: "walletAddress",
      }),
    },
    {
      dataTitle: "TAG",
      getPaymentData: ({ data = {} }) => data?.address_tag,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.address_tag,
        text: "TAG",
      }),
    },
    {
      getQR: ({ data = {} }) => {
        if (!data?.address || !data?.address_qr_code_image) return undefined;

        return `data:image/png+xml; base64,${data.address_qr_code_image}`;
      },
    },
  ],
  getInfo: () => "correctNetworkInfoTitle",
};

export default crypto;
