import { useRef, useState, useContext } from "preact/hooks";
import ChallengeQr from "./ChallengeQr";
import ChallengeBrLottery from "./ChallengeBrLottery";
import { Context } from "../../app";
import { state } from "../../services";
import ChallengeForm from "./ChallengeForm";
import ChallengeArgentinaInfo from "./ChallengeBankTransferArgentina/ChallengeArgentinaInfo";
import ChallengeStk from "./ChallengeStk";
import ChallengeBankTransferAfrica from "./ChallengeBankTransferAfrica";
import InteracEtransferInfo from "./ChallengeInteracEtrfansfer/InteracEtransferInfo";
import {
  RENDER_CHALLENGE_TYPES,
  CHALLENGE_TYPES_CONFIG,
} from "../../constants/challengeTypes";
import ChallengeUssd from "./ChallengeUssd/ChallengeUssd";
import { useLogo } from "../../services/hooks";
import ChallengeP2cPhone from "./ChallengeP2cPhone/ChallengeP2cPhone";
import ChallengeBankCode from "./ChallengeBankCode/ChallengeBankCode";
import { ContextUI } from "../../providers/UIProvider";
import PayCode from "./PayCode/PayCode";
import ChallengeBrBoleto from "./ChallengeBrBoleto";
import {
  ChallengeFormMainWrapper,
  Container,
  FormAmountOfPaymentWrapper,
} from "./style";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import ChallengeHeaderDefault from "../../themes/default/components/ChallengeHeader";
import ChallengeHeaderKombine from "../../themes/kombine/components/ChallengeHeader";
import ChallengeHeaderPM from "../../themes/PM_brand/components/ChallengeHeader";

const ChallengeControl = ({
  currentState,
  reference,
  amount,
  countdown,
  redirect_url,
  options,
  onButtonClick,
  cancelUrl,
  status,
  challenge,
  currency,
  isQrChallenge,
  isBrChallenge,
  sessionId,
  signature,
}) => {
  const transactionIdRef = useRef(null);
  const { getFormattedAmountParts } = useContext(Context);
  const { handleCopy } = useContext(ContextUI);

  const { wholeNumber, decimal } = getFormattedAmountParts(amount);
  const [onShow, setOnShow] = useState(false);

  const canReturnToMethods = state.hasPaymentMethodsToShow();

  const optionsState = state.get().options;
  const mobileMoneyPhone = currentState?.mobile_money?.phone;
  const currentMethod = currentState?.options?.method;
  const logoIcon = useLogo(optionsState?.theme);

  const { customer } = currentState;

  const [deviceView, setDeviceView] = useState();
  const isMobileDevice = () => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");

    return mediaQuery.matches;
  };

  if (isMobileDevice()) {
    setDeviceView("mobile");
  } else {
    setDeviceView("desktop");
  }

  const formTitle =
    deviceView === "mobile" ? "upi_qr_title_mob" : "upi_qr_title_desk";

  const handleDownloadPdf = async () => {
    const fileUrl = challenge?.br?.fields?.pdf_url;

    const fileName = "file.pdf";

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error(error);
    }
  };

  const getControlComponent = (selectedMethod) => {
    const components = {
      ControlBr: {
        boleto: ChallengeBrBoleto,
        lottery: ChallengeBrLottery,
      }[selectedMethod?.method],
      ControlQr: {
        pix: ChallengeQr,
        upi: ChallengeQr,
        wallet_opay: ChallengeQr,
        wallet_opay_qr: ChallengeQr,
        qr_code: ChallengeQr,
      }[selectedMethod?.method],
    };

    return components;
  };

  const selectedMethod = state.getSelectedPaymentMethod();

  const { ControlBr, ControlQr } = getControlComponent(selectedMethod);

  const { body, hasHeader, source, hideAmount } =
    CHALLENGE_TYPES_CONFIG[challenge?.type] || {};

  const expiredProviders = ["ops"]?.includes(selectedMethod?.provider?.name);

  const themeForComponent = useThemeComponent("ChallengeHeader");

  const Header = {
    default: ChallengeHeaderDefault,
    kombine: ChallengeHeaderKombine,
    PM_brand: ChallengeHeaderPM,
  }[themeForComponent];

  const renderHeader = () => (
    <Container>
      <div>
        <FormAmountOfPaymentWrapper>
          <Header
            isLogo={logoIcon}
            currentState={currentState}
            reference={reference}
            wholeNumber={!hideAmount ? wholeNumber : null}
            decimal={!hideAmount ? decimal : null}
            currency={currency}
            transactionIdRef={transactionIdRef}
            handleCopy={handleCopy}
            canReturnToMethods={canReturnToMethods}
            selectedMethod={selectedMethod?.method}
            mobileMoneyPhone={mobileMoneyPhone}
            customer={customer}
            type={challenge?.type}
            theme={options?.theme}
          />
        </FormAmountOfPaymentWrapper>
      </div>
    </Container>
  );

  if (body) {
    return (
      <>
        {hasHeader && renderHeader()}
        {body({
          amount,
          status,
          challenge,
          sessionId,
          signature,
          currentMethod,
          p2c: challenge?.p2c,
          data: challenge?.[source],
          handleCopy,
          selectedMethod: selectedMethod?.method,
          cancelUrl,
          expiredProviders,
        })}
      </>
    );
  }

  if (isQrChallenge && ControlQr) {
    return (
      <>
        {renderHeader()}
        <ControlQr
          reference={reference}
          selectedMethod={selectedMethod?.method}
          expiredProviders={expiredProviders}
          currency={currency}
          amount={amount}
          countdown={countdown}
          redirect_url={redirect_url}
          options={options}
          onButtonClick={onButtonClick}
          currentState={currentState}
          wholeNumber={wholeNumber}
          transactionIdRef={transactionIdRef}
          handleCopy={handleCopy}
          decimal={decimal}
          setOnShow={setOnShow}
          onShow={onShow}
          challenge={challenge}
          cancelUrl={cancelUrl}
          formTitle={formTitle}
          deviceView={deviceView}
        />
      </>
    );
  }

  if (isBrChallenge && ControlBr) {
    return (
      <>
        {renderHeader()}
        <ControlBr
          currentState={currentState}
          reference={reference}
          decimal={decimal}
          wholeNumber={wholeNumber}
          transactionIdRef={transactionIdRef}
          setOnShow={setOnShow}
          onShow={onShow}
          handleCopy={handleCopy}
          cancelUrl={cancelUrl}
          handleDownloadPdf={handleDownloadPdf}
        />
      </>
    );
  }

  if (challenge?.type === RENDER_CHALLENGE_TYPES.STK) {
    return (
      <>
        {renderHeader()}
        <ChallengeStk />
      </>
    );
  }

  if (challenge?.type === RENDER_CHALLENGE_TYPES.INTERAC_ETRANSFER) {
    return (
      <>
        {renderHeader()}
        <InteracEtransferInfo
          challenge={challenge?.interac_etransfer}
          currency={currency}
          amount={amount}
          transactionIdRef={transactionIdRef}
          reference={reference}
          handleCopy={handleCopy}
          cancelUrl={cancelUrl}
          theme={options?.theme}
        />
      </>
    );
  }

  if (challenge?.type === RENDER_CHALLENGE_TYPES.USSD) {
    return (
      <>
        <ChallengeUssd
          isLogo={logoIcon}
          currentState={currentState}
          reference={reference}
          wholeNumber={wholeNumber}
          decimal={decimal}
          currency={currency}
          amount={amount}
          challenge={challenge}
          transactionIdRef={transactionIdRef}
          handleCopy={handleCopy}
          cancelUrl={cancelUrl}
          canReturnToMethods={canReturnToMethods}
          selectedMethod={selectedMethod?.method}
          selectedMethodChannel={selectedMethod?.channel?.ui_code}
          mobileMoneyPhone={mobileMoneyPhone}
        />
      </>
    );
  }

  if (challenge?.type === RENDER_CHALLENGE_TYPES.BANK_TRANSFER_ARGENTINA) {
    return (
      <>
        {renderHeader()}
        <ChallengeArgentinaInfo
          challenge={challenge?.bank_transfer_argentina}
          currency={currency}
          amount={amount}
          transactionIdRef={transactionIdRef}
          reference={reference}
          handleCopy={handleCopy}
        />
      </>
    );
  }

  if (challenge?.type === RENDER_CHALLENGE_TYPES.PAY_CODE) {
    return (
      <>
        {renderHeader()}
        <PayCode
          data={challenge?.pay_code || {}}
          handleCopy={handleCopy}
          selectedMethod={selectedMethod?.method}
          cancelUrl={cancelUrl}
        />
      </>
    );
  }

  if (challenge?.type === RENDER_CHALLENGE_TYPES.P2C_PHONE) {
    return (
      <>
        {renderHeader()}
        <ChallengeP2cPhone
          data={challenge?.p2c_phone}
          handleCopy={handleCopy}
          currency={currency}
          amount={amount}
        />
      </>
    );
  }

  if (challenge?.type === RENDER_CHALLENGE_TYPES.BANK_CODE) {
    return (
      <>
        {renderHeader()}
        <ChallengeBankCode data={challenge?.bank_code} />
      </>
    );
  }

  if (challenge?.type === RENDER_CHALLENGE_TYPES.BANK_TRANSFER_AFRICA) {
    return (
      <>
        {renderHeader()}
        <ChallengeBankTransferAfrica
          handleCopy={handleCopy}
          cancelUrl={cancelUrl}
          bankTransferData={challenge?.bank_transfer_africa}
        />
      </>
    );
  }

  return (
    <>
      {renderHeader()}
      <ChallengeFormMainWrapper>
        <ChallengeForm
          challengeType={challenge?.type}
          challengeUrl={challenge?.url}
          sessionId={sessionId}
          signature={signature}
          currentMethod={currentMethod}
          challenge={challenge}
        />
      </ChallengeFormMainWrapper>
    </>
  );
};

export default ChallengeControl;
