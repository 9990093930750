import { useEffect } from "preact/hooks";
import { api, state } from "../../../services";
import ChallengeForm from "../ChallengeForm";
import Instruction from "../../Instruction";
import { PAYMENT_METHODS } from "../../../constants/paymentMethods";

const ChallengeBankCode = ({
  status,
  challenge,
  sessionId,
  signature,
  currentMethod,
}) => {
  const onChallengeFormSubmit = () => {
    state.setLoading({
      status: true,
      eventName: "challengeSubmit",
    });
  };

  useEffect(() => {
    if (status === "challenge_succeeded") {
      onChallengeFormSubmit();
    }
  }, [status]);

  return (
    <>
      <Instruction
        configName="bank_code_challenge"
        instructionData={{ currentMethod }}
      />
      <ChallengeForm
        challengeType={challenge?.type}
        challengeUrl={`${api.baseApiUrl}/payment/challenge/bank_code/${sessionId}/${signature}`}
        challenge={challenge}
        onFormSubmit={onChallengeFormSubmit}
        onFormSubmitFail={() => {
          state.setLoading({
            status: false,
            eventName: "challengeSubmit",
          });
        }}
        sessionId={sessionId}
        signature={signature}
        currentMethod={currentMethod}
        submitButtonText={
          currentMethod === PAYMENT_METHODS.BANK_TRANSFER_JAPAN && "continue"
        }
      />
    </>
  );
};

export default ChallengeBankCode;
