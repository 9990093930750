import Trans from "../Trans";
import leftIconArrow from "../../assets/icons/leftIconAsquad.svg";
import iconBackKobine from "../../assets/icons/iconBackKombine.svg";
import { state, i18n, theme } from "../../services";
import useApmTransaction from "../../hooks/useApmTransaction";
import * as Preact from "preact";
import { GoBackActionTitle, GoBackActionWrapper } from "./style";
import { BaseButton } from "../../themes/commons/styles/ButtonStyles";
import { ThemeConfig } from "../../themes/commons/interfaces/configInterface";

interface IProps {
  onClick: (
    cancelUrl: string,
    options?: { canReturnToMethods: boolean },
  ) => void;
  cancelText: string;
  type?: "link" | "button";
  cancelUrl: string;
  style?: Record<string, any>;
}

const iconBackConfig: Record<string, string> = {
  default: leftIconArrow,
  kombine: iconBackKobine,
};

const getTitle = (
  canReturnToMethods: boolean,
  cancelText: string = "cancel",
): Preact.JSX.Element => (
  <Trans message={canReturnToMethods ? "backToMethods" : cancelText} />
);

const GoBackAction = ({
  onClick,
  cancelText,
  type = "link",
  cancelUrl,
  style,
}: IProps): Preact.JSX.Element | null => {
  const { createSpan } = useApmTransaction("PaymentFlow");

  const canReturnToMethods = state.hasPaymentMethodsToShow();

  const title = getTitle(canReturnToMethods, cancelText);

  const themeName = state.getTheme() as keyof typeof iconBackConfig;

  const themeConfig = theme.getThemeConfig(themeName) as ThemeConfig;

  const isHideBackToActionTitle =
    themeConfig.isHideBackToActionTitle && !canReturnToMethods;

  const handleClick = (e: Preact.JSX.TargetedMouseEvent<EventTarget>) => {
    if (!canReturnToMethods) {
      const backToStoreSpan = createSpan("BackToStore");
      // @ts-ignore
      const confirmationMessage = i18n.getMessage({ message: "leftHpp" });

      const userConfirmed = window.confirm(confirmationMessage);

      if (!userConfirmed) {
        e.preventDefault();
      } else {
        onClick(cancelUrl, { canReturnToMethods });
      }

      backToStoreSpan?.end();
    } else {
      const returnToMethodsSpan = createSpan("ReturnToMethods");

      onClick(cancelUrl, { canReturnToMethods });

      setTimeout(() => {
        returnToMethodsSpan?.end();
      }, 0);
    }
  };

  if (type === "button") {
    return (
      <BaseButton
        onClick={handleClick as Preact.JSX.MouseEventHandler<HTMLButtonElement>}
      >
        {title}
      </BaseButton>
    );
  }

  if (type === "link") {
    return (
      <GoBackActionWrapper
        onClick={handleClick as Preact.JSX.MouseEventHandler<HTMLDivElement>}
        data-transaction-name="GoBack"
        data-role="back_to_methods_button"
        style={style}
      >
        <img
          src={
            iconBackConfig[themeName as keyof typeof iconBackConfig] ||
            leftIconArrow
          }
          alt="Left Arrow Icon"
        />{" "}
        {isHideBackToActionTitle ? null : (
          <GoBackActionTitle>
            {canReturnToMethods ? <Trans message="backToMethods" /> : title}
          </GoBackActionTitle>
        )}
      </GoBackActionWrapper>
    );
  }

  return null;
};

export default GoBackAction;
