import { useEffect, useState } from "preact/hooks";
import { FunctionComponent } from "preact";
import headerFieldsConfig from "../../../../components/Challenge/ChallengeHeaderFieldsConfig";
import { state, theme } from "../../../../services";
import Header from "../../../../components/Header";
import PaymentDetails, {
  PaymentDetailItem,
} from "../../../../components/PaymentDetails";

interface Customer {
  first_name?: string;
  last_name?: string;
  itn?: string;
}

interface ChallengeHeaderProps {
  currentState: string;
  reference: string;
  wholeNumber: string;
  decimal: string;
  currency: string;
  mobileMoneyPhone?: string;
  type: string;
  customer?: Customer;
}

const ChallengeHeader: FunctionComponent<ChallengeHeaderProps> = ({
  currentState,
  reference,
  wholeNumber,
  decimal,
  currency,
  mobileMoneyPhone,
  type,
  customer = {},
}) => {
  const [data, setData] = useState<PaymentDetailItem[]>([]);
  const options = (state.get() as { options: { theme?: string } }).options;

  const currentTheme = theme.getThemeConfig(options?.theme) as {
    isHideTransactionId?: boolean;
    isHideDescription?: boolean;
    isShowCurrencyCopy?: boolean;
  };
  const { first_name, last_name, itn } = customer || {};

  const { isHideTransactionId, isHideDescription, isShowCurrencyCopy } =
    currentTheme || {};

  const headerFieldData = {
    type,
    currentState,
    first_name,
    last_name,
    itn,
    reference,
    mobileMoneyPhone,
    wholeNumber,
    decimal,
    currency,
    isHideTransactionId,
    isHideDescription,
    isShowCurrencyCopy,
  };

  useEffect(() => {
    const formattedData: PaymentDetailItem[] = headerFieldsConfig?.fields?.map(
      (item): PaymentDetailItem => ({
        ...item,
        label: item.label,
        condition:
          item.condition && typeof item.condition === "function"
            ? item.condition(headerFieldData)
            : item.condition,
        value:
          item.value && typeof item.value === "function"
            ? item.value(headerFieldData)
            : item.value,
      }),
    );

    setData(formattedData);
  }, [headerFieldsConfig]);

  return (
    <>
      <Header hasGradient />
      <PaymentDetails
        detailedInfo={data}
        reference={reference}
        wholeNumber={wholeNumber}
        decimal={decimal}
        currency={currency}
      />
    </>
  );
};

export default ChallengeHeader;
