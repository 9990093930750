import Trans from "../Trans";
import {} from "../../styles";
// @ts-ignore
import {
  ErrorTitleWrapper,
  PaymentErrorMainTitle,
  PaymentErrorTitle,
  PaymentErrorWrapper,
} from "./style";

interface FailedRetryInfoProps {
  hasError: boolean;
  titleError?: string;
  titleErrorDescription?: string;
}

const FailedRetryInfo = ({
  hasError,
  titleError,
  titleErrorDescription,
}: FailedRetryInfoProps) => {
  if (!hasError) {
    return null;
  }

  return (
    <PaymentErrorWrapper>
      <ErrorTitleWrapper>
        <PaymentErrorMainTitle>
          <Trans message={titleError || ""} />
        </PaymentErrorMainTitle>
      </ErrorTitleWrapper>

      <PaymentErrorTitle>
        <Trans message={titleErrorDescription || "pleasePaymentAgain"} />
      </PaymentErrorTitle>
    </PaymentErrorWrapper>
  );
};

export default FailedRetryInfo;
