import styled from "styled-components";

export const CvvTooltipMainTitle = styled.p`
  span {
    font-family: var(--boldFont);
    color: #fff;
    font-size: 14px;
    line-height: 16.03px;
  }
`;

export const CvvTooltipSubTitle = styled.p`
  span {
    font-family: var(--regularFont);
    color: #fff;
    font-size: 14px;
    line-height: 16.03px;
  }
`;

export const FormSelectCvvWrapper = styled.div`
  border: none;
  flex-wrap: wrap;
  position: relative;

  width: 8px !important;
  margin-left: 10px;
  & > div {
    width: ${({ theme }) => theme?.inputCvvDateWidth};
  }
`;

export const TooltipCvvWrapper = styled.div`
  position: relative;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 42px;
`;

export const TooltipTextCvv = styled.div`
  width: 249px;
  font-size: 12px;
  top: -4px;
  right: 23px;
  background-color: #1a1a1a;
  color: #ffffff;
  margin-left: 7px;
  border-radius: 6px;
  padding: 12px 10px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);

  &::after {
    content: "";
    position: absolute;
    top: 12%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #1a1a1a;
  }
`;

export const FormIconCvvLock = styled.img``;
