import { FunctionalComponent } from "preact";
import arrowRightIcon from "../../../../assets/icons/arrowRignt.svg";
import defaultCard from "../../../../assets/icons/anotherCardPb.svg";
import deleteCardIcon from "../../../../assets/icons/deleteCardIconAsquad.svg";
import { CARD_BRANDS } from "../../../../constants/cardBrand";
import { COLORS } from "../../../../constants/themeConstants";
import { CardListProps } from "../../../commons/interfaces/CardInterfaces";
import {
  CardLabelDate,
  CardLabelNumber,
  SavedCardInner,
  SavedCardInnerWrapper,
  SavedCardWrapper,
} from "../../../commons/styles/ManageCardStyles";
import { RemoveCardManageWrapper } from "./style";

const CardList: FunctionalComponent<CardListProps> = ({
  isManage,
  card,
  handleCheckSavedCard,
  setIsPrevRemoveCard,
  setCurrentCard,
}) => (
  <div>
    <SavedCardWrapper key={card.last4}>
      <SavedCardInnerWrapper
        $height="64px"
        $backgroundColor="#05224C"
        $hoverColor="#10366B"
        onClick={() => handleCheckSavedCard(card)}
      >
        <img
          style={{ width: "64px", height: "32px" }}
          src={CARD_BRANDS[card.brand] || defaultCard}
          alt="brand"
        />
        <SavedCardInner>
          <CardLabelNumber
            $ml="0"
            $color={COLORS.DEFAULT_WHITE}
          >{`${card.bin?.slice(0, 4)} ${card.bin?.slice(
            4,
            6,
          )}** **** ${card?.last4}`}</CardLabelNumber>
          <CardLabelDate
            style={{
              color: "#fff",
              fontSize: "12px",
              width: "130px",
              marginLeft: "0",
            }}
          >
            <span style={{ color: "#FFFFFF99", fontSize: "12px" }}>
              Expiration:{" "}
            </span>
            {`${card?.exp_month}/${
              card?.exp_year?.length === 4
                ? card?.exp_year?.slice(2, 4)
                : card?.exp_year
            }`}
          </CardLabelDate>
        </SavedCardInner>
      </SavedCardInnerWrapper>
      <RemoveCardManageWrapper
        onClick={() => isManage && setIsPrevRemoveCard(true)}
      >
        {isManage ? (
          <img
            onClick={() => setCurrentCard(card)}
            style={{ cursor: "pointer" }}
            src={deleteCardIcon}
            alt="basket"
          />
        ) : (
          <img
            style={{ cursor: "pointer" }}
            src={arrowRightIcon}
            alt="basket"
          />
        )}
      </RemoveCardManageWrapper>
    </SavedCardWrapper>
  </div>
);

export default CardList;
