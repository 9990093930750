import { IPaymentInstructions } from "./interfaces";

const qrCodeChallenge: IPaymentInstructions = {
  title: "scanQr",
  isOrderedList: false,
  instructionList: [
    {
      getQR: ({ data = {} }) => {
        if (!data?.code || !data?.image) return undefined;

        return data.image;
      },
    },
  ],
  getExpirationDate: ({ data }) =>
    data?.expiration_date ? data?.expiration_date : null,
  getInfoTop: () => "scanQrTitleOpay",
  getInfo: () => "accountRequisitesMadeTransfer",
};

export default qrCodeChallenge;
