import { api, logger, localStorageService } from "../../../../services";
import { PMInstructionStepDataSubtext } from "../../components/Instruction/style";
import { IPaymentInstructions } from "./interfaces";

const accountRequisites: IPaymentInstructions = {
  title: "PMChallengeTitle",
  isOrderedList: true,
  instructionList: [
    {
      title: "PMaccountRequisitesChallengeAccountDetailsTitle",
      dataTitle: "PMaccountRequisitesChallengeAccountDetailsDataTitle",
      getPaymentData: ({ data = {} }) => data?.account,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.account,
        text: "accountRequisitesCopyAccountDetailsCopied",
      }),
    },
    {
      title: "PMaccountRequisitesChallengeAmountTitle",
      dataTitle: "PMaccountRequisitesChallengeAmountDataTitle",
      getPaymentData: ({ state = {} }) => (
        <>
          {state?.amount}{" "}
          <PMInstructionStepDataSubtext>
            {state?.currency}
          </PMInstructionStepDataSubtext>
        </>
      ),
      getCopyInfo: ({ state = {} }) => ({
        copy: state?.amount,
        text: "PMaccountRequisitesChallengeAmountDataTitleCopied",
      }),
    },
    {
      title: "PMaccountRequisitesChallengeThirdStepTitle",
    },
  ],
  getInfo: ({ data = {} }) =>
    !!data?.instruction ? "accountRequisitesMadeTransfer" : null,
  getExpirationDate: () => {
    let expirationDate;

    try {
      expirationDate = localStorageService.getSessionData(
        "sessionStorage",
        "expirationDate",
      )?.expirationDate;
    } catch (error) {
      logger.warn("Access to sessionStorage is denied", { error });
      return null;
    }

    if (!expirationDate) {
      const expirationTime = new Date(
        Date.now() + 10 * 60 * 1000,
      ).toISOString();

      try {
        localStorageService.setSessionData("sessionStorage", "expirationDate", {
          expirationDate: expirationTime,
        });
      } catch (error) {
        logger.warn("Failed to write to sessionStorage", { error });
      }

      return expirationTime;
    }

    return expirationDate;
  },
  submitAction: api.submitAccountRequisites,
};

export default accountRequisites;
