import { useContext, useRef } from "preact/hooks";
import { state } from "../../services";
import { Context } from "../../app";
import { Container, FormAmountOfPaymentWrapper } from "../Challenge/style";
// import AsyncComponent from "../AsyncComponent";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import FormHeaderDefault from "../../themes/default/components/FormHeader";
import FormHeaderKombine from "../../themes/kombine/components/FormHeader";
import FormHeaderPM from "../../themes/PM_brand/components/FormHeader";

const FormHeader = ({
  amount,
  currency,
  description,
  reference,
  detailedInfo,
  titleError,
  handleCopy,
  hasGradient,
  hasData,
  selectedMethod,
  titleErrorDescription,
}) => {
  const { getFormattedAmountParts } = useContext(Context);

  const notificationRef = useRef(null);

  const renderAmount = () => {
    if (amount) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return "";
  };

  const { wholeNumber, decimal } = getFormattedAmountParts(amount);

  const options = state.get().options;

  const themeForComponent = useThemeComponent("FormHeader");

  const FormHeaderComponent = {
    default: FormHeaderDefault,
    kombine: FormHeaderKombine,
    PM_brand: FormHeaderPM,
  }[themeForComponent];

  return (
    <Container>
      <div>
        <FormAmountOfPaymentWrapper>
          <FormHeaderComponent
            selectedMethod={selectedMethod}
            amount={amount}
            currency={currency}
            description={description}
            reference={reference}
            renderAmount={renderAmount}
            detailedInfo={detailedInfo}
            handleCopy={handleCopy}
            wholeNumber={wholeNumber}
            decimal={decimal}
            notificationRef={notificationRef}
            cancelUrl={options?.cancel_url}
            titleError={titleError}
            titleErrorDescription={titleErrorDescription}
            hasGradient={hasGradient}
            hasData={hasData}
            theme={options?.theme}
          />
          {/* <AsyncComponent
            importFn={(t) =>
              import(
                `../../themes/${themeForComponent}/components/FormHeader/index.tsx${t}`
              )
            }
            name="FormHeader"
          >
            {(FormHeaderComponent) => (
              <FormHeaderComponent
                selectedMethod={selectedMethod}
                titleErrorDescription={titleErrorDescription}
                amount={amount}
                currency={currency}
                description={description}
                reference={reference}
                renderAmount={renderAmount}
                detailedInfo={detailedInfo}
                handleCopy={handleCopy}
                wholeNumber={wholeNumber}
                decimal={decimal}
                notificationRef={notificationRef}
                cancelUrl={options?.cancel_url}
                titleError={titleError}
                hasGradient={hasGradient}
                hasData={hasData}
                theme={options?.theme}
              />
            )}
          </AsyncComponent> */}
        </FormAmountOfPaymentWrapper>
      </div>
    </Container>
  );
};

export default FormHeader;
