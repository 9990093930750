const InfoIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM13 16.5C13 17.1 12.6 17.5 12 17.5C11.4 17.5 11 17.1 11 16.5C11 15.4 11 13.1 11 12C11 11.4 11.4 11 12 11C12.6 11 13 11.4 13 12C13 13.1 13 15.4 13 16.5ZM10.7 8C10.7 7.3 11.3 6.7 12 6.7C12.7 6.7 13.3 7.3 13.3 8C13.3 8.7 12.7 9.3 12 9.3C11.3 9.3 10.7 8.7 10.7 8Z"
      fill="#2F8FC7"
    />
  </svg>
);

export default InfoIcon;
