import styled from "styled-components";
import { COLORS } from "../../../constants/themeConstants";

export const TransactionIDTitle = styled.span<{
  $fs?: string;
  $color?: string;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ $fs }) => $fs || "14px"};
  line-height: 16px;
  font-family: var(--regularFont);
  color: ${({ $color }) => $color || "#5a747a"};
`;

export const TransactionRefTitle = styled.span<{
  $fs?: string;
  $color?: string;
}>`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ $fs }) => $fs || "14px"};
  font-family: var(--boldFont);
  line-height: 16px;
  color: ${({ $color }) => $color || COLORS.DEFAULT_BLACK};
  margin-right: 8px;
`;

export const PaymentInfoBlock = styled.div<{
  $mb?: string;
  $alignItems?: string;
}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ $mb }) => $mb || "27px"};
  align-items: ${({ $alignItems }) => $alignItems || ""};
`;

export const TransactionTitle = styled.span<{
  $color?: string;
}>`
  font-style: normal;
  font-family: var(--boldFont);
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: ${({ $color }) => $color || "#5A747A"};
`;

export const TransactionInfoErrorBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 24px;
  background: #ffe0eb;
`;
