import styled from "styled-components";
import { PM_COLORS } from "../../enums";

export const PMModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  position: relative;
`;

export const PMModalHeaderTitle = styled.span`
  font-family: var(--boldFont);
  color: ${PM_COLORS.GRAY_DARK};
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.41px;
  text-align: center;
`;

export const PMModalHeaderActionButton = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    font-family: var(--regularFont);
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.32px;
    color: ${PM_COLORS.PRIMARY};
  }
`;

export const PMRadioItem = styled.div<{
  $isActive: boolean;
}>`
  background-color: ${({ $isActive }) =>
    $isActive ? PM_COLORS.DEFAULT_WHITE : PM_COLORS.GRAY_2};
  width: 24px;
  height: 24px;
  border: ${({ $isActive }) =>
    `8px solid ${$isActive ? PM_COLORS.PRIMARY : PM_COLORS.GRAY_2}`};
  transition: all 0.2s;
  border-radius: 50%;
`;

export const PMCardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: ${PM_COLORS.GRAY_DARK_2};
  border-radius: 12px;

  & > div {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      // TODO: COLORS PM
      background: #2929291a;
      width: calc(100% - 32px);
      height: 1px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
`;

export const PMCardListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0 16px;
  height: calc(100vh - 304px);
`;
