import styled, { keyframes } from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const LoaderContainer = styled.div<{
  $hasBlur?: boolean;
  theme?: {
    mainBackGroundColor?: string;
  };
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme?.mainBackGroundColor};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 24px;

  ${({ $hasBlur }) =>
    $hasBlur
      ? `
        background: rgba(240, 244, 245, 0.3);
        backdrop-filter: blur(5px);
      `
      : ``}
`;

export const LoaderPositionControl = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(50% + 56px);
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 24px;

  @media (max-height: 480px) {
    position: relative;
    bottom: auto;
    padding: 12px;
    height: 100%;
    gap: 12px;
    justify-content: center;
  }
`;

export const FormLoaderWrapper = styled.div<{
  height?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || "264px"};
  flex-direction: column;
`;

export const LoaderCenteredWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const rotating = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

export const AsquadLoaderContainer = styled.div<{
  $size?: "default" | "small";
}>`
  width: ${({ $size }) => ($size === "small" ? "24px" : "64px")};
  height: ${({ $size }) => ($size === "small" ? "24px" : "64px")};
  background: ${COLORS.DEFAULT_BLACK};
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotating} 1s linear infinite;

  ${({ $size }) => {
    if ($size === "small") {
      return `
        svg {
          height: 12px !important;
          width: 12px !important;
        }
      `;
    }

    return "";
  }}
`;

export const PMLoaderContainer = styled(AsquadLoaderContainer)<{
  $size?: "default" | "small";
}>`
  background: transparent;
  width: ${({ $size }) => ($size === "small" ? "24px" : "64px")};
  height: ${({ $size }) => ($size === "small" ? "24px" : "64px")};

  ${({ $size }) => {
    if ($size === "small") {
      return `
        svg {
          height: 20px !important;
          width: 20px !important;
        }
      `;
    }

    return "";
  }}
`;

export const LoaderExtraContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const LoaderTitle = styled.span`
  font-family: var(--mainFont);
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  color: ${COLORS.DEFAULT_BLACK};
`;

export const LoaderSubtitle = styled.span`
  font-family: var(--mainFont);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 32px;
  color: ${COLORS.GRAY};

  @media (max-height: 480px) {
    margin-bottom: 12px;
  }
`;

export const LoaderButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  gap: 12px;
`;
