import styled from "styled-components";
import { COLORS } from "../../../../constants/themeConstants";

export const CryptoFormContainer = styled.div<{
  $mr?: string;
  $pd?: string;
  $mb?: string;
}>`
  margin-right: ${({ $mr }) => $mr || ""};
  padding: ${({ $pd }) => ($pd ? $pd : "24px 16px")};
  margin-bottom: ${({ $mb }) => ($mb ? "16px" : "")};
  @media (min-width: 320px) and (max-width: 390px) {
    margin-bottom: ${({ $mb }) => ($mb ? "8px" : "")};
  }
  @media (min-width: 768px) {
    padding: ${({ $pd }) => ($pd ? $pd : "24px 32px")};
  }
`;

export const CryptoContentInfoTitle = styled.p<{
  $bold?: boolean;
}>`
  font-size: 14px;
  & span {
    letter-spacing: 0.1px;
    font-weight: ${({ $bold }) => ($bold ? "500" : "400")};
  }

  @media (min-width: 320px) and (max-width: 390px) {
    font-size: 12px;
  }
`;

export const CryptoInfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CryptoQrWrapper = styled.div`
  text-align: center;
`;

export const CryptoCurrencyWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  min-height: 24px;
`;

export const CryptoCurrencyTitle = styled.p<{
  $fs?: string;
  $ml?: string;
}>`
  color: #5a747a;
  font-size: ${({ $fs }) => $fs || "14px"};
  margin-left: ${({ $ml }) => $ml};
  margin-bottom: 8px;
  font-family: var(--regularFont);

  @media (min-width: 320px) and (max-width: 390px) {
    margin-bottom: 4px;
    font-size: 12px;
  }
`;

export const CryptoCurrencyLoaderWrapper = styled.div`
  div {
    max-width: 24px;
    max-height: 24px;
  }

  & > div > div {
    margin: 0 !important;
  }
`;

export const CryptoInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: #2929291a;
  border-radius: 12px;
  margin-bottom: 16px;
`;

export const DetailsCryptoTitle = styled.p<{
  $textAlign?: string;
  $mt?: string;
  $bold?: boolean;
}>`
  text-align: ${({ $textAlign }) => $textAlign || ""};
  font-size: 16px;
  line-height: 17px;
  color: #101820;
  letter-spacing: 0.3px;
  font-weight: ${({ $bold }) => ($bold ? 600 : 500)};
  margin-bottom: 22px;
  margin-top: ${({ $mt }) => $mt || ""};
  & span {
    letter-spacing: 0.3px;
  }

  @media (min-width: 320px) and (max-width: 390px) {
    margin-bottom: 16px;
    font-size: 14px;
    & span {
      letter-spacing: 0.1px;
    }
  }
`;

export const CryptoCurrencyBoldTitle = styled.span`
  font-family: var(--boldFont);
  font-weight: 700;
  color: #101820;
`;

export const CryptoCurrencyInputTitle = styled.span`
  color: #5a747a;
  font-family: var(--regularFont);
  font-size: 16px;
  @media (min-width: 320px) and (max-width: 390px) {
    font-size: 14px;
  }
`;

export const CryptoCurrencyInputTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CryptoValuesTitle = styled.p<{
  $textAlign?: string;
  $fs?: string;
  $mb?: string;
  $mt?: string;
  $bold?: boolean;
}>`
  flex: 1;
  text-align: ${({ $textAlign }) => $textAlign || ""};
  font-size: ${({ $fs }) => $fs || "13px"};
  line-height: 17px;
  color: #333f48;
  letter-spacing: 0.3px;
  font-weight: ${({ $bold }) => ($bold ? 600 : 400)};
  margin-bottom: ${({ $mb }) => $mb || ""};
  margin-top: ${({ $mt }) => $mt || ""};
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  min-width: 0;
  max-width: 352px;

  & span {
    letter-spacing: 0.1px;
  }

  @media (min-width: 320px) and (max-width: 390px) {
    font-size: 9px;
    max-width: 260px;
    font-weight: 600;
  }
`;

export const CryptoPaymentProgress = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 16px;
`;

export const CryptoPaymentProgressDescription = styled.span`
  font-family: var(--regularFont);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;

export const CryptoHelperBlock = styled.div<{
  $mb?: string;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 31;
  margin-bottom: ${({ $mb }) => $mb ?? ""};
`;

export const CryptoHelperBlockDescription = styled.span`
  font-family: var(--regularFont);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${COLORS.GRAY};
  letter-spacing: 0 !important;
`;

export const CryptoMinMaxTitle = styled.b<{
  $isAmountBelowMin?: boolean;
}>`
  color: ${({ $isAmountBelowMin }) =>
    $isAmountBelowMin ? "#EB0075" : "inherit"};
`;

export const CryptoHelperBlockIcon = styled.div`
  & > span,
  & > span > span {
    display: inline-flex;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CryptoCurrencyNotActiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px 0 0;
`;

export const LogoLangWrapper = styled.div<{
  $hasLogo?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  align-items: center;
  width: ${({ $hasLogo }) => ($hasLogo ? "100%" : "48px")};
`;

export const InputWrapper = styled.div<{
  $isAmountBelowMin?: boolean;
  $isFirstInput?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f6;
  border-top-left-radius: ${({ $isFirstInput }) =>
    $isFirstInput ? "12px" : "0px"};
  border-top-right-radius: ${({ $isFirstInput }) =>
    $isFirstInput ? "12px" : "0px"};
  border-bottom-left-radius: ${({ $isFirstInput }) =>
    $isFirstInput ? "0px" : "12px"};
  border-bottom-right-radius: ${({ $isFirstInput }) =>
    $isFirstInput ? "0px" : "12px"};
  padding: 7px 16px;
  height: 56px;
  border: ${({ $isAmountBelowMin }) =>
    $isAmountBelowMin ? "1px solid #EB0075" : "1px solid #F4F4F6"};
  box-shadow: ${({ $isAmountBelowMin }) =>
    $isAmountBelowMin ? "0px 0px 5px 0px #EB007580" : "none"};
`;

export const InputWrapperInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CurrencySymbol = styled.div`
  background-color: black;
  color: white;
  border-radius: 15px;
  font-size: 13px;
  line-height: 24px;
  width: 80px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;

  letter-spacing: 0.1px;
  font-size: clamp(11px, 14px - 1vw, 14px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 320px) and (max-width: 390px) {
    max-width: 75px;
    font-size: 11px;
  }
`;

export const AmountInput = styled.input<{
  $disabled?: boolean;
}>`
  border: ${({ $disabled }) => ($disabled ? "none" : "1px solid #BCBCBE")};
  outline: none;
  border-radius: 20px;
  padding: 8px 16px;
  text-align: end;
  height: 32px;
  max-width: 169px;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 0.1px;
  font-family: var(--boldFont);

  background-color: ${({ $disabled }) => ($disabled ? "#F4F4F6" : "white")};

  @media (min-width: 320px) and (max-width: 390px) {
    font-size: 14px;
  }
`;

export const AttentionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const AttentionTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.DANGER};
`;
