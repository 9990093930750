import { FunctionComponent } from "preact";
import { useState } from "preact/hooks";
import infoIcon from "../../../../assets/icons/dateTooltipIcon.svg";
import Trans from "../../../../components/Trans";
import {
  CvvTooltipMainTitle,
  TooltipContentWrapper,
} from "../../../commons/styles/InputStyles";
import { TooltipDateText } from "../../../../components/Tooltip/style";

const TooltipDateDefault: FunctionComponent = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <TooltipContentWrapper
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      <img src={infoIcon} />

      {tooltipVisible && (
        <TooltipDateText>
          <CvvTooltipMainTitle>
            <Trans message="dateTooltip" />
          </CvvTooltipMainTitle>
        </TooltipDateText>
      )}
    </TooltipContentWrapper>
  );
};

export default TooltipDateDefault;
