import { FunctionComponent } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import { PM_COLORS } from "../../enums";
import {
  CircleBg,
  CircleProgress,
  CircleWrapper,
  SvgCircle,
  TimeText,
} from "./style";

interface ExpirationDatePMProps {
  timeLeft: {
    difference: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
}

const ExpirationDatePM: FunctionComponent<ExpirationDatePMProps> = ({
  timeLeft,
}) => {
  const { difference, hours, minutes, seconds } = timeLeft || {};

  const [initialDifference, setInitialDifference] = useState(difference);

  useEffect(() => {
    if (difference > 0 && initialDifference === 0) {
      setInitialDifference(difference);
    }
  }, [difference, initialDifference]);

  const percentage = useMemo(() => {
    return (difference / initialDifference) * 100;
  }, [difference, initialDifference]);

  const strokeColor = useMemo(() => {
    if (difference <= 0) return "#FF3333";
    if (percentage <= 99.99) return "#F69B5A";
    return "#009E69";
  }, [percentage]);

  return (
    <CircleWrapper>
      <SvgCircle viewBox="0 0 100 100">
        <CircleBg stroke={strokeColor} cx="50" cy="50" r="45" />
        <CircleProgress
          cx="50"
          cy="50"
          r="45"
          stroke={difference <= 0 ? PM_COLORS.DANGER : PM_COLORS.GRAY}
          strokeDasharray="282.6"
          strokeDashoffset={-((difference <= 0 ? 0 : percentage) / 100) * 282.6}
        />
      </SvgCircle>
      <TimeText>
        {hours > 0 ? (hours < 10 ? `0${hours}:` : `${hours}:`) : null}
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </TimeText>
    </CircleWrapper>
  );
};

export default ExpirationDatePM;
