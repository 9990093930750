import ButtonUIDefault from "../../themes/default/ui/Button";
import ButtonUIPM from "../../themes/PM_brand/ui/Button";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import { IButtonProps } from "../../themes/commons/interfaces/Button";

const Button = ({
  children,
  onClick,
  variant,
  size,
  name,
  type,
  disabled,
  buttonRef,
  textTransform,
  isInIframe,
  "data-transaction-name": dataTransactionName,
}: IButtonProps) => {
  const themeForComponent = useThemeComponent("Button");

  const ButtonUI = {
    default: ButtonUIDefault,
    PM_brand: ButtonUIPM,
  }[themeForComponent];

  if (!ButtonUI) return null;

  return (
    <ButtonUI
      name={name}
      textTransform={textTransform}
      type={type}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
      buttonRef={buttonRef}
      isInIframe={isInIframe}
      data-transaction-name={dataTransactionName}
    >
      {children}
    </ButtonUI>
  );
};

export default Button;
