import accountRequisites from "./accountRequisites";
import bankCodeChallenge from "./bankCodeChallenge";
import bankTransferAfrica from "./bankTransferAfrica";
import crypto from "./crypto";
import p2cChallenge from "./p2cChallenge";
import selectAmountChallenge from "./selectAmountChallenge";
import walletOpayQr from "./walletOpayQr";
import qrCodeChallenge from "./qrCodeChallenge";
import bankAccountIndonesia from "./bankAccountIndonesia";
import expirationDate from "./expirationDate";

export default {
  account_requisites: accountRequisites,
  p2c: p2cChallenge,
  bank_transfer_africa: bankTransferAfrica,
  bank_code_challenge: bankCodeChallenge,
  select_amount_challenge: selectAmountChallenge,
  crypto: crypto,
  wallet_opay_qr: walletOpayQr,
  qr_code: qrCodeChallenge,
  bank_account_indonesia: bankAccountIndonesia,
  expirationDate: expirationDate,
};
