import { FunctionComponent } from "preact";
import ModalItem from "../../../../components/Modals/ModalComponents/ModalItem";
import Trans from "../../../../components/Trans";
import { CARD_BRANDS } from "../../../../constants/cardBrand";
import { COLORS } from "../../../../constants/themeConstants";
import { ManageCardProps } from "../../../commons/interfaces/CardInterfaces";
import {
  CardLabelDate,
  CardLabelNumber,
  CardLabelNumberContainer,
  ActionButtonCardEditWrapper,
  ActionButtonCardList,
  DeleteCardPopupTitle,
} from "../../../commons/styles/ManageCardStyles";

const ManageCard: FunctionComponent<ManageCardProps> = ({
  card,
  handleRemoveCard,
  handleCancel,
}) => (
  <>
    {/* @ts-ignore */}
    <ModalItem
      icon={CARD_BRANDS[card?.brand]}
      iconWidth="32px"
      hasArray={false}
    >
      <CardLabelNumberContainer>
        <CardLabelNumber>{`${card?.bin?.toString()?.slice(0, 4)} ${card?.bin
          ?.toString()
          ?.slice(4, 6)}** **** ${card?.last4}`}</CardLabelNumber>
      </CardLabelNumberContainer>
      <CardLabelDate style={{ marginRight: 0, marginLeft: "auto" }}>{`${
        card?.exp_month
      }/${
        card?.exp_year?.length === 4
          ? card?.exp_year?.toString()?.slice(2, 4)
          : card.exp_year
      }`}</CardLabelDate>
    </ModalItem>
    <DeleteCardPopupTitle>
      <Trans message="dltCardSystemTitle" withoutWrapper />
    </DeleteCardPopupTitle>
    <ActionButtonCardEditWrapper>
      <ActionButtonCardList
        type="button"
        $background={COLORS.LIGHT_GRAY}
        $color={COLORS.GRAY}
        onClick={handleCancel}
      >
        <Trans message="cancel" />
      </ActionButtonCardList>

      <ActionButtonCardList
        type="button"
        $background={COLORS.DANGER}
        $color={COLORS.DEFAULT_WHITE}
        $width="288px"
        onClick={() => handleRemoveCard(card.identifier)}
      >
        <Trans message="delete" />
      </ActionButtonCardList>
    </ActionButtonCardEditWrapper>
  </>
);

export default ManageCard;
