import styled from "styled-components";

export const InfoCopyBoldTitle = styled.span`
  margin-left: 6px;
  font-weight: 600;
`;

export const MethodIconInfoWrapper = styled.div`
  margin: 20px 0px 16px 0px;

  img {
    max-height: 24px;
  }
`;

export const ListItemInfoCopyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
