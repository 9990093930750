import { FunctionComponent } from "preact";
import { state } from "../../../../services";
import Header from "../../../../components/Header";
import PaymentDetails from "../../../../components/PaymentDetails";
import { HelperForChooseMethod } from "../../../commons/styles/FormStyles";
import testfile from "../../../../testfile";
import Trans from "../../../../components/Trans";

interface PaymentDetailItem {
  label: string;
  name: string;
  value: string;
  condition?: boolean;
}

interface FormHeaderProps {
  reference: string;
  description: string;
  detailedInfo: PaymentDetailItem[];
  wholeNumber: string;
  decimal: string;
  currency: string;
  titleError?: string;
  hasGradient?: boolean;
  hasData: boolean;
  selectedMethod?: string | null;
  titleErrorDescription?: string;
}

const FormHeader: FunctionComponent<FormHeaderProps> = ({
  reference,
  description,
  detailedInfo,
  wholeNumber,
  decimal,
  currency,
  titleError,
  hasGradient,
  hasData,
  selectedMethod,
  titleErrorDescription,
}) => {
  const canReturnToMethods = state.hasPaymentMethodsToShow();

  const hasError = !canReturnToMethods && !!titleError;

  return (
    <>
      <Header hasGradient={hasGradient} />
      {hasData ? (
        <PaymentDetails
          titleError={titleError}
          detailedInfo={detailedInfo}
          reference={reference}
          description={description}
          wholeNumber={wholeNumber}
          decimal={decimal}
          currency={currency}
          titleErrorDescription={titleErrorDescription}
        />
      ) : null}

      {!selectedMethod && !hasError ? (
        <HelperForChooseMethod
          onClick={() => {
            testfile.test();
          }}
        >
          <Trans message="chooseMethod" />
        </HelperForChooseMethod>
      ) : null}
    </>
  );
};

export default FormHeader;
