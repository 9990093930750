import accountRequisites from "./account_requisites";
import bankCodeChallenge from "./bankCodeChallenge";
import cryptoCurrencyNotActive from "./crypto_currency_not_active";
import p2cChallenge from "./p2cChallenge";
import selectAmountChallenge from "./selectAmountChallenge";
import bankTransferAfrica from "./bankTransferAfrica";
import bankAccountIndonesia from "./bankAccountIndonesia";

export default {
  account_requisites: accountRequisites,
  crypto_currency_not_active: cryptoCurrencyNotActive,
  select_amount_challenge: selectAmountChallenge,
  bank_code_challenge: bankCodeChallenge,
  p2c: p2cChallenge,
  bank_transfer_africa: bankTransferAfrica,
  bank_account_indonesia: bankAccountIndonesia,
};
