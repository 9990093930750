import logger from "../../services/logger";
import googlePay from "../../services/googlePay";
import { GoogleApplePayTestButton } from "./style";
import { useEffect, useRef } from "preact/hooks";
import "@google-pay/button-element";

const GooglePay = ({ amount, currency, description, identity, locale }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const mapMethods = identity?.allowed_auth_methods?.map((method) =>
      method?.toUpperCase(),
    );

    const mapNetworks = identity?.allowed_card_networks?.map((network) =>
      network?.toUpperCase(),
    );

    const paymentRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: mapMethods,
            allowedCardNetworks: mapNetworks,
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: identity?.gateway,
              gatewayMerchantId: identity?.gateway_merchant_id,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId:
          identity?.merchant_name === "Ibox Gpay"
            ? "01415593990397374268"
            : identity?.merchant_id,
        merchantName: identity?.merchant_name,
      },
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPriceLabel: description?.toString() || "",
        totalPrice: amount?.toString(),
        currencyCode: currency?.toString(),
        countryCode: identity?.country,
      },
    };

    const onLoadPaymentData = (event) => {
      const paymentData = event.detail;

      if (paymentData) {
        googlePay.onGooglePayButtonClick({
          paymentRequest: paymentData,
        });
      }
    };

    const onError = (event) => {
      const error = event.detail;
      logger.error(error);
    };

    if (buttonRef.current) {
      const googlePayButton = document.createElement("google-pay-button");
      googlePayButton.setAttribute(
        "environment",
        identity?.environment?.toUpperCase(),
      );
      googlePayButton.setAttribute("button-type", "buy");
      googlePayButton.setAttribute("button-size-mode", "fill");
      googlePayButton.setAttribute("button-locale", locale);
      googlePayButton.setAttribute("style", "width: 100%; height: 40px;");
      googlePayButton.paymentRequest = paymentRequest;
      googlePayButton.addEventListener("loadpaymentdata", onLoadPaymentData);
      googlePayButton.addEventListener("error", onError);
      buttonRef.current.appendChild(googlePayButton);
    }
  }, [amount, currency, description, identity, locale]);

  return <GoogleApplePayTestButton ref={buttonRef} />;
};

export default GooglePay;
