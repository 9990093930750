import {
  CurrencyImage,
  StyledSvgColoredImage,
  StyledPaymentIconWrapper,
} from "./style";
import ussdIcon from "../../assets/icons/ussdDefaultIcon.svg";
import bankTransferIcon from "../../assets/icons/bankTransferIcon.svg";
import { PAYMENT_METHODS_ICON } from "../../constants/paymentMethods";
import { FunctionComponent, JSX } from "preact";

interface PaymentMethodsLogoProps {
  selectedMethod?: string;
  resize?: {
    width?: string;
    height?: string;
  };
  coloredIcon?: boolean;
  hideIcon?: boolean;
  isBankCode?: boolean;
  channel?: { ui_code?: string };
  method?: string;
}

const PaymentMethodsLogo: FunctionComponent<PaymentMethodsLogoProps> = ({
  selectedMethod,
  resize,
  coloredIcon,
  hideIcon,
  isBankCode,
  channel,
  method,
}) => {
  if (hideIcon) {
    return null;
  }

  const defaultIcon = isBankCode ? bankTransferIcon : ussdIcon;
  const isDefaultChannelIcon =
    channel &&
    !PAYMENT_METHODS_ICON[
      channel?.ui_code as keyof typeof PAYMENT_METHODS_ICON
    ] &&
    method;

  const lowerCaseMethod =
    selectedMethod?.toLowerCase() as keyof typeof PAYMENT_METHODS_ICON;

  let selectedMethodIcon =
    lowerCaseMethod && PAYMENT_METHODS_ICON[lowerCaseMethod];

  if (!selectedMethodIcon) {
    const formattedMethod = selectedMethod
      ?.toLowerCase()
      ?.replace(/\s+/g, "_") as keyof typeof PAYMENT_METHODS_ICON;

    selectedMethodIcon = formattedMethod
      ? PAYMENT_METHODS_ICON[formattedMethod]
      : defaultIcon;
  }

  selectedMethodIcon = selectedMethodIcon || defaultIcon;

  let icon: string | JSX.Element = defaultIcon;

  if (selectedMethodIcon) {
    if (typeof selectedMethodIcon === "object") {
      icon =
        resize && selectedMethodIcon.big
          ? selectedMethodIcon.big
          : selectedMethodIcon.small;
    } else if (typeof selectedMethodIcon === "function") {
      icon = selectedMethodIcon();
    } else {
      icon = selectedMethodIcon;
    }
  }

  if (isDefaultChannelIcon) {
    const methodIcon =
      PAYMENT_METHODS_ICON[method as keyof typeof PAYMENT_METHODS_ICON];

    return (
      <StyledSvgColoredImage>
        {typeof methodIcon === "function" ? (
          methodIcon()
        ) : (
          <CurrencyImage src={methodIcon as string} alt={selectedMethod} />
        )}
      </StyledSvgColoredImage>
    );
  }

  return (
    <StyledPaymentIconWrapper
      data-transaction-name="method-logo"
      $resize={resize}
    >
      {coloredIcon && typeof selectedMethodIcon === "function" ? (
        <StyledSvgColoredImage>{icon}</StyledSvgColoredImage>
      ) : (
        <CurrencyImage
          $resize={resize}
          src={icon as string}
          alt={selectedMethod}
        />
      )}
    </StyledPaymentIconWrapper>
  );
};

export default PaymentMethodsLogo;
