import { FunctionComponent } from "preact";
import { useContext, useMemo } from "preact/hooks";
import CustomInfoBlocks from "../CustomInfoBlock";
import Button from "../../../../components/Button";
import Trans from "../../../../components/Trans";
import { Context } from "../../../../app";
import expirationDate from "../../configs/instructions/expirationDate";
import { PMInnerHeaderDataWrapper } from "../Instruction/style";

interface ExpirationDateInfoProps {
  cancelUrl?: string | undefined;
  instructionData: Record<string, string>;
}
export type TInstructionData = { [key: string]: string | boolean | number };

const ExpirationDateInfoPM: FunctionComponent<ExpirationDateInfoProps> = ({
  cancelUrl,
  instructionData = {},
}) => {
  const { onGoBackButtonClick } = useContext(Context);
  const customInfoBlocks = useMemo(() => {
    const blocks = expirationDate.getCustomInfoBlocks?.() || [];

    return blocks.map((block) => {
      return {
        ...block,
        content: block.content({ data: instructionData }),
      };
    });
  }, [expirationDate, instructionData]);
  return (
    <PMInnerHeaderDataWrapper>
      <CustomInfoBlocks
        customInfoBlocks={customInfoBlocks}
        instructionData={instructionData}
      />

      <Button
        textTransform="inherit"
        onClick={() => cancelUrl && onGoBackButtonClick(cancelUrl)}
      >
        <Trans message="goBack" />
      </Button>
    </PMInnerHeaderDataWrapper>
  );
};

export default ExpirationDateInfoPM;
