import DataPairsViewBuilder from "../InfoViewBuilder";
import { DISPLAY_PAYMENT_METHODS } from "../../../constants/paymentMethods";
import Trans from "../../Trans";
import { useContext } from "preact/hooks";
import { Context } from "../../../app";
import {
  BaseButton,
  ButtonWrapper,
} from "../../../themes/commons/styles/ButtonStyles";

const PayCode = ({ handleCopy, selectedMethod, cancelUrl, challenge }) => {
  const { onGoBackButtonClick } = useContext(Context);
  const data = challenge?.pay_code || {};

  const { expiration_date } = data;
  const subTitlesConfig = {
    pay_code_palmpay: "PalmPay APP",
  };

  const config = [
    {
      title: "code",
      key: "code",
      copyMessage: "codeCopied",
    },
  ];
  const steps = [
    {
      number: 1,
      title: "Copy",
      subTitle: `${DISPLAY_PAYMENT_METHODS[selectedMethod]}`,
    },
    {
      number: 2,
      title: "openThe",
      subTitle: `${subTitlesConfig[selectedMethod]}`,
    },
    { number: 3, title: "pastePaymentCode" },
  ];

  return (
    <>
      <DataPairsViewBuilder
        config={config}
        data={data}
        steps={steps}
        handleCopy={handleCopy}
        infoMessage="mobileMoneyInfoTitle"
        expiration_date={expiration_date}
        title="howToUse"
        subTitle={DISPLAY_PAYMENT_METHODS[selectedMethod]}
        showLine={true}
      />
      {cancelUrl ? (
        <ButtonWrapper>
          <BaseButton
            $textTransform="initial"
            onClick={() => onGoBackButtonClick(cancelUrl)}
            $mb="22px"
          >
            <Trans message="iHaveMadePayment" />
          </BaseButton>
        </ButtonWrapper>
      ) : null}
    </>
  );
};

export default PayCode;
