import styled from "styled-components";

interface OuterBlockHeaderProps {
  $maxWidth?: string;
}

export const OuterBlockHeader = styled.div<OuterBlockHeaderProps>`
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth || "440px"};
  margin: 0 auto;
  position: relative;
`;

interface HeaderLogoWrapperProps {
  $pt?: string;
  $pb?: string;
  $alignItems?: string;
  $display?: string;
  $hasGradient?: boolean;
  $maxWidth?: string;
}

export const HeaderLogoWrapper = styled.div<HeaderLogoWrapperProps>`
  background-color: ${(props) =>
    props.theme?.formHeaderBackground || "#0062eb"};
  margin: 0 auto;
  padding: ${({ $pt, $pb }) => `${$pt || "24px"} 32px ${$pb || "24px"}`};
  text-align: center;
  position: relative;
  width: 100%;
  align-items: ${({ $alignItems }) => $alignItems || "center"};
  display: ${({ $display }) => $display || "flex"};
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  & > * {
    z-index: 1;
  }

  ${({ $hasGradient, theme, $maxWidth }) => {
    if ($hasGradient) {
      return `
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: calc(100% - 38px);
          background: ${
            theme?.headerGradient ||
            "radial-gradient(at top, #0062eb, #0062eb 92%, transparent 50%)"
          };
          z-index: 0;
          height: 78px;
          width: 100%;
          max-width: ${$maxWidth || "440px"};
        }
      `;
    }
  }}

  @media (min-width: 768px) {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  @media (max-height: 768px) {
    padding: ${({ $pt, $pb }) => `${$pt || "16px"} 32px ${$pb || "8px"}`};
    gap: 8px;
  }

  @media (max-height: 768px) and (max-width: 768px) {
    padding: ${({ $pt, $pb }) => `${$pt || "16px"} 16px ${$pb || "8px"}`};
  }
`;

export const LogoBrandHeader = styled.img`
  max-width: 320px;
  height: 36px;
`;

export const TotalInfoWrapper = styled.div<{
  $mt?: string;
  $pd?: string;
}>`
  margin-top: ${({ $mt }) => $mt || ""};
  display: flex;
  width: 100%;
  padding: ${({ $pd }) => $pd || "12px"};
  justify-content: space-between;
  align-items: center;

  @media (max-height: 768px) {
    padding: ${({ $pd }) => $pd || "8px 12px"};
  }
`;
