import styled from "styled-components";

export const HelperForChooseMethod = styled.p`
  font-size: 16px;
  font-family: var(--regularFont);
  width: 70%;
  margin: 0 auto;
`;

export const ErrorTitle = styled.p<{
  $textAlign?: string;
  $ml?: string;
  $mb?: string;
  $color?: string;
}>`
  font-style: normal;
  font-family: var(--regularFont);
  font-weight: 600;
  font-size: 14px;
  text-align: ${({ $textAlign }) => $textAlign || ""};
  line-height: 16px;
  margin-left: ${({ $ml }) => $ml || ""};
  margin-bottom: ${({ $mb }) => $mb || ""};
  color: ${({ $color }) => $color || "#101820"};
`;

export const SavedCardMobileWrapper = styled.div<{
  width?: string;
}>`
  font-size: 14px;
  color: #0062eb;
  display: flex;
  padding: 4px 12px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 20px;
  max-width: ${({ width }) => width || "170px"};
  font-family: var(--regularFont);
  cursor: pointer;
`;
