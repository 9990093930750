import { FunctionComponent } from "preact";
import {
  ButtonWrapper,
  PMInfoWrapper,
  PMInnerHeaderDataWrapper,
  PMInstructionStepNumber,
  PMInstructionStepTitle,
  PMInstructionStepTitleWrapper,
  PMInstructionStepWrapper,
  PMInstructionTimerWrapper,
  PMBlockLine,
  PMInstructionTitle,
  PMInstructionWrapper,
} from "./style";
import Trans from "../../../../components/Trans";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import ExpirationDate from "../../../../components/BankInfo/ExpirationDate";
import {
  PMItemContent,
  PMItemDetails,
  PMItemIconAction,
  PMItemIconActionTitle,
  PMItemName,
  PMItemDescription,
} from "../SavedCard/style";
import CopyIcon from "../../../../assets/icons/brand/pm/ui/CopyIcon";
import { InstructionStepSecondaryTitle } from "../../../default/components/Instruction/style";
import { IPaymentInstructions } from "../../configs/instructions/interfaces";
import FormInfoBlockPM from "../FormInfoBlock";
import { useContext, useMemo, useRef } from "preact/hooks";
import { ContextUI } from "../../../../providers/UIProvider";
import CustomInfoBlock from "../CustomInfoBlock";
import QrCodeInstruction from "./Qr";
import Button from "../../../../components/Button";

interface InstructionPMProps {
  title?: string;
  instructionList?: any[];
  info?: string | null;
  expirationDate?: string | null;
  infoBlockHasBottomPosition?: boolean;
  instructionData: Record<string, string>;
  currentState: any;
  handleCopy: (copy: string, text: string) => void;
  config: IPaymentInstructions;
  handleButtonClick?: () => void;
  buttonText?: string;
  customComponent?: React.ReactNode;
}

const InstructionPM: FunctionComponent<InstructionPMProps> = ({
  instructionList,
  info,
  expirationDate,
  infoBlockHasBottomPosition = true,
  instructionData,
  currentState,
  config,
  handleButtonClick,
  buttonText,
  customComponent,
}) => {
  const ref = useRef(null);

  const customInfoBlocks = useMemo(() => {
    const blocks = config.getCustomInfoBlocks?.() || [];

    return blocks.map((block) => {
      return {
        ...block,
        content: block.content({ data: instructionData }),
      };
    });
  }, [config, instructionData]);

  const currentTitle =
    config.getTitle && config.getTitle({ data: instructionData });

  const { handleCopy } = useContext(ContextUI);
  const qrs = useMemo(
    () => instructionList?.filter((item) => item.getQR),
    [instructionList],
  );
  const steps = useMemo(
    () => instructionList?.filter((item) => !item.getQR),
    [instructionList],
  );

  const infoForTop = useMemo(() => {
    if (config.getInfoTop && typeof config.getInfoTop === "function") {
      return config.getInfoTop({ data: instructionData });
    }

    return null;
  }, [config]);

  const renderInfo = (customInfo?: string) => {
    if (customInfo || info) {
      return (
        <PMInfoWrapper
          $margin={
            customInfo
              ? "0"
              : infoBlockHasBottomPosition
                ? "12px auto 0"
                : "0 auto 12px"
          }
        >
          <FormInfoBlockPM
            type="info"
            currentInfo={{
              content: () => <Trans message={customInfo || info || ""} />,
            }}
          />
        </PMInfoWrapper>
      );
    }
    return null;
  };

  return (
    <PMInnerHeaderDataWrapper ref={ref}>
      {config?.title && (
        <PMInstructionTitle style={{ textAlign: "center" }}>
          <Trans message={config?.title} />
        </PMInstructionTitle>
      )}

      <CustomInfoBlock
        customInfoBlocks={customInfoBlocks}
        instructionData={instructionData}
      />

      {customComponent}

      {!infoBlockHasBottomPosition && renderInfo()}

      {currentTitle && (
        <PMInstructionTitle>
          <Trans
            message={currentTitle?.message}
            components={[...(currentTitle?.components || [])]}
            params={{ ...(currentTitle?.params || {}) }}
            withoutWrapper
          />
        </PMInstructionTitle>
      )}

      {infoForTop && renderInfo(infoForTop || "")}
      {steps && steps.length > 0 && (
        <PMInstructionWrapper $isOrderedList={config.isOrderedList}>
          {steps.map((step, index) => {
            const {
              title,
              dataTitle,
              secondaryTitle,
              getPaymentData,
              getCopyInfo,
            } = step || {};

            const paymentData =
              getPaymentData &&
              getPaymentData({
                data: instructionData,
                state: currentState || {},
              });

            const { copy, text, iconTitle } =
              (getCopyInfo &&
                getCopyInfo({
                  data: instructionData,
                  state: currentState || {},
                })) ||
              {};

            return (
              <PMInstructionStepWrapper
                $isOrderedList={
                  config?.isOrderedList && config?.isPaymentDataList
                }
                key={index}
              >
                {title && (
                  <PMInstructionStepTitleWrapper>
                    {config.isOrderedList && (
                      <PMInstructionStepNumber>
                        {index + 1}
                      </PMInstructionStepNumber>
                    )}

                    <PMInstructionStepTitle>
                      <Trans message={title} withoutWrapper />
                      {secondaryTitle && (
                        <InstructionStepSecondaryTitle
                          bold={secondaryTitle?.bold}
                        >
                          <Trans message={secondaryTitle.text} />
                        </InstructionStepSecondaryTitle>
                      )}
                    </PMInstructionStepTitle>
                  </PMInstructionStepTitleWrapper>
                )}

                {paymentData && (
                  <>
                    <PMItemContent $cursor="default">
                      <PMItemDetails>
                        {dataTitle && (
                          <PMItemDescription>
                            <Trans message={dataTitle} withoutWrapper />
                          </PMItemDescription>
                        )}
                        <PMItemName>{paymentData}</PMItemName>
                      </PMItemDetails>
                      <PMItemIconAction
                        $iconTitle={iconTitle}
                        onClick={() => handleCopy(copy, text)}
                      >
                        {iconTitle && (
                          <PMItemIconActionTitle>
                            <Trans message={iconTitle} />
                          </PMItemIconActionTitle>
                        )}
                        <Tooltip text={"Сopy"}>
                          <CopyIcon />
                        </Tooltip>
                      </PMItemIconAction>
                    </PMItemContent>
                    {index !== steps.length - 1 && <PMBlockLine />}
                  </>
                )}
              </PMInstructionStepWrapper>
            );
          })}
        </PMInstructionWrapper>
      )}
      {qrs && qrs.length > 0 && (
        <QrCodeInstruction
          qrs={qrs}
          instructionData={instructionData}
          handleButtonClick={handleButtonClick}
          buttonText={buttonText}
        />
      )}

      {expirationDate && (
        <PMInstructionTimerWrapper>
          <ExpirationDate expiration_date={expirationDate} />
        </PMInstructionTimerWrapper>
      )}
      {infoBlockHasBottomPosition && renderInfo()}

      {handleButtonClick && !qrs?.length && (
        <ButtonWrapper>
          <Button textTransform="inherit" onClick={handleButtonClick}>
            <Trans message={buttonText || ""} />
          </Button>
        </ButtonWrapper>
      )}
    </PMInnerHeaderDataWrapper>
  );
};

export default InstructionPM;
