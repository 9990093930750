const CopyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 2H10C9.20435 2 8.44129 2.31605 7.87868 2.87866C7.31607 3.44127 7 4.20435 7 5V6H6C5.20435 6 4.44129 6.31605 3.87868 6.87866C3.31607 7.44127 3 8.20435 3 9V19C3 19.7956 3.31607 20.5587 3.87868 21.1213C4.44129 21.6839 5.20435 22 6 22H13C13.7956 22 14.5587 21.6839 15.1213 21.1213C15.6839 20.5587 16 19.7956 16 19V18H18C18.7956 18 19.5587 17.6839 20.1213 17.1213C20.6839 16.5587 21 15.7956 21 15V5C21 4.20435 20.6839 3.44127 20.1213 2.87866C19.5587 2.31605 18.7956 2 18 2ZM14 19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V9C5 8.73478 5.10536 8.48044 5.29289 8.29291C5.48043 8.10537 5.73478 8 6 8H7V15C7 15.7956 7.31607 16.5587 7.87868 17.1213C8.44129 17.6839 9.20435 18 10 18H14V19ZM19 15C19 15.2652 18.8946 15.5196 18.7071 15.7071C18.5196 15.8946 18.2652 16 18 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15V5C9 4.73478 9.10536 4.48044 9.29289 4.29291C9.48043 4.10537 9.73478 4 10 4H18C18.2652 4 18.5196 4.10537 18.7071 4.29291C18.8946 4.48044 19 4.73478 19 5V15Z"
      fill="#7B756B"
    />
  </svg>
);

export default CopyIcon;
