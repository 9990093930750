import { IconColorWrapper } from "../../styles";
import Tooltip from "../Tooltip/Tooltip";
import Trans from "../Trans";
import IconCopy from "../../assets/icons/iconCopy";
import IconCopyKombine from "../../assets/icons/iconCopyCombine";
import {
  BlockDetailsWrapper,
  DetailsTransactionSubTitle,
  DetailsTransactionTitle,
} from "../BankInfo/style";
import { DetailsTransactionSubTitleWrapper } from "./ChallengeControlHeader/style";
import { CopyActionWrapper, CopyWrapper } from "../PaymentDetails/style";

const HeaderField = ({
  field,
  data,
  handleCopy,
  truncateText,
  isDetailedInfo,
  colorMainTitle,
  colorSubtitle,
  titleFs,
  showAnotherIconCopy,
}) => {
  if (!field.condition(data)) {
    return null;
  }

  const value = field.value(data);

  return (
    <BlockDetailsWrapper key={field.key}>
      <DetailsTransactionTitle
        $titleFs={titleFs}
        $color={colorMainTitle}
        $controlledLength
      >
        <Trans message={field.label} />
      </DetailsTransactionTitle>
      <DetailsTransactionSubTitleWrapper $flex={field.copyable}>
        <DetailsTransactionSubTitle
          $titleFs={titleFs}
          $color={colorSubtitle}
          $mr="8px"
        >
          {isDetailedInfo ? value : truncateText(value)}
        </DetailsTransactionSubTitle>
        {field.copyable && (
          <CopyWrapper>
            <CopyActionWrapper
              onClick={() => handleCopy(value, field.copyText)}
            >
              <IconColorWrapper>
                <Tooltip text={"Сopy"}>
                  {showAnotherIconCopy ? <IconCopyKombine /> : <IconCopy />}
                </Tooltip>
              </IconColorWrapper>
            </CopyActionWrapper>
          </CopyWrapper>
        )}
      </DetailsTransactionSubTitleWrapper>
    </BlockDetailsWrapper>
  );
};

export default HeaderField;
