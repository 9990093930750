import styled from "styled-components";

export const PendingWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  gap: 24px;
`;

export const PendingPageTitle = styled.p`
  color: ${({ theme }) => theme?.successTitleColor};
  font-size: 20px;
  text-align: center;
`;
