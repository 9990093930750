import applePay from "../../services/applePay";
import testData from "../../services/testData";
import {
  MANUALLY_PROCESSED_PAYMENT_METHODS,
  PAYMENT_METHODS,
} from "../../constants/paymentMethods";
import { getFields } from "../Form/FormFields";
import { state } from "../../services";
import AltMethods from "../AltMethods";
import FormInfoBlock from "./FormInfoBlock";
import Form from "../Form/Form";
import PaymentMethods from "../PaymentMethods/PaymentMethods";

const FormContent = ({
  amount,
  description,
  reference,
  method,
  currency,
  options,
  apple_pay,
  google_pay,
  themeConfig,
  sessionId,
  signature,
  status,
  fieldSources,
  onFormSubmit,
  titleError,
  titleErrorDescription,
}) => {
  const { card, theme } = options || {};
  const { enabled_apple_pay } = card || {};

  const selectedMethod = state.getSelectedPaymentMethod();

  const isTestMode = testData.isTestMode(sessionId, signature);

  // apple pay

  const hasApplePayButton =
    (method === "card" && enabled_apple_pay) ||
    method === PAYMENT_METHODS.APPLE_PAY;

  const hasApplePayError =
    hasApplePayButton &&
    (!applePay.isAllowedToShow() || (apple_pay && apple_pay.error));

  const fallbackToCardForm =
    amount &&
    method === PAYMENT_METHODS.APPLE_PAY &&
    hasApplePayError &&
    !isTestMode;

  const showForm =
    amount && MANUALLY_PROCESSED_PAYMENT_METHODS.includes(method);

  const isFormDisplaying = showForm || fallbackToCardForm;

  const fields = getFields({
    sources: fieldSources,
    selectedMethod: selectedMethod?.method,
    selectedProvider: selectedMethod?.provider,
    selectedMethodData: selectedMethod,
    theme,
  });

  return (
    <>
      <AltMethods
        amount={amount}
        currency={currency}
        description={description}
        reference={reference}
        method={method}
        options={options}
        status={status}
        themeConfig={themeConfig}
        apple_pay={apple_pay}
        google_pay={google_pay}
        sessionId={sessionId}
        signature={signature}
        showForm={showForm}
      />

      {showForm && (
        <FormInfoBlock
          fields={fields}
          hasContainer
          titleError={titleError}
          titleErrorDescription={titleErrorDescription}
        />
      )}

      {method && isFormDisplaying && (
        <Form onSubmit={onFormSubmit} fieldSources={fieldSources} />
      )}

      {(!method || !isFormDisplaying) && <PaymentMethods />}
    </>
  );
};

export default FormContent;
