import { IconColorWrapper } from "../../styles";
import {
  CryptoCurrencyTitle as CryptoCurrencyTitleDefault,
  CryptoValuesTitle as CryptoValuesTitleDefault,
  CryptoFormContainer as CryptoFormContainerDefault,
  CryptoQrWrapper as CryptoQrWrapperDefault,
} from "../../themes/default/components/CryptoForm/style";
import {
  CryptoCurrencyTitle as CryptoCurrencyTitlePM,
  CryptoValuesTitle as CryptoValuesTitlePM,
  CryptoFormContainer as CryptoFormContainerPM,
  CryptoQrWrapper as CryptoQrWrapperPM,
} from "../../themes/PM_brand/components/CryptoForm/style";
import Trans from "../Trans";
import IconCopy from "../../assets/icons/iconCopy";
import { useThemeComponent } from "../../hooks/useThemeComponent";
const Qr = ({ address, address_qr_code_image, address_tag, handleCopy }) => {
  const themeForComponent = useThemeComponent("CryptoForm");

  const CryptoCurrencyTitle =
    {
      default: CryptoCurrencyTitleDefault,
      PM_brand: CryptoCurrencyTitlePM,
    }[themeForComponent] || CryptoCurrencyTitleDefault;

  const CryptoValuesTitle =
    {
      default: CryptoValuesTitleDefault,
      PM_brand: CryptoValuesTitlePM,
    }[themeForComponent] || CryptoValuesTitleDefault;

  const CryptoFormContainer =
    {
      default: CryptoFormContainerDefault,
      PM_brand: CryptoFormContainerPM,
    }[themeForComponent] || CryptoFormContainerDefault;

  const CryptoQrWrapper =
    {
      default: CryptoQrWrapperDefault,
      PM_brand: CryptoQrWrapperPM,
    }[themeForComponent] || CryptoQrWrapperDefault;

  const renderCopySection = (title, value) => (
    <>
      <CryptoCurrencyTitle>
        <Trans message={title} />
      </CryptoCurrencyTitle>
      <CryptoValuesTitle style={{ display: "flex" }}>
        {value}{" "}
        <span
          onClick={() => handleCopy(value, `${title} copied`)}
          style={{
            cursor: "pointer",
            color: "blue",
          }}
        >
          <IconColorWrapper style={{ marginLeft: "4px" }}>
            <IconCopy />
          </IconColorWrapper>
        </span>
      </CryptoValuesTitle>
    </>
  );

  return (
    <>
      <CryptoFormContainer $mr="auto" $pd="0" $mb>
        {address && renderCopySection("walletAddress", address)}
      </CryptoFormContainer>

      <CryptoFormContainer $mr="auto" $pd="0">
        {address_tag && renderCopySection("TAG", address_tag)}
      </CryptoFormContainer>

      <CryptoQrWrapper>
        <img src={`data:image/png+xml; base64,${address_qr_code_image}`} />
      </CryptoQrWrapper>
    </>
  );
};

export default Qr;
