import { FunctionComponent } from "preact";
import { useState } from "preact/hooks";
import searchIcon from "../../../../assets/icons/search.svg";
import {
  DropdownSearch,
  DropdownSearchIcon,
} from "../../../../components/Dropdown/style";
import { i18n } from "../../../../services";
import FieldUI from "../../ui/Field";

interface SearchProps {
  onSearch: (query: string) => void;
}

const DropdownSearchPM: FunctionComponent<SearchProps> = ({ onSearch }) => {
  const [search, setSearch] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);

  const onInput = (e: Event) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
    onSearch(target.value);
  };

  return (
    <DropdownSearch>
      <FieldUI
        label="Search"
        value={search}
        onInput={onInput}
        type="text"
        placeholder={i18n.getMessage({ message: "search" })}
        focused={focused}
        setFocused={setFocused}
        extraComponent={
          <DropdownSearchIcon>
            <img src={searchIcon} alt="search" />
          </DropdownSearchIcon>
        }
      />
    </DropdownSearch>
  );
};

export default DropdownSearchPM;
