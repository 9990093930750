import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
import { api, crypto, i18n, logger, socket, state, theme } from "../services";
import { useConnection } from "../services/hooks";
import { localStorageService } from "../services";
import { preloadThemeComponents } from "../services/cache";
import { initializeLocale } from "../services/i18n";

type TActualRoute = {
  name: string;
};

type LoaderEvents = {
  extra?: boolean | null;
  name: string;
  status: boolean;
  text?: string;
  withBlurStatus?: boolean;
};

export type TState = {
  status?: string;
  identifier?: string;
  options?: {
    theme?: string;
  };
  actualRoute?: TActualRoute;
  isUnauthorized?: boolean;
  loadingData?: {
    status: boolean;
    hasBlur?: boolean;
    loadingEvents: Record<string, LoaderEvents>;
  };
};

type TSetStyle = (style: any) => void;
type TSetThemeConfig = (config: Record<string, any> | null) => void;
type TSetCurrentState = (state: TState) => void;
type TSetCurrentCryptoState = (state: TState) => void;

type TSocketResponse = {
  close: (options?: { reason: string }) => void;
};

type UseConnectionManagerProps = {
  matches: {
    type: "payment" | "crypto-address" | null;
    signature: string | null;
    sessionId: string | null;
  } | null;
  setters: {
    setStyle: TSetStyle;
    setThemeConfig: TSetThemeConfig;
    setCurrentCryptoState: TSetCurrentState;
    setCurrentState: TSetCurrentCryptoState;
  };
};

const pollingTimeout = 2 * 1000;
let pollingTimeoutId: number | NodeJS.Timeout | null = null;
let socketTimeoutId: number | NodeJS.Timeout | null = null;
let pollingRequestsCount = 0;
let isPolling = false;
const RECONNECT_TIME = 500;
const POLLING_TIMEOUT_FAST = 500;
const MAX_RETRIES = 3;
let retriesCount = 0;
let filesPreloaded = false;
const SOCKET_TIMEOUT = 5000;

const setPollingStatus = (status: boolean) => {
  isPolling = status;

  return isPolling;
};

const initData = async (): Promise<TState> => {
  const { type, sessionId, signature } = state.getSession();

  try {
    const data = await api.getInitData({ type, sessionId, signature });
    const store: TState = state.get();
    const { isIdle, isUnauthorized, isError } = data;

    if (isIdle || isUnauthorized || isError) {
      return new Promise((resolve) =>
        state.checkNoSuccessDataCases(data, resolve),
      );
    }

    state.setLoggerData(data);

    if (store.status === "opened" && data.status === "new") {
      data.status = "opened";
    }

    state.set({ ...data, hasSelectedMethod: data?.selected_method_id });

    return state.get();
  } catch (error) {
    logger.error("Error initializing data:", error);
    throw error;
  }
};

const useConnectionManager = ({
  matches,
  setters,
}: UseConnectionManagerProps) => {
  const [hasSocketConnection, setHasSocketConnection] = useState(false);
  const { setStyle, setThemeConfig, setCurrentCryptoState, setCurrentState } =
    setters || {};
  const { type, signature, sessionId } = matches || {};
  const hasConnection = useConnection();
  const [socketIsReadyMount, setSocketIsReadyMount] = useState(true);

  const checkStateAndRoute = async ({
    withPolling = false,
  }: {
    withPolling?: boolean;
  }) => {
    try {
      const currentState: TState = await initData();

      const { actualRoute, status, isUnauthorized = false } = currentState;

      if (pollingTimeoutId) clearTimeout(pollingTimeoutId);

      const pollingRoutes = ["main", "repay", "pending"];

      if (state.isTestMode()) {
        pollingRoutes.push("success");
        pollingRoutes.push("fail");
      }

      if (withPolling && !isPolling) {
        setPollingStatus(true);
      }

      if (withPolling && !isUnauthorized) {
        if (pollingRoutes.includes(actualRoute?.name || "")) {
          const timeout =
            status === "new" || state.isTestMode()
              ? POLLING_TIMEOUT_FAST
              : pollingTimeout + pollingRequestsCount * 100;

          if (hasSocketConnection) {
            if (pollingTimeoutId) clearTimeout(pollingTimeoutId);
            pollingRequestsCount = 0;

            stopPolling();

            return actualRoute;
          } else {
            pollingTimeoutId = setTimeout(
              checkStateAndRoute.bind(this, {
                withPolling,
              }),
              timeout,
            );
            pollingRequestsCount++;
          }
        } else {
          pollingRequestsCount = 0;
          pollingTimeoutId = null;
        }
      }

      return actualRoute;
    } catch (error) {
      logger.error("Error checking state and route:", error);
    }
  };

  const init = useCallback(
    async ({ withPolling = false }: { withPolling?: boolean }) => {
      const data: TState = state.get();

      if (data?.status === "error") {
        setCurrentState(data);
      }

      return checkStateAndRoute({
        withPolling,
      });
    },
    [type, sessionId, signature, setCurrentState],
  );

  const startPolling = useCallback(() => {
    if (isPolling) {
      logger.log("Polling is already running, skipping startPolling call.");
      return;
    }

    logger.log("Starting polling due to socket timeout");

    init({ withPolling: true });
  }, []);

  const stopPolling = useCallback(() => {
    if (pollingTimeoutId) clearTimeout(pollingTimeoutId);
    pollingTimeoutId = null;

    if (isPolling) {
      logger.log("Stopped polling as socket connected");
      setPollingStatus(false);
    }
  }, []);

  const createConnection = () => {
    const data: TState = state.get();

    setTimeout(async () => {
      if (!data?.identifier && retriesCount < MAX_RETRIES) {
        logger.error("Polling didn't send data", {
          data,
        });

        return init({}).then(() => {
          createConnection();
        });
      }

      if (data?.identifier || retriesCount === MAX_RETRIES) {
        return init({ withPolling: true });
      }

      logger.error("Polling didn't send data and shown error", {
        data,
      });
    }, RECONNECT_TIME);
  };

  const checkData = (res: TSocketResponse) => {
    const data: TState = state.get();

    if (!data?.identifier) {
      logger.error("Socket didn't send data", {
        data,
      });

      if (retriesCount < 2) {
        try {
          res?.close({
            reason: "Connection closed by client",
          });
        } catch (error) {
          logger.error("Socket close error", {
            error,
          });
        }

        setTimeout(() => {
          checkData(res);
        }, RECONNECT_TIME);
      } else if (retriesCount === 2) {
        createConnection();
      }

      retriesCount++;
    } else {
      retriesCount = 0;
    }
  };

  const isWindowAvailable = typeof window !== "undefined";

  const isSocketDisabled = useMemo(() => {
    if (isWindowAvailable) {
      return window?.location?.search?.includes("socket=disabled");
    }

    return false;
  }, [isWindowAvailable]);

  const socketConnection = useCallback(async (): Promise<
    TSocketResponse | void | TActualRoute
  > => {
    if (state.isTestMode() || isSocketDisabled) {
      logger.log("Started polling");

      return init({ withPolling: true });
    }

    if (!sessionId || !signature) {
      logger.error("Missing sessionId or signature for socket connection.");
      return;
    }

    try {
      socketTimeoutId = setTimeout(
        () => {
          if (!hasConnection) {
            startPolling();
          }
        },
        state.isTestMode() ? 0 : SOCKET_TIMEOUT,
      );

      const res: TSocketResponse = await socket.run({ init });
      setSocketIsReadyMount(false);
      setHasSocketConnection(true);

      if (socketTimeoutId) clearTimeout(socketTimeoutId);
      stopPolling();

      if (hasConnection) {
        setTimeout(() => {
          checkData(res);
        }, RECONNECT_TIME);
      }

      return res;
    } catch (e) {
      if (e === "Connection is already in progress") {
        console.warn("Connection is already in progress");

        if (socketTimeoutId) clearTimeout(socketTimeoutId);

        return;
      }

      logger.error("Socket connection error", e);

      if (socketTimeoutId) clearTimeout(socketTimeoutId);
      startPolling();
      setHasSocketConnection(false);
    }
  }, [state.isTestMode(), type, hasConnection]);

  const cryptoConnection = useCallback(async () => {
    try {
      const data = await crypto.initCrypto({ sessionId, signature });
      setCurrentCryptoState({ ...data });

      if (data?.locale) {
        i18n.setLocaleIfPresent(data.locale);
      }
    } catch (error) {
      logger.error("Error initializing crypto", error);
    }
  }, [sessionId, signature]);

  const preparationBeforeSocketConnection = useCallback(() => {
    const data: TState = state.get();
    const { sessionId } = state.getSession();
    let currentTheme;
    try {
      const sessionData = localStorageService?.getSessionData(
        "sessionStorage",
        sessionId,
      );

      currentTheme = sessionData?.currentTheme;
    } catch (error) {
      logger.warn("Access to sessionStorage is denied", { error });
    }

    if (currentTheme !== "undefined" && currentTheme !== data?.options?.theme) {
      state.set({
        options: {
          ...(data?.options || {}),
          theme: currentTheme,
        },
      });
    }
  }, []);

  const fetchData = async (): Promise<void> => {
    if (!sessionId || !signature || !type) {
      logger.warn("Missing sessionId, signature, or type. Skipping fetchData.");
      return;
    }

    try {
      if (type === "payment") {
        await handlePaymentFlow();
      } else if (type === "crypto-address") {
        await handleCryptoFlow();
      }

      await preloadingFiles();
    } catch (error) {
      logger.error("Error in fetchData:", error);
    }
  };

  const handlePaymentFlow = async (): Promise<void> => {
    if (!socketIsReadyMount) {
      logger.warn("Socket is not ready for payment flow. Skipping.");
      return;
    }

    try {
      state.setSession({ type, sessionId, signature });
      preparationBeforeSocketConnection();
      await socketConnection();
    } catch (error) {
      logger.error("Error in handlePaymentFlow:", error);
    }
  };

  const handleCryptoFlow = async (): Promise<void> => {
    try {
      await cryptoConnection();

      const themeName = state.getTheme();
      const [themeStyle, themeConfig] = await Promise.all([
        theme.getStyle(themeName, sessionId, signature),
        theme.getThemeConfig(themeName),
      ]);

      setStyle(themeStyle);
      setThemeConfig(themeConfig);
    } catch (error) {
      logger.error("Error in handleCryptoFlow:", error);
    }
  };

  const preloadingFiles = async () => {
    const themeName = state.getTheme();

    if (themeName && !filesPreloaded) {
      await preloadThemeComponents(themeName).then(() => {
        filesPreloaded = true;
      });
    }
  };

  useEffect(() => {
    const initializeConnection = async (): Promise<void> => {
      if (!sessionId || !signature || !type) {
        return;
      }

      if (!hasConnection) {
        setSocketIsReadyMount(true);
        setHasSocketConnection(false);
        return;
      }

      if (!i18n.getIsLocaleSet()) {
        initializeLocale();
      }

      if (type === "payment" && socketIsReadyMount) {
        await socketConnection();
      } else if (type === "crypto-address") {
        await cryptoConnection();
      }

      await preloadingFiles();
    };

    initializeConnection();
  }, [hasConnection]);

  useEffect(() => {
    if (!(state.get() as TState)?.loadingData?.loadingEvents.app_init) {
      state.setLoading({ status: true, eventName: "app_init" });
    }

    initializeLocale();

    fetchData();
  }, [sessionId, signature, type]);

  useEffect(() => {
    if (hasSocketConnection) {
      setTimeout(() => {
        stopPolling();
      }, 1000);
    }
  }, [hasSocketConnection]);
};

export default useConnectionManager;
