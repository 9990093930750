import { useContext } from "preact/hooks";
import {
  CustomCheckboxWrapper,
  HiddenCheckbox,
  StyledCheckbox,
  CheckboxLabel,
  CheckboxWrapper,
} from "./style";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { FunctionComponent } from "preact";
import { ContextUI } from "../../../../providers/UIProvider";
import Trans from "../../../../components/Trans";
import { state, theme } from "../../../../services";
import { ThemeConfig } from "../../../commons/interfaces/configInterface";

interface RememberMeProps {
  name?: string;
  checked: boolean;
  setRememberMe: (setter: (prev: boolean) => boolean) => void;
  checkedCard?: boolean | null;
}

const RememberMe: FunctionComponent<RememberMeProps> = ({
  name = "remember_me",
  checked,
  setRememberMe,
  checkedCard,
}) => {
  const { setNotificationData } = useContext(ContextUI);
  const isChecked = checkedCard || checked;

  const themeName = state.getTheme();

  const themeConfig = theme.getThemeConfig(themeName) as ThemeConfig;

  const handleCheckboxClick = () => {
    setNotificationData({
      text: "Card will not be saved!",
      status: "success",
      isOpen: checkedCard === null && checked,
    });

    if (!checkedCard) {
      setRememberMe((prev) => !prev);
    }
  };

  const CheckboxContent = (
    <CheckboxWrapper>
      <StyledCheckbox $checked={isChecked} onClick={handleCheckboxClick}>
        <svg viewBox="0 0 24 24">
          <polyline points="3,12 10,17 21,6" />
        </svg>
      </StyledCheckbox>
      <CheckboxLabel htmlFor={name}>
        <Trans message="saveThisCard" />
      </CheckboxLabel>
    </CheckboxWrapper>
  );

  return (
    <CustomCheckboxWrapper>
      <HiddenCheckbox
        id={name}
        checked={isChecked}
        onChange={handleCheckboxClick}
      />
      {themeConfig?.showRememberMeTooltip ? (
        <Tooltip
          background="#10366B"
          width="280px"
          textAlign="start"
          fs="12px"
          bottom="-220%"
          top="-12%"
          left="85%"
          leftArrow="8%"
          rotate="180deg"
          text="We only store the card number and expiration date for bulk orders on our website"
        >
          {CheckboxContent}
        </Tooltip>
      ) : (
        CheckboxContent
      )}
    </CustomCheckboxWrapper>
  );
};

export default RememberMe;
