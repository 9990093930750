import Trans from "../../../../components/Trans";
import { FunctionComponent } from "preact";
import {
  PMFinalPageContent,
  PMFinalPageDescription,
  PMFinalPageTitle,
  PMFinalPageWrapper,
} from "../SuccessPage/style";
import FailIcon from "../../../../assets/icons/brand/pm/FailIcon";

const FailPagePM: FunctionComponent = () => {
  return (
    <PMFinalPageWrapper>
      <FailIcon />
      <PMFinalPageContent>
        <PMFinalPageTitle>
          <Trans message="PMFail" />
        </PMFinalPageTitle>
        <PMFinalPageDescription>
          <Trans message="PMFailDescription" />
        </PMFinalPageDescription>
      </PMFinalPageContent>
    </PMFinalPageWrapper>
  );
};

export default FailPagePM;
