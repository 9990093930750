/* eslint-disable no-use-before-define */
import { useState, useEffect, useContext, useMemo } from "preact/hooks";
import { state, processing, theme } from "../../services";
import { CONST_ERROR_LIST } from "../../services/constants";
import CardListModal from "../Modals/CardListModal";
import IconCard from "../IconCard";
import { ContextUI } from "../../providers/UIProvider";
import ClearFormModal from "../Modals/ClearModalForm/ClearFormModal";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import FieldUIDefault from "../../themes/default/ui/Field";
import FieldUIPM from "../../themes/PM_brand/ui/Field";
import InputSavedCardDefault from "../../themes/default/components/InputSavedCard";
import InputSavedCardKombine from "../../themes/kombine/components/InputSavedCard";
import InputSavedCardPM from "../../themes/PM_brand/components/InputSavedCard";

export const INITIAL_VALUES = {
  card: "",
  month: "",
  year: "",
  cvv: "",
  date: "",
  cardHolder: "",
  brand: "",
};

const InputCard = ({
  values,
  setValues,
  errors,
  setErrors,
  nextStep,
  cvvStep,
  cardHolderStep,
  checkedCard,
  setCheckedCard,
  hiddenRememberMe,
  resetCardForm,
}) => {
  const [focused, setFocused] = useState(false);
  const { setModalData, handleCloseModal } = useContext(ContextUI);

  const cardList = state.get().cards || [];
  const options = state.get().options;
  const currentTheme = theme.getThemeConfig(options?.theme);

  const conditionForShowArrow =
    cardList &&
    Array.isArray(cardList) &&
    cardList.filter((e) => e.enabled).length > 0 &&
    !hiddenRememberMe;

  const acceptedCardLengths = [17, 18, 19, 21, 22];

  useEffect(() => {
    if (hiddenRememberMe) {
      setValues((prev) => ({
        ...prev,
        ...INITIAL_VALUES,
      }));
    }
  }, [hiddenRememberMe]);

  useEffect(() => {
    if (
      cardList &&
      Array.isArray(cardList) &&
      cardList.length > 0 &&
      !checkedCard
    ) {
      handleCheckSavedCard(cardList[0]);
    }
  }, [JSON.stringify(cardList)]);

  const onCardInput = (e) => {
    let val = e.target.value.replace(/[^\dA-Z]/g, "").trim();

    if (checkedCard && val !== values.card) {
      if (currentTheme.hasModalAlertForClearCardInfo) {
        setModalData({
          isOpen: true,
          title: "isSureClearCardData",
          content: <ClearFormModal resetCardForm={resetCardForm} />,
        });
      } else {
        resetCardForm(false);
      }

      return;
    }

    val = val
      .replace(/^(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2")
      .replace(/(\d{4})(\d)/, "$1 $2");

    const brand = processing.detectCardBrand(val);

    if (acceptedCardLengths.includes(val.length)) {
      if (!processing.checkLuhn(val)) {
        setErrors((prev) => ({
          ...prev,
          card: CONST_ERROR_LIST.wrong_card,
        }));
      } else {
        setErrors((prev) => {
          delete prev.card;

          return prev;
        });

        setTimeout(() => {
          nextStep && nextStep.current && nextStep.current.focus();
        }, 0);
      }
    }

    let target = e.target;
    let position = target.selectionEnd;
    let length = target.value.length;

    target.value = target.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;

    setValues((prev) => ({
      ...prev,
      card: val,
      brand:
        acceptedCardLengths.includes(val.length) && processing.checkLuhn(val)
          ? brand
          : "",
    }));
  };

  const onCardBlur = () => {
    setFocused(false);

    if (checkedCard) return setErrors({});

    if (values.card.length === 0) {
      return setErrors((prev) => ({
        ...prev,
        card: CONST_ERROR_LIST.required,
      }));
    }

    if (
      !acceptedCardLengths.includes(values.card.length) ||
      (acceptedCardLengths.includes(values.card.length) &&
        !processing.checkLuhn(values.card))
    ) {
      return setErrors((prev) => ({
        ...prev,
        card: CONST_ERROR_LIST.wrong_card,
      }));
    }

    return setErrors((prev) => {
      delete prev.card;

      return prev;
    });
  };

  const errorChecking = () => {
    if (errors.card) {
      if (focused) {
        return acceptedCardLengths.includes(values.card.length);
      }

      return true;
    }

    return false;
  };

  const handleCheckSavedCard = (card) => {
    if (card) {
      setCheckedCard(card);
      setValues((prev) => ({
        ...prev,
        card: `${card.bin?.slice(0, 4)} ${card.bin?.slice(4, 6)}** **** ${
          card.last4
        }`,
        date: `${card.exp_month}${
          card.exp_year?.length === 4
            ? card.exp_year?.slice(2, 4)
            : card.exp_year
        }`,
        cvv: "",
        brand: card.brand,
        cardHolder: card.holder_name,
      }));

      handleCloseModal();
      setErrors({});

      setTimeout(() => {
        cvvStep?.current?.focus();
      }, 200);
    }
  };

  const handleRemoveSavedCard = (identifier) => {
    if (checkedCard && checkedCard.identifier === identifier) {
      setCheckedCard(null);

      setValues((prev) => ({
        ...prev,
        ...INITIAL_VALUES,
      }));

      setErrors({});
    }

    setTimeout(() => {
      handleCloseModal();

      cardHolderStep?.current?.focus();
    }, 200);
  };

  const generateIconCard = (value) => {
    if (
      currentTheme.showCardIcon &&
      (!hiddenRememberMe || currentTheme?.cardIconPass)
    ) {
      return <IconCard value={value} values={values} />;
    }

    return null;
  };

  const openModal = () => {
    setModalData({
      isOpen: true,
      content: (
        <CardListModal
          handleCheckSavedCard={handleCheckSavedCard}
          handleRemoveSavedCard={handleRemoveSavedCard}
          checkedCard={checkedCard}
        />
      ),
    });
  };

  const themeForComponent = useThemeComponent("Field");

  const FieldUI = {
    default: FieldUIDefault,
    PM_brand: FieldUIPM,
  }[themeForComponent];

  const themeForInputSavedCardComponent = useThemeComponent("InputSavedCard");

  const InputSavedCard = {
    default: InputSavedCardDefault,
    kombine: InputSavedCardKombine,
    PM_brand: InputSavedCardPM,
  }[themeForInputSavedCardComponent];

  const smallMobileButton = useMemo(() => {
    if (typeof window === "undefined") {
      return;
    }

    const currentScreenWidth = window.innerWidth;

    return (
      (currentScreenWidth >= 320 && currentScreenWidth <= 340) ||
      conditionForShowArrow
    );
  }, [window && typeof window !== "undefined" && window?.innerWidth]);

  return (
    <FieldUI
      label={currentTheme?.inputCardLabel}
      value={values.card}
      onInput={onCardInput}
      onBlur={onCardBlur}
      setFocused={setFocused}
      focused={focused}
      errorChecking={errorChecking}
      error={errors.card}
      placeholder={currentTheme?.cardInputPlaceHolder}
      id="ccn"
      type="tel"
      inputmode="numeric"
      pattern="[0-9*\s]{16,22}"
      autocomplete="cc-number"
      maxlength="22"
      size="22"
      data-role="card_number"
      data-transaction-name="CardInput"
      isSmall={smallMobileButton}
      brandIcon={generateIconCard(values.brand)}
      extraComponent={
        <InputSavedCard
          conditionForShowArrow={conditionForShowArrow}
          smallMobileButton={smallMobileButton}
          openModal={openModal}
        />
      }
    />
  );
};

export default InputCard;
