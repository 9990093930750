import styled from "styled-components";
import { PM_COLORS } from "../../enums";

export const PMNotificationContainer = styled.div<{
  $isOpen?: boolean;
  $status?: string;
}>`
  position: fixed;
  z-index: 10000;
  top: 24px;
  left: 24px;
  width: calc(100% - 48px);
  background-color: ${({ $status }) => {
    switch ($status) {
      case "success":
        return PM_COLORS.PRIMARY;
      case "fail":
        return PM_COLORS.DANGER;
      case "error":
        return PM_COLORS.DANGER;
      default:
        PM_COLORS.PRIMARY;
    }
  }};
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;
  border-radius: 12px;
`;

export const PMNotificationIconWrapper = styled.div<{
  $cursor?: string;
}>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

export const PMNotificationBodyContainer = styled.div`
  width: 100%;
  flex-shrink: 1;
  padding: 10px 0;
  display: flex;
  align-items: center;
`;

export const PMNotificationText = styled.span`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: ${PM_COLORS.DEFAULT_WHITE};
`;
