import { useState, useEffect } from "preact/hooks";
import ChallengeCompleteDefault from "../../themes/default/components/ChallengeComplete";
import ChallengeCompletePM from "../../themes/PM_brand/components/ChallengeComplete";
import { useThemeComponent } from "../../hooks/useThemeComponent";

const DEFAULT_PROCESSING_TIME = 300;

const ChallengeComplete = ({ processingTime }) => {
  const [timer, setTimer] = useState(processingTime || DEFAULT_PROCESSING_TIME);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval);

          return 0;
        }

        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const timeExpired = timer === 0;

  const themeForComponent = useThemeComponent("ChallengeComplete");

  const ChallengeCompleteComponent = {
    default: ChallengeCompleteDefault,
    PM_brand: ChallengeCompletePM,
  }[themeForComponent];

  return (
    <ChallengeCompleteComponent
      timeExpired={timeExpired}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

export default ChallengeComplete;
