import { FunctionComponent } from "preact";
import { useState, useEffect } from "preact/hooks";
import CryptoInput from "./CryptoInput";
import { useIsAmountBelowMin, useSumMinValue } from "./cryptoUtils";
import { CryptoInputWrapper as CryptoInputWrapperDefault } from "../../themes/default/components/CryptoForm/style";
import { CryptoInputWrapper as CryptoInputWrapperPM } from "../../themes/PM_brand/components/CryptoForm/style";
import { useThemeComponent } from "../../hooks/useThemeComponent";

interface CryptoInputData {
  currency_amount?: string;
  pay_amount?: string;
}
interface CryptoFormProps {
  currentState: {
    currency_amount?: string;
    pay_amount?: string;
    pay_currency?: string;
    network?: string;
    address?: string;
    address_tag?: string;
    address_qr_code_image?: string;
    currency?: string;
  };
  onAmountChange: (data: CryptoInputData) => Promise<void>;
  exchangeData: {
    network_fee?: number;
    min?: number;
  };
  options?: {
    copySendAmountEnabled?: boolean;
    copyReceiveAmountEnabled?: boolean;
    sendLabel?: string;
    receiveLabel?: string;
  };
  isMinNotificationEnabled?: boolean;
}

const POLLING_TIME = 2000;

const CryptoForm: FunctionComponent<CryptoFormProps> = ({
  currentState,
  onAmountChange,
  exchangeData,
  options,
  isMinNotificationEnabled,
}) => {
  const [changedField, setChangedField] =
    useState<keyof CryptoInputData>("currency_amount");
  const [cryptoInputData, setUserInputData] = useState<CryptoInputData>({
    currency_amount: currentState?.currency_amount,
    pay_amount: currentState?.pay_amount,
  });
  const [isLoading, setIsLoading] = useState<{
    pay_amount: boolean;
    currency_amount: boolean;
  }>({
    pay_amount: false,
    currency_amount: false,
  });

  const { min, network_fee } = exchangeData;
  const sumMinValue = useSumMinValue(min ?? 0, network_fee ?? 0);
  const isAmountBelowMin = useIsAmountBelowMin(
    cryptoInputData.pay_amount ?? "",
    sumMinValue,
  );

  const {
    copySendAmountEnabled,
    copyReceiveAmountEnabled,
    sendLabel,
    receiveLabel,
  } = options || {};

  const handleInputChange = (e: {
    target: { name: string; value: string };
  }) => {
    const target = e.target as HTMLInputElement;
    const { name, value } = target;

    setUserInputData((prevData) => {
      const newData = { ...prevData, [name]: value };

      const oppositeField =
        name === "pay_amount" ? "currency_amount" : "pay_amount";

      setIsLoading(
        (prev) =>
          ({
            ...prev,
            [name]: false,
            [oppositeField]: true,
          }) as { pay_amount: boolean; currency_amount: boolean },
      );

      return newData;
    });

    setChangedField(name as keyof CryptoInputData);
  };

  useEffect(() => {
    setUserInputData({
      currency_amount: currentState?.currency_amount,
      pay_amount: currentState?.pay_amount,
    });
  }, [currentState?.currency_amount, currentState?.pay_amount]);

  useEffect(() => {
    if (
      changedField &&
      cryptoInputData[changedField] &&
      !isNaN(Number(cryptoInputData[changedField]))
    ) {
      const timer = setTimeout(() => {
        onAmountChange({
          [changedField]: cryptoInputData[changedField],
        }).finally(() => {
          setTimeout(() => {
            setIsLoading({
              pay_amount: false,
              currency_amount: false,
            });
          }, 100);
        });
      }, POLLING_TIME);

      return () => clearTimeout(timer);
    }
  }, [changedField, cryptoInputData[changedField], isLoading]);

  const themeForComponent = useThemeComponent("CryptoForm");
  const CryptoInputWrapper =
    {
      default: CryptoInputWrapperDefault,
      PM_brand: CryptoInputWrapperPM,
    }[themeForComponent] || CryptoInputWrapperDefault;

  return (
    <>
      <CryptoInputWrapper>
        <CryptoInput
          isFirstInput={true}
          label={sendLabel || "send"}
          isAmountBelowMin={isAmountBelowMin && isMinNotificationEnabled}
          name="pay_amount"
          currency={currentState?.pay_currency}
          value={cryptoInputData?.pay_amount}
          copyValue={
            (copySendAmountEnabled && cryptoInputData?.pay_amount) || ""
          }
          onChange={handleInputChange}
          disabled={true}
          isLoading={isLoading.pay_amount}
        />
        <CryptoInput
          isFirstInput={false}
          label={receiveLabel || "receive"}
          name="currency_amount"
          currency={currentState?.currency}
          autofocus
          value={cryptoInputData?.currency_amount}
          copyValue={
            (copyReceiveAmountEnabled && cryptoInputData?.currency_amount) || ""
          }
          onChange={handleInputChange}
          disabled={!handleInputChange}
          isLoading={isLoading.currency_amount}
        />
      </CryptoInputWrapper>
    </>
  );
};

export default CryptoForm;
