import { api, logger, localStorageService } from "../../../../services";
import { IPaymentInstructions } from "./interfaces";

const bankTransferAfrica: IPaymentInstructions = {
  title: "virtualAccountTitle",
  isOrderedList: false,
  instructionList: [
    {
      title: "accountNumber",
      getPaymentData: ({ data = {} }) => data?.account_number,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.account_number,
        text: "accountNumberCopied",
      }),
    },
    {
      title: "bankName",
      getPaymentData: ({ data = {} }) => data?.bank_name,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.bank_name,
        text: "bankNameCopied",
      }),
      getIcon: ({ data = {} }) =>
        `${data?.bank_name?.toLowerCase()?.replace(/\s+/g, "_")}`,
    },
    {
      title: "bankCode",
      getPaymentData: ({ data = {} }) => data?.bank_code,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.bank_code,
        text: "bankCodeCopied",
      }),
    },
    {
      title: "accountName",
      getPaymentData: ({ data = {} }) => data?.account_name,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.account_name,
        text: "accountNameCopied",
      }),
    },
  ],
  getInfo: () => "PMChallengeInfoText",
  getExpirationDate: () => {
    let expirationDate;

    try {
      expirationDate = localStorageService.getSessionData(
        "sessionStorage",
        "expirationDate",
      )?.expirationDate;
    } catch (error) {
      logger.warn("Access to sessionStorage is denied", { error });
      return null;
    }

    if (!expirationDate) {
      const expirationTime = new Date(
        Date.now() + 10 * 60 * 1000,
      ).toISOString();

      try {
        localStorageService.setSessionData("sessionStorage", "expirationDate", {
          expirationDate: expirationTime,
        });
      } catch (error) {
        logger.warn("Failed to write to sessionStorage", { error });
      }

      return expirationTime;
    }

    return expirationDate;
  },
  submitAction: api.submitAccountRequisites,
};

export default bankTransferAfrica;
