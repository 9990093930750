import styled from "styled-components";
import { COLORS } from "../../../../constants/themeConstants";
import { PM_COLORS } from "../../enums";

export const PMInnerHeaderDataWrapper = styled.div<{
  $isWarning?: boolean;
  $background?: string;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${({ $isWarning, $background }) =>
    $isWarning ? $background || "rgba(255, 31, 0, 0.1)" : PM_COLORS.GRAY_6};
  gap: 0;
  box-shadow: none;
  padding: 0 8px;
  margin: 0 auto;
  width: 100%;
`;

export const InfoBlockContainer = styled.div`
  background-color: ${COLORS.BlUE_LIGHT};
  padding: 12px 12px 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    text-align: center;
    font-weight: 700;
    font-family: var(--boldFont);
    color: ${({ theme }) => theme?.mainColor};
  }
`;

export const PMInfoBlockWrapper = styled.div<{
  $align?: string;
}>`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: ${({ $align }) => $align};
`;

export const PMInfoTextBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  padding: 8px 0;
`;

export const PMInfoBlockImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  svg {
    height: 24px;
  }
`;

export const PMInfoBlockMainTitle = styled.span`
  padding: 8px 0;
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.32px;
  color: ${PM_COLORS.GRAY_DARK};

  span {
    font-family: var(--regularFont);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.32px;
    color: ${PM_COLORS.GRAY_DARK};
  }
`;

export const PMINfoBlockLink = styled.a`
  font-family: var(--regularFont);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-offset: Auto;
  text-decoration-thickness: Auto;
  color: ${PM_COLORS.PRIMARY};
  text-align: center;
  margin: 0 auto 12px;
`;

export const FormFieldsTitle = styled.div`
  font-family: var(--mediumFont);
  padding: 12px 24px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000000;
  opacity: 0.9;
`;

export const PMContainerForInfoBlock = styled.div<{
  $hasContainer?: boolean;
}>`
  padding: ${({ $hasContainer }) => ($hasContainer ? "0 24px 12px" : "0")};
  width: 100%;
`;

interface PMInfoTextBlockTitleProps {
  $color?: string;
}

export const PMInfoTextBlockTitle = styled.span<PMInfoTextBlockTitleProps>`
  font-family: var(--boldFont);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: ${({ $color }) => $color || PM_COLORS.GRAY_DARK};
`;

export const PMInfoTextBlockDescription = styled.span`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: ${PM_COLORS.GRAY_DARK};
`;

export const PMAttentionTextBlockTitle = styled(PMInfoBlockMainTitle)`
  padding: 0;
  color: ${PM_COLORS.GRAY_DARK};
`;
