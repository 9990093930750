import { FunctionComponent } from "preact";
import Loader from "../../../../components/Loaders";
import { PMLoaderWrapper } from "../PendingLoader/style";

const StatusLoaderPM: FunctionComponent = () => (
  <PMLoaderWrapper data-transaction-name="processing-loader">
    <Loader loaderName="pmLoader" />
  </PMLoaderWrapper>
);

export default StatusLoaderPM;
