import { JSX } from "preact/jsx-runtime";
import {
  QRsContainer,
  QRWrapper,
  QRButtonWrapper,
  ButtonWrapper,
} from "./style";
import Button from "../../../../components/Button";
import Trans from "../../../../components/Trans";
import { EnumVariant } from "../../../commons/enums/ButtonEnums";
type QrCodeInstructionProps = {
  qrs: { getQR?: (args: { data: any }) => string }[];
  instructionData: Record<string, string>;
  handleButtonClick?: () => void;
  buttonText?: string;
};

const QrCodeInstruction = ({
  qrs,
  instructionData,
  handleButtonClick,
  buttonText,
}: QrCodeInstructionProps): JSX.Element | null => {
  if (!qrs || qrs.length === 0) return null;

  return (
    <>
      <QRsContainer>
        {qrs.map((step, index) => {
          const qr = step.getQR?.({ data: instructionData });

          return qr ? (
            <QRWrapper key={index}>
              <img src={qr} alt={`QR Code ${index + 1}`} />
            </QRWrapper>
          ) : null;
        })}
      </QRsContainer>
      {handleButtonClick && (
        <QRButtonWrapper>
          <ButtonWrapper>
            <Button
              variant={EnumVariant.GRAY}
              textTransform="inherit"
              onClick={handleButtonClick}
            >
              <Trans message={buttonText || ""} />
            </Button>
          </ButtonWrapper>
        </QRButtonWrapper>
      )}
    </>
  );
};

export default QrCodeInstruction;
