import { JSX } from "preact";
import { useState } from "preact/hooks";
import { TooltipWrapper, TooltipIcon, TooltipText } from "./style";
import Trans from "../Trans";

interface TooltipProps {
  text: string | (() => JSX.Element);
  children: preact.ComponentChildren;
  width?: string;
  fs?: string;
  background?: string;
  bottom?: string;
  top?: string;
  rotate?: string;
  left?: string;
  leftArrow?: string;
  textAlign?: string;
}

const Tooltip: preact.FunctionComponent<TooltipProps> = ({
  text,
  children,
  width,
  fs,
  background,
  bottom,
  top,
  rotate,
  left,
  leftArrow,
  textAlign,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <TooltipWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TooltipIcon>{children}</TooltipIcon>
      {showTooltip && (
        <TooltipText
          $fs={fs}
          $top={top}
          $left={left}
          $leftArrow={leftArrow}
          $bottom={bottom}
          $width={width}
          $rotate={rotate}
          $textAlign={textAlign}
          $background={background}
          $show={showTooltip}
        >
          {text && typeof text === "function" ? (
            text()
          ) : (
            <Trans message={text} />
          )}
        </TooltipText>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
