import styled from "styled-components";

export const EnterInformationTitle = styled.div`
  display: flex;
  margin-bottom: 0;
  justify-content: center;

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.0025em;
    font-family: var(--mainFont);
    text-align: center;
  }
`;

export const FormContainerStyled = styled.div`
  max-width: ${({ theme }) => theme.formMaxWidth};

  @media (min-width: 768px) {
    width: 408px;
    margin: 0 auto;
  }
`;

export const FormWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > form {
    display: flex;
    flex-wrap: wrap;
    gap: 6px 24px;
    padding: 0 16px;
  }
`;

export const InputFormActionWrapper = styled.div<{
  $margin?: string;
  $isNoEditable?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
  position: relative;
  max-width: 376px;
  margin: ${({ $margin, theme }) => {
    if (theme?.inputGap) {
      return theme.inputGap;
    }

    if ($margin) {
      return $margin;
    }

    return "";
  }};

  ${({ $isNoEditable }) => {
    if ($isNoEditable) {
      return `
        cursor: pointer;

        input {
          pointer-events: none;
        }
      `;
    }

    return "";
  }}

  @media (max-height: 768px) {
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    max-width: 408px;
  }
`;

export const FormInputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const FormInput = styled.input<{
  $focused?: boolean;
  $errorCard?: boolean;
  $inputBlocked?: boolean;
  isDropdown?: boolean;
}>`
  width: 100%;
  border-radius: ${({ theme }) => theme?.elementBorderRadius};
  font-size: 16px;
  border: ${({ theme }) => `1px solid ${theme?.inputBackgroundColor}`};
  color: ${({ theme }) => theme?.inputColor};
  background-color: ${({ theme, $focused }) =>
    $focused ? theme?.inputFocusedBackground : theme?.inputBackgroundColor};
  font-family: var(--boldFont);
  border: ${({ $focused, $errorCard, theme }) => {
    if ($focused) {
      return `1px solid  ${theme.mainColor}`;
    } else if ($errorCard) {
      return `1px solid ${theme?.errorTitleColor}`;
    }

    return "";
  }};
  padding: 12px 16px;
  margin-top: 10px;
  margin-bottom: 18px;
  box-shadow: ${({ $errorCard }) =>
    $errorCard ? "0 0 5px rgba(235, 0, 117, 0.5)" : ""};
  pointer-events: ${({ $inputBlocked }) => ($inputBlocked ? "none" : "auto")};
  cursor: ${({ $inputBlocked }) => ($inputBlocked ? "default" : "text")};
  user-select: ${({ $inputBlocked }) => ($inputBlocked ? "none" : "auto")};
  height: ${(props) => props.theme?.inputHeight};
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    background-color: white;
  }

  ${({ isDropdown }) => {
    if (isDropdown) {
      return `
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 28px;
      `;
    }

    return ``;
  }}
`;

export const InvalidCardErrorTitle = styled.p`
  position: absolute;
  top: ${({ theme }) => `${57 + parseInt(theme.bottomError, 10)}px`};
  left: 12px;
  color: ${({ theme }) => theme?.errorTitleColor};
  font-size: 11px;
  font-family: var(--regularFont);
  width: calc(100% - 24px);
  min-width: 230px;
`;

export const FormDescription = styled.span<{
  $margin?: string;
  $focused?: boolean;
  $fs?: string;
  $fw?: string;
}>`
  color: ${({ $focused, theme }) =>
    $focused
      ? theme?.inputColorFocusedPlaceholder
      : theme?.inputColorPlaceholder};
  font-size: ${({ $fs, theme }) => $fs || theme?.inputPlaceholderFontSize};
  margin-left: ${({ theme }) => theme?.inputPlaceHolderMarginLeft};
  margin: ${({ $margin }) => $margin ?? ""};
  font-weight: ${({ $fw }) => $fw ?? ""};
  font-family: var(--mediumFont);
`;

export const FormInputContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const FormErrorWrapper = styled.div`
  position: absolute;
  margin-top: 0;
  padding-top: 2px;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  > div {
    position: absolute;
    width: auto;
    left: 37%;
    transform: translateX(-50%);
    top: 15px;
  }

  &::before,
  &::after {
    display: none;
  }
`;

export const FormErrorTitle = styled.span`
  display: none;
`;

export const FormPayTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  span {
    font-family: var(--boldFont);
  }
`;
