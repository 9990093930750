/* eslint-disable no-useless-escape */
import { useRef } from "preact/hooks";
import { i18n } from "../../services";
import Field from "./Field";
import {
  PAYMENT_METHOD_PROVIDERS,
  PAYMENT_METHODS,
} from "../../constants/paymentMethods";
import { ITN_LABELS } from "../../constants/fieldLabels";
import {
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_PLACEHOLDER,
} from "../../constants/fieldPlaceholder";
import { FILTER_PATTERNS, PATTERNS } from "../../services/constants";
import { COUNTRIES } from "../../constants/Countries/countries";
import { getThemeConfig } from "../../services/theme";
import { getPrefixAndRegexp } from "../../services/utils";
import Trans from "../Trans";
import { EnterInformationTitle } from "./style";
import { COUNTRIES_FULL_LIST } from "../../constants/Countries/countriesFullList";
import { CURRENCY_COUNTRY_MAPPING } from "../../constants/Countries/currencyCountryMapping";

const METHODS_WITH_BASIC_FIELDS = [
  PAYMENT_METHODS.P2C,
  PAYMENT_METHODS.PIX,
  PAYMENT_METHODS.BOLETO,
  PAYMENT_METHODS.LOTTERY,
  PAYMENT_METHODS.UPI,
];

const METHODS_OF_ISETTLE_PAYIN = [
  PAYMENT_METHODS.POPYPARA,
  PAYMENT_METHODS.PAPARA,
  PAYMENT_METHODS.PARAZULA,
  PAYMENT_METHODS.PEP,
  PAYMENT_METHODS.MEFETE,
  PAYMENT_METHODS.PAYFIX,
  PAYMENT_METHODS.BANK_TRANSFER_TURKEY,
];

const METHODS_OF_SIMPLEPAY = [
  PAYMENT_METHODS.PAROLAPARA,
  PAYMENT_METHODS.PAPARA,
  PAYMENT_METHODS.MEFETE,
  PAYMENT_METHODS.BANK_TRANSFER_TURKEY,
];

const METHODS_OF_PAGSMILE = [
  PAYMENT_METHODS.WALLET_TPAGA,
  PAYMENT_METHODS.WALLET_CHEK,
  PAYMENT_METHODS.WALLET_MACH,
  PAYMENT_METHODS.WALLET_TODITO,
  PAYMENT_METHODS.CASH_CHILE,
  PAYMENT_METHODS.BANK_TRANSFER_KHIPU,
  PAYMENT_METHODS.BANK_TRANSFER_CHILE,
  PAYMENT_METHODS.BANK_TRANSFER_PERU,
];

const METHODS_OF_JETON = [PAYMENT_METHODS.MONEYTOLIA];

const METHODS_OF_BETTER_BRO = [PAYMENT_METHODS.BANK_TRANSFER_ARGENTINA];

const getFields = ({
  sources,
  selectedMethod,
  selectedProvider,
  theme,
  selectedMethodData,
}) => {
  return [
    {
      name: "cardHolderName",
      errorKey: "cardHolder",
      isActive: true,
      area: "general",
      isAvailable: ({ method }) => [PAYMENT_METHODS.CARD].includes(method),
    },
    {
      name: "cardNumber",
      errorKey: "card",
      isActive: true,
      area: "general",
      isAvailable: ({ method }) => [PAYMENT_METHODS.CARD].includes(method),
    },
    {
      name: "cardExpire",
      errorKey: "date",
      area: "general",
      isAvailable: ({ method }) => [PAYMENT_METHODS.CARD].includes(method),
    },
    {
      name: "cardCvv",
      errorKey: "cvv",
      isActive: true,
      area: "general",
      isAvailable: ({ method }) => [PAYMENT_METHODS.CARD].includes(method),
    },
    {
      name: "email",
      source: "customer",
      label: i18n.getMessage({ message: "email" }),
      autocomplete: "email",
      autofocus: true,
      pattern: PATTERNS.EMAIL,
      area: "general",
      nonRequired: ({ method, provider }) => {
        const isVirtualAccountMethod =
          method === PAYMENT_METHODS.VIRTUAL_ACCOUNT;
        const isFincraBankTransfer =
          method === PAYMENT_METHODS.BANK_TRANSFER_AFRICA &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.FINCRA;

        const isConnPayP2C =
          method === PAYMENT_METHODS.P2C &&
          provider?.name?.toLowerCase() == PAYMENT_METHOD_PROVIDERS.CONNPAY;

        return isVirtualAccountMethod || isFincraBankTransfer || isConnPayP2C;
      },
      isAvailable: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.UPI &&
          provider?.name?.toLowerCase() == PAYMENT_METHOD_PROVIDERS.INOPS
        ) {
          return false;
        }

        if (
          method === PAYMENT_METHODS.P2C &&
          provider?.name?.toLowerCase() == PAYMENT_METHOD_PROVIDERS.CONNPAY
        ) {
          return false;
        }

        if (
          [PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.GOOGLE_PAY].includes(
            method,
          ) &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        const isVirtualAccountMethod =
          method === PAYMENT_METHODS.VIRTUAL_ACCOUNT;
        const isFincraBankTransfer =
          method === PAYMENT_METHODS.BANK_TRANSFER_AFRICA &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.FINCRA;

        if (isVirtualAccountMethod || isFincraBankTransfer) {
          return false;
        }

        if (
          method === PAYMENT_METHODS.MOBILE_MONEY &&
          ![
            PAYMENT_METHOD_PROVIDERS.ZAMUPAY,
            PAYMENT_METHOD_PROVIDERS.MARGINGATE,
          ].includes(provider?.name?.toLowerCase())
        ) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
            provider?.name?.toLowerCase(),
          ) &&
          ["cepbank"].includes(
            selectedMethodData?.channel?.ui_code?.toLowerCase(),
          )
        ) {
          return false;
        }

        if (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            ...METHODS_WITH_BASIC_FIELDS,
            ...METHODS_OF_PAGSMILE,
            ...METHODS_OF_SIMPLEPAY,
            ...METHODS_OF_JETON,
            PAYMENT_METHODS.VIRTUAL_ACCOUNT,
            PAYMENT_METHODS.BANK_TRANSFER_AFRICA,
            PAYMENT_METHODS.BANK_TRANSFER_JAPAN,
            PAYMENT_METHODS.BANK_TRANSFER_SOUTH_KOREA,
            PAYMENT_METHODS.INTERAC_ETRANSFER,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
            PAYMENT_METHODS.BLIK,
          ].includes(method) ||
          [PAYMENT_METHOD_PROVIDERS.TPAY].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        return (
          [PAYMENT_METHODS.CARD_REDIRECT].includes(method) &&
          [
            PAYMENT_METHOD_PROVIDERS.CERTUS,
            PAYMENT_METHOD_PROVIDERS.BAZPAY,
            PAYMENT_METHOD_PROVIDERS.PAGSMILE,
            PAYMENT_METHOD_PROVIDERS.ISETTLE,
            PAYMENT_METHOD_PROVIDERS.SIMPLEPAY,
          ].includes(provider?.name?.toLowerCase())
        );
      },
    },
    {
      name: "first_name",
      source: "customer",
      label: i18n.getMessage({ message: "firstName" }),
      autocomplete: "cc-given-name",
      pattern: /^.{2,255}$/,
      placeholder: ({ method }) =>
        FIRST_NAME_PLACEHOLDER[method] ||
        i18n.getMessage({ message: "firstName" }),
      area: "general",
      nonRequired: ({ method, provider }) => {
        return (
          method === PAYMENT_METHODS.BANK_TRANSFER_AFRICA &&
          [PAYMENT_METHOD_PROVIDERS.KORAPAY].includes(
            provider?.name?.toLowerCase(),
          )
        );
      },
      isAvailable: ({ method, provider }) => {
        const isKorapayBankTransfer =
          method === PAYMENT_METHODS.BANK_TRANSFER_AFRICA &&
          [PAYMENT_METHOD_PROVIDERS.KORAPAY].includes(
            provider?.name?.toLowerCase(),
          );

        if (isKorapayBankTransfer) {
          return false;
        }

        if (
          [PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.GOOGLE_PAY].includes(
            method,
          ) &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.UPI &&
          provider?.name?.toLowerCase() == PAYMENT_METHOD_PROVIDERS.INOPS
        ) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.MOBILE_MONEY &&
          ![
            PAYMENT_METHOD_PROVIDERS.ZAMUPAY,
            PAYMENT_METHOD_PROVIDERS.MARGINGATE,
          ].includes(provider?.name?.toLowerCase())
        ) {
          return true;
        }

        if (method === "p2c") {
          return true;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale", "cepbank"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return true;
        }

        return (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            ...METHODS_WITH_BASIC_FIELDS,
            ...METHODS_OF_PAGSMILE,
            ...METHODS_OF_BETTER_BRO,
            ...METHODS_OF_SIMPLEPAY,
            ...METHODS_OF_JETON,
            PAYMENT_METHODS.WALLET_PAYZ,
            PAYMENT_METHODS.VIRTUAL_ACCOUNT,
            PAYMENT_METHODS.DEPOSIT_OPAY,
            PAYMENT_METHODS.BANK_TRANSFER_AFRICA,
            PAYMENT_METHODS.BANK_TRANSFER_JAPAN,
            PAYMENT_METHODS.BANK_TRANSFER_SOUTH_KOREA,
            PAYMENT_METHODS.INTERAC_ETRANSFER,
            PAYMENT_METHODS.WALLET_M10,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
          ].includes(method) ||
          [PAYMENT_METHOD_PROVIDERS.TPAY].includes(
            provider?.name?.toLowerCase(),
          ) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.CERTUS,
              PAYMENT_METHOD_PROVIDERS.PAGSMILE,
              PAYMENT_METHOD_PROVIDERS.ISETTLE,
              PAYMENT_METHOD_PROVIDERS.SIMPLEPAY,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "last_name",
      source: "customer",
      label: i18n.getMessage({ message: "lastName" }),
      autocomplete: "cc-family-name",
      pattern: /^.{2,255}$/,
      placeholder: ({ method }) =>
        LAST_NAME_PLACEHOLDER[method] ||
        i18n.getMessage({ message: "lastName" }),
      area: "general",
      nonRequired: ({ method, provider }) => {
        return (
          method === PAYMENT_METHODS.BANK_TRANSFER_AFRICA &&
          [PAYMENT_METHOD_PROVIDERS.KORAPAY].includes(
            provider?.name?.toLowerCase(),
          )
        );
      },
      isAvailable: ({ method, provider }) => {
        const isKorapayBankTransfer =
          method === PAYMENT_METHODS.BANK_TRANSFER_AFRICA &&
          [PAYMENT_METHOD_PROVIDERS.KORAPAY].includes(
            provider?.name?.toLowerCase(),
          );

        if (
          method === PAYMENT_METHODS.UPI &&
          provider?.name?.toLowerCase() == PAYMENT_METHOD_PROVIDERS.INOPS
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.GOOGLE_PAY].includes(
            method,
          ) &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.MOBILE_MONEY &&
          ![
            PAYMENT_METHOD_PROVIDERS.ZAMUPAY,
            PAYMENT_METHOD_PROVIDERS.MARGINGATE,
          ].includes(provider?.name?.toLowerCase())
        ) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        if (isKorapayBankTransfer) {
          return false;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale", "cepbank"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return true;
        }

        return (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            ...METHODS_WITH_BASIC_FIELDS,
            ...METHODS_OF_PAGSMILE,
            ...METHODS_OF_BETTER_BRO,
            ...METHODS_OF_JETON,
            PAYMENT_METHODS.VIRTUAL_ACCOUNT,
            PAYMENT_METHODS.WALLET_PAYZ,
            PAYMENT_METHODS.DEPOSIT_OPAY,
            PAYMENT_METHODS.BANK_TRANSFER_AFRICA,
            PAYMENT_METHODS.BANK_TRANSFER_JAPAN,
            PAYMENT_METHODS.BANK_TRANSFER_SOUTH_KOREA,
            PAYMENT_METHODS.INTERAC_ETRANSFER,
            PAYMENT_METHODS.WALLET_M10,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
            PAYMENT_METHODS.P2C,
          ].includes(method) ||
          [PAYMENT_METHOD_PROVIDERS.TPAY].includes(
            provider?.name?.toLowerCase(),
          ) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.CERTUS,
              PAYMENT_METHOD_PROVIDERS.PAGSMILE,
              PAYMENT_METHOD_PROVIDERS.ISETTLE,
              PAYMENT_METHOD_PROVIDERS.SIMPLEPAY,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "middle_name",
      source: "customer",
      label: i18n.getMessage({ message: "middleName" }),
      autocomplete: "additional-name",
      pattern: /^.{2,255}$/,
      nonRequired: true,
      area: "general",
      isAvailable: ({ method, provider }) => {
        return (
          method === PAYMENT_METHODS.CARD_REDIRECT &&
          [PAYMENT_METHOD_PROVIDERS.CERTUS].includes(
            provider?.name?.toLowerCase(),
          )
        );
      },
    },
    {
      name: "phone",
      source: "customer",
      label: i18n.getMessage({ message: "phone" }),
      autocomplete: "tel",
      prefix: ({ method }) => {
        const { method: selectedMethod, provider } = method || {};

        if ([PAYMENT_METHODS.WALLET_MUCHBETTER].includes(selectedMethod)) {
          return false;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
            PAYMENT_METHODS.CARD_REDIRECT,
          ].includes(selectedMethod) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(selectedMethod) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return "+90";
        }

        return "+";
      },
      pattern: ({ method }) => {
        const { method: selectedMethod, provider } = method || {};

        if ([PAYMENT_METHODS.WALLET_MUCHBETTER].includes(selectedMethod)) {
          return PATTERNS.PHONE_LOCAL;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
            PAYMENT_METHODS.CARD_REDIRECT,
          ].includes(selectedMethod) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(selectedMethod) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return /^(\+?90)\d{10}$/;
        }

        return PATTERNS.PHONE;
      },
      filterPattern: FILTER_PATTERNS.PHONE,
      validationMessage: ({ method }) => {
        if ([PAYMENT_METHODS.WALLET_MUCHBETTER].includes(method)) {
          return "validation.phone_muchbetter";
        }

        return "validation.phone";
      },
      placeholder: ({ method }) => {
        if ([PAYMENT_METHODS.WALLET_MUCHBETTER].includes(method)) {
          return "1234567890";
        }

        return "+1234567890";
      },
      validationHint: ({ method }) => {
        if ([PAYMENT_METHODS.WALLET_MUCHBETTER].includes(method)) {
          return "hint_muchbetter";
        }

        return null;
      },
      area: "general",
      isAvailable: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.UPI &&
          provider?.name?.toLowerCase() == PAYMENT_METHOD_PROVIDERS.INOPS
        ) {
          return false;
        }

        if ([PAYMENT_METHODS.UPI].includes(method)) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.GOOGLE_PAY].includes(
            method,
          ) &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return true;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
            provider?.name?.toLowerCase(),
          ) &&
          ["cepbank"].includes(
            selectedMethodData?.channel?.ui_code?.toLowerCase(),
          )
        ) {
          return false;
        }

        return (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            ...METHODS_OF_SIMPLEPAY,
            PAYMENT_METHODS.WALLET_TPAGA,
            PAYMENT_METHODS.CASH_CHILE,
            PAYMENT_METHODS.BANK_TRANSFER_KHIPU,
            PAYMENT_METHODS.BANK_TRANSFER_CHILE,
            PAYMENT_METHODS.INTERAC_ETRANSFER,
            PAYMENT_METHODS.UPI,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
          ].includes(method) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.CERTUS,
              PAYMENT_METHOD_PROVIDERS.PAGSMILE,
              PAYMENT_METHOD_PROVIDERS.ISETTLE,
              PAYMENT_METHOD_PROVIDERS.SIMPLEPAY,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "country",
      source: "customer",
      label: i18n.getMessage({ message: "country" }),
      autocomplete: "country",
      options: ({ data, method }) => {
        if ([PAYMENT_METHODS.OPEN_BANKING].includes(method)) {
          return COUNTRIES;
        }

        if (
          CURRENCY_COUNTRY_MAPPING.some(
            (item) => item.currency === data.currency,
          )
        ) {
          const countryListByCurrency = CURRENCY_COUNTRY_MAPPING.filter(
            (item) => item.currency === data.currency,
          ).map((item) => item.countryName);

          return COUNTRIES_FULL_LIST.filter((item) =>
            countryListByCurrency.includes(item.name),
          );
        }

        return COUNTRIES_FULL_LIST;
      },
      checkIsActiveStatus: ({ sources, field }) => {
        return (
          (!sources?.country && field.options?.length === 1) || sources?.country
        );
      },
      staticDropdown: true,
      inputOptions: {
        type: "dropdown-async",
        key: "name",
        useTranslatedSearch: true,
        display: ({ option }) => option?.label || option?.name || "",
        search: ({ option, search }) => {
          if (search) {
            return (
              option.name?.toLowerCase().includes(search.toLowerCase()) ||
              option.label?.toLowerCase().includes(search.toLowerCase()) ||
              option.subName?.toLowerCase().includes(search.toLowerCase())
            );
          }

          return true;
        },
      },
      area: "general",
      isAvailable: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.MOBILE_MONEY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.FLUTTERWAVE
        ) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.GOOGLE_PAY].includes(
            method,
          ) &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          method === PAYMENT_METHODS.USSD &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.OPAY
        ) {
          return true;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale", "cepbank"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return false;
        }

        return (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            PAYMENT_METHODS.OPEN_BANKING,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
          ].includes(method) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.CERTUS,
              PAYMENT_METHOD_PROVIDERS.ISETTLE,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "state_code",
      source: "customer",
      label: i18n.getMessage({ message: "state_code" }),
      autocomplete: "address-level1",
      pattern: /^[0-9A-Z]{2}$|^[0-9A-Z]{3}$/,
      area: "general",
      isAvailable: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.GOOGLE_PAY].includes(
            method,
          ) &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        return (
          method === PAYMENT_METHODS.CARD_REDIRECT &&
          [PAYMENT_METHOD_PROVIDERS.CERTUS].includes(
            provider?.name?.toLowerCase(),
          )
        );
      },
    },
    {
      name: "city",
      source: "customer",
      label: i18n.getMessage({ message: "city" }),
      autocomplete: "address-level2",
      pattern: /^.{2,32}$/,
      area: "general",
      isAvailable: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale", "cepbank"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return false;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        return (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            ...METHODS_OF_JETON,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
          ].includes(method) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.CERTUS,
              PAYMENT_METHOD_PROVIDERS.ISETTLE,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "address",
      source: "customer",
      label: i18n.getMessage({ message: "address" }),
      autocomplete: "street-address",
      pattern: /^.{2,64}$/,
      area: "general",
      isAvailable: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale", "cepbank"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return false;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        return (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            ...METHODS_OF_JETON,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
          ].includes(method) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.CERTUS,
              PAYMENT_METHOD_PROVIDERS.ISETTLE,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "zip_code",
      source: "customer",
      label: i18n.getMessage({ message: "zip_code" }),
      autocomplete: "postal-code",
      pattern: /^.{2,32}$/,
      area: "general",
      nonRequired: ({ method }) => {
        return [
          PAYMENT_METHODS.PIX,
          PAYMENT_METHODS.BOLETO,
          PAYMENT_METHODS.LOTTERY,
        ].includes(method);
      },
      isAvailable: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.MBWAY &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.GOOGLE_PAY].includes(
            method,
          ) &&
          provider?.name?.toLowerCase() === PAYMENT_METHOD_PROVIDERS.PAYADMIT
        ) {
          return true;
        }

        if (
          [
            PAYMENT_METHODS.PIX,
            PAYMENT_METHODS.BOLETO,
            PAYMENT_METHODS.LOTTERY,
          ].includes(method)
        ) {
          return false;
        }

        if (
          [
            PAYMENT_METHODS.APPLE_PAY_REDIRECT,
            PAYMENT_METHODS.GOOGLE_PAY_REDIRECT,
          ].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.IMPAYA].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return true;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale", "cepbank"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return false;
        }

        return (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            ...METHODS_OF_JETON,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
          ].includes(method) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.CERTUS,
              PAYMENT_METHOD_PROVIDERS.ISETTLE,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "birthday",
      source: "customer",
      area: "general",
      label: i18n.getMessage({ message: "birthday" }),
      placeholder: "1977-05-25",
      pattern: PATTERNS.DATE_ISO,
      validationMessage: "validation.birthday",
      isAvailable: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.BANK_TRANSFER_JAPAN &&
          [PAYMENT_METHOD_PROVIDERS.MDL].includes(provider?.name?.toLowerCase())
        ) {
          return false;
        }

        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
            provider?.name?.toLowerCase(),
          ) &&
          ["cepbank"].includes(
            selectedMethodData?.channel?.ui_code?.toLowerCase(),
          )
        ) {
          return false;
        }

        return (
          [
            ...METHODS_OF_ISETTLE_PAYIN,
            ...METHODS_OF_SIMPLEPAY,
            ...METHODS_OF_JETON,
            PAYMENT_METHODS.WALLET_PAYZ,
            PAYMENT_METHODS.BANK_TRANSFER_JAPAN,
            PAYMENT_METHODS.WALLET_MUCHBETTER,
          ].includes(method) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.ISETTLE,
              PAYMENT_METHOD_PROVIDERS.SIMPLEPAY,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "itn",
      source: "customer",
      label: ({ method, provider }) => {
        if (
          method === PAYMENT_METHODS.CARD_REDIRECT &&
          [PAYMENT_METHOD_PROVIDERS.PAGSMILE].includes(
            provider?.name?.toLowerCase(),
          )
        ) {
          return "RUN";
        }

        return ITN_LABELS[method] || "RUN";
      },
      area: "general",
      pattern: /^.{1,255}$/,
      isAvailable: ({ method, provider }) => {
        if (
          ([
            PAYMENT_METHODS.PAROLAPARA,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.MEFETE,
          ].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            )) ||
          ([PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
            [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
              provider?.name?.toLowerCase(),
            ) &&
            ["express_havale", "vip_havale"].includes(
              selectedMethodData?.channel?.ui_code?.toLowerCase(),
            ))
        ) {
          return true;
        }

        if (
          [PAYMENT_METHODS.BANK_TRANSFER_TURKEY].includes(method) &&
          [PAYMENT_METHOD_PROVIDERS.SIMPLEPAY].includes(
            provider?.name?.toLowerCase(),
          ) &&
          ["cepbank"].includes(
            selectedMethodData?.channel?.ui_code?.toLowerCase(),
          )
        ) {
          return false;
        }

        return (
          [
            ...METHODS_OF_PAGSMILE,
            ...METHODS_OF_BETTER_BRO,
            ...METHODS_OF_SIMPLEPAY,
            ...METHODS_OF_JETON,
            PAYMENT_METHODS.MEFETE,
            PAYMENT_METHODS.PAPARA,
            PAYMENT_METHODS.BANK_TRANSFER_TURKEY,
          ].includes(method) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            ![PAYMENT_METHOD_PROVIDERS.PAYMENTO].includes(
              provider?.name?.toLowerCase(),
            )) ||
          (method === PAYMENT_METHODS.CARD_REDIRECT &&
            [
              PAYMENT_METHOD_PROVIDERS.PAGSMILE,
              PAYMENT_METHOD_PROVIDERS.SIMPLEPAY,
            ].includes(provider?.name?.toLowerCase()))
        );
      },
    },
    {
      name: "account",
      source: "upi",
      label: i18n.getMessage({ message: "account" }),
      area: "general",
      isAvailable: ({ method, provider }) => {
        return (
          method === PAYMENT_METHODS.UPI &&
          provider?.name?.toLowerCase() !== PAYMENT_METHOD_PROVIDERS.INOPS
        );
      },
    },
    {
      name: "customer_bank_verification_number",
      source: "virtual_account_request",
      apiSourceName: "virtual_account_request",
      label: i18n.getMessage({ message: "bvn" }),
      area: "general",
      isAvailable: ({ provider, method }) => {
        return (
          [
            PAYMENT_METHOD_PROVIDERS.FINCRA,
            PAYMENT_METHOD_PROVIDERS.KORAPAY,
          ].includes(provider?.name?.toLowerCase()) &&
          method === PAYMENT_METHODS.VIRTUAL_ACCOUNT
        );
      },
    },
    {
      name: "phone",
      source: "mobile_money",
      alternativeSource: "customer",
      pattern: ({ method }) => {
        return (
          getPrefixAndRegexp(method.currency, method.channel.ui_code)?.regexp ||
          PATTERNS.PHONE
        );
      },
      prefix: ({ method }) => {
        return (
          getPrefixAndRegexp(method.currency, method.channel.ui_code)?.prefix ||
          "+"
        );
      },
      filterPattern: FILTER_PATTERNS.PHONE,
      validationMessage: "validation.phone_mobile_money",
      label: i18n.getMessage({ message: "phone" }),
      area: "general",
      placeholder: "+1234567890",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.MOBILE_MONEY;
      },
      preventAutoSubmit: () => {
        return !sources?.mobile_money?.phone;
      },
    },
    {
      name: "user_id",
      source: "wallet_vega",
      prefix: "U",
      label: i18n.getMessage({ message: "userId" }),
      pattern: /^.{7,7}$/,
      validationMessage: "validation.walletVegaUserId",
      area: "general",
      placeholder: "U123456",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.WALLET_VEGA;
      },
    },
    {
      name: "user_password",
      source: "wallet_vega",
      label: i18n.getMessage({ message: "userPassword" }),
      pattern: /^.{2,32}$/,
      validationMessage: "validation.walletVegaUserPassword",
      area: "general",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.WALLET_VEGA;
      },
    },
    {
      name: "number",
      source: "wallet",
      label: i18n.getMessage({ message: "number" }),
      pattern: /^.{0,64}$/,
      area: "general",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.WALLET_TODITO;
      },
    },
    {
      name: "verification_code",
      source: "wallet",
      pattern: /^.{0,64}$/,
      label: i18n.getMessage({ message: "verification_code" }),
      area: "general",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.WALLET_TODITO;
      },
    },
    {
      name: "account_number",
      source: "bank_account",
      label: i18n.getMessage({ message: "account_number" }),
      area: "general",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.BANK_ACCOUNT_AFRICA;
      },
    },
    {
      name: "bank_code",
      source: "bank_account",
      label: i18n.getMessage({ message: "bank_code" }),
      area: "general",
      checkIsActiveStatus: () => false,
      isAvailable: ({ method, provider }) => {
        if (
          [
            PAYMENT_METHOD_PROVIDERS.INOPS,
            PAYMENT_METHOD_PROVIDERS.OPS,
          ].includes(provider?.name?.toLowerCase()) &&
          [PAYMENT_METHODS.BANK_ACCOUNT_INDONESIA].includes(method)
        ) {
          return true;
        }

        return false;
      },
    },
    {
      name: "account_number",
      source: "wallet_m10",
      label: i18n.getMessage({ message: "account_number" }),
      area: "general",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.WALLET_M10;
      },
    },
    {
      name: "bank_code",
      source: "bank_account",
      label: i18n.getMessage({ message: "bank_code" }),
      area: "general",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.BANK_ACCOUNT_AFRICA;
      },
      inputOptions: {
        type: "dropdown-async",
        from: "payment/bank/africa/ng",
        key: "code",
        showBankIcon: true,
        search: ({ option, search }) => {
          if (search) {
            return (
              option.name.toLowerCase().includes(search.toLowerCase()) ||
              option.code.toLowerCase().includes(search.toLowerCase())
            );
          }

          return true;
        },
      },
    },
    {
      name: "bank_verification_number",
      source: "bank_account",
      label: i18n.getMessage({ message: "bvn" }),
      area: "general",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.BANK_ACCOUNT_AFRICA;
      },
    },
    {
      name: "date_of_birth",
      placeholder: "DD.MM.YYYY",
      source: "bank_account",
      pattern: PATTERNS.DATE,
      label: i18n.getMessage({ message: "date_of_birth" }),
      area: "general",
      isAvailable: ({ method }) => {
        return method === PAYMENT_METHODS.BANK_ACCOUNT_AFRICA;
      },
      checkIsActiveStatus: ({ sources }) => {
        return (
          !sources?.bank_account ||
          !sources?.bank_account.account_dob_day ||
          !sources?.bank_account.account_dob_month ||
          !sources?.bank_account.account_dob_year
        );
      },
      preventAutoSubmit: () => {
        return (
          !sources?.bank_account ||
          !sources?.bank_account.account_dob_day ||
          !sources?.bank_account.account_dob_month ||
          !sources?.bank_account.account_dob_year
        );
      },
      formatBeforeSend: ({ values }) => {
        const separators = [".", ",", "/", "-", "_", "\\", " "];
        const currentValue = values.date_of_birth;

        const currentSeparator = separators.find((separator) =>
          currentValue.includes(separator),
        );

        if (!currentSeparator) {
          return {};
        }

        const [DD, MM, YYYY] = currentValue.split(currentSeparator);

        if (!DD || !MM || !YYYY) {
          return {};
        }

        return {
          account_dob_day: DD.padStart(2, "0"),
          account_dob_month: MM.padStart(2, "0"),
          account_dob_year: YYYY,
        };
      },
    },
  ]
    .filter((field) => {
      return field.isAvailable({
        method: selectedMethod,
        provider: selectedProvider,
      });
    })
    .sort((a, b) => {
      const { getFieldOrder } = getThemeConfig(theme) || {};
      const orderA =
        getFieldOrder && typeof getFieldOrder === "function"
          ? getFieldOrder({ field: a, index: a.initialIndex })
          : null;
      const orderB =
        getFieldOrder && typeof getFieldOrder === "function"
          ? getFieldOrder({ field: b, index: b.initialIndex })
          : null;

      return orderA >= 0 && orderB >= 0
        ? orderA - orderB
        : orderA >= 0
          ? -1
          : orderB >= 0
            ? 1
            : a.initialIndex - b.initialIndex;
    })
    .map((field) => {
      let isActive = sources?.[field?.source]
        ? !sources[field.source][field.name]
        : true;

      if (
        field.source === "customer" &&
        (!sources.customer ||
          (sources.customer && !sources.customer.identifier))
      ) {
        isActive = false;
      }

      if (field.checkIsActiveStatus) {
        isActive = field.checkIsActiveStatus({ sources, field });
      }

      return {
        ...field,
        nonRequired:
          typeof field.nonRequired === "function"
            ? field.nonRequired({
                method: selectedMethod,
                provider: selectedProvider,
              })
            : field.nonRequired,
        validationMessage:
          typeof field.validationMessage === "function"
            ? field.validationMessage({
                method: selectedMethod,
                provider: selectedProvider,
              })
            : field.validationMessage,
        validationHint:
          typeof field.validationHint === "function"
            ? field.validationHint({
                method: selectedMethod,
                provider: selectedProvider,
              })
            : field.validationHint,
        label:
          typeof field.label === "function"
            ? field.label({
                method: selectedMethod,
                provider: selectedProvider,
              })
            : field.label,
        placeholder:
          typeof field.placeholder === "function"
            ? field.placeholder({
                method: selectedMethod,
                provider: selectedProvider,
              })
            : field.placeholder,
        preventAutoSubmit:
          typeof field.preventAutoSubmit === "function"
            ? field.preventAutoSubmit()
            : null,
        type:
          field.inputOptions?.type &&
          typeof field.inputOptions.type === "function"
            ? field.inputOptions.type({
                method: selectedMethod,
                provider: selectedProvider,
              })
            : field.inputOptions?.type,
        options:
          field.options && typeof field.options === "function"
            ? field.options({
                method: selectedMethod,
                provider: selectedProvider,
                data: selectedMethodData,
              })
            : field.options,
        isActive,
      };
    });
};

const FormFields = ({
  method,
  area,
  refSubmit,
  values,
  setValues,
  errors,
  setErrors,
  checkedCard,
  resetCardForm,
  setCheckedCard,
  theme,
  hiddenRememberMe,
  themeConfig,
  fieldSources,
  setRememberMe,
  isMethodForAutoSubmit,
  isFailedRetryStatus,
}) => {
  const refs = {
    email: [useRef()],
    first_name: [useRef()],
    last_name: [useRef()],
    middle_name: [useRef()],
    cardHolderName: [useRef()],
    cardExpire: [useRef()],
    cardCvv: useRef(),
  };

  const extendedFields = {
    cardNumber: {
      nextStep: refs.cardExpire,
      cvvStep: refs.cardCvv,
      cardHolderStep: refs.cardHolderName,
    },
    cardExpire: {
      nextStep: refs.cardCvv,
    },
    cardCvv: {
      nextStep: refSubmit,
    },
  };

  const fields = getFields({
    sources: fieldSources,
    selectedMethod: method?.method,
    selectedProvider: method?.provider,
    selectedMethodData: method,
    theme,
  });

  const formFields = fields.map((field) => {
    let extendedField = {
      ...field,
      inputRefs: refs[field.name],
    };

    if (extendedFields[field.name]) {
      extendedField = {
        ...extendedField,
        ...extendedFields[field.name],
      };
    }

    if (
      extendedField.type === "dropdown-async" &&
      extendedField.options?.length === 1 &&
      !values[extendedField.name]
    ) {
      setValues((prev) => ({
        ...prev,
        [extendedField.name]:
          extendedField.options[0]?.[extendedField?.inputOptions?.key],
      }));
    }

    return extendedField;
  });

  const areaMethodFields = formFields.filter((field) => field.area === area);

  if (!areaMethodFields || !areaMethodFields.length) {
    return null;
  }

  return (
    <>
      {areaMethodFields.some(
        ({ isActive }) =>
          ![PAYMENT_METHODS.CARD, PAYMENT_METHODS.WALLET_VEGA].includes(
            method?.method,
          ) &&
          (isActive || (isFailedRetryStatus && isMethodForAutoSubmit)),
      ) ? (
        <EnterInformationTitle>
          <Trans message="enterInformation" />
        </EnterInformationTitle>
      ) : null}

      {areaMethodFields.map(
        ({
          name,
          label,
          autocomplete,
          autofocus,
          prefix,
          filterPattern,
          pattern,
          validationMessage,
          nonRequired,
          inputRefs,
          nextStep,
          cvvStep,
          cardHolderStep,
          isActive,
          placeholder,
          inputOptions,
          staticDropdown,
          options,
          validationHint,
        }) =>
          isActive || (isFailedRetryStatus && isMethodForAutoSubmit) ? (
            <Field
              key={name}
              name={name}
              label={label}
              placeholder={placeholder}
              autocomplete={autocomplete}
              autofocus={autofocus}
              prefix={prefix}
              filterPattern={filterPattern}
              pattern={pattern}
              validationMessage={validationMessage}
              nonRequired={nonRequired}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              resetCardForm={resetCardForm}
              checkedCard={checkedCard}
              setCheckedCard={setCheckedCard}
              inputRefs={inputRefs}
              validationHint={validationHint}
              nextStep={nextStep}
              cvvStep={cvvStep}
              cardHolderStep={cardHolderStep}
              theme={theme}
              hiddenRememberMe={hiddenRememberMe}
              themeConfig={themeConfig}
              inputOptions={inputOptions}
              staticDropdown={staticDropdown}
              options={options}
              setRememberMe={setRememberMe}
            />
          ) : null,
      )}
    </>
  );
};

export { getFields };
export default FormFields;
