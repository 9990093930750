import { FunctionComponent } from "preact";
import StepLoader from "./StepLoader";
import GooeyLoader from "./GooeyLoader";
import FlexLoader from "./FlexLoader";
import BallLoader from "./BallLoader";
import AsquadLoader from "./AsquadLoader";
import LoaderLine from "./LoaderLine";
import KombineLoader from "./KombineLoader";

import { useColorScheme } from "../../services/hooks";
import { state } from "../../services";
import { LoaderCenteredWrapper } from "./style";
import PMLoader from "./PMLoader";

interface LoaderProps {
  size?: "default" | "small";
  loaderName?: string;
}

const loaders: Record<
  string,
  FunctionComponent<{ size?: "default" | "small" }>
> = {
  StepLoader: (props) => <StepLoader key={0} {...props} />,
  GooeyLoader: (props) => <GooeyLoader key={1} {...props} />,
  FlexLoader: (props) => <FlexLoader key={2} {...props} />,
  BallLoader: (props) => <BallLoader key={3} {...props} />,
  AsquadLoader: (props) => <AsquadLoader key={4} {...props} />,
  KombineLoader: (props) => <KombineLoader key={5} {...props} />,
  pmLoader: (props) => <PMLoader key={6} {...props} />,
};

const Loader: FunctionComponent<LoaderProps> = ({
  size = "default",
  loaderName,
}) => {
  const options = (state.get() as { options: { theme?: string } }).options;
  const { colorTheme } = useColorScheme(options?.theme);

  const SelectedLoader =
    loaders[colorTheme?.loaderName || ""] ||
    // @ts-ignore
    loaders[colorTheme?.hpp_loader_name || ""] ||
    loaders[loaderName || ""] ||
    (() => <LoaderLine />);

  return (
    <LoaderCenteredWrapper>
      <SelectedLoader size={size} />
    </LoaderCenteredWrapper>
  );
};

export default Loader;
