import { IPaymentInstructions } from "./interfaces";

const walletOpayQr: IPaymentInstructions = {
  title: "scanQr",
  isOrderedList: false,
  instructionList: [
    {
      getQR: ({ data = {} }) => {
        if (!data?.code || !data?.image) return undefined;

        return data.image;
      },
    },
  ],
  getInfoTop: () => "scanQrTitleOpay",
};

export default walletOpayQr;
