import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const LanguageItemWrapper = styled.div`
  & img {
    border-radius: 50%;
  }
`;

export const EditCardActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;

  img {
    cursor: pointer;
    margin-left: auto;
    height: 24px;
    width: 24px;
  }
`;

export const ManageCardActionTitle = styled.span<{
  $color?: string;
}>`
  margin-right: 16px;

  span {
    font-size: 20px;
    font-family: var(--boldFont);
    font-weight: 500;
    color: ${({ $color }) => $color || COLORS.DEFAULT_BLACK};
    letter-spacing: 0;
  }
`;

export const ManageCardBlockTitle = styled.span<{
  $textCenter?: string;
}>`
  text-align: ${({ $textCenter }) => $textCenter};
  color: ${COLORS.BLUE};
  font-size: 16px;
  font-family: var(--boldFont);
  font-weight: 500;
  cursor: pointer;
`;
