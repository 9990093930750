import * as Preact from "preact";
import { i18n, logger } from "../services";

export interface TranslationParams {
  [key: string]: string | number | boolean | Preact.JSX.Element;
}
interface IProps {
  message: string;
  children?: Preact.PreactDOMAttributes;
  withoutWrapper?: boolean;
  params?: TranslationParams;
  components?: Preact.FunctionComponent[];
  componentProps?: Array<Record<string, unknown>>;
}

/**
 * Recursively processes conditional expressions in the content string.
 */
const processConditions = (
  content: string,
  params: TranslationParams,
): string => {
  return content.replace(
    /{(\w+),\s*true:(\{.*?\}|[^{]*?)\s*false:(\{.*?\}|[^{]*?)}/g,
    (_match, conditionKey: string, truePart: string, falsePart: string) => {
      const condition = params[conditionKey];

      // Select the appropriate part based on the condition
      const selectedPart = condition ? truePart : falsePart;

      // Remove outer braces {} if they exist
      const cleanedPart = selectedPart.replace(/^\{(.*)\}$/, "$1");

      // Recursively process nested conditions
      return processConditions(cleanedPart, params);
    },
  );
};

/**
 * Replaces variables and renders the Trans component.
 */
const replaceText = (
  text?: string,
  params?: TranslationParams,
): (string | Preact.JSX.Element)[] => {
  const parts = text?.split(/(<br\s*\/?>)/g) || [];

  return parts.map((part, index) => {
    if (part.match(/<br\s*\/?>/)) {
      return <br key={`br-${index}`} />;
    }

    return part.replace(/\{(\w+)\}/g, (_match, paramKey) => {
      const value = params?.[paramKey];

      if (value === undefined) {
        logger.warn(`Missing value for variable: ${paramKey}`);
        return `{${paramKey}}`;
      }

      if (Preact.isValidElement(value)) {
        logger.error(
          `Cannot interpolate JSX element into plain string for key: ${paramKey}`,
        );
        return `{${paramKey}}`;
      }

      return value.toString();
    });
  });
};

const ContentRenderer = ({
  parts,
  params,
  components,
  componentProps = [],
}: {
  parts: string[];
  params: TranslationParams;
  components: Preact.FunctionComponent[];
  componentProps?: Array<Record<string, unknown>>;
}) => (
  <Preact.Fragment>
    {parts.map((part, index) => {
      const match = part.match(/^<(\d+)>(.*?)<\/\1>$/);

      if (match) {
        const componentIndex = parseInt(match[1], 10);
        const content = match[2];
        const Component = components[componentIndex];
        const props = componentProps[componentIndex] || {};

        if (!Component) {
          logger.error(
            `Component for index ${componentIndex} is not provided.`,
          );
          return <>{content}</>;
        }

        return (
          <Component {...props} key={index}>
            {replaceText(content, params)}
          </Component>
        );
      }

      return (
        <Preact.Fragment key={index}>
          {replaceText(part, params)}
        </Preact.Fragment>
      );
    })}
  </Preact.Fragment>
);

const Trans = ({
  message,
  children,
  withoutWrapper = false,
  params = {},
  components = [],
  componentProps = [],
}: IProps) => {
  const content = processConditions(
    i18n.getMessage({ message, children }),
    params,
  );

  const parts = content.split(/(<\d+>.*?<\/\d+>|<br\s*\/?>)/g);

  if (withoutWrapper) {
    return (
      <ContentRenderer
        parts={parts}
        params={params}
        components={components}
        componentProps={componentProps}
      />
    );
  }

  return (
    <span>
      <ContentRenderer
        parts={parts}
        params={params}
        components={components}
        componentProps={componentProps}
      />
    </span>
  );
};

export default Trans;
