import { FunctionComponent } from "preact";
import { useContext } from "preact/hooks";
import InfoWarningIcon from "../../../../assets/icons/brand/pm/ui/InfoWraningIcon";
import {
  ExpirationDateInfoMainWrapper,
  ExpirationDateInfoContentWrapper,
  ExpiredInfoTitleWrapper,
  ExpiredInfoSubtitle,
  ContentWrapper,
} from "./style";
import { QrActionsWrapper } from "../ChallengeQr/style";
import Button from "../../../../components/Button";
import Trans from "../../../../components/Trans";
import { Context } from "../../../../app";

interface ExpirationDateInfoProps {
  cancelUrl?: string;
}

const ExpirationDateInfo: FunctionComponent<ExpirationDateInfoProps> = ({
  cancelUrl,
}) => {
  const { onGoBackButtonClick } = useContext(Context);
  return (
    <>
      <ExpirationDateInfoMainWrapper>
        <ExpirationDateInfoContentWrapper>
          <div>
            <InfoWarningIcon color="#EB0075" />
          </div>
          <ContentWrapper>
            <ExpiredInfoTitleWrapper>
              <Trans message="paymentTimeLimit" />
            </ExpiredInfoTitleWrapper>
            <ExpiredInfoSubtitle>
              <Trans message="transferNotCompleted" />
            </ExpiredInfoSubtitle>
          </ContentWrapper>
        </ExpirationDateInfoContentWrapper>
        <QrActionsWrapper>
          {cancelUrl && (
            <Button
              textTransform="inherit"
              data-role="back_to_store_button"
              onClick={() => onGoBackButtonClick(cancelUrl)}
            >
              <Trans message="goBack" />
            </Button>
          )}
        </QrActionsWrapper>
      </ExpirationDateInfoMainWrapper>
    </>
  );
};

export default ExpirationDateInfo;
