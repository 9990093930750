import { useEffect, useContext } from "preact/hooks";
import { logger } from "../../services";
import { Context } from "../../app";
import Scroll from "../../components/Scroll";
import useApmPageTracking from "../../hooks/useApmPageTracking";
// import AsyncComponent from "../../components/AsyncComponent";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import FailDefault from "../../themes/default/components/FailPage";
import FailKombine from "../../themes/kombine/components/FailPage";
import FailPagePM from "../../themes/PM_brand/components/FailPage";

const FailPage = () => {
  useApmPageTracking("FailPage");

  const context = useContext(Context);
  const { currentState, onGoBackButtonClick } = context;

  const { amount, currency, options, reference, request_id } =
    currentState || {};
  const { status_page, redirect_url } = options || {};
  const { enabled } = status_page || {};

  const onButtonClick = () => {
    onGoBackButtonClick(redirect_url);
  };

  useEffect(() => {
    const targetUrl = redirect_url;

    if (!targetUrl) {
      logger.error("Target fail page redirect URL not fount");

      return;
    }

    if (!enabled) {
      onGoBackButtonClick(redirect_url);
    }
  }, [redirect_url, enabled]);

  const themeForComponent = useThemeComponent("FailPage");

  const Fail = {
    default: FailDefault,
    kombine: FailKombine,
    PM_brand: FailPagePM,
  }[themeForComponent];

  return (
    <Scroll>
      <Fail
        amount={amount}
        currency={currency}
        onButtonClick={onButtonClick}
        reference={reference}
        options={options}
        request_id={request_id}
        hasGoBack
      />
      {/* <AsyncComponent
        importFn={(t) =>
          import(
            `../../themes/${themeForComponent}/components/FailPage/index.tsx${t}`
          )
        }
        name="Fail"
      >
        {(Fail) => (
          <Fail
            amount={amount}
            currency={currency}
            onButtonClick={onButtonClick}
            reference={reference}
            options={options}
            request_id={request_id}
            hasGoBack
          />
        )}
      </AsyncComponent> */}
    </Scroll>
  );
};

export default FailPage;
