import styled from "styled-components";

export const PMFinalPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 24px;
  height: 100%;
`;

export const PMFinalPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PMFinalPageTitle = styled.div`
  font-family: var(--boldFont);
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #000;
  opacity: 0.9;
`;

export const PMFinalPageDescription = styled.div`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #000;
  opacity: 0.56;
`;
