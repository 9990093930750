import styled from "styled-components";

export const PMFormContainer = styled.div`
  max-width: 100%;
  padding: 0 24px;
`;

export const PMFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > form {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

export const PMButtonWrapper = styled.div`
  padding: 24px;
`;
