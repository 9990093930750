import { useState } from "preact/hooks";
import Loader from "../Loaders";
import ChallengeComplete from "./ChallengeComplete";
import useApmTransaction from "../../hooks/useApmTransaction";
import { FormLoaderWrapper } from "../Loaders/style";
import Instruction from "../Instruction";

const ChallengeP2C = ({ p2c }) => {
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const { createSpan } = useApmTransaction("PaymentFlow");

  const handlePaymentMade = () => {
    const handlePaymentSpan = createSpan("MadePayment");

    setIsPaymentCompleted(true);

    setTimeout(() => {
      handlePaymentSpan?.end();
    }, 0);
  };

  return p2c ? (
    <>
      {isPaymentCompleted ? (
        <ChallengeComplete />
      ) : (
        <Instruction
          configName="p2c"
          instructionData={p2c}
          handleButtonClick={handlePaymentMade}
          buttonText="madePayment"
        />
      )}
    </>
  ) : (
    <FormLoaderWrapper>
      <Loader />
    </FormLoaderWrapper>
  );
};

export default ChallengeP2C;
