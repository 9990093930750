const SuccessIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.16 15.38C33.588 15.6889 33.8765 16.1545 33.9627 16.6752C34.0488 17.196 33.9257 17.7297 33.62 18.16L23.62 32.16C23.4524 32.3977 23.235 32.5961 22.9828 32.7412C22.7306 32.8863 22.4498 32.9746 22.16 33H22C21.7368 33.0015 21.4759 32.9511 21.2322 32.8516C20.9885 32.752 20.7668 32.6053 20.58 32.42L14.58 26.42C14.2034 26.0434 13.9918 25.5326 13.9918 25C13.9918 24.4674 14.2034 23.9566 14.58 23.58C14.9566 23.2034 15.4674 22.9918 16 22.9918C16.5326 22.9918 17.0434 23.2034 17.4201 23.58L21.74 27.92L30.38 15.92C30.5269 15.7017 30.7153 15.5146 30.9346 15.3693C31.1539 15.224 31.3997 15.1234 31.658 15.0732C31.9162 15.0231 32.1818 15.0243 32.4396 15.077C32.6973 15.1296 32.9422 15.2326 33.16 15.38ZM44 24C44 27.9556 42.827 31.8224 40.6294 35.1114C38.4318 38.4004 35.3082 40.9638 31.6537 42.4776C27.9992 43.9914 23.9778 44.3874 20.0982 43.6157C16.2185 42.844 12.655 40.9392 9.85792 38.1422C7.06087 35.3451 5.15599 31.7814 4.38429 27.9018C3.61258 24.0222 4.00872 20.0008 5.52247 16.3463C7.03622 12.6918 9.59958 9.56823 12.8886 7.37061C16.1775 5.17298 20.0444 4 24 4C26.6264 4 29.2272 4.51731 31.6537 5.5224C34.0802 6.52749 36.2849 8.00068 38.1421 9.85785C39.9993 11.715 41.4725 13.9198 42.4775 16.3463C43.4826 18.7728 44 21.3736 44 24ZM40 24C40 20.8355 39.0616 17.7421 37.3035 15.1109C35.5454 12.4797 33.0466 10.429 30.1229 9.21796C27.1993 8.00695 23.9822 7.69007 20.8785 8.30743C17.7749 8.9248 14.9239 10.4486 12.6863 12.6863C10.4486 14.9239 8.92487 17.7748 8.3075 20.8785C7.69014 23.9822 8.0069 27.1993 9.2179 30.1229C10.4289 33.0465 12.4797 35.5454 15.1108 37.3035C17.742 39.0616 20.8355 40 24 40C28.2435 40 32.3131 38.3143 35.3137 35.3137C38.3143 32.3131 40 28.2435 40 24Z"
      fill="#17AB71"
    />
  </svg>
);

export default SuccessIcon;
