import { PAYMENT_METHODS } from "../../../../constants/paymentMethods";
import { IPaymentInstructions } from "./interfaces";

const bankCodeChallenge: IPaymentInstructions = {
  getInfo: ({ data }) => {
    if (data?.currentMethod === PAYMENT_METHODS.BANK_TRANSFER_JAPAN) {
      return "initiatedAmountDeclined";
    }

    return null;
  },
};

export default bankCodeChallenge;
