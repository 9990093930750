import { FunctionComponent, JSX, RefObject } from "preact";
import {
  InputWrapper,
  Label,
  StyledInput,
  InputContainer,
  FieldError,
  DropdownIconWrapper,
} from "./style";
import Trans from "../../../../components/Trans";
import { i18n } from "../../../../services";
import DropdownIcon from "../../../../assets/icons/brand/pm/ui/DropdownIcon";
import { getFormattedTitle } from "../../../../components/Modals/ModalComponents/ModalItem";

interface FieldUIProps {
  label: string;
  value: string;
  onInput: JSX.GenericEventHandler<HTMLInputElement>;
  onBlur?: JSX.FocusEventHandler<HTMLInputElement>;
  setFocused: (focused: boolean) => void;
  focused: boolean;
  errorChecking?: () => boolean;
  placeholder: string;
  inputRef?: RefObject<HTMLInputElement>;
  error?: string;
  id?: string;
  type?: string;
  inputmode?: string;
  pattern?: string;
  autocomplete?: string;
  maxlength?: number;
  size?: number;
  "data-role"?: string;
  "data-transaction-name"?: string;
  brandIcon?: JSX.Element | null;
  extraComponent?: JSX.Element | null;
  showBrandIcon?: boolean;
  isSmall?: boolean;
  isDropdown?: boolean;
  fieldType?: string;
  onClick?: () => void;
  validationHint?: string;
  nameKey?: string;
}

const FieldUI: FunctionComponent<FieldUIProps> = ({
  label,
  value,
  onInput,
  onBlur,
  setFocused,
  focused,
  errorChecking,
  inputRef,
  error,
  placeholder,
  id,
  type,
  inputmode,
  pattern,
  autocomplete,
  maxlength,
  size,
  "data-role": dataRole,
  "data-transaction-name": dataTransactionName,
  extraComponent = null,
  fieldType,
  isDropdown,
  onClick,
}) => {
  const isFilled = !!value;
  const hasPlaceholder = !!placeholder && !isDropdown;
  const hasError = errorChecking && errorChecking();

  return (
    <InputWrapper $fieldType={fieldType} onClick={onClick}>
      <InputContainer $focused={focused} $hasError={hasError}>
        <Label
          $focused={focused}
          $filled={isFilled || hasPlaceholder}
          $hasError={hasError}
          $isNoEditable={!!onClick}
        >
          <Trans message={label} withoutWrapper />
        </Label>
        <StyledInput
          value={isDropdown ? getFormattedTitle("", value) : value}
          onInput={onInput}
          onFocus={() => setFocused(true)}
          onBlur={onBlur}
          ref={inputRef}
          placeholder={
            hasPlaceholder ? i18n.getMessage({ message: placeholder }) : ""
          }
          $hasPlaceholder={hasPlaceholder}
          id={id}
          type={type}
          inputmode={inputmode}
          pattern={pattern}
          autocomplete={autocomplete}
          maxlength={maxlength}
          size={size}
          data-role={dataRole}
          data-transaction-name={dataTransactionName}
          $isDropdown={isDropdown}
          $isNoEditable={!!onClick}
        />
        {isDropdown && (
          <DropdownIconWrapper>
            <DropdownIcon />
          </DropdownIconWrapper>
        )}
      </InputContainer>
      {hasError && (
        <FieldError>
          <Trans message={error || ""} />
        </FieldError>
      )}
      {extraComponent}
    </InputWrapper>
  );
};

export default FieldUI;
