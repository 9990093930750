import styled from "styled-components";

export const Bold = styled.span`
  font-family: var(--boldFont);
  font-weight: 500 !important;
`;

export const Regular = styled.span`
  font-family: var(--regularFont);
  font-weight: 400 !important;
`;
