import { FunctionComponent } from "preact";
import InfoIcon from "../../../../assets/icons/infoIcon";
import {
  ContainerDetailsWrapper,
  DetailsTransactionTitle,
} from "../../../../components/BankInfo/style";
import {
  OuterBlockHeader,
  TotalInfoWrapper,
} from "../../../../components/Header/style";
import {
  TransactionCurrencyTitle,
  TransactionDecimalTitle,
  TransactionSumContainer,
  TransactionSumTitle,
  TransactionSumWrapper,
} from "../../../../components/PaymentDetails/style";
import Trans from "../../../../components/Trans";
import { COLORS } from "../../../../constants/themeConstants";
import { state } from "../../../../services";
import {
  ErrorTitle,
  HelperForChooseMethod,
} from "../../../commons/styles/FormStyles";
import { TransactionInfoErrorBlockKomb } from "../../components/FailPage/style";
import { ErrorListItem, KombineErrorWrapper } from "./style";

interface FormHeaderProps {
  wholeNumber: string;
  decimal: string;
  currency: string;
  titleError?: string;
  titleErrorDescription?: string;
  hasData: boolean;
  selectedMethod?: string | null;
}

const FormHeader: FunctionComponent<FormHeaderProps> = ({
  wholeNumber,
  decimal,
  currency,
  titleError,
  titleErrorDescription,
  hasData,
  selectedMethod,
}) => {
  const canReturnToMethods = state.hasPaymentMethodsToShow();

  const hasError = !canReturnToMethods && !!titleError;

  return (
    <OuterBlockHeader>
      {hasData ? (
        <>
          <TotalInfoWrapper $pd="4px 16px 16px 16px">
            <ContainerDetailsWrapper $pb="16px" $alignItems="center">
              <DetailsTransactionTitle
                $mb="4px"
                style={{ textAlign: "center" }}
                $controlledLength
              >
                <Trans message="Total amount" />
              </DetailsTransactionTitle>
              <TransactionSumWrapper>
                <TransactionSumContainer $hasError={hasError}>
                  <TransactionSumTitle $color={COLORS.DEFAULT_WHITE}>
                    {wholeNumber}
                  </TransactionSumTitle>
                  {decimal && (
                    <TransactionDecimalTitle $color={COLORS.DEFAULT_WHITE}>
                      .{decimal}
                    </TransactionDecimalTitle>
                  )}
                  <TransactionCurrencyTitle $color="#9ba2af" $ml="8px">
                    {currency}{" "}
                  </TransactionCurrencyTitle>
                </TransactionSumContainer>
              </TransactionSumWrapper>
            </ContainerDetailsWrapper>
          </TotalInfoWrapper>

          {/* Failed retry popup */}

          {hasError && (
            <TransactionInfoErrorBlockKomb $mb="24px" $background="#FFFFFF">
              <KombineErrorWrapper>
                <InfoIcon />
                <ErrorTitle $textAlign="left" $color="#E53956" $ml="12px">
                  <Trans message={titleError} />
                </ErrorTitle>
              </KombineErrorWrapper>
              <ErrorTitle $mb="12px">Try the following:</ErrorTitle>

              <ErrorListItem>
                <Trans
                  message={titleErrorDescription || "pleasePaymentAgain"}
                />
              </ErrorListItem>
            </TransactionInfoErrorBlockKomb>
          )}
        </>
      ) : null}
      {!selectedMethod && !hasError ? (
        <HelperForChooseMethod>
          <Trans message="chooseMethod" />
        </HelperForChooseMethod>
      ) : null}
    </OuterBlockHeader>
  );
};

export default FormHeader;
