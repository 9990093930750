import styled from "styled-components";

export const PMChallengeInputWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 24px;
  padding: 24px 16px;

  @media (min-width: 768px) {
    padding: 24px 24px;
  }
`;
