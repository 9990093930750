import { INFORMATION_ABOUT_PAYMENT_METHODS } from "../../constants/paymentMethodsInfo";
import { state } from "../../services";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import FormInfoBlockDefault from "../../themes/default/components/FormInfoBlock";
import FormInfoBlockPM from "../../themes/PM_brand/components/FormInfoBlock";

const FormInfoBlock = ({
  fields,
  hasContainer,
  titleError,
  titleErrorDescription,
}: {
  fields: { isActive: boolean }[];
  hasContainer?: boolean;
  titleError?: string;
  titleErrorDescription?: string;
}) => {
  const selectedMethod = state.getSelectedPaymentMethod();
  const themeName = state.getTheme();
  const { method } = selectedMethod || {};
  const currentInfo =
    INFORMATION_ABOUT_PAYMENT_METHODS[method] ||
    INFORMATION_ABOUT_PAYMENT_METHODS["default"];
  const { isAvailable } = currentInfo;

  const themeForComponent = useThemeComponent("FormInfoBlock");

  const InfoBlock = {
    default: FormInfoBlockDefault,
    PM_brand: FormInfoBlockPM,
  }[themeForComponent];

  if (
    !InfoBlock ||
    (isAvailable && !isAvailable({ fields, theme: themeName })) ||
    !isAvailable
  )
    return null;

  return (
    <InfoBlock
      type="info"
      currentInfo={currentInfo}
      hasContainer={hasContainer}
      titleError={titleError}
      titleErrorDescription={titleErrorDescription}
    />
  );
};

export default FormInfoBlock;
