import { FunctionComponent } from "preact";
import Trans from "../../../../components/Trans";
import {
  PMNotificationBodyContainer,
  PMNotificationContainer,
  PMNotificationIconWrapper,
  PMNotificationText,
} from "./style";
import WrongIcon from "../../../../assets/icons/brand/pm/ui/WrongIcon";
import CheckIcon from "../../../../assets/icons/brand/pm/ui/CheckIcon";
import CloseNotificationIcon from "../../../../assets/icons/brand/pm/ui/CloseNotificationIcon";

interface NotificationProps {
  status: "success" | "error";
  isOpen: boolean;
  text: string;
  handleCloseNotification: () => void;
}

const NotificationPM: FunctionComponent<NotificationProps> = ({
  status,
  isOpen,
  text,
  handleCloseNotification,
}) => {
  return (
    <PMNotificationContainer
      $status={status}
      $isOpen={isOpen}
      data-transaction-name="notification"
    >
      <PMNotificationIconWrapper>
        {status === "success" ? <CheckIcon /> : <WrongIcon />}
      </PMNotificationIconWrapper>

      <PMNotificationBodyContainer data-transaction-name="notification-content">
        <PMNotificationText>
          <Trans message={text} withoutWrapper />
        </PMNotificationText>
      </PMNotificationBodyContainer>
      <PMNotificationIconWrapper
        onClick={handleCloseNotification}
        $cursor="pointer"
      >
        <CloseNotificationIcon />
      </PMNotificationIconWrapper>
    </PMNotificationContainer>
  );
};

export default NotificationPM;
