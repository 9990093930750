import { api, localStorageService, logger } from "../../../../services";
import { IPaymentInstructions } from "./interfaces";

const p2cChallenge: IPaymentInstructions = {
  title: "accountRequisitesTitleMakePayment",
  instructionList: [
    {
      title: "pan",
      getPaymentData: ({ data = {} }) => data?.card_number,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.card_number,
        text: "panCopied",
      }),
    },
    {
      title: "bankName",
      getPaymentData: ({ data = {} }) => data?.bank_name,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.bank_name,
        text: "bankNameCopied",
      }),
    },
    {
      title: "cardHolder",
      getPaymentData: ({ data = {} }) => data?.card_holder,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.card_holder,
        text: "cardHolderCopied",
      }),
    },
  ],
  getInfo: ({ data = {} }) =>
    !!data?.instruction ? "accountRequisitesMadeTransfer" : null,
  getExpirationDate: () => {
    let expirationDate;

    try {
      expirationDate = localStorageService.getSessionData(
        "sessionStorage",
        "expirationDate",
      )?.expirationDate;
    } catch (error) {
      logger.warn("Access to sessionStorage is denied", { error });
      return null;
    }

    if (!expirationDate) {
      const expirationTime = new Date(
        Date.now() + 10 * 60 * 1000,
      ).toISOString();

      try {
        localStorageService.setSessionData("sessionStorage", "expirationDate", {
          expirationDate: expirationTime,
        });
      } catch (error) {
        logger.warn("Failed to write to sessionStorage", { error });
      }

      return expirationTime;
    }

    return expirationDate;
  },
  submitAction: api.submitAccountRequisites,
};

export default p2cChallenge;
