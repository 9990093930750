import { useState } from "preact/hooks";
import { CONST_ERROR_LIST } from "../../services/constants";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import InputCvvDefault from "../../themes/default/components/InputCvv";
import InputCvvKombine from "../../themes/kombine/components/InputCvv";
import InputCvvPM from "../../themes/PM_brand/components/InputCvv";

const REQUIRED_LENGTH = 3;

const InputCvv = ({
  values,
  setValues,
  errors,
  setErrors,
  inputRef,
  nextStep,
}) => {
  const [focused, setFocused] = useState(false);

  const clearErrors = () => {
    setErrors((prev) => {
      delete prev.cvv;

      return prev;
    });
  };

  const onCvvInput = (e) => {
    let val = e.target.value.replace(/\s/g, "").replace(/[^\d]/g, "");

    if (val.length === REQUIRED_LENGTH) {
      clearErrors();

      setTimeout(() => {
        nextStep && nextStep.current && nextStep.current.focus();
      }, 0);
    }

    setValues((prev) => ({ ...prev, cvv: val }));
  };

  const onCvvBlur = () => {
    setFocused(false);

    if (values.cvv.length === 0) {
      return setErrors((prev) => ({
        ...prev,
        cvv: CONST_ERROR_LIST.required,
      }));
    }

    if (values.cvv.length < REQUIRED_LENGTH) {
      return setErrors((prev) => ({
        ...prev,
        cvv: CONST_ERROR_LIST.wrong_cvv,
      }));
    }

    clearErrors();
  };

  const errorChecking = () => {
    if (!errors.cvv) {
      return false;
    }

    if (focused) {
      return false;
    }

    return true;
  };

  const themeForComponent = useThemeComponent("InputCvv");

  const Cvv = {
    default: InputCvvDefault,
    kombine: InputCvvKombine,
    PM_brand: InputCvvPM,
  }[themeForComponent];

  return (
    <Cvv
      focused={focused}
      errorChecking={errorChecking}
      onCvvBlur={onCvvBlur}
      onCvvInput={onCvvInput}
      setFocused={setFocused}
      values={values}
      inputRef={inputRef}
      errors={errors}
    />
  );
};

export default InputCvv;
