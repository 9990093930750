import styled from "styled-components";
import { PM_COLORS } from "../../enums";

export const PMPopupHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 1px;
    background-color: #292929;
    opacity: 0.1;
  }
`;

export const PMPopupHeaderTitle = styled.span`
  font-family: var(--boldForm);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: ${PM_COLORS.GRAY_DARK};
`;
