import { FunctionComponent } from "preact";
import Trans from "../../../../components/Trans";
import Search from "../DropdownSearch";
import { PMPopupHeaderTitle, PMPopupHeaderWrapper } from "./style";

interface DropdownHeaderDefaultProps {
  label: string;
  handleClose: () => void;
  handleSearch: (query: string) => void;
}

const DropdownHeaderPM: FunctionComponent<DropdownHeaderDefaultProps> = ({
  label,
  handleSearch,
}) => {
  return (
    <>
      <PMPopupHeaderWrapper>
        <PMPopupHeaderTitle>
          <Trans message={label} withoutWrapper />
        </PMPopupHeaderTitle>
      </PMPopupHeaderWrapper>
      <Search onSearch={handleSearch} />
    </>
  );
};

export default DropdownHeaderPM;
