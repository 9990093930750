import { FunctionComponent } from "preact";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { DISPLAY_PAYMENT_METHODS } from "../../../../constants/paymentMethods";
import { Context } from "../../../../app";
import { state, theme } from "../../../../services";
import {
  HeaderLogoWrapper,
  OuterBlockHeader,
  TotalInfoWrapper,
} from "../../../../components/Header/style";
import GoBackAction from "../../../../components/GoBackButton";
import {
  DetailToggler,
  FormHeaderTitle,
  OuterInnerBlock,
} from "../../../../components/Challenge/ChallengeControlHeader/style";
import { InnerHeaderDataWrapper } from "../../../default/components/Instruction/style";
import {
  BlockDetailsWrapper,
  ContainerDetailsWrapper,
  DetailsTransactionTitle,
} from "../../../../components/BankInfo/style";
import {
  PaymentMethodTitle,
  PaymentMethodWrapper,
} from "../../../../components/Challenge/style";
import PaymentMethodsLogo from "../../../../components/PaymentMethods/PaymentMethodsLogo";
import Trans from "../../../../components/Trans";
import headerFieldsConfig from "../../../../components/Challenge/ChallengeHeaderFieldsConfig";
import ChallengeHeaderField from "../../../../components/Challenge/ChallengeHeaderField";
import { truncateText } from "../../../../services/utils";
import {
  CopyActionWrapper,
  TransactionCurrencyTitle,
  TransactionDecimalTitle,
  TransactionSumContainer,
  TransactionSumTitle,
  TransactionSumWrapper,
} from "../../../../components/PaymentDetails/style";
import { COLORS } from "../../../../constants/themeConstants";
import { IconColorWrapper } from "../../../../styles";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import IconCopyKombine from "../../../../assets/icons/iconCopyCombine";

interface Customer {
  first_name?: string;
  last_name?: string;
  itn?: string;
}

interface ChallengeHeaderProps {
  currentState: string;
  reference: string;
  wholeNumber: string;
  decimal?: string;
  currency: string;
  handleCopy: (value: string, title: string) => void;
  canReturnToMethods: boolean;
  mobileMoneyPhone?: string;
  selectedMethod: keyof typeof DISPLAY_PAYMENT_METHODS;
  type: string;
  customer?: Customer;
}

const ChallengeHeader: FunctionComponent<ChallengeHeaderProps> = ({
  currentState,
  reference,
  wholeNumber,
  decimal,
  currency,
  handleCopy,
  canReturnToMethods,
  mobileMoneyPhone,
  selectedMethod,
  type,
  customer = {},
}) => {
  const [height, setHeight] = useState(0);
  const [isDetailedInfo, setIsDetailedInfo] = useState(false);
  const [isTruncateText, setIsTruncateText] = useState(false);

  const { onGoBackButtonClick } = useContext(Context);

  const ref = useRef<HTMLDivElement>(null);

  const options = (
    state.get() as { options: { cancel_url?: string; theme?: string } }
  ).options;

  const { cancel_url } = options || {};

  const currentTheme = theme.getThemeConfig(options?.theme) as {
    isHideTransactionId?: boolean;
    isHideDescription?: boolean;
    isShowCurrencyCopy?: boolean;
  };

  const { first_name, last_name, itn } = customer;

  const { isHideTransactionId, isHideDescription, isShowCurrencyCopy } =
    currentTheme || {};

  const headerFieldData = {
    type,
    currentState,
    first_name,
    last_name,
    itn,
    reference,
    mobileMoneyPhone,
    wholeNumber,
    decimal,
    currency,
    isHideTransactionId,
    isHideDescription,
    isShowCurrencyCopy,
  };

  useEffect(() => {
    setHeight(ref.current?.clientHeight || 0);
  }, [ref.current]);

  const onToggleDetailsClick = (event: Event) => {
    event.preventDefault();

    setIsDetailedInfo(!isDetailedInfo);
  };

  return (
    <OuterBlockHeader>
      <HeaderLogoWrapper $alignItems={"center"} $pb="26px" $display="flex">
        {cancel_url || canReturnToMethods ? (
          <GoBackAction
            onClick={onGoBackButtonClick}
            cancelUrl={cancel_url || ""}
            cancelText="goBack"
            style={{ marginTop: "6px", position: "absolute" }}
          />
        ) : null}
        <FormHeaderTitle>Deposit</FormHeaderTitle>
      </HeaderLogoWrapper>
      <OuterInnerBlock $position={height} />
      <InnerHeaderDataWrapper $padding="20px" ref={ref}>
        <TotalInfoWrapper>
          <ContainerDetailsWrapper>
            <PaymentMethodWrapper>
              <PaymentMethodsLogo selectedMethod={selectedMethod} />
              <PaymentMethodTitle $color="#FFFFFFE5">
                {DISPLAY_PAYMENT_METHODS[selectedMethod]}
              </PaymentMethodTitle>
            </PaymentMethodWrapper>

            {isTruncateText ? (
              <DetailToggler onClick={onToggleDetailsClick}>
                {isDetailedInfo ? (
                  <Trans message="hideDetails" />
                ) : (
                  <Trans message="showDetails" />
                )}
              </DetailToggler>
            ) : null}
            {headerFieldsConfig.fields.map((field) => (
              <ChallengeHeaderField
                colorMainTitle="#FFFFFF99"
                titleFs="12px"
                colorSubtitle="#FFFFFFE5"
                key={field.label}
                field={field}
                data={headerFieldData}
                handleCopy={handleCopy}
                showAnotherIconCopy
                truncateText={(text: string) =>
                  truncateText(text, setIsTruncateText)
                }
                isDetailedInfo={isDetailedInfo}
              />
            ))}

            <BlockDetailsWrapper $mb="0">
              <DetailsTransactionTitle $fw="500" $controlledLength>
                <Trans message="amount" />
              </DetailsTransactionTitle>
              <TransactionSumWrapper>
                <TransactionSumContainer>
                  <TransactionSumTitle $fs="20px" $color={COLORS.DEFAULT_WHITE}>
                    {wholeNumber}
                  </TransactionSumTitle>
                  {decimal && (
                    <TransactionDecimalTitle
                      $fs="20px"
                      $color={COLORS.DEFAULT_WHITE}
                    >
                      .{decimal}
                    </TransactionDecimalTitle>
                  )}
                  <TransactionCurrencyTitle
                    $fs="20px"
                    $color="#FFFFFF99"
                    $ml="8px"
                  >
                    {currency}{" "}
                  </TransactionCurrencyTitle>
                </TransactionSumContainer>
                {isShowCurrencyCopy ? (
                  <CopyActionWrapper
                    onClick={() =>
                      handleCopy(
                        `${wholeNumber}${decimal ? `.${decimal}` : ""}`,
                        "amountCopy",
                      )
                    }
                  >
                    <IconColorWrapper>
                      <Tooltip text={"Copy"}>
                        <IconCopyKombine />
                      </Tooltip>
                    </IconColorWrapper>
                  </CopyActionWrapper>
                ) : null}
              </TransactionSumWrapper>
            </BlockDetailsWrapper>
          </ContainerDetailsWrapper>
        </TotalInfoWrapper>
      </InnerHeaderDataWrapper>
    </OuterBlockHeader>
  );
};

export default ChallengeHeader;
