/* eslint-disable no-use-before-define */
import { FunctionComponent, RefObject } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { api, i18n } from "../../services";
import { ContextUI, ModalData } from "../../providers/UIProvider";
import CustomerInput from "../CustomerInput";
import DropdownListDefault from "../../themes/default/components/DropdownList";
import DropdownListPM from "../../themes/PM_brand/components/DropdownList";
import DropdownHeaderDefault from "../../themes/default/components/DropdownHeader";
import DropdownHeaderPM from "../../themes/PM_brand/components/DropdownHeader";
import { useThemeComponent } from "../../hooks/useThemeComponent";

interface DropdownProps {
  nameKey: string;
  label: string;
  autocomplete?: string;
  pattern?: RegExp | ((params: { method?: any }) => RegExp);
  nonRequired?: boolean;
  autofocus?: boolean;
  values: Record<string, any>;
  setValues: (
    values: (prev: Record<string, any>) => Record<string, any>,
  ) => void;
  errors: Record<string, string>;
  setErrors: (
    errors: (prev: Record<string, string>) => Record<string, string>,
  ) => void;
  inputRef?: RefObject<HTMLInputElement>;
  placeholder?: string;
  inputOptions?: {
    key?: string;
    from?: string;
    showBankIcon?: boolean;
    useTranslatedSearch?: boolean;
    search?: (params: { option: any; search: string }) => boolean;
    display?: (params: { option: any }) => string;
    inputDisplay?: (value: any) => string;
    onChange?: (params: {
      option: any;
      setValues: DropdownProps["setValues"];
    }) => void;
  };
  options?: any[];
  staticDropdown?: boolean;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
  nameKey,
  label,
  autocomplete,
  pattern,
  nonRequired,
  autofocus,
  values,
  setValues,
  errors,
  setErrors,
  inputRef,
  placeholder,
  inputOptions = {},
  options = [],
  staticDropdown,
}) => {
  const { modalData, setModalData } = useContext(ContextUI);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [localSelected, setLocalSelected] = useState<Record<string, any>>({});
  const [localOptions, setLocalOptions] = useState<any[]>([]);

  const themeForDropdownListComponent = useThemeComponent("DropdownList");

  const List =
    {
      default: DropdownListDefault,
      PM_brand: DropdownListPM,
    }[themeForDropdownListComponent] ?? DropdownListDefault;

  const themeForDropdownHeaderComponent = useThemeComponent("DropdownHeader");

  const DropdownHeader =
    {
      default: DropdownHeaderDefault,
      PM_brand: DropdownHeaderPM,
    }[themeForDropdownHeaderComponent] ?? DropdownHeaderDefault;

  useEffect(() => {
    if (modalData?.isOpen && localOptions.length === 0) {
      setIsLoading(true);

      if (options.length) {
        setLocalOptions(options);
        setIsLoading(false);
      } else {
        api
          .getDropdownList({ uri: inputOptions.from || "" })
          .then((res) => {
            if (res && typeof res === "object") {
              setLocalOptions("data" in res ? res.data : res);
            } else {
              setLocalOptions([]);
            }
          })
          .catch(() => setLocalOptions([]))
          .finally(() => setIsLoading(false));
      }
    }
  }, [modalData?.isOpen]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleChange = (option: any) => {
    setLocalSelected(option);

    if (inputOptions?.onChange && typeof inputOptions.onChange === "function") {
      inputOptions.onChange({ option, setValues });
    } else if (staticDropdown) {
      setValues((prev) => ({
        ...prev,
        [nameKey]: option[inputOptions.key ?? "id"] || option,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        [nameKey]: option[inputOptions.key ?? "id"],
      }));
    }

    handleClose();
  };

  useEffect(() => {
    setModalData((prev: ModalData) => ({
      ...prev,
      modalName: "dropdown",
      isLoading,
    }));
  }, [isLoading]);

  const filterOptions = (
    options: any[],
    search: string,
    inputOptions: DropdownProps["inputOptions"],
  ) => {
    return options.filter((option) => {
      if (inputOptions?.useTranslatedSearch) {
        const translatedOptionName = i18n
          ?.getMessage({ message: option.label })
          ?.toLowerCase();

        const translatedSearch = i18n
          ?.getMessage({ message: search })
          ?.toLowerCase();

        if (!search) return true;

        return translatedOptionName?.includes(translatedSearch);
      }

      return inputOptions?.search && search
        ? inputOptions?.search({ option, search })
        : true;
    });
  };

  useEffect(() => {
    if (modalData.modalName === "dropdown") {
      setModalData((prev) => ({
        ...prev,
        modalName: "dropdown",
        content: (
          <List
            showBankIcon={inputOptions?.showBankIcon}
            foreignKey={inputOptions?.key || ""}
            options={filterOptions(localOptions, search, inputOptions)}
            selected={localSelected}
            onChange={handleChange}
            display={inputOptions?.display}
            inputOptions={inputOptions}
          />
        ),
      }));
    }
  }, [localOptions, search]);

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      modalName: "dropdown",
      content: (
        <List
          showBankIcon={inputOptions?.showBankIcon}
          foreignKey={inputOptions?.key || ""}
          options={filterOptions(localOptions, search, inputOptions)}
          selected={localSelected}
          onChange={handleChange}
          display={inputOptions?.display}
          inputOptions={inputOptions}
        />
      ),
      header: () => (
        <DropdownHeader
          label={label}
          handleClose={handleClose}
          handleSearch={handleSearch}
        />
      ),
      isFullView: true,
      isLoading,
    });

    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[nameKey];
      return newErrors;
    });
  };

  const handleClose = () => {
    setModalData({
      isOpen: false,
      content: null,
      header: null,
      title: "",
      modalName: "",
      subTitle: "",
      isFullView: false,
      isLoading: false,
    });

    setSearch("");
  };

  return (
    <CustomerInput
      nameKey={nameKey}
      placeholder={placeholder || "pleaseSelectOption"}
      label={label}
      autocomplete={autocomplete}
      autofocus={autofocus}
      pattern={pattern}
      nonRequired={nonRequired}
      values={values}
      setValues={setValues}
      errors={errors}
      setErrors={setErrors}
      inputRef={inputRef}
      onClick={handleOpen}
      inputDisplay={inputOptions?.inputDisplay}
      isDropdown
    />
  );
};

export default Dropdown;
