// helpers/cryptoUtils.ts
import { useMemo } from "preact/hooks";
import { sum } from "../../services/utils";
import { onCustomerFee } from "./utils";

export const useSumMinValue = (
  min: number | string,
  network_fee: number | string,
) => {
  return useMemo(() => {
    const isOnCustomerFee = onCustomerFee();

    return !isOnCustomerFee
      ? Number(min)
      : [min, network_fee]
          .map((value) => (!!value && !isNaN(+value) ? +value : 0))
          .reduce((acc, value) => sum(acc, value), 0);
  }, [min, network_fee]);
};

export const useIsAmountBelowMin = (
  pay_amount: number | string,
  sumMinValue: number | string,
) => {
  return useMemo(() => {
    return +pay_amount < +sumMinValue;
  }, [pay_amount, sumMinValue]);
};
