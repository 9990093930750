import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const PopupWrapper = styled.div<{
  $isFullView?: string;
}>`
  z-index: 200;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme?.managePopupBackgroundColor};
  width: 100%;
  max-width: 440px;
  max-height: 100%;
  height: auto;
  min-height: ${({ $isFullView }) => ($isFullView ? "calc(100% - 0px)" : "0")};
  padding: ${({ theme }) => theme?.paddingPopup || "20px 0 40px"};
  border-radius: ${({ $isFullView }) => ($isFullView ? "0" : "16px 16px 0 0")};
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;

  & > div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 16px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const PopupHeaderContainer = styled.div<{
  $withHeader?: string;
}>`
  flex-shrink: 0;
  display: ${({ $withHeader }) => ($withHeader ? "block" : "none !important")};
`;

export const PopupChildrenContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PopupMainWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  &:hover {
    img {
      cursor: pointer;
    }
  }
`;

export const PopupTitle = styled.span<{ $hasSubtitle?: boolean }>`
  span {
    font-size: 18px;
    font-family: var(--boldFont);
    font-weight: ${({ $hasSubtitle }) => ($hasSubtitle ? 600 : 500)};
    color: ${({ theme }) => theme?.popupTextTitleColor};
    line-height: 25px;
  }
`;

export const PopupSubtitle = styled.span`
  font-size: 16px;
`;

export const PopupContentActionWrapper = styled.div<{
  $isActive?: boolean;
}>`
  padding: 12px 16px;
  border-radius: 10px;
  height: 48px;
  background-color: ${({ $isActive }) =>
    $isActive ? COLORS.BlUE_LIGHT : "#f0f4f5"};
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  & > img {
    height: 24px !important;
    width: 24px !important;
    border-radius: 50%;
    object-fit: cover;
  }

  &:hover {
    background-color: ${COLORS.BlUE_LIGHT};
  }
`;

export const PopupIcon = styled.div<{ $iconWidth?: string }>`
  width: ${({ $iconWidth }) => $iconWidth};
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
  }
`;

export const PopupArrowIcon = styled.div`
  width: 16px !important;
  height: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
  }
`;

export const PopupActionTitle = styled.span<{
  $fw?: number | string;
  $color?: string;
}>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: ${({ $fw }) => $fw ?? 600};
  color: ${({ $color }) => $color || COLORS.DEFAULT_BLACK};
  font-family: var(--regularFont);
  ::first-letter {
    text-transform: uppercase;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--regularFont);
  }
`;

export const PopupActionSubTitle = styled(PopupActionTitle)`
  margin-left: 8px;
`;

export const PopupItemLoadingBlock = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;

  & > div > div,
  & img {
    height: 24px !important;
    width: 24px !important;
  }
`;
