import { createContext, FunctionComponent, JSX } from "preact";
import { useState } from "preact/hooks";
import FormPopup from "../components/FormPopup";
import Notification from "../components/Notification";
import { state } from "../services";

export interface ModalData {
  isOpen?: boolean;
  content?: JSX.Element | null;
  header?: (() => JSX.Element) | null;
  title?: string;
  subTitle?: string;
  isFullView?: boolean;
  modalName?: string;
  isLoading?: boolean;
}

interface NotificationData {
  isOpen: boolean;
  isError?: boolean;
  text: string;
  status: "success" | "error" | "fail";
  isCloseAutomatically?: boolean;
}

interface UIContextProps {
  notificationData: NotificationData;
  setNotificationData: (data: NotificationData) => void;
  modalData: ModalData;
  setModalData: (data: ModalData | ((prev: ModalData) => ModalData)) => void;
  handleCloseModal: () => void;
  handleCopy: (copy: string, text: string) => void;
  handleCloseNotification: () => void;
  handleDownloadImage: (base64: string, filename?: string) => void;
}
const MODAL_DATA_INIT: ModalData = {
  isOpen: false,
  content: null,
  header: null,
  title: "",
  subTitle: "",
  isFullView: false,
  modalName: "",
};

const NOTIFICATION_DATA_INIT: NotificationData = {
  isOpen: false,
  isError: false,
  text: "",
  status: "success",
  isCloseAutomatically: true,
};

export const ContextUI = createContext<UIContextProps>({
  notificationData: NOTIFICATION_DATA_INIT,
  setNotificationData: () => {},
  modalData: MODAL_DATA_INIT,
  setModalData: () => {},
  handleCloseModal: () => {},
  handleCopy: () => {},
  handleCloseNotification: () => {},
  handleDownloadImage: () => {},
});

const UIProvider: FunctionComponent = ({ children }) => {
  const [modalData, setModalData] = useState<ModalData>(MODAL_DATA_INIT);
  const [notificationData, setNotificationData] = useState<NotificationData>(
    NOTIFICATION_DATA_INIT,
  );

  const handleCloseModal = () => {
    setModalData(MODAL_DATA_INIT);
  };

  const handleCloseNotification = () => {
    setNotificationData(NOTIFICATION_DATA_INIT);
    state.set({ notification: null });
  };

  const handleCopy = (value: string, text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = value;

    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);

    const notification: NotificationData = {
      isOpen: true,
      status: "success",
      text,
      isCloseAutomatically: true,
    };

    setNotificationData((prev) => ({
      ...prev,
      ...notification,
    }));
  };

  const handleDownloadImage = (base64: string, filename = "image.png") => {
    if (!base64) return;

    const link = document.createElement("a");
    link.href = base64;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <ContextUI.Provider
      value={{
        notificationData,
        setNotificationData,
        modalData,
        setModalData,
        handleCloseModal,
        handleCloseNotification,
        handleCopy,
        handleDownloadImage,
      }}
    >
      <Notification />
      <FormPopup />
      {children}
    </ContextUI.Provider>
  );
};

export default UIProvider;
