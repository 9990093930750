import {
  InfoMainTitleContainer,
  InnerHeaderDataWrapper,
  InstructionWrapper,
  InstructionStepWrapper,
  InstructionStepTitleWrapper,
  InstructionStepNumber,
  InstructionStepTitle,
  InstructionStepSecondaryTitle,
  InstructionStepDataWrapper,
  InstructionStepDataText,
  InstructionStepCopyIconWrapper,
  NonOrderListItemWrapper,
  NonOrderListItemTitle,
  NonOrderListItemValue,
  ButtonWrapper,
} from "./style";
import InfoIcon from "../../../../assets/icons/infoIcon";
import { BlockDetailsWrapper } from "../../../../components/BankInfo/style";
import {
  ChallengeInfoWrapper,
  ChallengeMainTitle,
  StyledInfoImage,
} from "../../../../components/Challenge/style";
import Trans from "../../../../components/Trans";
import {
  IconColorWrapper,
  InnerLine,
  IconColorCopyTitle,
} from "../../../../styles";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import IconCopy from "../../../../assets/icons/iconCopy";
import ExpirationDate from "../../../../components/BankInfo/ExpirationDate";
import { FunctionComponent } from "preact";
import { IPaymentInstructions } from "../../configs/instructions/interfaces";
import PaymentMethodsLogo from "../../../../components/PaymentMethods/PaymentMethodsLogo";
import Button from "../../../../components/Button";

interface InstructionDefaultProps {
  title?: string;
  instructionList?: any[];
  info?: string | null;
  expirationDate?: string | null;
  infoBlockHasBottomPosition?: boolean;
  instructionData: Record<string, string>;
  currentState: any;
  handleCopy: (copy: string, text: string) => void;
  config: IPaymentInstructions;
  handleButtonClick?: () => void;
  buttonText?: string;
}

const InstructionDefault: FunctionComponent<InstructionDefaultProps> = ({
  title,
  instructionList,
  info,
  expirationDate,
  infoBlockHasBottomPosition = true,
  instructionData,
  currentState,
  handleCopy,
  config,
  handleButtonClick,
  buttonText,
}) => {
  const { isOrderedList } = config || {};
  const renderInfo = () => {
    if (info) {
      return (
        <>
          <BlockDetailsWrapper $mb="0" $width="100%" $justifyContent="center">
            <ChallengeInfoWrapper>
              <StyledInfoImage>
                <InfoIcon />
              </StyledInfoImage>
              <InfoMainTitleContainer>
                <Trans message={info} />
              </InfoMainTitleContainer>
            </ChallengeInfoWrapper>
          </BlockDetailsWrapper>
          {!infoBlockHasBottomPosition && (
            <InnerLine style={{ marginBottom: 0 }} />
          )}
        </>
      );
    }
    return null;
  };

  if (!config || Object.keys(config).length === 0) {
    return null;
  }

  return (
    <>
      <InnerHeaderDataWrapper
        $padding={!title && !instructionList ? "0" : "20px"}
        $gap="16px"
      >
        {!infoBlockHasBottomPosition && renderInfo()}
        {title && (
          <ChallengeMainTitle data-transaction-name="instruction-title">
            <Trans message={title} />:
          </ChallengeMainTitle>
        )}

        {instructionList && (
          <InstructionWrapper>
            {instructionList.map((step, index) => {
              const {
                title,
                secondaryTitle,
                getPaymentData,
                getCopyInfo,
                getIcon,
              } = step || {};
              const paymentData =
                getPaymentData &&
                getPaymentData({
                  data: instructionData,
                  state: currentState || {},
                });
              const { copy, text, iconTitle } =
                (getCopyInfo &&
                  getCopyInfo({
                    data: instructionData,
                    state: currentState || {},
                  })) ||
                {};

              const currentIcon =
                getIcon &&
                typeof getIcon === "function" &&
                getIcon({ data: instructionData });

              return (
                <InstructionStepWrapper key={index}>
                  {isOrderedList !== false && (
                    <>
                      <InstructionStepTitleWrapper>
                        <InstructionStepNumber>
                          {index + 1}
                        </InstructionStepNumber>
                        <InstructionStepTitle>
                          <Trans message={title} withoutWrapper />
                          {secondaryTitle && (
                            <InstructionStepSecondaryTitle
                              bold={secondaryTitle?.bold}
                            >
                              <Trans message={secondaryTitle.text} />
                            </InstructionStepSecondaryTitle>
                          )}
                        </InstructionStepTitle>
                      </InstructionStepTitleWrapper>

                      {paymentData && (
                        <InstructionStepDataWrapper>
                          <InstructionStepDataText>
                            {paymentData}
                          </InstructionStepDataText>
                          {copy && (
                            <InstructionStepCopyIconWrapper
                              onClick={() => handleCopy(copy, text)}
                            >
                              <IconColorWrapper>
                                {iconTitle && (
                                  <IconColorCopyTitle>
                                    <Trans message={iconTitle} />
                                  </IconColorCopyTitle>
                                )}
                                <Tooltip text={"Сopy"}>
                                  <IconCopy />
                                </Tooltip>
                              </IconColorWrapper>
                            </InstructionStepCopyIconWrapper>
                          )}
                        </InstructionStepDataWrapper>
                      )}
                    </>
                  )}
                  {isOrderedList === false && (
                    <NonOrderListItemWrapper>
                      <NonOrderListItemTitle>
                        <Trans message={title} withoutWrapper />
                      </NonOrderListItemTitle>
                      <NonOrderListItemValue>
                        {currentIcon && (
                          <PaymentMethodsLogo selectedMethod={currentIcon} />
                        )}
                        {paymentData}
                      </NonOrderListItemValue>
                      {copy && (
                        <InstructionStepCopyIconWrapper
                          onClick={() => handleCopy(copy, text)}
                        >
                          <IconColorWrapper>
                            <Tooltip text={"Сopy"}>
                              <IconCopy />
                            </Tooltip>
                          </IconColorWrapper>
                        </InstructionStepCopyIconWrapper>
                      )}
                    </NonOrderListItemWrapper>
                  )}
                </InstructionStepWrapper>
              );
            })}
          </InstructionWrapper>
        )}

        {expirationDate && <ExpirationDate expiration_date={expirationDate} />}
        {infoBlockHasBottomPosition && renderInfo()}
      </InnerHeaderDataWrapper>
      {handleButtonClick && (
        <ButtonWrapper>
          <Button onClick={handleButtonClick}>
            <Trans message={buttonText || ""} />
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
};

export default InstructionDefault;
