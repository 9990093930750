import asquadFooterIcon from "../../assets/icons/asquadFooterIcon.png";
import asquadLogoFooter from "../../assets/icons/asquadLogoFooter.png";
import kombineFooterIcon from "../../assets/icons/kombineFooterIcon.svg";
import { useColorScheme } from "../../services/hooks";
import { state } from "../../services";
import { FooterContainer } from "./style";
import { FunctionalComponent } from "preact";

interface FooterProps {
  margin?: string;
  background?: string;
  forceOnMobile?: boolean;
}

interface IState {
  options?: {
    theme?: string;
  };
}
interface IColorTheme {
  colorTheme?: {
    showSecurityIcon?: boolean;
  };
}

const Footer: FunctionalComponent<FooterProps> = ({
  margin,
  background,
  forceOnMobile,
}) => {
  const options = (state.get() as IState).options;

  const { colorTheme } = useColorScheme(options?.theme) as IColorTheme;

  const footerIconConfig = {
    default: asquadFooterIcon,
    kombine: kombineFooterIcon,
  };

  return (
    <FooterContainer
      $margin={margin}
      $background={background}
      $forceOnMobile={forceOnMobile}
    >
      <img
        width="180px"
        height="24px"
        src={`${footerIconConfig[options?.theme as keyof typeof footerIconConfig] || asquadFooterIcon} `}
      />
      {colorTheme?.showSecurityIcon && (
        <img width="123px" height="24px" src={`${asquadLogoFooter}`} />
      )}
    </FooterContainer>
  );
};

export default Footer;
