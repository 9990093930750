import defaultCard from "../../../../assets/icons/anotherCardPb.svg";
import { ManageCardProps } from "../../../commons/interfaces/CardInterfaces";
import Trans from "../../../../components/Trans";
import { CARD_BRANDS } from "../../../../constants/cardBrand";
import { COLORS } from "../../../../constants/themeConstants";
import {
  ActionButtonCardEditWrapper,
  ActionButtonCardList,
  CardLabelDate,
  CardLabelNumber,
  DeleteCardPopupTitle,
  SavedCardInner,
  SavedCardInnerWrapper,
  SavedCardWrapper,
} from "../../../commons/styles/ManageCardStyles";
import { ManageCardWrapper, SavedCardPopUpTitle } from "./style";
import { FunctionComponent } from "preact";

const ManageCard: FunctionComponent<ManageCardProps> = ({
  card,
  handleRemoveCard,
  handleCancel,
  handleCheckSavedCard,
}) => (
  <ManageCardWrapper>
    <SavedCardPopUpTitle>
      <Trans message="Delete card?" />
    </SavedCardPopUpTitle>

    <SavedCardWrapper key={card.last4}>
      <SavedCardInnerWrapper
        $height="64px"
        $backgroundColor="#05224C"
        onClick={() => handleCheckSavedCard(card)}
      >
        <img
          style={{ width: "64px", height: "32px" }}
          src={CARD_BRANDS[card.brand] || defaultCard}
          alt="brand"
        />
        <SavedCardInner>
          <CardLabelNumber
            $color={COLORS.DEFAULT_WHITE}
            style={{ margin: "0" }}
          >{`${card.bin?.slice(0, 4)} ${card.bin?.slice(
            4,
            6,
          )}** **** ${card?.last4}`}</CardLabelNumber>
          <CardLabelDate
            style={{
              color: "#fff",
              fontSize: "12px",
              width: "130px",
              marginLeft: "0",
            }}
          >
            <span style={{ color: "#FFFFFF99", fontSize: "12px" }}>
              Expiration:{" "}
            </span>
            {`${card?.exp_month}/${
              card?.exp_year?.length === 4
                ? card?.exp_year?.slice(2, 4)
                : card?.exp_year
            }`}
          </CardLabelDate>
        </SavedCardInner>
      </SavedCardInnerWrapper>
    </SavedCardWrapper>
    <DeleteCardPopupTitle>
      <Trans message="dltCardSystemTitle" withoutWrapper />
    </DeleteCardPopupTitle>
    <ActionButtonCardEditWrapper>
      <ActionButtonCardList
        $background="#10366B"
        type="button"
        $color={COLORS.DEFAULT_WHITE}
        $maxWidth="147px"
        $width="100%"
        $fontSize="16px"
        $textTransform="uppercase"
        $fontWeight="700"
        onClick={handleCancel}
      >
        <Trans message="cancel" />
      </ActionButtonCardList>

      <ActionButtonCardList
        $background="linear-gradient(#C71432, #FF5975)"
        type="button"
        $color="#fff"
        $width="100%"
        $maxWidth="147px"
        $fontSize="16px"
        $textTransform="uppercase"
        $fontWeight="700"
        onClick={() => handleRemoveCard(card.identifier)}
      >
        <Trans message="delete" />
      </ActionButtonCardList>
    </ActionButtonCardEditWrapper>
  </ManageCardWrapper>
);

export default ManageCard;
