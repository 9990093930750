import { state } from "../../services";

export const formatNumberWithSpaces = (
  number: string | number,
  label: string,
) => {
  if (isNaN(+number) && number !== "") {
    return "";
  }

  if (label === "send") {
    return number;
  }

  const parts = number?.toString()?.split(".");

  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  const maxDecimalLength = 2;

  if (parts[1]) {
    parts[1] = parts[1]?.slice(0, maxDecimalLength);
  }

  return parts.join(".");
};

export const onCustomerFee = () => {
  const selectedMethod = state.getSelectedPaymentMethod();

  const networkFeeField = selectedMethod?.public_fields?.find(
    (field: { name: string }) => field.name === "network_fee",
  );

  return networkFeeField?.value === "on_customer";
};
