import { useContext, useState } from "preact/hooks";
import { CryptoState } from "../../../../Context";
import { Context } from "../../../../app";
import { useSumMinValue } from "../../../../components/CryptoWidgetForm/cryptoUtils";
import { CryptoCurrencyNotActiveWrapper } from "../CryptoForm/style";
import Trans from "../../../../components/Trans";
import { Bold } from "../../../../ui/GeneralStyles/style";
import {
  BaseButton,
  ButtonWrapper,
} from "../../../../themes/commons/styles/ButtonStyles";
import Instruction, {
  TInstructionData,
} from "../../../../components/Instruction";
import { FormLoaderWrapper } from "../../../../components/Loaders/style";
import Loader from "../../../../components/Loaders";
import Button from "../../../../components/Button";
import {
  PMButtonWrapper,
  PMInnerHeaderDataWrapper,
  PMModalContainer,
  PMModalOverlay,
  PMModalText,
  PMModalTitle,
  PMModalWrapper,
} from "./style";
import { EnumVariant } from "../../../commons/enums/ButtonEnums";
import CryptoForm from "../../../../components/CryptoWidgetForm/CryptoForm";
interface CryptoInputData {
  currency_amount?: string;
  pay_amount?: string;
}
interface CryptoContentProps {
  redirectUrl: string;
  networkFeeField?: string;
  isMinNotificationEnabled?: boolean;
  isCryptoCalculated?: boolean;
  exchangeData: {
    info?: {
      active: boolean;
      amount: { min: number };
      waiting_time_minutes: number;
      network_fee: number;
    };
    data?: {
      info?: {
        active: boolean;
        amount: { min: number };
        waiting_time_minutes: number;
        network_fee: number;
      };
    };
  };
  currentState: CryptoState;
  isCryptoFormVisible?: boolean;
  onAmountChange: (data: CryptoInputData) => Promise<void>;
}

const CryptoContent = ({
  isCryptoFormVisible,
  currentState,
  exchangeData,
  networkFeeField,
  redirectUrl,
  isCryptoCalculated,
  isMinNotificationEnabled,
  onAmountChange,
}: CryptoContentProps) => {
  const { onGoBackButtonClick } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);

  const {
    active,
    amount,
    waiting_time_minutes = 5,
    network_fee,
  } = exchangeData?.data?.info || exchangeData?.info || {};
  const { min } = amount || {};
  const sumMinValue = useSumMinValue(min ?? 0, network_fee ?? 0);
  const hasMinValue = !!min && !isNaN(+min) && +min !== 0;

  const handlePaymentClick = () => {
    setIsOpen(true);
  };

  const handleGoBack = () => {
    setIsOpen(false);

    onGoBackButtonClick(redirectUrl);
  };

  if (active === false) {
    return (
      <CryptoCurrencyNotActiveWrapper>
        <Instruction
          configName="crypto_currency_not_active"
          instructionData={{}}
        />
        <ButtonWrapper style={{ marginTop: "auto" }}>
          <BaseButton
            $textTransform="initial"
            onClick={() =>
              onGoBackButtonClick("", { canReturnToMethods: true })
            }
            style={{ marginTop: "auto" }}
          >
            <Trans message="backToMethods" />
          </BaseButton>
        </ButtonWrapper>
      </CryptoCurrencyNotActiveWrapper>
    );
  }

  if (
    !isCryptoFormVisible ||
    !currentState?.pay_currency ||
    !currentState?.network ||
    !currentState?.address
  ) {
    return (
      <FormLoaderWrapper style={{ height: "100%" }}>
        <Loader />
      </FormLoaderWrapper>
    );
  }
  return (
    <>
      {isOpen && (
        <PMModalWrapper>
          <PMModalOverlay onClick={() => setIsOpen(false)} />
          <PMModalContainer>
            <PMModalTitle>
              <Trans message="paymentInProgress" withoutWrapper />
            </PMModalTitle>
            <PMModalText>
              <Trans
                message="paymentInProgressDescription"
                params={{ minutes: waiting_time_minutes }}
                components={[Bold]}
                withoutWrapper
              />
            </PMModalText>
            <Button variant={EnumVariant.SECONDARY} onClick={handleGoBack}>
              <Trans message="backToWebsite" withoutWrapper />
            </Button>
          </PMModalContainer>
        </PMModalWrapper>
      )}

      <PMInnerHeaderDataWrapper>
        <Instruction
          configName="crypto"
          instructionData={
            {
              ...currentState,
              sumMinValue,
              hasMinValue,
              isMinNotificationEnabled,
              isCryptoCalculated: isCryptoCalculated || false,
              networkFeeField: networkFeeField || "",
            } as TInstructionData
          }
          customComponent={
            isCryptoCalculated ? (
              <CryptoForm
                currentState={currentState}
                onAmountChange={onAmountChange}
                exchangeData={{ network_fee, min }}
                isMinNotificationEnabled={isMinNotificationEnabled}
                options={{
                  copySendAmountEnabled: true,
                }}
              />
            ) : null
          }
        />
      </PMInnerHeaderDataWrapper>

      <PMButtonWrapper>
        <Button onClick={handlePaymentClick}>
          <Trans message="iHaveMadePayment" />
        </Button>
      </PMButtonWrapper>
    </>
  );
};

export default CryptoContent;
