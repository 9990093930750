import { useContext } from "preact/hooks";
import { ContextUI } from "../../providers/UIProvider";
import { Context } from "../../app";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import InstructionDefault from "../../themes/default/components/Instruction";
import InstructionPM from "../../themes/PM_brand/components/Instruction";
import { state } from "../../services";
import instructionsDefault from "../../themes/default/configs/instructions";
import instructionsPM from "../../themes/PM_brand/configs/instructions";

export type TInstructionData = { [key: string]: string | boolean | number };

const Instruction = ({
  configName,
  instructionData = {},
  customComponent,
  handleButtonClick,
  buttonText,
}: {
  configName: keyof typeof instructionsDefault | keyof typeof instructionsPM;
  instructionData: TInstructionData;
  handleButtonClick?: () => void;
  buttonText?: string;
  customComponent?: React.ReactNode;
}) => {
  const { currentState } = useContext(Context);
  const { handleCopy } = useContext(ContextUI);
  const options = (state.get() as { options: { theme?: string } }).options;
  const theme = options?.theme;
  const instructions =
    {
      default: instructionsDefault,
      PM_brand: instructionsPM,
    }[theme || "default"] || instructionsDefault;

  const config =
    instructions[configName as keyof typeof instructions] ||
    instructionsDefault[configName as keyof typeof instructionsDefault];

  if (!config) {
    return null;
  }

  const themeForComponent = useThemeComponent("Instruction");

  const {
    title,
    instructionList,
    getInfo,
    getExpirationDate,
    infoBlockHasBottomPosition = true,
  } = config || {};
  const info =
    getInfo && getInfo({ data: instructionData as Record<string, string> });
  const expirationDate =
    getExpirationDate &&
    getExpirationDate({ data: instructionData as Record<string, string> });

  const InstructionComponent = {
    default: InstructionDefault,
    PM_brand: InstructionPM,
  }[themeForComponent];

  if (!InstructionComponent) return null;

  return (
    <InstructionComponent
      title={title}
      instructionList={instructionList}
      info={info}
      expirationDate={expirationDate}
      infoBlockHasBottomPosition={infoBlockHasBottomPosition}
      instructionData={instructionData as Record<string, string>}
      currentState={currentState}
      handleCopy={handleCopy}
      config={config as any}
      handleButtonClick={handleButtonClick}
      buttonText={buttonText}
      customComponent={customComponent}
    />
  );
};

export default Instruction;
