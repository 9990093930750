import { useContext } from "preact/hooks";
import { ContextUI } from "../../../providers/UIProvider";
import { state } from "../../../services";
import clearModalControl from "./ClearModalControl";

interface ClearFormModalsProps {
  resetCardForm?: () => void;
}

const ClearFormModal: React.FC<ClearFormModalsProps> = ({ resetCardForm }) => {
  const { handleCloseModal } = useContext(ContextUI);
  // @ts-ignore
  const options = state.get().options;

  const ControlClearForm =
    // @ts-ignore
    clearModalControl[options?.theme] || clearModalControl.default;

  return (
    <>
      <ControlClearForm
        resetCardForm={resetCardForm}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default ClearFormModal;
