import styled from "styled-components";
import { EnumSize, EnumVariant } from "../../../commons/enums/ButtonEnums";
import { COLORS } from "../../../../constants/themeConstants";

interface IPropsButtonStyled {
  $width?: string;
  $mb?: string;
  $textTransform?: string;
  $variant?: EnumVariant;
  $size?: EnumSize;
  $isInIframe?: boolean;
}

interface VariantConfig {
  buttonColor: string;
  buttonTextColor: string;
}

const VARIANT_CONFIG: Record<EnumVariant, VariantConfig> = {
  [EnumVariant.PRIMARY]: {
    buttonColor: COLORS.BLUE,
    buttonTextColor: COLORS.DEFAULT_WHITE,
  },
  [EnumVariant.SECONDARY]: {
    buttonColor: COLORS.LIGHT_GRAY,
    buttonTextColor: COLORS.GRAY,
  },
  [EnumVariant.GRAY]: {
    buttonColor: COLORS.LIGHT_GRAY,
    buttonTextColor: COLORS.GRAY,
  },
};

interface SizeConfig {
  width: string;
}

const SIZE_CONFIG: Record<EnumSize, SizeConfig> = {
  [EnumSize.AUTO]: {
    width: "auto",
  },
};

export const ButtonStyled = styled.button<IPropsButtonStyled>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  width: ${({ $width, $size }) =>
    $size ? SIZE_CONFIG[$size].width : $width || "100%"};
  max-width: 392px;
  height: 40px;
  border-radius: ${(props) => props.theme.elementBorderRadius || ""};
  background: ${(props) =>
    props.$variant && VARIANT_CONFIG[props.$variant]?.buttonColor
      ? VARIANT_CONFIG[props.$variant]?.buttonColor
      : props.theme.buttonColor || ""};
  border: ${(props) =>
    props.$variant && VARIANT_CONFIG[props.$variant]?.buttonColor
      ? `1px solid ${VARIANT_CONFIG[props.$variant]?.buttonColor}`
      : props.theme.buttonColor
        ? `1px solid ${props.theme.buttonColor}`
        : "none"};
  border-radius: ${({ theme }) => theme.elementBorderRadius || ""};
  color: ${(props) =>
    props.$variant && VARIANT_CONFIG[props.$variant]?.buttonTextColor
      ? VARIANT_CONFIG[props.$variant]?.buttonTextColor
      : props.theme.buttonTextColor || ""};
  font-weight: 500;
  font-size: ${({ theme }) => theme.mainFormFontSize || "18px"};
  line-height: 20px;
  margin: ${({ $isInIframe, theme }) =>
    $isInIframe
      ? theme.iframeButtonMargin
      : theme.buttonMargin || "auto auto 16px"};
  margin-bottom: ${({ $mb }) => $mb || "0px"};
  font-family: var(--regularFont);
  transition: ease 0.3s;
  cursor: pointer;
  appearance: none;
  outline: 0;
  text-transform: ${({ $textTransform }) => $textTransform || "uppercase"};

  & + div {
    margin-top: 0;
  }

  &:hover {
    background-color: ${COLORS.DEFAULT_BLACK};
    color: ${COLORS.DEFAULT_WHITE};
  }

  & span {
    font-family: var(--regularFont);
    font-size: ${({ theme }) => theme.mainFormFontSize};
  }

  &:disabled {
    background-color: #7b949d;
    pointer-events: none;
    opacity: ${({ theme }) => theme.disableButtonOpacity || "1"};
  }
`;
