import { useState, useEffect, useContext, useRef } from "preact/hooks";
import { IconColorWrapper } from "../../styles";
import {
  AmountInput as AmountInputDefault,
  CryptoCurrencyInputTitle as CryptoCurrencyInputTitleDefault,
  CryptoCurrencyInputTitleWrapper as CryptoCurrencyInputTitleWrapperDefault,
  CurrencySymbol as CurrencySymbolDefault,
  InputWrapper as InputWrapperDefault,
  InputWrapperInner as InputWrapperInnerDefault,
} from "../../themes/default/components/CryptoForm/style";
import {
  AmountInput as AmountInputPM,
  CryptoCurrencyInputTitle as CryptoCurrencyInputTitlePM,
  CryptoCurrencyInputTitleWrapper as CryptoCurrencyInputTitleWrapperPM,
  CurrencySymbol as CurrencySymbolPM,
  InputWrapper as InputWrapperPM,
  InputWrapperInner as InputWrapperInnerPM,
} from "../../themes/PM_brand/components/CryptoForm/style";
import IconCopy from "../../assets/icons/iconCopy";
import { formatCryptoCurrency } from "../../constants/CryptoCurrencyIcon";
import { ContextUI } from "../../providers/UIProvider";
import { adjustFontSize } from "../../services/utils";
import FlexLoad from "../Loaders/FlexLoader";
import { FormLoaderWrapper } from "../Loaders/style";
import Trans from "../Trans";
import { formatNumberWithSpaces } from "./utils";
import { FunctionComponent } from "preact";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import { PMItemIconAction } from "../../themes/PM_brand/components/SavedCard/style";
import Tooltip from "../Tooltip/Tooltip";
import CopyIcon from "../../assets/icons/brand/pm/ui/CopyIcon";
import Loader from "../Loaders";
interface CryptoInputProps {
  label: string;
  name: string;
  currency?: string;
  value?: string;
  onChange?: (e: { target: { name: string; value: string } }) => void;
  copyValue?: string;
  autofocus?: boolean;
  disabled?: boolean;
  isAmountBelowMin?: boolean;
  isLoading?: boolean;
  isFirstInput?: boolean;
}

const CryptoInput: FunctionComponent<CryptoInputProps> = ({
  label,
  name,
  currency,
  value = "",
  onChange,
  copyValue,
  autofocus = false,
  disabled = false,
  isAmountBelowMin = false,
  isLoading = false,
  isFirstInput = false,
}) => {
  const themeForComponent = useThemeComponent("CryptoForm");

  const InputLoader =
    {
      default: FlexLoad,
      PM_brand: Loader,
    }[themeForComponent] || FlexLoad;

  const AmountInput =
    {
      default: AmountInputDefault,
      PM_brand: AmountInputPM,
    }[themeForComponent] || AmountInputDefault;

  const CryptoCurrencyInputTitle =
    {
      default: CryptoCurrencyInputTitleDefault,
      PM_brand: CryptoCurrencyInputTitlePM,
    }[themeForComponent] || CryptoCurrencyInputTitleDefault;

  const CryptoCurrencyInputTitleWrapper =
    {
      default: CryptoCurrencyInputTitleWrapperDefault,
      PM_brand: CryptoCurrencyInputTitleWrapperPM,
    }[themeForComponent] || CryptoCurrencyInputTitleWrapperDefault;

  const CurrencySymbol =
    {
      default: CurrencySymbolDefault,
      PM_brand: CurrencySymbolPM,
    }[themeForComponent] || CurrencySymbolDefault;

  const InputWrapper =
    {
      default: InputWrapperDefault,
      PM_brand: InputWrapperPM,
    }[themeForComponent] || InputWrapperDefault;

  const InputWrapperInner =
    {
      default: InputWrapperInnerDefault,
      PM_brand: InputWrapperInnerPM,
    }[themeForComponent] || InputWrapperInnerDefault;

  const CopyButtonDefault = ({ copyValue }: { copyValue: string }) => (
    <span
      onClick={() => handleCopy(copyValue, "amountCopy")}
      style={{ cursor: "pointer", color: "blue" }}
    >
      <IconColorWrapper style={{ margin: "4px 4px 0px 0px" }}>
        <IconCopy />
      </IconColorWrapper>
    </span>
  );

  const CopyButton =
    {
      default: CopyButtonDefault,
      PM_brand: ({ copyValue }: { copyValue: string }) => (
        <PMItemIconAction onClick={() => handleCopy(copyValue, "amountCopy")}>
          <Tooltip text={"Сopy"}>
            <CopyIcon />
          </Tooltip>
        </PMItemIconAction>
      ),
    }[themeForComponent] || CopyButtonDefault;

  const ref = useRef(null);
  const { handleCopy } = useContext(ContextUI);
  const [formattedValue, setFormattedValue] = useState(
    formatNumberWithSpaces(value, label),
  );

  useEffect(() => {
    const formatted = formatNumberWithSpaces(value, label);
    setFormattedValue(formatted);
  }, [value, label]);

  const handleInputChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    let newValue = target.value.replace(/\s/g, "");

    if (/[^0-9.]/.test(newValue)) {
      return;
    }

    if ((newValue.match(/\./g) || []).length > 1) {
      return;
    }

    if (newValue.includes("..")) {
      return;
    }

    if (onChange && typeof onChange === "function") {
      onChange({
        target: {
          name,
          value: newValue,
        },
      });
    }
  };

  useEffect(() => {
    if (ref?.current) {
      adjustFontSize({ ref, size: 14, length: 5 });
    }
  }, [ref, label]);

  return (
    <>
      <InputWrapper
        $isAmountBelowMin={isAmountBelowMin}
        $isFirstInput={isFirstInput}
      >
        <InputWrapperInner>
          <CurrencySymbol ref={ref}>
            <Trans message={label} withoutWrapper />
          </CurrencySymbol>
          <CryptoCurrencyInputTitle>
            <CryptoCurrencyInputTitleWrapper>
              {formatCryptoCurrency(currency)}
            </CryptoCurrencyInputTitleWrapper>
          </CryptoCurrencyInputTitle>
        </InputWrapperInner>
        {isLoading ? (
          <FormLoaderWrapper height="24px" style={{ marginLeft: "auto" }}>
            <InputLoader size="small" />
          </FormLoaderWrapper>
        ) : (
          <InputWrapperInner>
            <AmountInput
              type="text"
              autofocus={autofocus}
              readOnly={disabled}
              value={formattedValue}
              onInput={handleInputChange}
              $disabled={disabled}
            />
            {copyValue && <CopyButton copyValue={copyValue} />}
          </InputWrapperInner>
        )}
      </InputWrapper>
    </>
  );
};

export default CryptoInput;
