import { COLORS } from "../../../constants/themeConstants";
import {
  ActionButtonCardEditWrapper,
  ActionButtonCardList,
} from "../../../themes/commons/styles/ManageCardStyles";
import { PopupActionTitle } from "../../FormPopup/style";
import Trans from "../../Trans";

interface ClearFormModalsProps {
  resetCardForm?: () => void;
  handleCloseModal?: () => void;
}

const ClearFormModalDefault: React.FC<ClearFormModalsProps> = ({
  resetCardForm,
  handleCloseModal,
}) => {
  return (
    <>
      <PopupActionTitle $fw="400">
        <Trans message="clearCardInfoConfirmation" />
      </PopupActionTitle>
      <div style={{ display: "flex" }}>
        <ActionButtonCardEditWrapper>
          <ActionButtonCardList
            type="button"
            $background={COLORS.LIGHT_GRAY}
            $color={COLORS.GRAY}
            onClick={handleCloseModal}
          >
            <Trans message="cancel" />
          </ActionButtonCardList>

          <ActionButtonCardList
            type="button"
            $background={COLORS.DANGER}
            $color={COLORS.DEFAULT_WHITE}
            $width="288px"
            onClick={resetCardForm}
          >
            <Trans message="Clear data" />
          </ActionButtonCardList>
        </ActionButtonCardEditWrapper>
      </div>
    </>
  );
};

export default ClearFormModalDefault;
