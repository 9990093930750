import colorSchema from "../../../../colorThemes/colorSchemes";
import brand from "../../../../services/brand";
import { FunctionComponent } from "preact";
import { BaseButton } from "../../../../themes/commons/styles/ButtonStyles";
import { LogoBrandHeader } from "../../../../components/Header/style";
import Trans from "../../../../components/Trans";
import {
  PaymentTitle,
  SuccessPageWrapper,
  TransactionInfoSecondBlock,
  SuccessPageBlockWrapper,
  TransactionInfoFirstBlock,
} from "../../../commons/styles/SuccessPageStyles";
import { BrandData } from "../../../commons/interfaces/SchemaInterface";

interface SuccessPageProps {
  onButtonClick: () => void;
  countdown?: number | null;
}

const SuccessPage: FunctionComponent<SuccessPageProps> = ({
  onButtonClick,
  countdown,
}) => {
  const brandData = brand?.getBrand() as BrandData;
  const successIcon = colorSchema?.[brandData?.color_scheme]?.successIcon;

  return (
    <SuccessPageBlockWrapper>
      <SuccessPageWrapper>
        <TransactionInfoFirstBlock>
          <div style={{ position: "relative" }}>
            <LogoBrandHeader
              style={{
                width: "64px",
                height: "64px",
              }}
              src={`${successIcon}`}
            />
          </div>

          <PaymentTitle>
            <Trans message="success" />
          </PaymentTitle>
        </TransactionInfoFirstBlock>

        <TransactionInfoSecondBlock>
          <BaseButton
            data-role="back_to_store_button"
            onClick={onButtonClick}
            style={{ marginTop: "auto" }}
          >
            <Trans message="backToWebsite" /> {countdown && `(${countdown})`}
          </BaseButton>
        </TransactionInfoSecondBlock>
      </SuccessPageWrapper>
    </SuccessPageBlockWrapper>
  );
};

export default SuccessPage;
