import { FunctionComponent } from "preact";
import Trans from "../../../../components/Trans";
import {
  FormFieldsTitle,
  PMInnerHeaderDataWrapper,
  PMInfoBlockWrapper,
  PMInfoBlockImage,
  PMInfoBlockMainTitle,
  PMINfoBlockLink,
  PMContainerForInfoBlock,
  PMInfoTextBlockWrapper,
  PMInfoTextBlockTitle,
  PMInfoTextBlockDescription,
} from "./style";
import { PaymentMethodInfo } from "../../../../constants/paymentMethodsInfo";
import InfoIcon from "../../../../assets/icons/brand/pm/ui/InfoIcon";
import { state } from "../../../../services";
import InfoWarningIcon from "../../../../assets/icons/brand/pm/ui/InfoWraningIcon";
import { PM_COLORS } from "../../enums";

interface InfoBlockContentProps {
  content: any;
  isWarning: boolean;
  link?: { href: string; target: string; message: string };
  formFieldsTitle?: any;
  hasContainer: boolean;
  blockWithType?: {
    type?: "info" | "warning";
    position?: number;
    warningMessage?: string;
    iconColor?: string;
    titleColor?: string;
    backgroundColor?: string;
    content?: {
      message: string;
      components?: any[];
      params?: any;
    };
    condition: (data: any) => boolean;
  };
}

const InfoBlockContent: FunctionComponent<InfoBlockContentProps> = ({
  content,
  isWarning,
  link,
  formFieldsTitle,
  hasContainer,
  blockWithType,
}) => {
  if (!content) return null;

  const Icon = isWarning ? InfoWarningIcon : InfoIcon;
  const titleColor = isWarning ? PM_COLORS.WARNING : undefined;

  return (
    <PMContainerForInfoBlock $hasContainer={hasContainer}>
      <PMInnerHeaderDataWrapper
        $isWarning={isWarning}
        $background={blockWithType?.backgroundColor}
      >
        <PMInfoBlockWrapper $align="center">
          <PMInfoBlockImage>
            <Icon color={blockWithType?.iconColor || titleColor} />
          </PMInfoBlockImage>
          <PMInfoTextBlockWrapper>
            {isWarning && (
              <PMInfoTextBlockTitle
                $color={blockWithType?.titleColor || titleColor}
              >
                <Trans message={blockWithType?.warningMessage || "attention"} />
              </PMInfoTextBlockTitle>
            )}
            <PMInfoBlockMainTitle>
              {typeof content === "function" ? content() : content}
            </PMInfoBlockMainTitle>
          </PMInfoTextBlockWrapper>
        </PMInfoBlockWrapper>
      </PMInnerHeaderDataWrapper>

      {link && (
        <PMINfoBlockLink href={link.href} target={link.target}>
          <Trans message={link.message} withoutWrapper />
        </PMINfoBlockLink>
      )}

      {formFieldsTitle && (
        <FormFieldsTitle>
          {typeof formFieldsTitle === "function"
            ? formFieldsTitle()
            : formFieldsTitle}
        </FormFieldsTitle>
      )}
    </PMContainerForInfoBlock>
  );
};

interface FormInfoBlockPMProps {
  type: "info" | "warning";
  currentInfo?: PaymentMethodInfo;
  hasContainer?: boolean;
  titleError?: string;
  titleErrorDescription?: string;
  blockWithType?: {
    type?: "info" | "warning";
    position?: number;
    content?: {
      message: string;
      components?: any[];
      params?: any;
    };
    condition: (data: any) => boolean;
  };
}

const FormInfoBlockPM: FunctionComponent<FormInfoBlockPMProps> = ({
  type,
  currentInfo,
  hasContainer,
  titleError,
  titleErrorDescription,
  blockWithType,
}) => {
  const { content, formFieldsTitle, link } = currentInfo || {};
  const status = (state.get() as { status?: string }).status;
  const isWarning = type === "warning";

  return (
    <>
      {status === "failed_retry" && (
        <PMContainerForInfoBlock $hasContainer={hasContainer}>
          <PMInnerHeaderDataWrapper $isWarning>
            <PMInfoBlockWrapper $align="center">
              <PMInfoBlockImage>
                <InfoWarningIcon />
              </PMInfoBlockImage>
              <PMInfoTextBlockWrapper>
                <PMInfoTextBlockTitle>
                  <Trans message={titleError || ""} withoutWrapper />
                </PMInfoTextBlockTitle>
                <PMInfoTextBlockDescription>
                  <Trans
                    message={titleErrorDescription || "pleasePaymentAgain"}
                    withoutWrapper
                  />
                </PMInfoTextBlockDescription>
              </PMInfoTextBlockWrapper>
            </PMInfoBlockWrapper>
          </PMInnerHeaderDataWrapper>
        </PMContainerForInfoBlock>
      )}

      <InfoBlockContent
        content={content}
        isWarning={isWarning}
        link={link}
        formFieldsTitle={formFieldsTitle}
        hasContainer={hasContainer ?? false}
        blockWithType={blockWithType}
      />
    </>
  );
};

export default FormInfoBlockPM;
