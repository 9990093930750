import { FunctionComponent } from "preact";
import { DropdownEmpty } from "../../../../components/Dropdown/style";
import ModalItem from "../../../../components/Modals/ModalComponents/ModalItem";
import Trans from "../../../../components/Trans";
import { state } from "../../../../services";
import empty from "../../../../assets/icons/dropdownEmpty.svg";

interface Option {
  [key: string]: any;
  name?: string;
  subName?: string;
  code?: string;
}

interface InputOptions {
  getMethodNameForIcon?: () => string;
  display?: (args: { option: Option }) => string;
}

interface DropdownListDefaultProps {
  options: Option[];
  selected: Option;
  onChange: (option: Option) => void;
  foreignKey: string;
  showBankIcon?: boolean;
  display?: (args: { option: Option }) => string;
  inputOptions: InputOptions;
}

const DropdownListDefault: FunctionComponent<DropdownListDefaultProps> = ({
  options,
  selected,
  onChange,
  foreignKey,
  showBankIcon = false,
  display,
  inputOptions,
}) => {
  const selectedMethod = state.getSelectedPaymentMethod();

  if (options.length === 0) {
    return (
      <DropdownEmpty>
        <img src={empty} alt="icon" />
        <Trans message="dropdownEmpty" />
      </DropdownEmpty>
    );
  }

  return (
    <>
      {options.map((option) => (
        // @ts-ignore
        <ModalItem
          key={option[foreignKey]}
          isActive={selected[foreignKey] === option[foreignKey]}
          handleClick={() => onChange(option)}
          title={
            display && typeof display === "function"
              ? display({ option })
              : option.name || ""
          }
          subTitle={option.subName}
          withoutIcon={!showBankIcon}
          isBankCode={showBankIcon}
          name={
            (inputOptions.getMethodNameForIcon &&
              typeof inputOptions.getMethodNameForIcon === "function" &&
              inputOptions.getMethodNameForIcon()) ||
            option.code ||
            option.name ||
            ""
          }
          method={selectedMethod?.method}
        />
      ))}
    </>
  );
};

export default DropdownListDefault;
