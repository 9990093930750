import styled from "styled-components";
import { COLORS } from "../../../constants/themeConstants";

export const ChallengeTimerTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-family: var(--regularFont);
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  margin-bottom: 24px;
`;

export const ResendTimeTitle = styled.span`
  font-family: var(--boldFont);
  color: ${COLORS.DEFAULT_BLACK};
  span {
    font-family: var(--boldFont);
  }
`;

export const ResendSpan = styled.span<{
  $isButtonDisabled?: boolean;
  $isResendClicked?: boolean;
}>`
  color: ${(props) => props.theme.mainColor};
  cursor: ${({ $isButtonDisabled, $isResendClicked }) =>
    $isButtonDisabled || $isResendClicked ? "not-allowed" : "pointer"};
  opacity: ${({ $isButtonDisabled, $isResendClicked }) =>
    $isButtonDisabled || $isResendClicked ? 0.5 : 1};
  span {
    font-family: var(--boldFont);
  }
`;
