import { FunctionComponent } from "preact";
import Loader from "../../../../components/Loaders";
import Trans from "../../../../components/Trans";
import {
  PMChallengeCompleteText,
  PMChallengeCompleteTitle,
  PMChallengeCompleteWrapper,
} from "./style";
import { Bold } from "../../../../ui/GeneralStyles/style";

interface ChallengeCompleteProps {
  timeExpired?: boolean;
  minutes: string;
  seconds: string;
}

const ChallengeCompletePM: FunctionComponent<ChallengeCompleteProps> = ({
  timeExpired,
  minutes,
  seconds,
}) => {
  return (
    <PMChallengeCompleteWrapper>
      {timeExpired ? (
        <>
          <PMChallengeCompleteTitle>
            <Trans message="transactionStatus" />
          </PMChallengeCompleteTitle>
          <PMChallengeCompleteText>
            <Trans message="paymentsSlowMessage" />
          </PMChallengeCompleteText>
          <PMChallengeCompleteText>
            <Trans message="receiptPaymentConfirmation" />
          </PMChallengeCompleteText>
        </>
      ) : (
        <>
          <Loader loaderName="pmLoader" />
          <PMChallengeCompleteText data-transaction-name="challenge-complete-message">
            <Trans
              message="challengeCompleteMessage"
              params={{ minutes, seconds }}
              components={[Bold]}
              withoutWrapper
            />
          </PMChallengeCompleteText>
          <PMChallengeCompleteText data-transaction-name="challenge-complete-message-refresh">
            <Trans message="doNotRefreshPage" withoutWrapper />
          </PMChallengeCompleteText>
        </>
      )}
    </PMChallengeCompleteWrapper>
  );
};

export default ChallengeCompletePM;
