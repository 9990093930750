import styled from "styled-components";
import { PM_COLORS } from "../../enums";

export const PMButtonWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 24px 24px;
`;

export const PMModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
`;

export const PMModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background: #0b0b0a;
  opacity: 0.65;
  height: 100vh;
  width: 100vw;
`;

export const PMModalContainer = styled.div`
  background-color: ${PM_COLORS.DEFAULT_WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 102;
  width: calc(100% - 24px);
`;

export const PMModalTitle = styled.span`
  font-family: var(--mediumFont);
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.41px;
  text-align: center;
  color: ${PM_COLORS.GRAY_DARK};
  margin-bottom: 8px;
`;

export const PMModalText = styled.span`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: center;
  color: ${PM_COLORS.GRAY_5};
  margin-bottom: 32px;
`;

export const PMInnerHeaderDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;
