import StepsList from "./ChallengeStepsList/StepsList";
import { InnerLine } from "../../styles";
import { ChallengeFormMainWrapper, ChallengeMainTitle } from "./style";
import Trans from "../Trans";
import ChallengeForm from "./ChallengeForm";
import { state } from "../../services";
import { useEffect } from "preact/hooks";
import { InnerHeaderDataWrapper } from "../../themes/default/components/Instruction/style";

const ChallengeBlikCode = ({
  status,
  challenge,
  sessionId,
  signature,
  currentMethod,
}) => {
  const blikCodeChallengeUrl = `/api/payment/challenge/blik-code/${sessionId}/${signature}`;
  const steps = [
    { number: 1, title: "blikCodeInstructions" },
    { number: 2, title: "blikCodeOption" },
    { number: 3, title: "blikCodeGenerate" },
    { number: 4, title: "blikCodeEnter" },
  ];

  const onChallengeFormSubmit = () => {
    state.setLoading({
      status: true,
      eventName: "challengeSubmit",
      text: "confirmInBankApp",
    });
  };

  useEffect(() => {
    if (status === "challenge_succeeded") {
      onChallengeFormSubmit();
    }
  }, [status]);

  return (
    <>
      <InnerHeaderDataWrapper $pt="20px">
        <ChallengeMainTitle $mt="16px" $mb="16px">
          <Trans message="whereFindBlikCode" />
        </ChallengeMainTitle>
        <StepsList steps={steps} />
        <InnerLine themeBackground />
      </InnerHeaderDataWrapper>
      <ChallengeFormMainWrapper>
        <ChallengeForm
          challengeType={challenge?.type}
          challengeUrl={challenge?.url || blikCodeChallengeUrl}
          onFormSubmit={onChallengeFormSubmit}
          onFormSubmitFail={() => {
            state.setLoading({
              status: false,
              eventName: "challengeSubmit",
            });
          }}
          sessionId={sessionId}
          signature={signature}
          currentMethod={currentMethod}
        />
      </ChallengeFormMainWrapper>
    </>
  );
};

export default ChallengeBlikCode;
