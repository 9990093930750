import { useEffect, useState } from "preact/hooks";
import Loader from "../../../Loaders";
import useApmTransaction from "../../../../hooks/useApmTransaction";
import Instruction from "../../../Instruction";
import { api, state } from "../../../../services";
import ChallengeComplete from "../../ChallengeComplete";
import { FormLoaderWrapper } from "../../../Loaders/style";
import { useExpirationStatus } from "../../../../services/hooks";
import ExpirationDateInfoDefault from "../../../../themes/default/components/ExpirationDate/expirationDateInfo";
import ExpirationDateInfoPM from "../../../../themes/PM_brand/components/ExpirationDate/expirationDateInfo";
import { useThemeComponent } from "../../../../hooks/useThemeComponent";

interface IProps {
  data: { account: string; institution: string; expiration_date: string };
  status: "challenge" | "challenge_accepted" | "challenge_succeeded";
  selectedMethod: string;
  cancelUrl?: string;
  expiredProviders?: boolean;
}

const ChallengeAccountRequisites = ({
  data,
  status,
  selectedMethod,
  expiredProviders,
  cancelUrl,
}: IProps) => {
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const { createSpan } = useApmTransaction("PaymentFlow");
  const themeForComponent = useThemeComponent("ExpirationDate");
  useEffect(() => {
    if (status === "challenge_succeeded") {
      setIsPaymentCompleted(true);
    }
  }, [status]);

  const handlePaymentMade = () => {
    const handlePaymentSpan = createSpan("MadePayment");

    state.setLoading({ status: true, eventName: "submit_account_requisites" });

    api
      .submitAccountRequisites()
      .then(() => {
        setIsPaymentCompleted(true);
      })
      .finally(() => {
        state.setLoading({
          status: false,
          eventName: "submit_account_requisites",
        });
      });

    setTimeout(() => {
      handlePaymentSpan?.end();
    }, 0);
  };

  const ExpirationDateInfoComponent = {
    default: ExpirationDateInfoDefault,
    PM_brand: ExpirationDateInfoPM,
  }[themeForComponent];

  const configMap: Record<
    string,
    "bank_account_indonesia" | "account_requisites"
  > = {
    bank_account_indonesia: "bank_account_indonesia",
  };

  const configName = configMap[selectedMethod] || "account_requisites";
  if (isPaymentCompleted) {
    return <ChallengeComplete processingTime={60} />;
  }

  const isExpired = useExpirationStatus(data?.expiration_date);

  return isExpired && expiredProviders ? (
    ExpirationDateInfoComponent && (
      <ExpirationDateInfoComponent cancelUrl={cancelUrl} instructionData={{}} />
    )
  ) : data ? (
    <Instruction
      configName={configName}
      instructionData={data}
      handleButtonClick={handlePaymentMade}
      buttonText="madePayment"
    />
  ) : (
    <FormLoaderWrapper>
      <Loader />
    </FormLoaderWrapper>
  );
};

export default ChallengeAccountRequisites;
