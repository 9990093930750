const CloseNotificationIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4 12C13.4 12 17 8.4 18.2 7.2C18.6 6.8 18.6 6.2 18.2 5.8C17.8 5.4 17.2 5.4 16.8 5.8C15.6 7 12 10.6 12 10.6C12 10.6 8.4 7 7.2 5.8C6.8 5.4 6.2 5.4 5.8 5.8C5.4 6.2 5.4 6.8 5.8 7.2C7 8.4 10.6 12 10.6 12C10.6 12 7 15.6 5.8 16.8C5.4 17.2 5.4 17.8 5.8 18.2C6.2 18.6 6.8 18.6 7.2 18.2C8.4 17 12 13.4 12 13.4C12 13.4 15.6 17 16.8 18.2C17.2 18.6 17.8 18.6 18.2 18.2C18.6 17.8 18.6 17.2 18.2 16.8C17 15.6 13.4 12 13.4 12Z"
      fill="#BBB5AA"
    />
    <path
      d="M13.4 12C13.4 12 17 8.4 18.2 7.2C18.6 6.8 18.6 6.2 18.2 5.8C17.8 5.4 17.2 5.4 16.8 5.8C15.6 7 12 10.6 12 10.6C12 10.6 8.4 7 7.2 5.8C6.8 5.4 6.2 5.4 5.8 5.8C5.4 6.2 5.4 6.8 5.8 7.2C7 8.4 10.6 12 10.6 12C10.6 12 7 15.6 5.8 16.8C5.4 17.2 5.4 17.8 5.8 18.2C6.2 18.6 6.8 18.6 7.2 18.2C8.4 17 12 13.4 12 13.4C12 13.4 15.6 17 16.8 18.2C17.2 18.6 17.8 18.6 18.2 18.2C18.6 17.8 18.6 17.2 18.2 16.8C17 15.6 13.4 12 13.4 12Z"
      fill="white"
    />
  </svg>
);

export default CloseNotificationIcon;
