import { FunctionComponent } from "preact";

interface InputSavedCardProps {
  openModal: () => void;
}

const InputSavedCard: FunctionComponent<InputSavedCardProps> = () => {
  return null;
};

export default InputSavedCard;
