import ModalItem from "../../../../components/Modals/ModalComponents/ModalItem";
import { CARD_BRANDS } from "../../../../constants/cardBrand";
import deleteCardIcon from "../../../../assets/icons/deleteCardIconAsquad.svg";

import { FunctionalComponent } from "preact";
import { CardListProps } from "../../../commons/interfaces/CardInterfaces";
import {
  CardLabelNumberContainer,
  CardLabelNumber,
  CardLabelDate,
} from "../../../commons/styles/ManageCardStyles";

const CardList: FunctionalComponent<CardListProps> = ({
  index,
  isManage,
  card,
  handleCheckSavedCard,
  setIsPrevRemoveCard,
  setCurrentCard,
}) => (
  // @ts-ignore
  <ModalItem
    icon={CARD_BRANDS[card?.brand]}
    arrowIcon={isManage && deleteCardIcon}
    dataRole={`card_${index + 1}`}
    arrowAction={
      isManage
        ? () => {
            setIsPrevRemoveCard(true);
            setCurrentCard(card);
          }
        : null
    }
    handleClick={() => handleCheckSavedCard(card)}
    iconWidth="32px"
  >
    <CardLabelNumberContainer>
      <CardLabelNumber>{`${card?.bin?.toString()?.slice(0, 4)} ${card?.bin
        ?.toString()
        ?.slice(4, 6)}** **** ${card?.last4}`}</CardLabelNumber>
    </CardLabelNumberContainer>
    <CardLabelDate>{`${card?.exp_month}/${
      card.exp_year?.length === 4
        ? card?.exp_year?.toString()?.slice(2, 4)
        : card.exp_year
    }`}</CardLabelDate>
  </ModalItem>
);

export default CardList;
