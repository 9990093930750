import { useEffect, useState } from "preact/hooks";
import loadZenFingerprintScript from "../Challenge/ChallengeZenFingerScript";
import { api, logger } from "../../services";
import {
  CHALLENGE_TYPES,
  RENDER_CHALLENGE_TYPES,
} from "../../constants/challengeTypes";
import Scroll from "../Scroll";
import useTimer from "../../hooks/useTimer";
import { STATUS_TO_ROUTER_MAP } from "../../services/constants";
import ChallengeControl from "../Challenge/ChallengeControl";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import PendingLoaderDefault from "../../themes/default/components/PendingLoader";
import PendingLoaderPM from "../../themes/PM_brand/components/PendingLoader";

const PendingPage = ({
  reference,
  currency,
  amount,
  countdown,
  redirect_url,
  options,
  onButtonClick,
  currentState,
  status,
  challenge,
  cancelUrl,
  sessionId,
  signature,
}) => {
  const [showRedirectButtons, setShowRedirectButtons] = useState(false);
  const [showCancelButtons, setShowCancelButtons] = useState(false);

  const isChallenge = ["challenge", "challenge_accepted"].includes(status);
  const isQrChallenge =
    isChallenge && challenge?.type === RENDER_CHALLENGE_TYPES.QR;
  const isBrChallenge =
    isChallenge && challenge?.type === RENDER_CHALLENGE_TYPES.BR;
  const isInputChallenge =
    isChallenge && CHALLENGE_TYPES.includes(challenge?.type);

  const isRenderChallenge = isQrChallenge || isBrChallenge || isInputChallenge;

  const isZenChallenge = isChallenge && challenge?.type === "zen_fingerprint";

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isZenChallenge) {
          const data = await loadZenFingerprintScript(sessionId);
          api.setFingerprintZenData(data);
        }
      } catch (error) {
        api.setFingerprintZenData("not completed");

        logger.error("Error loading Zen fingerprint script:", error);
      }
    };

    fetchData();
  }, [isZenChallenge, sessionId]);

  const handleRedirectTimeout = () => {
    setShowRedirectButtons(true);
  };

  const handleLongLoadingTimeout = () => {
    setShowCancelButtons(true);
  };

  useTimer({
    status:
      !isRenderChallenge &&
      STATUS_TO_ROUTER_MAP[status] === "pending" &&
      challenge?.type === "redirect" &&
      challenge?.url,
    action: handleRedirectTimeout,
    timeout: 5000,
  });

  useTimer({
    status: !isRenderChallenge && STATUS_TO_ROUTER_MAP[status] === "pending",
    action: handleLongLoadingTimeout,
  });

  const themeForComponent = useThemeComponent("PendingLoader");

  const PendingLoader = {
    default: PendingLoaderDefault,
    PM_brand: PendingLoaderPM,
  }[themeForComponent];

  if (isRenderChallenge) {
    return (
      <Scroll style={{ display: "flex", flexDirection: "column" }}>
        <ChallengeControl
          reference={reference}
          currency={currency}
          amount={amount}
          countdown={countdown}
          redirect_url={redirect_url}
          options={options}
          onButtonClick={onButtonClick}
          currentState={currentState}
          isQrChallenge={isQrChallenge}
          isBrChallenge={isBrChallenge}
          cancelUrl={cancelUrl}
          challenge={challenge}
          sessionId={sessionId}
          signature={signature}
          status={status}
        />
      </Scroll>
    );
  }

  return (
    <PendingLoader
      showCancelButtons={showCancelButtons}
      showRedirectButtons={showRedirectButtons}
      options={options}
      challenge={challenge}
    />
  );
};

export default PendingPage;
