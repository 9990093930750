import { FunctionComponent } from "preact";
import FormInfoBlockPM from "../FormInfoBlock";
import Trans from "../../../../components/Trans";
import { PMInfoWrapper } from "../Instruction/style";

interface CustomInfoBlock {
  type?: "info" | "warning";
  position?: number;
  content?: {
    message: string;
    components?: any[];
    params?: any;
  };
  condition: (data: any) => boolean;
}

interface CustomInfoBlocksProps {
  customInfoBlocks: CustomInfoBlock[];
  instructionData: any;
}

const CustomInfoBlocks: FunctionComponent<CustomInfoBlocksProps> = ({
  customInfoBlocks,
  instructionData,
}) => {
  return customInfoBlocks
    ?.filter((block) => block.condition(instructionData))
    ?.sort((a, b) => {
      const blockA = a as CustomInfoBlock;
      const blockB = b as CustomInfoBlock;

      return blockA?.position && blockB?.position
        ? blockA.position - blockB.position
        : 0;
    })
    .map((block, index) => {
      const blockWithType = block as CustomInfoBlock;

      const message = blockWithType?.content?.message || "";

      return (
        <PMInfoWrapper $margin="0" key={index}>
          <FormInfoBlockPM
            type={blockWithType?.type ?? "info"}
            blockWithType={blockWithType}
            currentInfo={{
              content: () => (
                <Trans
                  message={message}
                  components={[...(blockWithType?.content?.components || [])]}
                  params={{ ...(blockWithType?.content?.params || {}) }}
                  withoutWrapper
                />
              ),
            }}
          />
        </PMInfoWrapper>
      );
    });
};

export default CustomInfoBlocks;
