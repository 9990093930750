import { FunctionComponent } from "preact";
import { FormPayTitle } from "../../../../components/Form/style";
import FormFields from "../../../../components/Form/FormFields";
import { PAYMENT_METHODS } from "../../../../constants/paymentMethods";
import Button from "../../../../components/Button";
import Trans from "../../../../components/Trans";
import { INFORMATION_ABOUT_PAYMENT_METHODS } from "../../../../constants/paymentMethodsInfo";
import {
  TransactionDecimalTitle,
  TransactionSumTitle,
} from "../../../../components/PaymentDetails/style";
import { FormProps } from "../../../commons/interfaces/FormInterfaces";
import RememberMe from "../../../../components/RememberMe";
import { PMButtonWrapper, PMFormContainer, PMFormWrapper } from "./style";
import { state } from "../../../../services";
import { Card } from "../../../commons/interfaces/CardInterfaces";
import SavedCardPM from "../SavedCard";
import CardListModal from "../CardListModal";
import { useContext } from "preact/hooks";
import { ContextUI } from "../../../../providers/UIProvider";

const FormPM: FunctionComponent<FormProps> = ({
  handleFormSubmit,
  selectedMethod,
  refSubmit,
  fieldSources,
  values,
  setValues,
  errors,
  setErrors,
  checkedCard,
  setCheckedCard,
  resetCardForm,
  hiddenRememberMe,
  themeConfig,
  setRememberMe,
  isMethodForAutoSubmit,
  isFailedRetryStatus,
  hasSelectedMethod,
  isDisabledButton,
  checkPrivacyPolicy,
  rememberMe,
  formButtonTitle,
  replacedFormPaymentAmountButton,
  wholeNumber,
  decimal,
  formButtonCurrency,
  currency,
  theme,
}) => {
  const cardList = (state.get() as { cards: Card[] }).cards || [];

  const { setModalData } = useContext(ContextUI);

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      content: (
        <CardListModal
          checkedCard={checkedCard}
          setCheckedCard={setCheckedCard}
          setErrors={setErrors}
          setValues={setValues}
        />
      ),
    });
  };

  return (
    <>
      <PMFormContainer>
        {selectedMethod?.method === PAYMENT_METHODS.CARD &&
          cardList &&
          cardList.length > 0 && (
            <SavedCardPM handleOpen={handleOpen} card={cardList[0]} />
          )}
        <PMFormWrapper>
          <form onSubmit={handleFormSubmit}>
            <FormFields
              method={selectedMethod}
              fieldSources={fieldSources}
              area="general"
              refSubmit={refSubmit}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              checkedCard={checkedCard}
              setCheckedCard={setCheckedCard}
              resetCardForm={resetCardForm}
              hiddenRememberMe={hiddenRememberMe}
              theme={theme}
              themeConfig={themeConfig}
              setRememberMe={setRememberMe}
              isMethodForAutoSubmit={isMethodForAutoSubmit}
              isFailedRetryStatus={isFailedRetryStatus}
            />
          </form>
          {!hiddenRememberMe &&
            selectedMethod?.method === PAYMENT_METHODS.CARD && (
              <RememberMe
                checkedCard={checkedCard}
                checked={rememberMe}
                setRememberMe={setRememberMe}
              />
            )}
        </PMFormWrapper>
      </PMFormContainer>

      {!["apple_pay", "google_pay"].includes(selectedMethod?.method) && (
        <PMButtonWrapper>
          <Button
            data-role="pay_button"
            name="cardFormPayButton"
            type="submit"
            disabled={
              isDisabledButton || !hasSelectedMethod || !checkPrivacyPolicy
            }
            onClick={handleFormSubmit}
            buttonRef={refSubmit}
            data-transaction-name="FormPayButton"
            isInIframe={state.isInIframe()}
          >
            {!["card", "crypto"].includes(selectedMethod?.method) ? (
              <FormPayTitle>
                <Trans
                  message={
                    INFORMATION_ABOUT_PAYMENT_METHODS[selectedMethod?.method]
                      ?.payButtonText || "buttonPayTextDefault"
                  }
                />
              </FormPayTitle>
            ) : (
              <>
                <FormPayTitle>
                  <Trans message={formButtonTitle} />
                </FormPayTitle>
                {replacedFormPaymentAmountButton && (
                  <span>
                    <TransactionSumTitle>{wholeNumber}</TransactionSumTitle>
                    {decimal && (
                      <TransactionDecimalTitle>
                        .{decimal}
                      </TransactionDecimalTitle>
                    )}{" "}
                    {formButtonCurrency && (
                      <TransactionDecimalTitle>
                        {currency}
                      </TransactionDecimalTitle>
                    )}
                  </span>
                )}
              </>
            )}
          </Button>
        </PMButtonWrapper>
      )}
    </>
  );
};

export default FormPM;
