import { FunctionComponent } from "preact";
import { CloseIcon } from "../../../../components/FormPopup";
import {
  PopupMainWrapper,
  PopupTitle,
} from "../../../../components/FormPopup/style";
import Trans from "../../../../components/Trans";
import Search from "../DropdownSearch";

interface DropdownHeaderDefaultProps {
  label: string;
  handleClose: () => void;
  handleSearch: (query: string) => void;
}

const DropdownHeaderDefault: FunctionComponent<DropdownHeaderDefaultProps> = ({
  label,
  handleClose,
  handleSearch,
}) => {
  return (
    <>
      <PopupMainWrapper>
        <PopupTitle>
          <Trans message={label} />
        </PopupTitle>
        <CloseIcon onClick={handleClose} />
      </PopupMainWrapper>
      <Search onSearch={handleSearch} />
    </>
  );
};

export default DropdownHeaderDefault;
