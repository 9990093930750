import { IPaymentInstructions } from "./interfaces";

const p2cChallenge: IPaymentInstructions = {
  title: "p2cPMTitle",
  isOrderedList: false,
  instructionList: [
    {
      dataTitle: "pan",
      getPaymentData: ({ data = {} }) => data?.card_number,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.card_number,
        text: "panCopied",
      }),
    },
    {
      dataTitle: "bankName",
      getPaymentData: ({ data = {} }) => data?.bank_name,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.bank_name,
        text: "bankNameCopied",
      }),
    },
    {
      dataTitle: "cardHolder",
      getPaymentData: ({ data = {} }) => data?.card_holder,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.card_holder,
        text: "cardHolderCopied",
      }),
    },
  ],
  getInfo: () => "PMChallengeInfoText",
};

export default p2cChallenge;
