import { useEffect, useState, useContext } from "preact/hooks";
import { FunctionComponent } from "preact";
import { processing, state } from "../../../../services";
import { Card } from "../../../commons/interfaces/CardInterfaces";
import { ContextUI } from "../../../../providers/UIProvider";
import Trans from "../../../../components/Trans";
import { INITIAL_VALUES } from "../../../../components/InputCard";
import {
  PMCardListContainer,
  PMCardListWrapper,
  PMModalHeaderActionCloseButton,
  PMModalHeaderActionEditButton,
  PMModalHeaderContainer,
  PMModalHeaderTitle,
  PMRadioItem,
} from "./style";
import Basket from "../../../../assets/icons/brand/pm/ui/Basket";
import {
  PMItemContent,
  PMItemDetails,
  PMItemIcon,
  PMItemIconAction,
  PMItemName,
  PMItemDescription,
} from "../SavedCard/style";
import { CARD_BRANDS } from "../../../../constants/cardBrand";
import Button from "../../../../components/Button";
import { EnumVariant } from "../../../commons/enums/ButtonEnums";
import { BrandType } from "../../../commons/interfaces/FormInterfaces";
import CloseIcon from "../../../../assets/icons/brand/pm/ui/CloseIcon";

interface ICardListModalProps {
  checkedCard: Card | null;
  setValues: (values: (prev: Partial<Card>) => Partial<Card>) => void;
  setErrors: (errors: Record<string, string>) => void;
  setCheckedCard: (card: Card | null) => void;
}

const CardListModal: FunctionComponent<ICardListModalProps> = ({
  checkedCard,
  setValues,
  setErrors,
  setCheckedCard,
}) => {
  const cardList = (state.get() as { cards: Card[] }).cards || [];

  const [isManage, setIsManage] = useState(false);
  const [chosenCard, setChosenCard] = useState<Card | null>(null);

  const { modalData, setModalData, handleCloseModal, setNotificationData } =
    useContext(ContextUI);

  const { isOpen = false } = modalData || {};

  const handleChooseItem = (card: Card) => {
    setChosenCard(card);
  };

  const handleRemoveSavedCard = (identifier: string) => {
    if (checkedCard && checkedCard.identifier === identifier) {
      setCheckedCard(null);

      setValues((prev: Partial<Card>) => ({
        ...prev,
        ...INITIAL_VALUES,
        brand: INITIAL_VALUES.brand as keyof typeof CARD_BRANDS,
      }));

      setErrors({});
    }

    setTimeout(() => {
      handleCloseModal();
    }, 200);
  };

  const handleRemoveCardPopup = (id: string) => {
    setIsManage(false);
    processing
      .processRemoveCard(id)
      .then(() => handleRemoveSavedCard(id))
      .then(() => {
        setNotificationData({
          text: "deletedCard",
          status: "success",
          isOpen: true,
        });
      })
      .catch((e) => {
        setNotificationData({
          text: e?.response?.reason || e.toString(),
          status: "fail",
          isOpen: true,
        });
      });
  };

  const handleCheckSavedCard = (card: Card) => {
    if (card) {
      setCheckedCard(card);
      setValues((prev: Partial<Card>) => ({
        ...prev,
        card: `${card.bin?.slice(0, 4)} ${card.bin?.slice(4, 6)}** **** ${card.last4}`,
        date: `${card.exp_month}${card.exp_year?.length === 4 ? card.exp_year.slice(2, 4) : card.exp_year}`,
        cvv: "",
        brand: card.brand as BrandType,
        cardHolder: card.holder_name,
      }));

      handleCloseModal();
      setErrors({});
    }
  };

  const customHeader = () => (
    <PMModalHeaderContainer>
      <PMModalHeaderTitle>
        <Trans message={isManage ? "removing" : "savedCard"} withoutWrapper />
      </PMModalHeaderTitle>

      <PMModalHeaderActionEditButton
        data-role="manager_button"
        onClick={() => setIsManage(!isManage)}
      >
        {isManage ? <Basket /> : <Trans message="edit" />}
      </PMModalHeaderActionEditButton>
      <PMModalHeaderActionCloseButton
        data-role="close_button"
        onClick={handleCloseModal}
      >
        <CloseIcon />
      </PMModalHeaderActionCloseButton>
    </PMModalHeaderContainer>
  );

  const handleSubmit = () => {
    if (chosenCard) {
      if (isManage) {
        handleRemoveCardPopup(chosenCard?.identifier || "");
      } else if (!isManage) {
        handleCheckSavedCard(chosenCard);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      // @ts-ignore
      setModalData((prev: Partial<Record<string, unknown>>) => ({
        ...prev,
        header: customHeader,
        isFullView: cardList?.length > 4,
      }));
    }
  }, [isOpen, isManage]);

  useEffect(() => {
    setIsManage(false);
  }, [isOpen]);

  return (
    <PMCardListWrapper>
      <PMCardListContainer>
        {cardList &&
          Array.isArray(cardList) &&
          cardList.map((card) => {
            if (!card.enabled) return null;

            return (
              <PMItemContent $hasImage onClick={() => handleChooseItem(card)}>
                <PMItemIcon>
                  <img src={CARD_BRANDS[card.brand]} alt="brand" />
                </PMItemIcon>
                <PMItemDetails>
                  <PMItemName>{card.brand}</PMItemName>
                  <PMItemDescription>{`${card?.bin?.toString()?.slice(0, 4)} ${card?.bin
                    ?.toString()
                    ?.slice(4, 6)}** **** ${card?.last4}`}</PMItemDescription>
                </PMItemDetails>
                <PMItemIconAction>
                  <PMRadioItem
                    $isActive={
                      chosenCard?.last4 === card.last4 &&
                      chosenCard?.bin === card.bin
                    }
                  />
                </PMItemIconAction>
              </PMItemContent>
            );
          })}
      </PMCardListContainer>

      <Button
        onClick={handleSubmit}
        disabled={!chosenCard}
        variant={EnumVariant.SECONDARY}
        data-transaction-name="pm_modal_submit"
      >
        <Trans message={isManage ? "delete" : "done"} />
      </Button>
    </PMCardListWrapper>
  );
};

export default CardListModal;
