import styled from "styled-components";
import { COLORS } from "../../../constants/themeConstants";

export const InnerDataBoletto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 7px;
  position: relative;
  width: 376px;
  background: ${COLORS.DEFAULT_WHITE};
  box-shadow: 0px 0px 15px rgba(123, 148, 157, 0.25);
  border-radius: 15px;
  top: -18px;
  margin: 30px auto 0;
`;

export const BolettoDetailsSubTitle = styled.span`
  color: ${COLORS.DEFAULT_BLACK};
  margin: 0 auto;
  font-size: 12px;
  line-height: 16px;
  width: 363px;
  letter-spacing: 0.5px;
  cursor: pointer;
`;

export const ChallengeButtonWrapper = styled.div<{
  $pdf_url?: boolean;
  $width?: string;
}>`
  display: ${({ $pdf_url }) => ($pdf_url ? "" : "flex")};
  width: ${({ $width }) => $width || ""};
  margin: 0 auto;
  justify-content: space-between;
`;
