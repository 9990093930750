const Basket = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 4.5H17V4C17 3.20435 16.6839 2.44127 16.1213 1.87866C15.5587 1.31605 14.7956 1 14 1H10C9.20435 1 8.44127 1.31605 7.87866 1.87866C7.31605 2.44127 7 3.20435 7 4V4.5H3C2.73478 4.5 2.48044 4.60537 2.29291 4.79291C2.10537 4.98044 2 5.23478 2 5.5C2 5.76522 2.10537 6.01956 2.29291 6.20709C2.48044 6.39463 2.73478 6.5 3 6.5H4.10999L4.84998 19.16C4.89097 19.9276 5.2251 20.6502 5.78333 21.1786C6.34155 21.7071 7.08129 22.0011 7.84998 22H16.17C16.9387 22.0011 17.6785 21.7071 18.2367 21.1786C18.7949 20.6502 19.129 19.9276 19.17 19.16L19.91 6.5H21C21.2652 6.5 21.5196 6.39463 21.7071 6.20709C21.8946 6.01956 22 5.76522 22 5.5C22 5.23478 21.8946 4.98044 21.7071 4.79291C21.5196 4.60537 21.2652 4.5 21 4.5ZM9 4C9 3.73478 9.10537 3.48044 9.29291 3.29291C9.48044 3.10537 9.73478 3 10 3H14C14.2652 3 14.5196 3.10537 14.7071 3.29291C14.8946 3.48044 15 3.73478 15 4V4.5H9V4ZM17.16 19C17.16 19.2652 17.0547 19.5196 16.8671 19.7071C16.6796 19.8946 16.4252 20 16.16 20H7.84003C7.57481 20 7.32041 19.8946 7.13287 19.7071C6.94534 19.5196 6.84003 19.2652 6.84003 19L6.10999 6.45001H17.89L17.16 19ZM9 16V10.5C9 10.2348 9.10537 9.98044 9.29291 9.79291C9.48044 9.60537 9.73478 9.5 10 9.5C10.2652 9.5 10.5196 9.60537 10.7071 9.79291C10.8946 9.98044 11 10.2348 11 10.5V16C11 16.2652 10.8946 16.5196 10.7071 16.7071C10.5196 16.8946 10.2652 17 10 17C9.73478 17 9.48044 16.8946 9.29291 16.7071C9.10537 16.5196 9 16.2652 9 16ZM13 16V10.5C13 10.2348 13.1054 9.98044 13.2929 9.79291C13.4804 9.60537 13.7348 9.5 14 9.5C14.2652 9.5 14.5196 9.60537 14.7071 9.79291C14.8946 9.98044 15 10.2348 15 10.5V16C15 16.2652 14.8946 16.5196 14.7071 16.7071C14.5196 16.8946 14.2652 17 14 17C13.7348 17 13.4804 16.8946 13.2929 16.7071C13.1054 16.5196 13 16.2652 13 16Z"
      fill="#FF3333"
    />
  </svg>
);

export default Basket;
