import { useLogo } from "../../services/hooks";
import { i18n, state } from "../../services";
import { useContext } from "preact/hooks";
import { ContextUI } from "../../providers/UIProvider";
import LanguageModal from "../Modals/LanguageModal";
import {
  HeaderLogoWrapper,
  LogoBrandHeader,
  OuterBlockHeader,
} from "../Header/style";
import { StyledCurrentLanguageIcon } from "../FormHeader/style";
import { LogoLangWrapper as LogoLangWrapperDefault } from "../../themes/default/components/CryptoForm/style";
import { LogoLangWrapper as LogoLangWrapperPM } from "../../themes/PM_brand/components/CryptoForm/style";
import { useThemeComponent } from "../../hooks/useThemeComponent";
const CryptoHeader = ({ onChangeLanguage }) => {
  const { setModalData } = useContext(ContextUI);

  const options = state.get().options;
  const logoIcon = useLogo(options?.theme);

  const handleOpen = () => {
    setModalData({
      isOpen: true,
      content: <LanguageModal onChange={onChangeLanguage} />,
      title: "chooseLanguage",
      isFullView: i18n.getLanguageConfig()?.length > 4,
    });
  };

  const themeForComponent = useThemeComponent("CryptoForm");

  const LogoLangWrapper =
    {
      default: LogoLangWrapperDefault,
      PM_brand: LogoLangWrapperPM,
    }[themeForComponent] || LogoLangWrapperDefault;

  return (
    <OuterBlockHeader>
      <HeaderLogoWrapper>
        <LogoLangWrapper $hasLogo={logoIcon}>
          <LogoBrandHeader src={`${logoIcon}`} />
          <StyledCurrentLanguageIcon
            onClick={handleOpen}
            src={`/flags/${i18n.getCountryCode()?.toString()?.toLowerCase()}.svg`}
          />
        </LogoLangWrapper>
      </HeaderLogoWrapper>
    </OuterBlockHeader>
  );
};

export default CryptoHeader;
