export const WrongIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 12C20 16.4 16.4 20 12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C16.2 4 20 7.6 20 12ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM13 12C13 10.9 13 8.6 13 7.5C13 6.9 12.6 6.5 12 6.5C11.4 6.5 11 6.9 11 7.5C11 8.6 11 10.9 11 12C11 12.6 11.4 13 12 13C12.6 13 13 12.6 13 12ZM10.7 16C10.7 16.7 11.3 17.3 12 17.3C12.7 17.3 13.3 16.7 13.3 16C13.3 15.3 12.7 14.7 12 14.7C11.3 14.7 10.7 15.3 10.7 16Z"
      fill="white"
    />
  </svg>
);

export default WrongIcon;
