import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

  ${(props) => {
    const theme = props.theme || {};
    const mainFont = theme?.mainFont;
    const mainNameFont = theme?.mainNameFont;
    const mainUrlFont = theme?.mainUrlFont;
    const regularFont = theme?.regularFont;
    const regularNameFont = theme?.regularNameFont;
    const regularUrlFont = theme?.regularUrlFont;
    const mediumFont = theme?.mediumFont;
    const mediumNameFont = theme?.mediumNameFont;
    const mediumUrlFont = theme?.mediumUrlFont;

    return `
    ${
      mainUrlFont
        ? `
    @font-face {
      font-family: ${mainNameFont};
      src: local(${mainNameFont}),
        url(${mainUrlFont}) format('truetype');
    }
    `
        : ``
    }

    ${
      regularUrlFont
        ? `
    @font-face {
      font-family: ${regularNameFont};
        src: local(${regularNameFont}),
          url(${regularUrlFont}) format('truetype');
    }
    `
        : ``
    }

    ${
      mediumUrlFont
        ? `
    @font-face {
      font-family: ${mediumNameFont};
        src: local(${mediumNameFont}),
          url(${mediumUrlFont}) format('truetype');
    }
    `
        : ``
    }
      

      :root {
        --mainFont: ${mainFont};
        --regularFont: ${regularFont};
        --boldFont: ${mediumFont};
        --runcPinkPM: #ff7e7e;
  }

   apple-pay-merchandising-modal {
     z-index: 0 !important;
    }
    `;
  }}

html,
body {
  max-height: 100%;
  padding: 0;
  max-width: ${(props) => props.theme?.formMaxWidth};
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
  background:  ${({ theme }) => theme?.bodyBackground};
  font-family: var(--mainFont);
  font-weight: 400;
  color: #101820;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus-visible {
  outline: none;
}

body > div {
  min-width: 320px;
  max-width: ${(props) => props.theme?.formMaxWidth};
  width: 100%;
  background: ${({ theme }) => theme?.mainBackGroundColor};
  position: relative;
}

h1,
p,
h2,
ul,
span {
  font-family: var(--mainFont);
  padding: 0;
  margin: 0;
  letter-spacing: 0.4px;
}

* {
  box-sizing: border-box;
}

#app {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;


  @media (min-width: 768px) and (min-height: 500px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100vh - 160px);
    border-radius:  ${({ theme }) => theme?.appBorderRadius};
  }

  @media  (max-height: 768px) and (min-width: 768px) {
    height: calc(100vh - 32px);
  }
  @media  (max-height: 500px) {
    height: calc(var(--vh, 1vh) * 100);
  }
}

#app > *, #app  > * {
  flex-shrink: 0;
  user-select: none;
}

apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 40px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}

`;

export const WhiteOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const InnerLine = styled.div`
  width: 100%;
  margin-top: ${({ $mt }) => $mt || ""};
  height: 1px;
  background: ${({ theme }) => theme?.innerLineBackground};
  width: 100%;
  margin-bottom: 16px;
`;

export const IconColorWrapper = styled.div`
  position: relative;
  display: flex;
  margin-left: ${({ ml }) => (ml ? ml : "")};
  margin-right: ${({ mr }) => (mr ? mr : "")};
  svg {
    path {
      fill: ${(props) =>
        props.theme?.iconCopyColorIcon || props.color || props.theme.mainColor};
    }
  }
  p {
    color: ${(props) =>
      props.theme?.iconCopyColorIcon || props.color || props.theme.mainColor};
  }
`;

export const IconColorCopyTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-right: 4px;
`;
