import { useContext } from "preact/hooks";
import { OuterBlockHeader } from "../../../../components/Header/style";
import { ContextUI } from "../../../../providers/UIProvider";
import { FunctionComponent } from "preact";
import { Context } from "../../../../app";
import Instruction from "../../../../components/Instruction";
import Button from "../../../../components/Button";
import Trans from "../../../../components/Trans";
import { PMButtonWrapper } from "../Form/style";

interface ChallengeQrProps {
  challenge: {
    qr?: {
      image?: string;
      code?: string;
    };
  };
  infoBlockTitle: Record<string, string>;
  selectedMethod: string;
  cancelUrl?: string;
}

const ChallengeQrPM: FunctionComponent<ChallengeQrProps> = ({
  challenge,
  selectedMethod,
  cancelUrl,
}) => {
  const { handleDownloadImage } = useContext(ContextUI);
  const { onGoBackButtonClick } = useContext(Context);

  return (
    <OuterBlockHeader>
      <Instruction
        configName={selectedMethod as "qr_code" | "wallet_opay_qr"}
        instructionData={challenge.qr || {}}
        handleButtonClick={() =>
          handleDownloadImage(challenge?.qr?.image || "", "qr_code.png")
        }
        buttonText="Download"
      />
      <PMButtonWrapper>
        <Button
          textTransform="inherit"
          onClick={() => onGoBackButtonClick(cancelUrl || "")}
        >
          <Trans message="iHaveMadePayment" />
        </Button>
      </PMButtonWrapper>
    </OuterBlockHeader>
  );
};

export default ChallengeQrPM;
