import { FunctionComponent } from "preact";
import Button from "../../../../components/Button";
import Timer from "../../../../components/Challenge/ChallengeSubmitFormTimer";
import CustomerInput from "../../../../components/CustomerInput";
import Dropdown from "../../../../components/Dropdown";
import Trans from "../../../../components/Trans";
import { state } from "../../../../services";
import { PMChallengeInputWrapper } from "./style";
import { ChallengeField } from "../../../../components/Challenge/ChallengeFormConfig";

interface ChallengeProps {
  challengeFormConfig: ChallengeField;
  challengeType: string;
  values: Record<string, any>;
  setValues: (values: Record<string, any>) => void;
  errors: Record<string, string>;
  setErrors: (
    errors: (prev: Record<string, string>) => Record<string, string>,
  ) => void;
  sessionId?: string;
  signature?: string;
  handleSubmit: () => void;
  challenge: any;
  isDisabled: boolean;
  submitButtonText?: string;
}

const ChallengeFormPM: FunctionComponent<ChallengeProps> = ({
  challengeFormConfig,
  challengeType,
  values,
  setValues,
  errors,
  setErrors,
  sessionId,
  signature,
  handleSubmit,
  challenge,
  isDisabled,
  submitButtonText,
}) => {
  const isTimerVisible = challenge?.otp?.otp_resend_support;

  return (
    <PMChallengeInputWrapper>
      {Object.entries(challengeFormConfig).map(([fieldName, fieldConfig]) => {
        const {
          name,
          placeholder,
          label,
          inputOptions,
          supportedChallengeTypes,
          type,
          pattern,
          validationMessage,
        } = fieldConfig;

        if (
          supportedChallengeTypes &&
          !supportedChallengeTypes.includes(challengeType)
        ) {
          return null;
        }

        if (type === "dropdown") {
          return (
            <div key={fieldName}>
              <Dropdown
                nameKey={name}
                placeholder={placeholder}
                label={label}
                inputOptions={inputOptions}
                options={
                  inputOptions?.getOptions &&
                  inputOptions?.getOptions({ challenge })
                }
                staticDropdown
                values={values}
                setValues={setValues}
                errors={errors}
                setErrors={setErrors}
              />
            </div>
          );
        }

        return (
          <div key={fieldName}>
            <CustomerInput
              nameKey={name}
              label={label}
              type={type}
              pattern={pattern}
              validationMessage={validationMessage}
              placeholder={placeholder}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
          </div>
        );
      })}
      {isTimerVisible && (
        <Timer
          initialSeconds={30}
          sessionId={sessionId}
          signature={signature}
        />
      )}
      <Button
        onClick={handleSubmit}
        disabled={isDisabled}
        isInIframe={state.isInIframe()}
      >
        <Trans message={submitButtonText || "submit"} withoutWrapper />
      </Button>
    </PMChallengeInputWrapper>
  );
};

export default ChallengeFormPM;
