import { useContext } from "preact/hooks";
import { Context } from "../../app";
import Scroll from "../../components/Scroll";
import useApmPageTracking from "../../hooks/useApmPageTracking";
// import AsyncComponent from "../../components/AsyncComponent";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import FailDefault from "../../themes/default/components/FailPage";
import FailKombine from "../../themes/kombine/components/FailPage";
import FailPagePM from "../../themes/PM_brand/components/FailPage";

const ErrorPage = () => {
  useApmPageTracking("ErrorPage");

  const context = useContext(Context);
  const { currentState, onGoBackButtonClick } = context;

  const { amount, currency, options, reference, error } = currentState || {};
  const { redirect_url } = options || {};

  const onButtonClick = () => {
    onGoBackButtonClick(redirect_url);
  };

  const themeForComponent = useThemeComponent("FailPage");

  const Fail = {
    default: FailDefault,
    kombine: FailKombine,
    PM_brand: FailPagePM,
  }[themeForComponent];

  if (!error) {
    return null;
  }

  return (
    <Scroll>
      <Fail
        amount={amount}
        currency={currency}
        onButtonClick={onButtonClick}
        reference={reference}
        options={options}
        title={error?.title}
        description={error?.description}
        hasGoBack
      />
      {/* <AsyncComponent
        importFn={(t) =>
          import(
            `../../themes/${themeForComponent}/components/FailPage/index.tsx${t}`
          )
        }
        name="Fail"
      >
        {(Fail) => (
          <Fail
            amount={amount}
            currency={currency}
            onButtonClick={onButtonClick}
            reference={reference}
            options={options}
            title={error?.title}
            description={error?.description}
            hasGoBack
          />
        )}
      </AsyncComponent> */}
    </Scroll>
  );
};

export default ErrorPage;
