import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const ChallengeMainTitle = styled.span<{
  $mt?: string;
  $mb?: string;
  $bankNameIcon?: boolean;
}>`
  margin-bottom: ${({ $mb }) => $mb || ""};
  margin-top: ${({ $mt }) => $mt || ""};
  width: 100%;
  font-family: var(--boldFont);
  color: ${({ theme }) => theme?.mainTextColor || "#101820"};
  font-size: 14px;
  font-weight: 500;
  line-height: 16.3px;
  letter-spacing: 0.01px;
  span {
    font-family: var(--boldFont);
    color: ${({ theme }) => theme?.mainTextColor || "#101820"};
    font-size: 14px;
    font-weight: 500;
    line-height: 16.3px;
    letter-spacing: 0.01px;
  }

  display: ${({ $bankNameIcon }) => ($bankNameIcon ? "flex" : "")};
  align-items: center;
`;

export const ChallgeMainSubTitle = styled.span`
  font-family: var(--boldFont);
  color: #101820;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.3px;
  letter-spacing: 0.01px;
`;

export const StkChallengeSubTitle = styled.p`
  margin-bottom: 16px;

  span {
    color: #101820;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.3px;
    letter-spacing: 0.5px;
    color: #5a747a;
    font-family: var(--regularFont);
  }
`;

export const StyledInfoImage = styled.div`
  & svg {
    margin-right: 12px;
    circle {
      fill: ${({ theme }) => theme.mainColor};
    }
  }
`;

export const ChallengeInfoWrapper = styled.div<{
  $mb?: string;
}>`
  background-color: #e5f0ff;
  padding: 8px 12px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  margin-bottom: ${({ $mb }) => $mb || ""};
`;

export const PaymentMethodWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  height: 32px;
  align-items: center;
`;

export const PaymentMethodTitle = styled.span<{
  $fs?: string;
  $mr?: string;
  $mb?: string;
  $mt?: string;
  $color?: string;
}>`
  font-size: ${({ $fs }) => $fs || "18px"};
  font-family: var(--boldFont);
  margin-right: ${({ $mr }) => $mr || ""};
  margin-bottom: ${({ $mb }) => $mb || ""};
  color: ${({ $color }) => $color || "#101820"};
  margin-top: ${({ $mt }) => $mt || ""};
  & span {
    font-family: var(--boldFont);
    font-weight: 600;
  }
`;

export const GoBackButtonWrapper = styled.div`
  margin: 16px;
`;

export const ChallengeUssdInfoWrapper = styled.div<{
  $mr?: string;
  $mb?: string;
}>`
  display: flex;
  margin-right: ${({ $mr }) => $mr || "auto"};
  align-items: baseline;
  margin-bottom: ${({ $mb }) => $mb || ""};
  & span {
    font-size: 14px;
    font-family: var(--regularFont);
    color: ${({ theme }) => theme?.mainTextColor || "#5a747a"};
    letter-spacing: 0px;
  }
`;

export const ChallengeNumberTitle = styled.p`
  color: ${({ theme }) => theme?.mainTextColor || theme.mainColor};
  font-size: 14px;
  font-family: var(--boldFont);
  margin-right: 12px;
  width: 9px;
`;

export const StepListSubtitle = styled.span`
  margin-left: 4px;
`;

export const ChallengeInfoMainTitle = styled.span`
  width: 100%;
  font-family: var(--boldFont);
  color: #101820;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.3px;
  letter-spacing: 0.01px;

  span {
    font-family: var(--boldFont);
    color: #101820;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.3px;
    letter-spacing: 0.01px;
  }
`;

export const UssdCurrencyAmountWrapper = styled.div`
  max-width: 376px;
  width: 100%;
  border-radius: 20px;
  font-size: 18px;
  border: 1px solid #fff;
  color: #101820;
  font-family: var(--boldFont);
  padding: 12px 16px;
  margin-top: 10px;
  margin-bottom: 18px;
  pointer-events: auto;
  cursor: text;
  user-select: auto;
  height: 40px;
  display: flex;
  margin: 0 auto;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  & b {
    font-size: 18px;
    font-weight: 400;
    font-family: var(--boldFont);
    line-height: 16.03px;
  }
  & span {
    font-size: 14px;
    font-family: var(--regularFont);
    line-height: 16.03px;
  }
`;

export const InputCurrencyTitle = styled.span`
  color: #5a747a;
`;

export const BankTransferAfricaInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px 20px 0px 20px;

  span {
    text-align: center;
  }
`;

export const VideoLink = styled.a`
  color: ${({ theme }) => theme?.buttonColor};
  cursor: pointer;
  font-family: var(--boldFont);
  font-size: 15px;
  text-decoration: none;
  transition: ease 0.3s;
  margin-bottom: 12px;

  &:hover {
    color: ${COLORS.DEFAULT_BLACK};
  }
`;

export const QrCodeWrapper = styled.div`
  border-radius: 20px;
  padding: 10px;
  background-color: ${COLORS.DEFAULT_WHITE};
  max-width: 376px;
  margin: 0 auto;
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const QrCodeButtonWrapper = styled.div`
  margin-bottom: 12px;
  width: 100%;
`;

export const PaymentInfoWrapper = styled.div`
  background: ${COLORS.DEFAULT_WHITE};
  border-radius: 15px;
  padding: 12px 9px 17px 12px;
  width: 376px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 32px;
  span {
    line-height: 17.17px;
    letter-spacing: 0.5px;
  }
`;

export const ChallengeFormMainWrapper = styled.div`
  align-items: center;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: 440px;
  margin: 0 auto;
  flex-shrink: 0;
  width: 100%;
`;

export const FormAmountOfPaymentWrapper = styled.div`
  text-align: center;
`;
