import { FunctionComponent } from "preact";
import InfoIcon from "../../../../assets/icons/infoCombineIcon";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { InputCvvProps } from "../../../commons/interfaces/CardInterfaces";
import FieldUI from "../../../default/ui/Field";
import { KombineTooltipWrapper } from "./style";

const TooltipComponent = () => {
  return (
    <KombineTooltipWrapper>
      <Tooltip
        background="#10366B"
        width="220px"
        fs="12px"
        bottom="-73px"
        textAlign="start"
        left="-300%"
        leftArrow="74%"
        top="-15%"
        rotate="180deg"
        text="Secure protocol with 256-bit encryption, located on the back of your card"
      >
        <InfoIcon />
      </Tooltip>
    </KombineTooltipWrapper>
  );
};

const InputCvv: FunctionComponent<InputCvvProps> = ({
  focused,
  errorChecking,
  onCvvBlur,
  onCvvInput,
  setFocused,
  values,
  inputRef,
  errors,
}) => {
  return (
    <FieldUI
      id="cvv"
      label="CVV2"
      value={values.cvv}
      onInput={onCvvInput}
      onBlur={onCvvBlur}
      setFocused={setFocused}
      focused={focused}
      inputRef={inputRef}
      type="password"
      pattern="[0-9\s]{3}"
      maxlength={3}
      size={3}
      autocomplete="cc-csc"
      placeholder="•••"
      data-role="card_cvv"
      data-transaction-name="InputCvv"
      error={errors?.cvv}
      errorChecking={errorChecking}
      extraComponent={<TooltipComponent />}
      extraComponentPosition="label"
      fieldType="small"
    />
  );
};

export default InputCvv;
