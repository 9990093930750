import { FunctionComponent } from "preact";
import Trans from "../../../../components/Trans";
import {
  RemainingTimeTitle,
  TimeRemainingTitle,
  TimeRemainingWrapper,
} from "./style";

interface ExpirationDateDefault {
  timeLeft: {
    difference: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
}

const ExpirationDateDefault: FunctionComponent<ExpirationDateDefault> = ({
  timeLeft,
}) => {
  const { difference, hours, minutes, seconds } = timeLeft || {};
  return (
    <TimeRemainingWrapper
      data-transaction-name="timer"
      timeRemaining={
        difference / 1000 / 60 < 1 &&
        (minutes < 1 || (minutes === 0 && seconds === 0))
      }
    >
      <div>
        <TimeRemainingTitle>
          <Trans message="remainingTime" />{" "}
          <RemainingTimeTitle>
            {hours < 10 ? `0${hours}` : hours}:
            {minutes < 10 ? `0${minutes}` : minutes}:
            {seconds < 10 ? `0${seconds}` : seconds}
          </RemainingTimeTitle>
          <TimeRemainingTitle />
        </TimeRemainingTitle>
      </div>
    </TimeRemainingWrapper>
  );
};

export default ExpirationDateDefault;
