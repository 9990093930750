const SpinnerIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8889 0.333496H18.1111V12.9834C18.4441 13.0753 18.7604 13.2075 19.0544 13.3744L27.9994 4.42938L29.5708 6.00073L20.6258 14.9457C20.7927 15.2397 20.9249 15.556 21.0169 15.8891H33.6666V18.1113H21.0169C20.9249 18.4443 20.7927 18.7607 20.6257 19.0547L29.5706 27.9996L27.9993 29.571L19.0543 20.626C18.7603 20.7929 18.4441 20.925 18.1111 21.0169V33.6668H15.8889V21.0169C15.5559 20.925 15.2397 20.7928 14.9457 20.6258L6.00057 29.571L4.42922 27.9996L13.3744 19.0544C13.2075 18.7605 13.0753 18.4442 12.9834 18.1113H0.333252V15.8891H12.9834C13.0753 15.5561 13.2074 15.2399 13.3743 14.946L4.42904 6.00073L6.00039 4.42938L14.9456 13.3746C15.2396 13.2076 15.5559 13.0754 15.8889 12.9835V0.333496Z"
      fill="#009E69"
    />
  </svg>
);

export default SpinnerIcon;
