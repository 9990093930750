import styled from "styled-components";

export const ChallengeInputWrapper = styled.div`
  margin: 0 auto 32px;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 24px;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0 24px;
  }
`;
