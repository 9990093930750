import styled from "styled-components";
import { PM_COLORS } from "../../enums";

export const PMInstructionWrapper = styled.div<{
  $isOrderedList?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: ${({ $isOrderedList }) => ($isOrderedList ? "8px" : 0)};
  background-color: ${({ $isOrderedList }) =>
    $isOrderedList ? "transparent" : PM_COLORS.GRAY_6};
`;

export const PMInnerHeaderDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 12px;
  width: 100%;
  max-width: 100%;
`;

export const PMInstructionStepWrapper = styled.div<{
  $isOrderedList?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ $isOrderedList }) => ($isOrderedList ? "56px" : "")};
`;

export const PMBlockLine = styled.div`
  width: calc(100% - 32px);
  height: 1px;
  margin: 0 16px;
  background-color: ${PM_COLORS.GRAY_DARK_LIGHT};
`;

export const PMInstructionStepTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 16px;
`;

export const PMInstructionStepNumber = styled.p`
  font-family: var(--mediumFont);
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: ${PM_COLORS.PRIMARY};
  width: 12px;
  flex-shrink: 0;
`;

export const PMInstructionStepTitle = styled.span`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #000;
  opacity: 0.56;
  width: 100%;
`;

export const PMInstructionTitle = styled.span`
  font-family: var(--regularFont);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #000000;
  opacity: 0.9;
`;

export const PMInfoWrapper = styled.div<{
  $margin?: string;
}>`
  display: flex;
  margin: ${({ $margin }) => $margin ?? "0 auto"};
  width: 100%;
`;

export const PMInstructionStepDataSubtext = styled.span`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: #000;
  opacity: 0.56;
`;

export const PMInstructionTimerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
`;

export const QRWrapper = styled.div<{
  $width?: number;
}>`
  background-color: ${PM_COLORS.GRAY_4};
  border-radius: 20px;
  height: ${({ $width }) => ($width ? `${$width - 48}px` : "300px")};
  width: ${({ $width }) => ($width ? `${$width - 48}px` : "300px")};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
  }
`;

export const QRButtonWrapper = styled(QRWrapper)`
  border: none;
  height: auto;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  background-color: initial;
`;

export const QRsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 12px 0;
`;

export const ButtonWrapper = styled.div`
  padding: 12px 0;
  width: 100%;
`;
