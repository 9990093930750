import styled from "styled-components";
import { TooltipContentWrapper } from "../../../commons/styles/InputStyles";
import { TooltipTextCvv } from "../../../default/components/InputCvv/style";

export const PMTooltipContentWrapper = styled(TooltipContentWrapper)`
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
`;

export const PMTooltipTextCvv = styled(TooltipTextCvv)`
  right: 29px;
  top: -3px;
`;
