import { FunctionComponent } from "preact";
import { useState } from "preact/hooks";
import searchIcon from "../../../../assets/icons/search.svg";
import {
  DropdownSearch,
  DropdownSearchIcon,
  SearchInput,
} from "../../../../components/Dropdown/style";
import { i18n } from "../../../../services";

interface SearchProps {
  onSearch: (query: string) => void;
}

const Search: FunctionComponent<SearchProps> = ({ onSearch }) => {
  const [search, setSearch] = useState<string>("");

  const onInput = (e: Event) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
    onSearch(target.value);
  };

  return (
    <DropdownSearch>
      <SearchInput
        value={search}
        onInput={onInput}
        type="text"
        placeholder={i18n.getMessage({ message: "search" })}
      />
      <DropdownSearchIcon>
        <img src={searchIcon} alt="search" />
      </DropdownSearchIcon>
    </DropdownSearch>
  );
};

export default Search;
