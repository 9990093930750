import Trans from "../Trans";
import { InnerLine } from "../../styles";
import { useContext } from "preact/hooks";
import { Context } from "../../app";
import { OuterBlockHeader, TotalInfoWrapper } from "../Header/style";
import { InnerHeaderDataWrapper } from "../../themes/default/components/Instruction/style";
import {
  BlockDetailsWrapper,
  ContainerDetailsWrapper,
  DetailsTransactionTitle,
} from "../BankInfo/style";
import { PaymentInfoWrapper, VideoLink } from "./style";
import { BaseButton } from "../../themes/commons/styles/ButtonStyles";

const ChallengeBrLottery = ({ currentState, transactionIdRef, cancelUrl }) => {
  const { challenge } = currentState;
  const { onGoBackButtonClick } = useContext(Context);
  const { cpf, bank_no } = challenge?.br?.fields || {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <OuterBlockHeader>
        <InnerHeaderDataWrapper $padding="20px">
          <DetailsTransactionTitle>
            Pay in Lottery using the details below:
          </DetailsTransactionTitle>

          <TotalInfoWrapper>
            <ContainerDetailsWrapper>
              <BlockDetailsWrapper>
                <DetailsTransactionTitle>
                  Convention code:
                </DetailsTransactionTitle>
                <span>
                  <DetailsTransactionTitle>{bank_no}</DetailsTransactionTitle>
                </span>
              </BlockDetailsWrapper>
              <BlockDetailsWrapper>
                <DetailsTransactionTitle>
                  CPF/CNPJ number:
                </DetailsTransactionTitle>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <DetailsTransactionTitle
                    $mr="8px"
                    $fw="600"
                    ref={transactionIdRef}
                  >
                    {cpf}
                  </DetailsTransactionTitle>
                </span>
              </BlockDetailsWrapper>
              <InnerLine />

              <BlockDetailsWrapper $mb="0">
                <DetailsTransactionTitle $color="#7b949d">
                  It's mean the payment IS NOT via bank slip.
                </DetailsTransactionTitle>
              </BlockDetailsWrapper>
            </ContainerDetailsWrapper>
          </TotalInfoWrapper>
        </InnerHeaderDataWrapper>

        {cancelUrl && (
          <BaseButton
            data-role="back_to_store_button"
            onClick={() => onGoBackButtonClick(cancelUrl)}
            $mb="22px"
          >
            <Trans message="goBack" />
          </BaseButton>
        )}
        <PaymentInfoWrapper>
          <DetailsTransactionTitle>
            Have a question about your payment?
          </DetailsTransactionTitle>{" "}
          <VideoLink
            href="https://www.youtube.com/watch?v=Vw8Iib_uGeE"
            target="_blank"
            rel="noreferrer"
          >
            Click here and watch "How to?.." video.{" "}
          </VideoLink>
          <DetailsTransactionTitle style={{ paddingTop: "10px" }}>
            Approval happens during 1 hour after payment
          </DetailsTransactionTitle>
        </PaymentInfoWrapper>
      </OuterBlockHeader>
    </div>
  );
};

export default ChallengeBrLottery;
