import Loader from "../../../../components/Loaders";
import { FunctionComponent } from "preact";
import { PMLoaderWrapper } from "./style";

const PendingLoaderPM: FunctionComponent = () => {
  return (
    <PMLoaderWrapper data-transaction-name="processing-loader">
      <Loader loaderName="pmLoader" />
    </PMLoaderWrapper>
  );
};

export default PendingLoaderPM;
