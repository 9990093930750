import styled from "styled-components";
import { PM_COLORS } from "../../enums";
import { InvalidCardErrorTitle } from "../../../commons/styles/InputStyles";

export const InputWrapper = styled.div<{
  $fieldType?: string;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ $fieldType }) =>
    $fieldType === "small" ? "calc(50% - 4px)" : "100%"};
  max-width: 100%;
  cursor: pointer;
`;

export const InputContainer = styled.div<{
  $focused?: boolean;
  $hasError?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 56px;
  border: ${({ $hasError }) =>
    `1px solid ${$hasError ? PM_COLORS.DANGER : PM_COLORS.BORDER}`};
  border-radius: 12px;
  background-color: ${PM_COLORS.DEFAULT_WHITE};
  display: flex;
  align-items: center;
  padding: 27px 16px 9px;
  transition: all 0.2s ease-in-out;
`;

export const Label = styled.label<{
  $focused?: boolean;
  $filled?: boolean;
  $hasError?: boolean;
  $isNoEditable?: boolean;
}>`
  position: absolute;
  left: 16px;
  top: ${({ $focused, $filled }) => ($focused || $filled ? "9px" : "50%")};
  transform: translateY(
    ${({ $focused, $filled }) => ($focused || $filled ? "0" : "-50%")}
  );
  font-size: ${({ $focused, $filled }) =>
    $focused || $filled ? "13px" : "16px"};
  color: ${({ $hasError }) =>
    $hasError ? PM_COLORS.DANGER : PM_COLORS.GRAY_SECONDARY};
  transition: ${({ $isNoEditable }) =>
    $isNoEditable ? "none" : "all 0.2s ease-in-out"};
`;

export const StyledInput = styled.input<{
  $hasPlaceholder?: boolean;
  $isDropdown?: boolean;
  $isNoEditable?: boolean;
}>`
  width: 100%;
  height: 100%;
  border: none;
  font-size: 16px;
  color: ${PM_COLORS.GRAY_DARK};
  background: transparent;
  outline: none;
  padding: 0;

  &::placeholder {
    color: ${PM_COLORS.GRAY_LIGHT};
  }

  ${({ $isDropdown }) => {
    if ($isDropdown) {
      return `
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 28px;
        text-transform: capitalize;
      `;
    }

    return ``;
  }}

  ${({ $isNoEditable }) => {
    if ($isNoEditable) {
      return `
          cursor: pointer;
  
          input {
            pointer-events: none;
          }
        `;
    }

    return "";
  }}
`;

export const FieldError = styled(InvalidCardErrorTitle)`
  position: relative;
  top: 0;
  left: 0;
  color: ${PM_COLORS.DANGER};
  padding: 4px 16px;
`;

export const DropdownIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
