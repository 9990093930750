import Trans from "../../Trans";
import { IconColorWrapper } from "../../../styles";
import IconCopy from "../../../assets/icons/iconCopy";
import { Context } from "../../../app";
import { useContext } from "preact/hooks";
import { OuterBlockHeader, TotalInfoWrapper } from "../../Header/style";
import {
  BlockDetailsWrapper,
  ContainerDetailsWrapper,
  DetailsTransactionSubTitle,
} from "../../BankInfo/style";
import {
  BolettoDetailsSubTitle,
  ChallengeButtonWrapper,
  InnerDataBoletto,
} from "./style";
import { COLORS } from "../../../constants/themeConstants";
import { PaymentInfoWrapper } from "../style";
import { BaseButton } from "../../../themes/commons/styles/ButtonStyles";
import { TransactionTitle } from "../../../themes/commons/styles/FailPageStyles";

const ChallengeBrBoleto = ({
  currentState,
  handleCopy,
  cancelUrl,
  handleDownloadPdf,
}) => {
  const { challenge } = currentState;
  const { onGoBackButtonClick } = useContext(Context);
  const { bank_code, pdf_url, image } = challenge?.br.fields || {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <OuterBlockHeader>
        <InnerDataBoletto>
          <TransactionTitle $color={COLORS.DEFAULT_BLACK}>
            <Trans message="boletoGenerated" />
          </TransactionTitle>

          <TotalInfoWrapper $mt="12px">
            <ContainerDetailsWrapper>
              <img style={{ marginBottom: "6px" }} src={image} />
              {bank_code && (
                <BlockDetailsWrapper
                  onClick={() => handleCopy(bank_code, "codeCopied")}
                  $mb="0"
                >
                  <BolettoDetailsSubTitle>{bank_code}</BolettoDetailsSubTitle>
                  <span
                    style={{
                      cursor: "pointer",
                      color: "blue",
                    }}
                  >
                    <IconColorWrapper>
                      <IconCopy />
                    </IconColorWrapper>
                  </span>
                </BlockDetailsWrapper>
              )}
            </ContainerDetailsWrapper>
          </TotalInfoWrapper>
        </InnerDataBoletto>

        <ChallengeButtonWrapper $pdf_url={pdf_url} $width="376px">
          <BaseButton
            $width={"100%"}
            $mb="12px"
            onClick={() => handleCopy(bank_code, "Code copied")}
          >
            <Trans message="Copy" />
          </BaseButton>
          {pdf_url && (
            <BaseButton $mb="12px" onClick={() => handleDownloadPdf()}>
              <Trans message="savePdf" />
            </BaseButton>
          )}
        </ChallengeButtonWrapper>

        {cancelUrl && (
          <ChallengeButtonWrapper $width="376px">
            <BaseButton
              data-role="back_to_store_button"
              $mb="22px"
              onClick={() => onGoBackButtonClick(cancelUrl)}
            >
              <Trans message="goBack" />
            </BaseButton>
          </ChallengeButtonWrapper>
        )}
        <PaymentInfoWrapper>
          <DetailsTransactionSubTitle>
            <Trans message="boletoDetailsTitle" />
          </DetailsTransactionSubTitle>
        </PaymentInfoWrapper>
      </OuterBlockHeader>
    </div>
  );
};

export default ChallengeBrBoleto;
