import styled from "styled-components";

export const GoogleApplePayTestButton = styled.div`
  height: 40px;
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  & > img {
    margin: 0 3px;
  }
`;
