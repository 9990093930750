import { FunctionComponent } from "preact";
import { DropdownEmpty } from "../../../../components/Dropdown/style";
import Trans from "../../../../components/Trans";
import { state } from "../../../../services";
import empty from "../../../../assets/icons/dropdownEmpty.svg";
import { PMCardListContainer, PMCardListWrapper, PMRadioItem } from "./style";
import {
  PMItemContent,
  PMItemDetails,
  PMItemIcon,
  PMItemIconAction,
  PMItemName,
} from "../SavedCard/style";
import Button from "../../../../components/Button";
import { EnumVariant } from "../../../commons/enums/ButtonEnums";
import { useState } from "preact/hooks";
import PaymentMethodsLogo from "../../../../components/PaymentMethods/PaymentMethodsLogo";
import { getFormattedTitle } from "../../../../components/Modals/ModalComponents/ModalItem";
import Scroll from "../../../../components/Scroll";

interface Option {
  [key: string]: any;
  name?: string;
  subName?: string;
  code?: string;
}

interface InputOptions {
  getMethodNameForIcon?: () => string;
  display?: (args: { option: Option }) => string;
}

interface DropdownListPMProps {
  options: Option[];
  selected: Option;
  onChange: (option: Option) => void;
  foreignKey: string;
  showBankIcon?: boolean;
  display?: (args: { option: Option }) => string;
  inputOptions: InputOptions;
}

const DropdownListPM: FunctionComponent<DropdownListPMProps> = ({
  options,
  selected,
  onChange,
  foreignKey,
  showBankIcon = false,
  display,
  inputOptions,
}) => {
  const [chosenOption, setChosenOption] = useState<Option | null>(null);
  const selectedMethod = state.getSelectedPaymentMethod();

  const handleChooseItem = (option: Option) => {
    setChosenOption(option);
  };

  const handleSubmit = () => {
    if (chosenOption) {
      onChange(chosenOption);
    }
  };

  if (options.length === 0) {
    return (
      <DropdownEmpty>
        <img src={empty} alt="icon" />
        <Trans message="dropdownEmpty" />
      </DropdownEmpty>
    );
  }

  return (
    <PMCardListWrapper>
      <Scroll style={{}}>
        <PMCardListContainer>
          {options.map((option) => {
            const name =
              (inputOptions.getMethodNameForIcon &&
                typeof inputOptions.getMethodNameForIcon === "function" &&
                inputOptions.getMethodNameForIcon()) ||
              option.code ||
              option.name ||
              "";

            const title =
              display && typeof display === "function"
                ? display({ option })
                : option.name || "";

            return (
              <PMItemContent
                key={option[foreignKey]}
                onClick={() => handleChooseItem(option)}
                $hasImage={!!showBankIcon}
              >
                {showBankIcon && (
                  <PMItemIcon>
                    <PaymentMethodsLogo
                      selectedMethod={name}
                      isBankCode={showBankIcon}
                      coloredIcon
                      method={selectedMethod?.method}
                    />
                  </PMItemIcon>
                )}

                <PMItemDetails>
                  <PMItemName>
                    <Trans
                      message={getFormattedTitle(selectedMethod?.method, title)}
                      withoutWrapper
                    />
                  </PMItemName>
                </PMItemDetails>
                <PMItemIconAction>
                  <PMRadioItem
                    $isActive={
                      chosenOption
                        ? chosenOption[foreignKey] === option[foreignKey]
                        : selected[foreignKey] === option[foreignKey]
                    }
                  />
                </PMItemIconAction>
              </PMItemContent>
            );
          })}
        </PMCardListContainer>
      </Scroll>

      <Button
        onClick={handleSubmit}
        disabled={!chosenOption}
        variant={EnumVariant.SECONDARY}
        data-transaction-name="pm_modal_submit"
      >
        <Trans message="done" />
      </Button>
    </PMCardListWrapper>
  );
};

export default DropdownListPM;
