import { useThemeComponent } from "../../hooks/useThemeComponent";
import { state } from "../../services";
// import AsyncComponent from "../AsyncComponent";
import RememberMeDefault from "../../themes/default/components/RememberMe";
import RememberMeKombine from "../../themes/kombine/components/RememberMe";
import RememberMeKPM from "../../themes/PM_brand/components/RememberMe";

const RememberMe = ({ checkedCard, checked, setRememberMe }) => {
  const options = state.get().options;

  const themeForComponent = useThemeComponent("RememberMe");

  const RememberMeComponent = {
    default: RememberMeDefault,
    kombine: RememberMeKombine,
    PM_brand: RememberMeKPM,
  }[themeForComponent];

  if (options?.card && options?.card.enabled_remember_me) {
    return (
      <RememberMeComponent
        checkedCard={checkedCard}
        options={options}
        checked={checked}
        setRememberMe={setRememberMe}
      />
      // <AsyncComponent
      //   importFn={(t) => {
      //     return import(
      //       `../../themes/${themeForComponent}/components/RememberMe/index.tsx${t}`
      //     );
      //   }}
      //   name="RememberMe"
      // >
      //   {(RememberMeComponent) => (
      //     <RememberMeComponent
      //       checkedCard={checkedCard}
      //       options={options}
      //       checked={checked}
      //       setRememberMe={setRememberMe}
      //     />
      //   )}
      // </AsyncComponent>
    );
  }

  return null;
};

export default RememberMe;
