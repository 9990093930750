import { useContext } from "preact/hooks";
import { Context } from "../../app";
import { api, crypto } from "../../services";
import CryptoHeaderForm from "./CryptoHeaderForm";
// import AsyncComponent from "../AsyncComponent";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import CryptoContentDefault from "../../themes/default/components/CryptoContent";
import CryptoContentPM from "../../themes/PM_brand/components/CryptoContent";

const CryptoWidget = ({ currentState, handleCopy, onChangeLanguage }) => {
  const { matchesData, setCurrentCryptoState } = useContext(Context);
  const { type, signature, sessionId } = matchesData?.matches || {};

  const onAmountChange = (updatedData) => {
    crypto.set(updatedData);

    return api
      .getInitDataCrypto({
        type,
        sessionId,
        signature,
        updatedData,
      })
      .then((res) => {
        setCurrentCryptoState((state) => ({
          ...state,
          ...(res?.data || res || {}),
        }));

        return res;
      });
  };

  const themeForComponent = useThemeComponent("CryptoContent");

  const CryptoContent = {
    default: CryptoContentDefault,
    PM_brand: CryptoContentPM,
  }[themeForComponent];

  return (
    <>
      <CryptoHeaderForm onChangeLanguage={onChangeLanguage} />
      <CryptoContent
        isCryptoFormVisible={currentState?.exchange_allow_calculator}
        onAmountChange={onAmountChange}
        currentState={currentState}
        handleCopy={handleCopy}
      />
      {/* <AsyncComponent
        importFn={(t) =>
          import(
            `../../themes/${themeForComponent}/components/CryptoContent/index.tsx${t}`
          )
        }
        name="CryptoContent"
      >
        {(CryptoContent) => (
          <CryptoContent
            isCryptoFormVisible={currentState?.exchange_allow_calculator}
            onAmountChange={onAmountChange}
            currentState={currentState}
            handleCopy={handleCopy}
          />
        )}
      </AsyncComponent> */}
    </>
  );
};

export default CryptoWidget;
