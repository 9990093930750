import styled from "styled-components";

export const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 24, 32, 0.5);
  z-index: 199;
  display: block;
`;
