import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { PAYMENT_METHODS } from "../../constants/paymentMethods";
import { state } from "../../services";
import { Context } from "../../app";
// import AsyncComponent from "../AsyncComponent";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import CryptoContentDefault from "../../themes/default/components/CryptoContent";
import CryptoContentPM from "../../themes/PM_brand/components/CryptoContent";

const Crypto = () => {
  const pollingTimerRef = useRef(null);
  const [exchangeData, setExchangeData] = useState({});
  const { currentState = {} } = useContext(Context);
  const { amount, currency, cryptoConverted, crypto, options } =
    currentState || {};
  const redirectUrl = options?.redirect_url;
  const isCryptoCalculated = options?.crypto_calculation_enabled;
  const isMinNotificationEnabled = options?.min_notification_enabled;
  const selectedMethod = state.getSelectedPaymentMethod();

  const selectedCrypto = crypto?.find((item) => {
    return item.currency === selectedMethod?.currency;
  });

  const publicFields = selectedMethod?.public_fields;
  const networkFeeField = publicFields?.find(
    (field) => field.name === "network_fee",
  )?.value;

  const isCryptoMethod = selectedMethod?.method === PAYMENT_METHODS.CRYPTO;

  const selectedCryptoWithConversion = {
    ...selectedCrypto,
    ...cryptoConverted,
    currency_amount: cryptoConverted?.conversion_amount,
    currency: cryptoConverted?.conversion_currency,
  };

  const parseAmount = (amount) => {
    if (amount && typeof amount === "string") {
      amount = amount.replace(/\.$/, "");
      amount = amount ? parseFloat(amount) : null;
    }

    return amount;
  };

  const onCryptoAmountChange = ({ currency_amount, pay_amount }) => {
    currency_amount = parseAmount(currency_amount);
    pay_amount = parseAmount(pay_amount);

    const result = state.getAndSaveConversion(
      {
        conversion_currency: pay_amount ? selectedMethod?.currency : currency,
        conversion_amount: currency_amount || pay_amount || amount,
        pay_currency: pay_amount ? currency : selectedMethod?.currency,
      },
      { isInverted: !!pay_amount },
    );

    return result.then((res) => {
      setExchangeData(res);

      return res;
    });
  };

  useEffect(() => {
    if (
      isCryptoMethod &&
      selectedCrypto?.address &&
      !cryptoConverted?.pay_amount
    ) {
      onCryptoAmountChange({});
    }

    return () => {
      clearTimeout(pollingTimerRef.current);
    };
  }, [isCryptoMethod, selectedCrypto?.address, cryptoConverted?.pay_amount]);

  const themeForComponent = useThemeComponent("CryptoContent");

  const CryptoContent = {
    default: CryptoContentDefault,
    PM_brand: CryptoContentPM,
  }[themeForComponent];

  return (
    <CryptoContent
      isCryptoFormVisible={cryptoConverted?.pay_amount}
      onAmountChange={onCryptoAmountChange}
      currentState={selectedCryptoWithConversion}
      redirectUrl={redirectUrl}
      exchangeData={exchangeData}
      networkFeeField={networkFeeField}
      isCryptoCalculated={isCryptoCalculated}
      isMinNotificationEnabled={isMinNotificationEnabled}
    />
    // <AsyncComponent
    //   importFn={(t) =>
    //     import(
    //       `../../themes/${themeForComponent}/components/CryptoContent/index.tsx${t}`
    //     )
    //   }
    //   name="CryptoContent"
    // >
    //   {(CryptoContent) => {
    //     return (
    //       <CryptoContent
    //         isCryptoFormVisible={cryptoConverted?.pay_amount}
    //         onAmountChange={onCryptoAmountChange}
    //         currentState={selectedCryptoWithConversion}
    //         redirectUrl={redirectUrl}
    //         exchangeData={exchangeData}
    //       />
    //     );
    //   }}
    // </AsyncComponent>
  );
};

export default Crypto;
