import styled from "styled-components";
import { PM_COLORS } from "../../enums";

export const PMSavedCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;

export const PMLabelSavedCard = styled.div`
  padding: 6px 16px;
  width: 100%;

  span {
    font-family: var(--regularFont);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.15px;
    color: #000000;
    opacity: 0.56;
  }
`;

export const PMItemContent = styled.div<{
  $hasImage?: boolean;
  $cursor?: string;
}>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 12px;
  height: 56px;
  background-color: ${PM_COLORS.GRAY_6};
  padding: ${({ $hasImage }) => ($hasImage ? "8px" : "8px 16px")};
  gap: 8px;
  cursor: ${({ $cursor }) => $cursor || "pointer"};
`;

export const PMItemIcon = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${PM_COLORS.DEFAULT_WHITE};
  border-radius: 50%;

  & > * {
    width: 100%;
    height: 100%;
    max-width: 24px;
    max-height: 24px;
  }
`;

export const PMItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 82px);
  flex-shrink: 1;
  gap: 2px;
  flex-grow: 1;
`;

export const PMItemName = styled.span`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
  color: #000000;
  opacity: 0.9;
  text-transform: capitalize;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PMItemDescription = styled.span`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: #000000;
  opacity: 0.56;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PMItemIconAction = styled.div<{
  $iconTitle?: boolean;
}>`
  width: ${({ $iconTitle }) => ($iconTitle ? "52px" : "24px")};
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PMItemIconActionTitle = styled.span`
  font-size: 12px;
  color: #000000;
  opacity: 0.9;
  margin-right: 8px;
`;
