const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.28567 17.7011C8.289 16.7048 6.29567 14.7122 5.299 13.7159C4.90033 13.3173 4.90033 12.7196 5.299 12.321C5.69767 11.9225 6.29567 11.9225 6.69433 12.321C7.49167 13.1181 9.88367 15.5092 9.88367 15.5092C9.88367 15.5092 15.0663 9.03321 17.1593 6.4428C17.4583 6.04428 18.0563 5.84502 18.5547 6.14391C19.053 6.4428 19.1527 7.14022 18.754 7.63838C16.5613 10.3284 11.9767 16.0074 10.7807 17.5018C10.5022 17.8498 10.2823 18 9.98334 18C9.68434 18 9.485 17.9004 9.28567 17.7011Z"
      fill="white"
    />
  </svg>
);

export default CheckIcon;
