import { FunctionComponent } from "preact";
import rightBlueArrow from "../../../../assets/icons/rightBlueIcon.svg";
import { ChangeCardMobDesktopWrapper, CircleIconWrapper } from "./style";
import { IconColorWrapper } from "../../../../styles/uiStyles";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import IconSavedCard from "../../../../assets/icons/iconSavedCard";
import Trans from "../../../../components/Trans";
import { SavedCardMobileWrapper } from "../../../commons/styles/FormStyles";

interface InputSavedCardProps {
  conditionForShowArrow: boolean;
  smallMobileButton: boolean;
  openModal: () => void;
}

const InputSavedCard: FunctionComponent<InputSavedCardProps> = ({
  conditionForShowArrow,
  smallMobileButton,
  openModal,
}) => {
  return (
    <>
      {conditionForShowArrow &&
        (!smallMobileButton ? (
          <ChangeCardMobDesktopWrapper
            onClick={() => openModal()}
            data-role="card_dropdown_list"
          >
            <CircleIconWrapper>
              <IconColorWrapper>
                <Tooltip text={"savedCard"}>
                  <IconSavedCard />
                </Tooltip>
              </IconColorWrapper>
            </CircleIconWrapper>
          </ChangeCardMobDesktopWrapper>
        ) : (
          <SavedCardMobileWrapper
            onClick={() => openModal()}
            data-role="card_dropdown_list"
          >
            <span>
              <Trans message="savedCard" />
            </span>{" "}
            <img
              style={{ marginLeft: "6px" }}
              src={rightBlueArrow}
              alt="arrow"
            />{" "}
          </SavedCardMobileWrapper>
        ))}
    </>
  );
};

export default InputSavedCard;
