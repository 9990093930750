import { FunctionComponent } from "preact";
import { DetailsTransactionTitle } from "../../../../components/BankInfo/style";
import {
  BankTransferAfricaInfoWrapper,
  ChallengeMainTitle,
} from "../../../../components/Challenge/style";
import { TotalInfoWrapper } from "../../../../components/Header/style";
import Loader from "../../../../components/Loaders";
import Trans from "../../../../components/Trans";
import { InnerHeaderDataWrapper } from "../../../default/components/Instruction/style";

interface ChallengeCompleteProps {
  timeExpired?: boolean;
  minutes?: number;
  seconds?: number;
}

const ChallengeCompleteDefault: FunctionComponent<ChallengeCompleteProps> = ({
  timeExpired,
  minutes,
  seconds,
}) => {
  return (
    <InnerHeaderDataWrapper>
      <TotalInfoWrapper>
        <BankTransferAfricaInfoWrapper>
          {timeExpired ? (
            <>
              <ChallengeMainTitle $mb="8px">
                <Trans message="transactionStatus" />
              </ChallengeMainTitle>
              <DetailsTransactionTitle>
                <Trans message="paymentsSlowMessage" />
              </DetailsTransactionTitle>
              <DetailsTransactionTitle>
                <Trans message="receiptPaymentConfirmation" />
              </DetailsTransactionTitle>
            </>
          ) : (
            <>
              <DetailsTransactionTitle
                $mb="8px"
                data-transaction-name="challenge-complete-message"
              >
                <Trans message="confirmingTransfer" /> {minutes}{" "}
                <Trans message="mins" /> {seconds} <Trans message="secs" />
              </DetailsTransactionTitle>
              <DetailsTransactionTitle
                $mb="22px"
                data-transaction-name="challenge-complete-message-refresh"
              >
                <Trans message="doNotRefreshPage" />
              </DetailsTransactionTitle>
              <Loader />
            </>
          )}
        </BankTransferAfricaInfoWrapper>
      </TotalInfoWrapper>
    </InnerHeaderDataWrapper>
  );
};

export default ChallengeCompleteDefault;
