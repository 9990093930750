import {
  ChallengeMainTitle,
  StkChallengeSubTitle,
  StyledInfoImage,
  ChallengeInfoWrapper,
} from "./style";
import InfoIcon from "../../assets/icons/infoIcon";
import Trans from "../Trans";
import { InnerHeaderDataWrapper } from "../../themes/default/components/Instruction/style";
import { TotalInfoWrapper } from "../Header/style";
import ExpirationDate from "../BankInfo/ExpirationDate";
import { textCodeMapping } from "../../constants/textCodeMobileMoney";

interface ChallengeProps {
  challenge: {
    text_code: string;
    meta?: {
      timestamp?: string;
      show_confirm_button?: boolean;
    };
  };
}

const ChallengeUserAction: React.FC<ChallengeProps> = ({ challenge }) => {
  const { text_code, meta } = challenge;

  return (
    <>
      <InnerHeaderDataWrapper $alignItems="start" $padding="20px">
        <ChallengeMainTitle $mb="16px">
          <Trans message="mobileMoneyMainTitle" />
        </ChallengeMainTitle>

        <TotalInfoWrapper>
          <StkChallengeSubTitle>
            <Trans
              message={
                textCodeMapping[text_code as keyof typeof textCodeMapping] ||
                "mobileMoneySubTitle"
              }
            />
          </StkChallengeSubTitle>
        </TotalInfoWrapper>
        {meta?.timestamp && (
          <ExpirationDate expiration_date={meta?.timestamp} />
        )}
        <ChallengeInfoWrapper>
          <StyledInfoImage>
            <InfoIcon />
          </StyledInfoImage>
          <ChallengeMainTitle>
            <Trans message="mobileMoneyInfoTitle" />
          </ChallengeMainTitle>
        </ChallengeInfoWrapper>
      </InnerHeaderDataWrapper>

      {/* // TODO: next step */}
      {/* {meta?.show_confirm_button ? (
        <ButtonWrapper>
          <BaseButton
            $textTransform="initial"
            onClick={() => onGoBackButtonClick()}
            $mb="22px"
          >
            <Trans message="iHaveMadePayment" />
          </BaseButton>
        </ButtonWrapper>
      ) : null} */}
    </>
  );
};

export default ChallengeUserAction;
