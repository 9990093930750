import styled from "styled-components";

export const KombineErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const ErrorListItem = styled.li`
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
`;
