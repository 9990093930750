import styled from "styled-components";

export const RemoveCardManageWrapper = styled.div`
  height: 28px;
  width: 28px;
  cursor: pointer;
  margin: 0 0 0 auto;
  position: absolute;
  top: 56%;
  transform: translateY(-50%);
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
