import styled from "styled-components";
import { COLORS } from "../../../constants/themeConstants";

export const CardLabelNumberContainer = styled.div`
  width: 165px;
  flex-shrink: 0;
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

export const CardLabelNumber = styled.span<{
  $color?: string;
  $ml?: string;
}>`
  font-family: var(--boldFont);
  white-space: nowrap;
  color: ${({ $color }) => $color || COLORS.DEFAULT_BLACK};
  font-weight: 500;

  @media (min-width: 320px) {
    margin-left: ${({ $ml }) => $ml || "7px"};
    font-size: 14px;
    width: 165px;
  }

  @media (min-width: 390px) {
    margin-left: ${({ $ml }) => $ml || "14px"};
    font-size: 16px;
    width: 190px;
  }

  @media (min-width: 420px) {
    width: 215px;
  }
`;

export const CardLabelDate = styled.div`
  margin-left: 24px;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 14px;
  font-family: var(--mainFont);
  color: ${COLORS.GRAY};
`;

export const DeleteCardPopupTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-family: var(--mainFont);
  color: ${({ theme }) => theme?.popupTextTitleColor};
`;

export const ActionButtonCardEditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 16px;
`;

export const ActionButtonCardList = styled.button<{
  $background?: string;
  $color?: string;
  $maxWidth?: string;
  $width?: string;
  $textTransform?: string;
  $fontSize?: string;
  $fontWeight?: string;
}>`
  background: ${({ $background }) => $background || COLORS.LIGHT_GRAY};
  padding: 10px 16px;
  border: none;
  border-radius: ${({ theme }) => theme?.elementBorderRadius};
  max-width: ${({ $maxWidth }) => $maxWidth || ""};
  width: ${({ $width }) => $width || ""};
  text-transform: ${({ $textTransform }) => $textTransform || ""};
  cursor: pointer;

  span {
    font-family: var(--boldFont);
    font-size: ${({ $fontSize }) => $fontSize || "18px"};
    font-weight: ${({ $fontWeight }) => $fontWeight || 500};
    color: ${({ $color }) => $color || ""};
    white-space: nowrap;
  }
`;

export const SavedCardInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 16px;
`;

export const SavedCardInnerWrapper = styled.div<{
  $height?: string;
  $backgroundColor?: string;
  $hoverColor?: string;
}>`
  display: flex;
  align-items: center;
  height: ${({ theme, $height }) =>
    $height || theme?.managePopupElementHeight || ""};
  flex-wrap: nowrap;
  padding: 9px 18px 5px 18px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || COLORS.GREY_WHITE};
  border-radius: 8px;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ $hoverColor }) =>
      $hoverColor || COLORS.MAIN_BODY_GREY};
  }

  @media (min-width: 320px) {
    padding: 5px 5px 5px 5px;
  }

  @media (min-width: 390px) {
    padding: 9px 18px 5px 18px;
  }
`;

export const SavedCardWrapper = styled.div`
  position: relative;
`;
