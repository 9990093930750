import { InnerLine, IconColorWrapper } from "../../styles";
import InfoIcon from "../../assets/icons/infoIcon";
import {
  ChallengeMainTitle,
  ChallengeUssdInfoWrapper,
  ChallengeNumberTitle,
  ChallengeInfoWrapper,
  StyledInfoImage,
} from "../Challenge/style";
import Loader from "../Loaders";
import Trans from "../Trans";
import OpayIcon from "../../assets/icons/opayLogo.svg";
import PalmpayIcon from "../../assets/icons/palmpayBig.png";
import IconCopy from "../../assets/icons/iconCopy";
import Tooltip from "../Tooltip/Tooltip";
import { PAYMENT_METHODS } from "../../constants/paymentMethods";
import { useContext } from "preact/hooks";
import { Context } from "../../app";
import { state } from "../../services";
import { FormLoaderWrapper } from "../Loaders/style";
import { InnerHeaderDataWrapper } from "../../themes/default/components/Instruction/style";
import { BlockDetailsWrapper } from "../BankInfo/style";
import {
  InfoCopyBoldTitle,
  ListItemInfoCopyWrapper,
  MethodIconInfoWrapper,
} from "./style";
import {
  BaseButton,
  ButtonWrapper,
} from "../../themes/commons/styles/ButtonStyles";

const DepositPay = ({ data, handleCopy }) => {
  const { number } = data || {};
  const { currentState = {}, onGoBackButtonClick } = useContext(Context);
  const { options } = currentState || {};
  const redirectUrl = options?.redirect_url;
  const selectedMethod = state.getSelectedPaymentMethod();

  const publicFields = selectedMethod?.public_fields;

  return number ? (
    <>
      <InnerHeaderDataWrapper $pt="20px">
        <ChallengeMainTitle mr="auto" $mb="21px">
          <MethodIconInfoWrapper>
            <img
              src={
                selectedMethod?.method === PAYMENT_METHODS.DEPOSIT_OPAY
                  ? OpayIcon
                  : PalmpayIcon
              }
            />
          </MethodIconInfoWrapper>
          <Trans message="depositInfoMainTitle" />
        </ChallengeMainTitle>

        <ChallengeUssdInfoWrapper $mb="12px">
          <ChallengeNumberTitle>1</ChallengeNumberTitle>
          <Trans message="launchApp" />
        </ChallengeUssdInfoWrapper>
        <ListItemInfoCopyWrapper>
          <ChallengeUssdInfoWrapper $mr="0" $mb="12px">
            <ChallengeNumberTitle>2</ChallengeNumberTitle>
            <Trans message="select" />{" "}
            <InfoCopyBoldTitle>{publicFields[0]?.value}</InfoCopyBoldTitle>
          </ChallengeUssdInfoWrapper>
          <IconColorWrapper
            ml="8px"
            mr="auto"
            onClick={() => handleCopy(publicFields[0]?.value, "nameCopy")}
          >
            <Tooltip text={"Сopy"}>
              <IconCopy />
            </Tooltip>
          </IconColorWrapper>
        </ListItemInfoCopyWrapper>
        <ListItemInfoCopyWrapper>
          <ChallengeUssdInfoWrapper $mr="0" $mb="12px">
            <ChallengeNumberTitle>3</ChallengeNumberTitle>
            <Trans message="enterYourIdentifier" />
            <InfoCopyBoldTitle>({number})</InfoCopyBoldTitle>
          </ChallengeUssdInfoWrapper>
          <IconColorWrapper
            ml="8px"
            mr="auto"
            onClick={() => handleCopy(number, "userIdCopy")}
          >
            <Tooltip text={"Сopy"}>
              <IconCopy />
            </Tooltip>
          </IconColorWrapper>
        </ListItemInfoCopyWrapper>
        <ChallengeUssdInfoWrapper $mb="12px">
          <ChallengeNumberTitle>4</ChallengeNumberTitle>
          <Trans message="enterAmount" />
        </ChallengeUssdInfoWrapper>
        <ChallengeUssdInfoWrapper $mb="12px">
          <ChallengeNumberTitle>5</ChallengeNumberTitle>
          <Trans message="userNameTransactionAmount" />
        </ChallengeUssdInfoWrapper>
        <ChallengeUssdInfoWrapper $mb="20px">
          <ChallengeNumberTitle>6</ChallengeNumberTitle>
          <Trans message="selectPaymentMethod" />
        </ChallengeUssdInfoWrapper>
        <InnerLine />
        <BlockDetailsWrapper $mb="0">
          <ChallengeInfoWrapper>
            <StyledInfoImage>
              <InfoIcon />
            </StyledInfoImage>
            <ChallengeMainTitle>
              <Trans message="amountComplete15Mins" />
            </ChallengeMainTitle>
          </ChallengeInfoWrapper>
        </BlockDetailsWrapper>
      </InnerHeaderDataWrapper>
      <ButtonWrapper>
        <BaseButton
          $textTransform="initial"
          onClick={() => onGoBackButtonClick(redirectUrl)}
          $mb="22px"
        >
          <Trans message="iHaveMadePayment" />
        </BaseButton>
      </ButtonWrapper>
    </>
  ) : (
    <FormLoaderWrapper>
      <Loader />
    </FormLoaderWrapper>
  );
};

export default DepositPay;
