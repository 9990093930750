import { useEffect, useContext, useRef } from "preact/hooks";
import { ContextUI } from "../../providers/UIProvider";
import { useData } from "../../services/hooks";
import { state } from "../../services";
import NotificationDefault from "../../themes/default/components/Notification";
import NotificationPM from "../../themes/PM_brand/components/Notification";
import { useThemeComponent } from "../../hooks/useThemeComponent";

const Notification = () => {
  const { notificationData, setNotificationData, handleCloseNotification } =
    useContext(ContextUI);

  const currentState = useData(state.get());
  const theme = currentState?.options?.theme || "default";

  const {
    isOpen,
    status,
    text,
    isCloseAutomatically = true,
    buttonConfig,
  } = notificationData;

  const { hasButton, buttonText, buttonAction } = buttonConfig || {};

  const timerRef = useRef(null);
  const previousTextRef = useRef(text);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (isCloseAutomatically && previousTextRef.current !== text) {
      previousTextRef.current = text;
      timerRef.current = setTimeout(() => {
        handleCloseNotification();
      }, 3000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isOpen, isCloseAutomatically, text]);

  useEffect(() => {
    if (currentState?.notification) {
      const { isError, title, status, isCloseAutomatically } =
        currentState?.notification || {};
      const tempData = { ...notificationData };

      setNotificationData((prev) => ({
        ...prev,
        isOpen: true,
        status: isError ? "error" : status,
        text: title,
      }));

      if (isCloseAutomatically === undefined) {
        setTimeout(() => {
          setNotificationData(tempData);
        }, 3000);
      }
    }
  }, [currentState?.notification]);

  const themeForComponent = useThemeComponent("Notification");

  const NotificationComponent = {
    default: NotificationDefault,
    PM_brand: NotificationPM,
  }[themeForComponent];

  return (
    <NotificationComponent
      status={status}
      isOpen={isOpen}
      text={text}
      theme={theme}
      hasButton={hasButton}
      buttonText={buttonText}
      buttonAction={buttonAction}
      handleCloseNotification={handleCloseNotification}
    />
  );
};

export default Notification;
