import { useMemo } from "preact/hooks";
import { themesComponents } from "../themes/themesIndex";
import { state } from "../services";

export const useThemeComponent = (componentName: string) => {
  const options = (state.get() as { options: { theme?: string } }).options;

  const theme = options?.theme;

  const resolvedTheme = useMemo(() => {
    if (
      theme &&
      themesComponents[theme as keyof typeof themesComponents]?.includes(
        componentName,
      )
    ) {
      return theme;
    }

    return "default";
  }, [theme, componentName]);

  return resolvedTheme;
};
