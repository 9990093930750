import appleIcon from "../../assets/icons/apple.svg";
import { memo } from "preact/compat";
import { GoogleApplePayTestButton } from "../GooglePay/style";

const ApplePayTestBtn = memo(() => {
  return (
    <GoogleApplePayTestButton>
      <img src={appleIcon} alt="apple_icon" />
      <span>Pay</span>
    </GoogleApplePayTestButton>
  );
});

export default ApplePayTestBtn;
