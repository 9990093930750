import { useContext } from "preact/hooks";
import {
  HiddenCheckbox,
  CheckboxLabel,
  PmCheckboxWrapper,
  PMdCheckbox,
} from "./style";
import { FunctionComponent } from "preact";
import { ContextUI } from "../../../../providers/UIProvider";
import Trans from "../../../../components/Trans";

interface RememberMeProps {
  name?: string;
  checked: boolean;
  setRememberMe: (setter: (prev: boolean) => boolean) => void;
  checkedCard?: boolean | null;
}

const RememberMe: FunctionComponent<RememberMeProps> = ({
  name = "remember_me",
  checked,
  setRememberMe,
  checkedCard,
}) => {
  const { setNotificationData } = useContext(ContextUI);
  const isChecked = checkedCard || checked;

  const handleCheckboxClick = () => {
    setNotificationData({
      text: "Card will not be saved!",
      status: "success",
      isOpen: checkedCard === null && checked,
    });

    if (!checkedCard) {
      setRememberMe((prev) => !prev);
    }
  };

  return (
    <PmCheckboxWrapper>
      <HiddenCheckbox
        id={name}
        checked={isChecked}
        onChange={handleCheckboxClick}
      />
      <CheckboxLabel>
        <Trans message="saveThisCardPM" />
      </CheckboxLabel>
      <PMdCheckbox $checked={isChecked} onClick={handleCheckboxClick}>
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clip-Rule="evenodd"
            d="M10.0433 0.910549C10.507 1.21058 10.6396 1.82969 10.3396 2.29337L4.83959 10.7934C4.66821 11.0582 4.38189 11.2267 4.06713 11.2479C3.75237 11.269 3.44607 11.1404 3.24076 10.9009L0.240762 7.40091C-0.118661 6.98158 -0.0700991 6.35028 0.349227 5.99086C0.768553 5.63144 1.39985 5.68 1.75927 6.09933L3.88786 8.58268L8.66045 1.20687C8.96048 0.743186 9.57959 0.610519 10.0433 0.910549Z"
            fill="white"
          />
        </svg>
      </PMdCheckbox>
    </PmCheckboxWrapper>
  );
};

export default RememberMe;
