import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const CheckboxWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const CheckboxInput = styled.input`
  appearance: none;
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid ${COLORS.DEFAULT_GREY};
  background: ${COLORS.DEFAULT_WHITE};
  border-radius: 4px;
  transition: 500ms;
  cursor: pointer;
  flex-shrink: 0;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjI4MDMzIDQuNzc0OTdDMC45ODc0MzcgNC40ODIwOCAwLjUxMjU2MyA0LjQ4MjA4IDAuMjE5NjcgNC43NzQ5N0MtMC4wNzMyMjMzIDUuMDY3ODcgLTAuMDczMjIzMyA1LjU0Mjc0IDAuMjE5NjcgNS44MzU2M0wyLjk0MjAyIDguNTU3OTlDMy4yMzQ5MiA4Ljg1MDg4IDMuNzA5NzkgOC44NTA4OCA0LjAwMjY4IDguNTU3OTlMMTAuNDQ3IDIuMTEzNjdDMTAuNzM5OSAxLjgyMDc4IDEwLjczOTkgMS4zNDU5MSAxMC40NDcgMS4wNTMwMUMxMC4xNTQxIDAuNzYwMTIgOS42NzkyMyAwLjc2MDEyIDkuMzg2MzQgMS4wNTMwMUwzLjQ3MjM1IDYuOTY3TDEuMjgwMzMgNC43NzQ5N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s;
  }

  &:checked {
    transition: 0.3s;
    border: 1px solid ${COLORS.BLUE};
    background: ${COLORS.BLUE};
  }
`;

export const CheckboxLabel = styled.label`
  font-family: var(--regularFont);
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  cursor: pointer;
`;
