import { IButtonProps } from "../../../commons/interfaces/Button";
import { ButtonStyled } from "./style";

const ButtonUI = ({
  children,
  onClick,
  variant,
  size,
  name,
  type,
  disabled,
  buttonRef,
  isInIframe,
  textTransform,
  "data-transaction-name": dataTransactionName,
}: IButtonProps) => (
  <ButtonStyled
    $variant={variant}
    $textTransform={textTransform}
    $size={size}
    onClick={onClick}
    name={name}
    type={type}
    disabled={disabled}
    ref={buttonRef}
    $isInIframe={isInIframe}
    data-transaction-name={dataTransactionName}
  >
    {children}
  </ButtonStyled>
);

export default ButtonUI;
