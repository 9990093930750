import { useContext, useEffect, useMemo, useState } from "preact/hooks";
import FormHeader from "../FormHeader";
import { Context } from "../../app";
import Footer from "../Footer";
import FormContent from "./FormContent";
import NonProcessedMethod from "../NonProcessedMethods";
import { state } from "../../services";
import { getFields } from "../Form/FormFields";
import {
  NON_PROCESSED_PAYMENT_METHODS,
  PRE_PROCESSED_PAYMENT_METHODS,
  PAYMENT_METHODS,
} from "../../constants/paymentMethods";
import { FIELD_SOURCES } from "../../constants/formFields";

const FormContainer = ({
  sessionId,
  signature,
  handleCopy,
  titleError,
  titleErrorDescription,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { themeConfig, currentState = {} } = useContext(Context);

  const {
    amount,
    currency,
    description,
    options,
    apple_pay,
    google_pay,
    reference,
    status,
  } = currentState || {};

  const selectedMethod = state.getSelectedPaymentMethod();

  const publicFieldsData = useMemo(
    () =>
      selectedMethod?.public_fields?.reduce((acc, item) => {
        acc = { ...acc, [item.name]: item.value };

        return acc;
      }, {}),
    [selectedMethod],
  );

  const { exchange_page } = publicFieldsData || {};

  const selectedMethodRequisites =
    state.getRequisitesBySelectedMethodId(selectedMethod?.id) || {};

  const selectedMethodRequisitesData =
    selectedMethodRequisites[selectedMethod?.method];

  const hideTransactionDetails =
    (selectedMethod?.method === PAYMENT_METHODS.BLIK &&
      (exchange_page === "true" || exchange_page === true)) ||
    selectedMethod?.method === PAYMENT_METHODS.CRYPTO;

  const isCardMethod = selectedMethod?.method === PAYMENT_METHODS.CARD;

  const isAppleGooglePayMethod = [
    PAYMENT_METHODS.APPLE_PAY,
    PAYMENT_METHODS.GOOGLE_PAY,
  ].includes(selectedMethod?.method);
  const isFooterAvailableForMethod = isCardMethod || isAppleGooglePayMethod;

  const isPreProcessedMethod = PRE_PROCESSED_PAYMENT_METHODS.includes(
    selectedMethod?.method,
  );
  const isNonProcessedMethod = NON_PROCESSED_PAYMENT_METHODS.includes(
    selectedMethod?.method,
  );

  const headerDescription =
    themeConfig?.isHideFormHeaderDescription ||
    selectedMethod?.method === PAYMENT_METHODS.APPLE_PAY
      ? null
      : description;

  const fieldSources = FIELD_SOURCES.reduce((acc, source) => {
    acc[source] = currentState?.[source];

    return acc;
  }, {});

  const formFields = selectedMethod
    ? getFields({
        sources: fieldSources,
        selectedMethod: selectedMethod?.method,
        selectedProvider: selectedMethod?.provider,
        selectedMethodData: selectedMethod,
        theme: options?.theme,
      })
    : [];

  const detailedInfo = formFields.reduce((acc, field) => {
    const dataSource = fieldSources[field.source];

    if (dataSource && dataSource[field.name]) {
      acc.push({
        label: field.label,
        name: field.name,
        value: dataSource[field.name],
      });
    }

    return acc;
  }, []);

  const isNonFormPayment =
    isNonProcessedMethod ||
    (isPreProcessedMethod && isFormSubmitted) ||
    (isPreProcessedMethod && selectedMethodRequisitesData);

  useEffect(() => {
    if (!selectedMethod) {
      setIsFormSubmitted(false);
    }
  }, [JSON.stringify(selectedMethod)]);

  return (
    <>
      {amount ? (
        <FormHeader
          amount={amount}
          selectedMethod={selectedMethod?.method}
          currency={currency}
          description={headerDescription}
          reference={reference}
          theme={options?.theme}
          detailedInfo={detailedInfo}
          handleCopy={handleCopy}
          titleError={titleError}
          titleErrorDescription={titleErrorDescription}
          hasGradient={!hideTransactionDetails}
          hasData={!hideTransactionDetails}
        />
      ) : null}

      {isNonFormPayment ? (
        <NonProcessedMethod handleCopy={handleCopy} />
      ) : (
        <FormContent
          amount={amount}
          currency={currency}
          description={description}
          reference={reference}
          method={selectedMethod?.method}
          options={options}
          status={status}
          apple_pay={apple_pay}
          google_pay={google_pay}
          sessionId={sessionId}
          signature={signature}
          themeConfig={themeConfig}
          fieldSources={fieldSources}
          onFormSubmit={() => {
            setIsFormSubmitted(true);
          }}
          titleError={titleError}
          titleErrorDescription={titleErrorDescription}
        />
      )}
      {!hideTransactionDetails && isFooterAvailableForMethod && (
        <Footer forceOnMobile={isAppleGooglePayMethod} />
      )}
    </>
  );
};

export default FormContainer;
