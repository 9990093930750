import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const GoBackActionWrapper = styled.div<{
  $ml?: string;
}>`
  display: flex;
  align-items: center;
  width: calc(100% - 48px);
  flex-shrink: 0;
  gap: 8px;
  height: 26px;
  cursor: pointer;

  @media (min-width: 768px) {
    margin-left: ${({ $ml }) => $ml || "0"};
  }
`;

export const GoBackActionTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${COLORS.DEFAULT_WHITE};
  cursor: pointer;
  font-family: var(--boldFont);

  & span {
    font-family: var(--boldFont);
    font-size: 14px;
  }
`;
