import { FunctionComponent } from "preact";
import {
  FormContainerStyled,
  FormPayTitle,
  FormWrapperStyled,
} from "../../../../components/Form/style";
import ExchangeBlock from "../../../../components/ExchangeBlock";
import FormFields from "../../../../components/Form/FormFields";
import { PAYMENT_METHODS } from "../../../../constants/paymentMethods";
import PrivacyPolicyCheckbox from "../../../../components/PrivacyPolicyCheckbox";
import Button from "../../../../components/Button";
import Trans from "../../../../components/Trans";
import { INFORMATION_ABOUT_PAYMENT_METHODS } from "../../../../constants/paymentMethodsInfo";
import {
  TransactionDecimalTitle,
  TransactionSumTitle,
} from "../../../../components/PaymentDetails/style";
import { FormProps } from "../../../commons/interfaces/FormInterfaces";
import RememberMe from "../../../../components/RememberMe";
import { state } from "../../../../services";
import { ButtonWrapper } from "./style";

const FormDefault: FunctionComponent<FormProps> = ({
  handleFormSubmit,
  selectedMethod,
  refSubmit,
  fieldSources,
  values,
  setValues,
  errors,
  setErrors,
  checkedCard,
  setCheckedCard,
  resetCardForm,
  hiddenRememberMe,
  themeConfig,
  setRememberMe,
  isMethodForAutoSubmit,
  isFailedRetryStatus,
  hasSelectedMethod,
  isDisabledButton,
  checkPrivacyPolicy,
  exchange_to,
  privacy_policy_link,
  terms_of_service_link,
  rememberMe,
  formButtonTitle,
  replacedFormPaymentAmountButton,
  wholeNumber,
  decimal,
  formButtonCurrency,
  currency,
  theme,
}) => {
  return (
    <>
      <FormContainerStyled>
        <FormWrapperStyled>
          <form onSubmit={handleFormSubmit}>
            {exchange_to && (
              <ExchangeBlock data={fieldSources.publicFieldsData} />
            )}

            <FormFields
              method={selectedMethod}
              fieldSources={fieldSources}
              area="general"
              refSubmit={refSubmit}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
              checkedCard={checkedCard}
              setCheckedCard={setCheckedCard}
              resetCardForm={resetCardForm}
              hiddenRememberMe={hiddenRememberMe}
              themeConfig={themeConfig}
              setRememberMe={setRememberMe}
              isMethodForAutoSubmit={isMethodForAutoSubmit}
              isFailedRetryStatus={isFailedRetryStatus}
              theme={theme}
            />

            {(privacy_policy_link || terms_of_service_link) && (
              <PrivacyPolicyCheckbox data={fieldSources.publicFieldsData} />
            )}
          </form>
        </FormWrapperStyled>

        {!hiddenRememberMe &&
          selectedMethod?.method === PAYMENT_METHODS.CARD &&
          !themeConfig?.innerInputRememberMeShow && (
            <RememberMe
              checkedCard={checkedCard}
              checked={rememberMe}
              setRememberMe={setRememberMe}
            />
          )}
      </FormContainerStyled>

      {!["apple_pay", "google_pay"].includes(selectedMethod?.method) && (
        <ButtonWrapper>
          <Button
            data-role="pay_button"
            name="cardFormPayButton"
            type="submit"
            disabled={
              isDisabledButton || !hasSelectedMethod || !checkPrivacyPolicy
            }
            onClick={handleFormSubmit}
            buttonRef={refSubmit}
            data-transaction-name="FormPayButton"
            isInIframe={state.isInIframe()}
          >
            {!["card", "crypto"].includes(selectedMethod?.method) ? (
              <FormPayTitle>
                <Trans
                  message={
                    INFORMATION_ABOUT_PAYMENT_METHODS[selectedMethod?.method]
                      ?.payButtonText || "buttonPayTextDefault"
                  }
                />
              </FormPayTitle>
            ) : (
              <>
                <FormPayTitle>
                  <Trans message={formButtonTitle} />
                </FormPayTitle>
                {replacedFormPaymentAmountButton && (
                  <span>
                    <TransactionSumTitle>{wholeNumber}</TransactionSumTitle>
                    {decimal && (
                      <TransactionDecimalTitle>
                        .{decimal}
                      </TransactionDecimalTitle>
                    )}{" "}
                    {formButtonCurrency && (
                      <TransactionDecimalTitle>
                        {currency}
                      </TransactionDecimalTitle>
                    )}
                  </span>
                )}
              </>
            )}
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
};

export default FormDefault;
