import { FunctionComponent } from "preact";
import PMSpinner from "../../assets/icons/brand/pm/Spinner";
import { PMLoaderContainer } from "./style";

interface PMLoaderProps {
  size?: "default" | "small";
}

const PMLoader: FunctionComponent<PMLoaderProps> = ({ size = "default" }) => {
  return (
    <PMLoaderContainer $size={size}>
      <PMSpinner />
    </PMLoaderContainer>
  );
};

export default PMLoader;
