import styled from "styled-components";
import { DetailsTransactionTitle } from "../../BankInfo/style";

export const DetailsTransactionTitleBankTransferArgentina = styled(
  DetailsTransactionTitle,
)`
  width: 138px;
  text-align: justify;
  font-weight: ${({ theme }) => theme?.subMainInfoTexFontWeight || "600"};
`;
