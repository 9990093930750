import styled from "styled-components";

export const FooterContainer = styled.div<{
  $margin?: string;
  $background?: string;
  $forceOnMobile?: boolean;
}>`
  background: ${({ $background, theme }) =>
    $background || theme?.mainBackGroundColor};
  display: flex;
  justify-content: space-around;
  max-width: 440px;
  margin: ${({ $margin }) => $margin || "auto auto 0"};
  padding: 24px 0px;
  width: 100%;
  flex-shrink: 0;
  min-height: 73px;

  @media (max-height: 768px) {
    ${({ $forceOnMobile }) =>
      $forceOnMobile ? "display: flex;" : "display: none;"}
  }
`;
