const FailIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 8C32.8 8 40 15.2 40 24C40 32.8 32.8 40 24 40C15.2 40 8 32.8 8 24C8 15.2 15.2 8 24 8ZM24 4C13 4 4 13 4 24C4 35 13 44 24 44C35 44 44 35 44 24C44 13 35 4 24 4ZM26.8 24C26.8 24 31 19.8 32.4 18.4C33.2 17.6 33.2 16.4 32.4 15.6C31.6 14.8 30.4 14.8 29.6 15.6C28.2 17 24 21.2 24 21.2C24 21.2 19.8 17 18.4 15.6C17.6 14.8 16.4 14.8 15.6 15.6C14.8 16.4 14.8 17.6 15.6 18.4C17 19.8 21.2 24 21.2 24C21.2 24 17 28.2 15.6 29.6C14.8 30.4 14.8 31.6 15.6 32.4C16.4 33.2 17.6 33.2 18.4 32.4C19.8 31 24 26.8 24 26.8C24 26.8 28.2 31 29.6 32.4C30.4 33.2 31.6 33.2 32.4 32.4C33.2 31.6 33.2 30.4 32.4 29.6C31 28.2 26.8 24 26.8 24Z"
      fill="#FF1F00"
    />
  </svg>
);

export default FailIcon;
