import { FunctionComponent } from "preact";
import Trans from "../../../../components/Trans";
import SuccessIcon from "../../../../assets/icons/brand/pm/SuccessIcon";
import {
  PMFinalPageContent,
  PMFinalPageDescription,
  PMFinalPageTitle,
  PMFinalPageWrapper,
} from "./style";

const SuccessPagePM: FunctionComponent = () => {
  return (
    <PMFinalPageWrapper>
      <SuccessIcon />
      <PMFinalPageContent>
        <PMFinalPageTitle>
          <Trans message="PMSuccess" />
        </PMFinalPageTitle>
        <PMFinalPageDescription>
          <Trans message="PMSuccessDescription" />
        </PMFinalPageDescription>
      </PMFinalPageContent>
    </PMFinalPageWrapper>
  );
};

export default SuccessPagePM;
