import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const StyledCurrentLanguageIcon = styled.img`
  border: 1px solid ${COLORS.DEFAULT_WHITE};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
`;

export const StyledLanguageButton = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: auto;
`;
