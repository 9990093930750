import { useEffect, useState, useContext } from "preact/hooks";
import { state, processing } from "../../services";
import Trans from "../Trans";
import closeIcon from "../../assets/icons/closeIcon.svg";
import { ContextUI } from "../../providers/UIProvider";
import { COLORS } from "../../constants/themeConstants";
// import AsyncComponent from "../AsyncComponent";
import {
  EditCardActionWrapper,
  ManageCardActionTitle,
  ManageCardBlockTitle,
} from "./style";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import ManageCardDefault from "../../themes/default/components/ManageCard";
import ManageCardKombine from "../../themes/kombine/components/ManageCard";
import CardListDefault from "../../themes/default/components/CardList";
import CardListKombine from "../../themes/kombine/components/CardList";

const CardListModal = ({
  handleCheckSavedCard,
  handleRemoveSavedCard,
  checkedCard,
}) => {
  const cardList = state.get().cards || [];
  const options = state.get().options;

  const [isManage, setIsManage] = useState(false);
  const [isPrevRemoveCard, setIsPrevRemoveCard] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);

  const { modalData, setModalData, handleCloseModal, setNotificationData } =
    useContext(ContextUI);

  const { isOpen } = modalData;

  const handleRemoveCardPopup = (id) => {
    setIsManage(false);
    setCurrentCard(null);
    processing
      .processRemoveCard(id)
      .then(() => handleRemoveSavedCard(id))
      .then(() => {
        setNotificationData({
          text: "deletedCard",
          status: "success",
          isOpen: true,
        });
      })
      .catch((e) => {
        setNotificationData({
          text: e?.response?.reason || e.toString(),
          status: "fail",
          isOpen: true,
        });
      });
  };

  const handleCancel = () => {
    setIsManage(false);
    setCurrentCard(null);
  };

  const customHeader = () => (
    <>
      <EditCardActionWrapper>
        {isPrevRemoveCard ? (
          <ManageCardActionTitle
            $color={options?.theme === "kombine" && COLORS.DEFAULT_WHITE}
          >
            <Trans message="youWantDltCard" />
          </ManageCardActionTitle>
        ) : (
          <>
            <ManageCardActionTitle
              $color={options?.theme === "kombine" && COLORS.DEFAULT_WHITE}
            >
              <Trans message={isManage ? "manageSavedCard" : "savedCard"} />
            </ManageCardActionTitle>

            <ManageCardBlockTitle
              data-role="manager_button"
              onClick={() => setIsManage(!isManage)}
            >
              <Trans message={isManage ? "done" : "manage"} withoutWrapper />
            </ManageCardBlockTitle>

            <img onClick={() => handleCloseModal()} src={`${closeIcon}`} />
          </>
        )}
      </EditCardActionWrapper>
    </>
  );

  useEffect(() => {
    if (isOpen) {
      setModalData((prev) => ({
        ...prev,
        header: customHeader,
        isFullView: cardList?.length > 4 && !isPrevRemoveCard,
      }));
    }
  }, [isPrevRemoveCard, isOpen, isManage]);

  useEffect(() => {
    setIsManage(false);
    setIsPrevRemoveCard(false);
  }, [isOpen]);

  useEffect(() => {
    if (!isManage) {
      setIsPrevRemoveCard(false);
    }
  }, [isManage]);

  const themeForComponentManageCard = useThemeComponent("ManageCard");
  const themeForComponentCardList = useThemeComponent("CardList");

  const ManageCard = { default: ManageCardDefault, kombine: ManageCardKombine }[
    themeForComponentManageCard
  ];

  const CardList = { default: CardListDefault, kombine: CardListKombine }[
    themeForComponentCardList
  ];

  return (
    <>
      {isPrevRemoveCard &&
        currentCard &&
        [currentCard].map((card) => {
          return (
            <ManageCard
              key={card.last4}
              checkedCard={checkedCard}
              card={card}
              setIsManage={setIsManage}
              handleRemoveCard={handleRemoveCardPopup}
              handleCheckSavedCard={handleCheckSavedCard}
              handleCancel={handleCancel}
            />
            // <AsyncComponent
            //   key={card.last4}
            //   importFn={(t) =>
            //     import(
            //       `../../themes/${themeForComponentManageCard}/components/ManageCard/index.tsx${t}`
            //     )
            //   }
            //   name="ManageCard"
            // >
            //   {(ManageCard) => (
            //     <ManageCard
            //       checkedCard={checkedCard}
            //       card={card}
            //       setIsManage={setIsManage}
            //       handleRemoveCard={handleRemoveCardPopup}
            //       handleCheckSavedCard={handleCheckSavedCard}
            //       handleCancel={handleCancel}
            //     />
            //   )}
            // </AsyncComponent>
          );
        })}

      {cardList &&
        !currentCard &&
        Array.isArray(cardList) &&
        cardList.map((card, index) => {
          if (!card.enabled) return null;

          return (
            <CardList
              key={card.last4}
              index={index}
              checkedCard={checkedCard}
              handleCheckSavedCard={handleCheckSavedCard}
              setCurrentCard={setCurrentCard}
              card={card}
              isManage={isManage}
              setIsPrevRemoveCard={setIsPrevRemoveCard}
            />
            // <AsyncComponent
            //   key={card.last4}
            //   importFn={(t) =>
            //     import(
            //       `../../themes/${themeForComponentCardList}/components/CardList/index.tsx${t}`
            //     )
            //   }
            //   name="CardList"
            // >
            //   {(CardList) => (
            //     <CardList
            //       index={index}
            //       checkedCard={checkedCard}
            //       handleCheckSavedCard={handleCheckSavedCard}
            //       setCurrentCard={setCurrentCard}
            //       card={card}
            //       isManage={isManage}
            //       setIsPrevRemoveCard={setIsPrevRemoveCard}
            //     />
            //   )}
            // </AsyncComponent>
          );
        })}
    </>
  );
};

export default CardListModal;
