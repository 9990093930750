import { useState, useRef, useEffect, useContext, useMemo } from "preact/hooks";
import { logger, processing, state, api } from "../../services";
import { getFields } from "./FormFields";
import Trans from "../Trans";
import { Context } from "../../app";
import {
  MANUALLY_PROCESSED_PAYMENT_METHODS,
  PAYMENT_METHODS,
} from "../../constants/paymentMethods";
import Loader from "../Loaders";
import useApmTransaction from "../../hooks/useApmTransaction";
import useTimer from "../../hooks/useTimer";
import { getThemeConfig } from "../../services/theme";
import brand from "../../services/brand";
import { debounce } from "../../services/utils";
import { FormLoaderWrapper } from "../Loaders/style";
import { ContextUI } from "../../providers/UIProvider";
import Button from "../Button";
import FormDefault from "../../themes/default/components/Form";
import FormPM from "../../themes/PM_brand/components/Form";
import { useThemeComponent } from "../../hooks/useThemeComponent";

const Form = ({ onSubmit, fieldSources }) => {
  const {
    getFormattedAmountParts = () => {},
    currentState = {},
    onGoBackButtonClick,
  } = useContext(Context);

  const themeName = state.getTheme();

  const themeConfig = getThemeConfig(themeName);

  const { handleCloseModal } = useContext(ContextUI);

  const {
    options,
    amount,
    customer,
    upi,
    virtual_account_request,
    mobile_money,
    hasSelectedMethod,
    currency,
    status,
    isPrivacyPolicyAgreed,
  } = currentState || {};

  const isFailedRetryStatus = useMemo(() => {
    return status === "failed_retry";
  }, [status]);

  const defaultCardHolder =
    customer?.last_name && customer?.first_name
      ? `${customer?.last_name} ${customer?.first_name}`
      : "";

  const selectedMethod = state.getSelectedPaymentMethod();

  const isMethodForAutoSubmit = ![
    PAYMENT_METHODS.APPLE_PAY,
    PAYMENT_METHODS.GOOGLE_PAY,
    PAYMENT_METHODS.CARD,
  ].includes(selectedMethod?.method);

  const areAllFieldsActive = isFailedRetryStatus && isMethodForAutoSubmit;

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [checkedCard, setCheckedCard] = useState(null);
  const [rememberMe, setRememberMe] = useState(true);
  const [isReadySubmit, setIsReadySubmit] = useState(false);
  const { createSpan } = useApmTransaction("PaymentFlow");
  const [values, setValues] = useState({
    cardHolder: defaultCardHolder,
    card: "",
    month: "",
    year: "",
    cvv: "",
    date: "",
    brand: "",
  });

  const { card, theme, cancel_url } = options || {};
  const { enabled_remember_me } = card || {};

  const hiddenRememberMe = !enabled_remember_me;
  const {
    replacedFormPaymentAmountButton,
    formButtonTitle,
    formButtonCurrency,
  } = themeConfig || {};
  const { wholeNumber, decimal } = getFormattedAmountParts(amount);

  const publicFieldsData = useMemo(
    () =>
      selectedMethod?.public_fields?.reduce((acc, item) => {
        acc = { ...acc, [item.name]: item.value };

        return acc;
      }, {}),
    [selectedMethod],
  );

  const { exchange_to, privacy_policy_link, terms_of_service_link } =
    publicFieldsData || {};

  const checkPrivacyPolicy = useMemo(
    () =>
      ((privacy_policy_link || terms_of_service_link) &&
        isPrivacyPolicyAgreed) ||
      (!privacy_policy_link && !terms_of_service_link),
    [isPrivacyPolicyAgreed, privacy_policy_link, terms_of_service_link],
  );

  const handleTimeout = () => {
    state.setLoading({
      eventName: "submit",
      extra: () => (
        <LoaderExtraContentWrapper>
          <LoaderSubtitle>
            <Trans message="subtitleLoader" />
          </LoaderSubtitle>
          <LoaderButtonsWrapper>
            <Button onClick={() => onGoBackButtonClick(cancel_url)}>
              <Trans message="Back" />
            </Button>
          </LoaderButtonsWrapper>
        </LoaderExtraContentWrapper>
      ),
    });
  };

  useTimer({
    status: isLoading,
    action: handleTimeout,
    repeatTrigger: !currentState?.loadingData?.extra,
  });

  const fields = getFields({
    sources: fieldSources,
    selectedMethod: selectedMethod?.method,
    selectedProvider: selectedMethod?.provider,
    selectedMethodData: selectedMethod,
    theme,
  });

  const refSubmit = useRef();

  const processFormData = () => {
    const brandData = brand.getBrand();
    let data = {};

    state.setLoading({
      status: true,
      withBlur:
        currentState?.available_methods?.length > 1 ||
        (currentState?.available_methods?.length === 1 &&
          !isMethodForAutoSubmit),
      eventName: "submit",
    });

    if (selectedMethod?.method === PAYMENT_METHODS.CARD) {
      if (checkedCard) {
        data.card = {
          cvv: values.cvv,
          token: checkedCard.token,
        };
      } else {
        data.card = {
          cvv: values.cvv,
          exp_month: values.date.slice(0, 2),
          exp_year: `20${values.date.slice(2, 4)}`,
          pan: values.card.replace(/\s/g, ""),
          brand: values.brand,
          holder_name: processing.sanitizeCardHolderName(values.cardHolder),
        };

        if (rememberMe) {
          data.card = { ...data.card, remember_me: true };
        }
      }
    } else {
      data = fields.reduce((acc, field) => {
        const fieldValue = values[field.name];

        if (!fieldValue) return acc;

        const key = field?.apiSourceName || field?.source;

        if (!acc[key]) {
          acc[key] = {};
        }

        if (typeof field?.formatBeforeSend === "function") {
          acc[key] = { ...acc[key], ...field.formatBeforeSend({ values }) };
        } else {
          acc[key][field.name] = fieldValue;
        }

        return acc;
      }, {});
    }

    if (selectedMethod?.method === PAYMENT_METHODS.BANK_CODE) {
      data.bank_code = selectedMethod?.code;

      if (data?.bank_code) {
        api.sendChallengeBankCodeData({
          bank_code: data.bank_code,
        });
      }
    }

    if (brandData?.antifraud_enabled) {
      data.antifraud = {
        [brandData?.antifraud_provider]: {
          pixel: state.getDeviceFingerprint(),
        },
      };
    }

    onSubmit({
      method: selectedMethod?.method,
      ...data,
    });

    return processing
      .processFormData({
        method: selectedMethod?.method,
        ...data,
      })
      .finally(() => {
        setIsDisabledButton(false);

        state.setLoading({ status: false, eventName: "submit" });
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    setIsDisabledButton(true);

    const formSubmitSpan = createSpan("FormSubmit");

    if (!MANUALLY_PROCESSED_PAYMENT_METHODS.includes(selectedMethod?.method)) {
      logger.error("onFormSubmit: unsupported payment method", {
        method_id: selectedMethod?.id,
        method: selectedMethod?.method,
      });

      formSubmitSpan?.end();

      return;
    }

    if (
      processing.validate({
        values,
        errors,
        method: selectedMethod?.method,
        fields,
        isManuallyTriggered: true,
        areAllFieldsActive,
      }) &&
      checkPrivacyPolicy
    ) {
      processFormData().finally(() => {
        formSubmitSpan?.end();
      });
    } else {
      formSubmitSpan?.end();
      setIsDisabledButton(false);
      processing
        .getFieldsErrors({
          fields,
          values,
          areAllFieldsActive,
        })
        .forEach(({ key, value }) => {
          setErrors((prev) => ({
            ...prev,
            [key]: value,
          }));
        });
    }
  };

  const handleFormSubmit = debounce((e) => {
    onFormSubmit(e);
  }, 300);

  useEffect(() => {
    if (fields?.length && selectedMethod?.method !== "card") {
      const newValues = fields.reduce((acc, field) => {
        const sourceData = fieldSources[field.source];
        const alternativeSourceData = fieldSources[field.alternativeSource];

        if (sourceData) {
          acc[field.name] = sourceData[field.name];
        } else if (alternativeSourceData) {
          acc[field.name] = alternativeSourceData[field.name];
        }

        return acc;
      }, {});

      setValues((prev) => ({
        ...prev,
        ...Object?.fromEntries(
          Object?.entries(newValues)?.filter(
            ([key]) => prev[key] === undefined || prev[key] === "",
          ),
        ),
      }));

      setIsReadySubmit(true);
    } else {
      setIsReadySubmit(true);
    }
  }, [
    JSON.stringify(fields),
    selectedMethod?.method,
    JSON.stringify(customer),
    JSON.stringify(upi),
    JSON.stringify(virtual_account_request),
    JSON.stringify(mobile_money),
  ]);

  useEffect(() => {
    const getDisableButtonStatus =
      getThemeConfig(theme)?.getDisableButtonStatus;

    if (
      getDisableButtonStatus &&
      typeof getDisableButtonStatus === "function"
    ) {
      setIsDisabledButton(getDisableButtonStatus({ values, errors }));
    }
  }, [values, errors]);

  useEffect(() => {
    setIsLoading(!currentState?.loadingData?.status && !hasSelectedMethod);
  }, [hasSelectedMethod, currentState?.loadingData?.status]);

  // auto-submit
  useEffect(() => {
    if (!exchange_to && checkPrivacyPolicy) {
      const isFormCompleted = processing.validate({
        values,
        errors,
        method: selectedMethod?.method,
        fields,
        isManuallyTriggered: false,
      });

      if (
        hasSelectedMethod &&
        isReadySubmit &&
        isFormCompleted &&
        isMethodForAutoSubmit &&
        !isFailedRetryStatus
      ) {
        processFormData();
      }
    }
  }, [hasSelectedMethod, isReadySubmit]);

  const themeForComponent = useThemeComponent("Form");

  const FormComponent = {
    default: FormDefault,
    PM_brand: FormPM,
  }[themeForComponent];

  if (isLoading) {
    return (
      <FormLoaderWrapper>
        <Loader />
      </FormLoaderWrapper>
    );
  }

  const resetCardForm = (clean = true) => {
    setCheckedCard(null);
    setRememberMe(null);

    if (clean) {
      setValues({
        card: "",
        month: "",
        year: "",
        cvv: "",
        date: "",
        cardHolder: "",
        brand: "",
      });
      handleCloseModal();
      setErrors({});
    }
  };

  return (
    <FormComponent
      handleFormSubmit={handleFormSubmit}
      selectedMethod={selectedMethod}
      refSubmit={refSubmit}
      fieldSources={fieldSources}
      values={values}
      setValues={setValues}
      errors={errors}
      setErrors={setErrors}
      checkedCard={checkedCard}
      setCheckedCard={setCheckedCard}
      resetCardForm={resetCardForm}
      hiddenRememberMe={hiddenRememberMe}
      themeConfig={themeConfig}
      setRememberMe={setRememberMe}
      isMethodForAutoSubmit={isMethodForAutoSubmit}
      isFailedRetryStatus={isFailedRetryStatus}
      hasSelectedMethod={hasSelectedMethod}
      isDisabledButton={isDisabledButton}
      checkPrivacyPolicy={checkPrivacyPolicy}
      exchange_to={exchange_to}
      privacy_policy_link={privacy_policy_link}
      terms_of_service_link={terms_of_service_link}
      rememberMe={rememberMe}
      formButtonTitle={formButtonTitle}
      replacedFormPaymentAmountButton={replacedFormPaymentAmountButton}
      wholeNumber={wholeNumber}
      decimal={decimal}
      currency={currency}
      formButtonCurrency={formButtonCurrency}
      theme={theme}
    />
  );
};

export default Form;
