import { FunctionComponent, JSX, RefObject } from "preact";
import {
  BrandIconWrapper,
  FieldLabelWrapper,
  FormDescription,
  FormInputContainerWrapper,
  FormInputIconWrapper,
  HintCardFieldTitle,
  InputFormActionWrapper,
} from "./style";
import Trans from "../../../../components/Trans";
import {
  FormInput,
  InvalidCardErrorTitle,
} from "../../../commons/styles/InputStyles";
import { COLORS } from "../../../../constants/themeConstants";
import ArrowIcon from "../../../../assets/icons/ui/arrow";
import { i18n } from "../../../../services";

interface FieldUIProps {
  label: string;
  value: string;
  onInput: JSX.GenericEventHandler<HTMLInputElement>;
  onBlur: JSX.FocusEventHandler<HTMLInputElement>;
  setFocused: (focused: boolean) => void;
  focused: boolean;
  errorChecking: () => boolean;
  inputRef?: RefObject<HTMLInputElement>;
  error?: string;
  placeholder: string;
  id?: string;
  type?: string;
  inputmode?: string;
  pattern?: string;
  autocomplete?: string;
  maxlength?: number;
  size?: number;
  "data-role"?: string;
  brandIcon?: JSX.Element | null;
  extraComponent?: JSX.Element | null;
  showBrandIcon?: boolean;
  isSmall?: boolean;
  isDropdown?: boolean;
  fieldType?: string;
  onClick?: () => void;
  extraComponentPosition?: "label" | "input";
  validationHint?: string;
  nameKey?: string;
}

const FieldUI: FunctionComponent<FieldUIProps> = ({
  label,
  value,
  onInput,
  onBlur,
  setFocused,
  focused,
  errorChecking,
  inputRef,
  error,
  placeholder,
  id,
  type,
  inputmode,
  pattern,
  autocomplete,
  maxlength,
  size,
  "data-role": dataRole,
  brandIcon = null,
  extraComponent = null,
  showBrandIcon = true,
  isSmall = false,
  isDropdown,
  onClick,
  fieldType,
  extraComponentPosition,
  validationHint,
  nameKey,
}) => {
  return (
    <InputFormActionWrapper
      onClick={onClick}
      $fieldType={fieldType}
      $isNoEditable={!!onClick}
      data-transaction-name={`field-${nameKey || id}`}
    >
      <FieldLabelWrapper>
        <FormDescription
          $focused={focused}
          data-transaction-name={`field-label-${nameKey || id}`}
        >
          <Trans message={label} />
        </FormDescription>
        {extraComponentPosition &&
          extraComponentPosition === "label" &&
          extraComponent}
      </FieldLabelWrapper>

      <FormInputContainerWrapper>
        <FormInput
          value={value}
          onInput={onInput}
          onFocus={() => setFocused(true)}
          onBlur={onBlur}
          $focused={focused}
          autofocus
          $errorCard={errorChecking()}
          placeholder={i18n.getMessage({ message: placeholder })}
          ref={inputRef}
          id={id}
          type={type}
          inputmode={inputmode}
          pattern={pattern}
          autocomplete={autocomplete}
          maxlength={maxlength}
          size={size}
          data-role={dataRole}
          $isDropdown={isDropdown}
          data-transaction-name={`CustomerInput-${nameKey || id}`}
        />
        {isDropdown && (
          <FormInputIconWrapper>
            <ArrowIcon color={COLORS.DEFAULT_BLACK} />
          </FormInputIconWrapper>
        )}

        {showBrandIcon && brandIcon && (
          <BrandIconWrapper $isSmall={isSmall}>{brandIcon}</BrandIconWrapper>
        )}

        {errorChecking() ? (
          <InvalidCardErrorTitle
            data-transaction-name={`field-error-${nameKey || id}`}
          >
            <Trans message={error || ""} />
          </InvalidCardErrorTitle>
        ) : (
          validationHint && (
            <HintCardFieldTitle>
              <Trans message={validationHint || ""} />
            </HintCardFieldTitle>
          )
        )}
      </FormInputContainerWrapper>
      {(!extraComponentPosition ||
        (extraComponentPosition && extraComponentPosition === "input")) &&
        extraComponent}
    </InputFormActionWrapper>
  );
};

export default FieldUI;
