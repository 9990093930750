import { useContext } from "preact/hooks";
import { Context } from "../../../../app";
import Button from "../../../../components/Button";
import Loader from "../../../../components/Loaders";
import {
  LoaderButtonsWrapper,
  LoaderExtraContentWrapper,
  LoaderPositionControl,
  LoaderSubtitle,
  LoaderWrapper,
} from "../../../../components/Loaders/style";
import { PendingPageTitle } from "../../../../components/PendingPage/style";
import Trans from "../../../../components/Trans";
import { state } from "../../../../services";
import { EnumSize, EnumVariant } from "../../../commons/enums/ButtonEnums";
import { FunctionComponent } from "preact";

interface PendingLoaderProps {
  showCancelButtons?: boolean;
  showRedirectButtons?: boolean;
  challenge?: {
    url?: string;
  };
  options?: {
    cancel_url?: string;
    theme?: string;
  };
}

const PendingLoaderDefault: FunctionComponent<PendingLoaderProps> = ({
  showCancelButtons,
  showRedirectButtons,
  challenge,
  options,
}) => {
  const { onGoBackButtonClick } = useContext(Context);

  return (
    <LoaderPositionControl data-transaction-name="processing-loader">
      <LoaderWrapper>
        <Loader />
        <PendingPageTitle>
          {showCancelButtons || showRedirectButtons ? (
            <Trans message="cancelPaymentTextForLoader" />
          ) : (
            <Trans
              message="processingYourPayment"
              params={{ hasBr: options?.theme === "default" }}
            />
          )}
        </PendingPageTitle>
        {(showRedirectButtons || showCancelButtons) && (
          <LoaderExtraContentWrapper>
            <LoaderSubtitle>
              <Trans message="subtitleLoader" />
            </LoaderSubtitle>
            <LoaderButtonsWrapper>
              {(showRedirectButtons || showCancelButtons) && (
                <Button
                  size={(showRedirectButtons && EnumSize.AUTO) || undefined}
                  variant={
                    (showRedirectButtons && EnumVariant.SECONDARY) || undefined
                  }
                  onClick={() => onGoBackButtonClick(options?.cancel_url || "")}
                >
                  <Trans message="Back" />
                </Button>
              )}

              {showRedirectButtons && (
                <Button
                  onClick={() => state.changeWindowLocation(challenge?.url)}
                >
                  <Trans message="redirectNotificationButton" />
                </Button>
              )}
            </LoaderButtonsWrapper>
          </LoaderExtraContentWrapper>
        )}
      </LoaderWrapper>
    </LoaderPositionControl>
  );
};

export default PendingLoaderDefault;
