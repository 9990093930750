import { state } from "../../services";
import { PATTERNS } from "../../services/constants";

interface InputOptions {
  type: "dropdown";
  key: string;
  showBankIcon?: boolean;
  getOptions?: (params: { challenge: any }) => any[];
  onChange?: (params: {
    option: any;
    setValues: (
      setter: (prev: Record<string, any>) => Record<string, any>,
    ) => void;
  }) => void;
  getMethodNameForIcon?: () => string | undefined;
  display?: (params: { option: any }) => string;
  inputDisplay?: (value: any) => string;
  search?: (params: { option: any; search: string }) => boolean;
}

export interface ChallengeField {
  label: string;
  name: string;
  type: "text" | "dropdown" | "tel";
  pattern?: RegExp | string;
  validationMessage?: string;
  supportedChallengeTypes: string[];
  placeholder?: string;
  inputOptions?: InputOptions;
}

const challengeFormConfig: Record<string, ChallengeField> = {
  pin: {
    label: "pin",
    name: "pin",
    type: "text",
    pattern: /^\d{4,12}$/,
    validationMessage: "validation.pin",
    supportedChallengeTypes: ["pin"],
  },
  card_pin: {
    label: "card_pin",
    name: "card_pin",
    type: "text",
    pattern: /^\d{4,12}$/,
    validationMessage: "validation.pin",
    supportedChallengeTypes: ["card_pin"],
  },
  otp: {
    label: "OTP",
    name: "otp",
    type: "text",
    pattern: /^\d{1,32}$/,
    supportedChallengeTypes: ["otp"],
  },
  country: {
    label: "country",
    name: "country",
    type: "text",
    pattern: /^[a-zA-Z0-9\s,\-/.]{1,32}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  state_code: {
    label: "state_code",
    name: "state_code",
    type: "text",
    pattern: /^[a-zA-Z0-9\s]{1,32}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  city: {
    label: "city",
    name: "city",
    type: "text",
    pattern: /^[a-zA-Z\s]{1,32}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  address: {
    label: "address",
    name: "address",
    type: "text",
    pattern: /^[a-zA-Z0-9\s,\-/.]{1,64}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  zip_code: {
    label: "zip_code",
    name: "zip_code",
    type: "text",
    pattern: /^\d{1,16}$/,
    supportedChallengeTypes: ["address_verification"],
  },
  phone_verification: {
    label: "phone",
    name: "phone",
    type: "tel",
    pattern: PATTERNS.PHONE,
    supportedChallengeTypes: ["phone_verification"],
  },
  blik_code: {
    label: "blikCodeLabel",
    name: "blik_code",
    type: "text",
    pattern: /^\d{6}$/,
    validationMessage: "blikCodeInvalid",
    placeholder: "123456",
    supportedChallengeTypes: ["blik_code"],
  },
  bank_code: {
    label: "select_bank",
    name: "bank_code",
    type: "dropdown",
    validationMessage: "required",
    supportedChallengeTypes: ["bank_code"],
    placeholder: "select_bank_placeholder",
    inputOptions: {
      type: "dropdown",
      key: "code",
      showBankIcon: true,
      getOptions: ({ challenge }) => challenge?.bank_code?.bank_code || [],
      onChange: ({ option, setValues }) => {
        setValues((prev) => ({
          ...prev,
          bank_code: option,
        }));
      },
      getMethodNameForIcon: () => state.getSelectedPaymentMethod()?.method,
      display: ({ option }) => option?.name || "",
      inputDisplay: (value) => value?.name || "",
      search: ({ option, search }) =>
        search
          ? option.name?.toLowerCase().includes(search.toLowerCase()) ||
            option.code?.toLowerCase().includes(search.toLowerCase())
          : true,
    },
  },
  amount: {
    label: "selectAmount",
    name: "amount",
    type: "dropdown",
    validationMessage: "required",
    supportedChallengeTypes: ["select_amount"],
    placeholder: "select_amount_placeholder",
    inputOptions: {
      type: "dropdown",
      key: "amount",
      showBankIcon: false,
      getOptions: ({ challenge }) => challenge?.select_amount?.amounts || [],
      onChange: ({ option, setValues }) => {
        setValues((prev) => ({
          ...prev,
          amount: option,
        }));
      },
      getMethodNameForIcon: () => state.getSelectedPaymentMethod()?.method,
      display: ({ option }) => `¥ ${option?.amount}` || "",
      inputDisplay: (value) => (value?.amount ? `¥ ${value?.amount}` : ""),
      search: ({ option, search }) =>
        search
          ? option?.amount
              ?.toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          : true,
    },
  },
};

export default challengeFormConfig;
