import styled from "styled-components";

export const PMChallengeCompleteText = styled.span`
  font-family: var(--regularFont);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #000;
  opacity: 0.56;
`;

export const PMChallengeCompleteTitle = styled.span`
  font-family: var(--regularFont);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #000;
  opacity: 0.9;
`;

export const PMChallengeCompleteWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 24px 40px;
`;
