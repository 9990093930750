import { FunctionComponent } from "preact";
import { useState } from "preact/hooks";
import asquadSvgLockIcon from "../../../../assets/icons/asquadLockIcon.svg";
import cvvIconCardIcon from "../../../../assets/icons/cvvTooltipCardIcon.svg";
import {
  CvvTooltipMainTitle,
  CvvTooltipSubTitle,
  TooltipTextCvv,
  FormIconCvvLock,
} from "./style";
import Trans from "../../../../components/Trans";
import { InputCvvProps } from "../../../commons/interfaces/CardInterfaces";
import FieldUI from "../../ui/Field";
import { TooltipContentWrapper } from "../../../commons/styles/InputStyles";

const Tooltip = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <TooltipContentWrapper
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      <FormIconCvvLock src={asquadSvgLockIcon} />

      {tooltipVisible && (
        <TooltipTextCvv>
          <div style={{ display: "flex", marginBottom: "12px" }}>
            <CvvTooltipSubTitle>
              <Trans message="last3Digits" />
            </CvvTooltipSubTitle>
            <img alt="cvv" src={cvvIconCardIcon} />
          </div>
          <CvvTooltipMainTitle>
            <Trans message="sslHintTitle" />
          </CvvTooltipMainTitle>
          <CvvTooltipSubTitle>
            <Trans message="sslHintDescription" />
          </CvvTooltipSubTitle>
        </TooltipTextCvv>
      )}
    </TooltipContentWrapper>
  );
};

const InputCvv: FunctionComponent<InputCvvProps> = ({
  focused,
  errorChecking,
  onCvvBlur,
  onCvvInput,
  setFocused,
  values,
  inputRef,
  errors,
}) => {
  return (
    <FieldUI
      id="cvv"
      label="CVV2"
      value={values.cvv}
      onInput={onCvvInput}
      onBlur={onCvvBlur}
      setFocused={setFocused}
      focused={focused}
      inputRef={inputRef}
      type="password"
      pattern="[0-9\s]{3}"
      maxlength={3}
      size={3}
      autocomplete="cc-csc"
      placeholder="•••"
      data-role="card_cvv"
      data-transaction-name="InputCvv"
      error={errors?.cvv}
      errorChecking={errorChecking}
      extraComponent={<Tooltip />}
      fieldType="small"
    />
  );
};

export default InputCvv;
