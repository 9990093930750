import { FunctionalComponent } from "preact";
import Trans from "../Trans";
import { formatCryptoCurrency } from "../../constants/CryptoCurrencyIcon";
import { Bold } from "../../ui/GeneralStyles/style";
import { DetailsCryptoTitle as DetailsCryptoTitleDefault } from "../../themes/default/components/CryptoForm/style";
import { DetailsCryptoTitle as DetailsCryptoTitlePM } from "../../themes/PM_brand/components/CryptoForm/style";
import { useThemeComponent } from "../../hooks/useThemeComponent";

interface CryptoDisclaimerProps {
  currency: string;
}

const CryptoDisclaimer: FunctionalComponent<CryptoDisclaimerProps> = ({
  currency,
}) => {
  const themeForComponent = useThemeComponent("CryptoForm");

  const DetailsCryptoTitle =
    {
      default: DetailsCryptoTitleDefault,
      PM_brand: DetailsCryptoTitlePM,
    }[themeForComponent] || DetailsCryptoTitleDefault;

  return (
    <DetailsCryptoTitle $textAlign="start" $mt="16px">
      <Trans
        message="cryptoDisclaimer"
        params={{ value: formatCryptoCurrency(currency, true) || "" }}
        components={[Bold]}
      />
    </DetailsCryptoTitle>
  );
};

export default CryptoDisclaimer;
