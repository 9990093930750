import styled from "styled-components";

export const ChangeCardMobDesktopWrapper = styled.div`
  margin-top: 10px;
  margin-left: 6px;

  @media (min-width: 768px) {
    margin-left: 16px;
  }
`;

export const CircleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
`;
