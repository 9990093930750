import styled from "styled-components";
import { COLORS } from "../../../constants/themeConstants";

export const OuterInnerBlock = styled.div<{
  $position?: number;
}>`
  background: ${({ theme }) =>
    theme?.headerGradient ||
    "radial-gradient(at top, #0062eb, #0062eb 92%, transparent 50%)"};
  height: 78px;
  position: absolute;
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
  margin-top: ${({ $position }) =>
    $position && $position < 100 ? "-30px" : "-1px"};

  @media (min-width: 320px) and (max-width: 340px) {
    height: 40px;
  }

  @media (min-width: 768px) {
    max-width: 440px;
  }
`;

export const FormHeaderTitle = styled.p`
  color: ${COLORS.DEFAULT_WHITE};
  margin: 0 auto;
  font-size: 20px;
  font-weight: 500;
`;

export const DetailToggler = styled.a<{
  $color?: keyof typeof COLORS;
}>`
  color: ${({ $color, theme }) => $color || theme?.buttonColor || "#FFFFFFE5"};
  font-family: var(--boldFont);
  cursor: pointer;
  padding-bottom: 12px;
  margin: 0 auto;
`;

export const DetailsTransactionSubTitleWrapper = styled.span<{
  $flex?: string;
}>`
  display: ${({ $flex }) => ($flex ? "flex" : "")};
  text-align: ${({ $flex }) => ($flex ? "center" : "end")};
`;
