import { useContext, useState } from "preact/hooks";
import { state, theme } from "../../services";
import { validateCardHolder } from "../../constants/cardHolderInputValidate";
import { CONST_ERROR_LIST } from "../../services/constants";
import { ContextUI } from "../../providers/UIProvider";
import ClearFormModal from "../Modals/ClearModalForm/ClearFormModal";
import FieldUIDefault from "../../themes/default/ui/Field";
import FieldUIPM from "../../themes/PM_brand/ui/Field";
import { useThemeComponent } from "../../hooks/useThemeComponent";

const CardHolderInput = ({
  values,
  setValues,
  errors,
  setErrors,
  inputRef,
  resetCardForm,
  checkedCard,
}) => {
  const [focused, setFocused] = useState(false);
  const { setModalData } = useContext(ContextUI);

  const options = state.get().options;
  const currentTheme = theme.getThemeConfig(options?.theme);

  const clearErrors = () => {
    setErrors((prev) => {
      delete prev.cardHolder;

      return prev;
    });
  };

  const onInput = (e) => {
    let val = e.target.value.replace(/[^\p{L}\s'`~.-]/gu, "");

    if (checkedCard && val !== values.cardHolder) {
      if (currentTheme.hasModalAlertForClearCardInfo) {
        setModalData({
          isOpen: true,
          title: "isSureClearCardData",
          content: <ClearFormModal resetCardForm={resetCardForm} />,
        });
      } else {
        resetCardForm(false);
      }

      return;
    }

    if (validateCardHolder(currentTheme?.cardHolderValidation, val)) {
      clearErrors();
    }

    setValues((prev) => ({ ...prev, cardHolder: val }));
  };

  const onBlur = () => {
    setFocused(false);

    if (values.cardHolder.length === 0) {
      return setErrors((prev) => ({
        ...prev,
        cardHolder: CONST_ERROR_LIST.required,
      }));
    }

    if (
      !validateCardHolder(currentTheme?.cardHolderValidation, values.cardHolder)
    ) {
      return setErrors((prev) => ({
        ...prev,
        cardHolder: CONST_ERROR_LIST.wrongNameCard,
      }));
    }

    return clearErrors();
  };

  const errorChecking = () => {
    return errors.cardHolder ? !focused : false;
  };

  const themeForComponent = useThemeComponent("Field");

  const FieldUI = {
    default: FieldUIDefault,
    PM_brand: FieldUIPM,
  }[themeForComponent];

  return (
    <FieldUI
      label={currentTheme?.inputCardHolderLabel}
      value={values.cardHolder}
      onInput={onInput}
      onBlur={onBlur}
      setFocused={setFocused}
      focused={focused}
      errorChecking={errorChecking}
      inputRef={inputRef}
      error={errors.cardHolder}
      placeholder={currentTheme?.cardHolderInputPlaceHolder}
      id="cardholder"
      type="text"
      autocomplete="cc-name"
      data-transaction-name="CardHolderInput"
    />
  );
};

export default CardHolderInput;
