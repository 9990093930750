import styled from "styled-components";
import { COLORS } from "../../constants/themeConstants";

export const ContainerDetailsWrapper = styled.div<{
  $alignItems?: string;
  $pb?: string;
  $mt?: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems || ""};
  padding-bottom: ${({ $pb }) => $pb || ""};
  margin-top: ${({ $mt }) => $mt || ""};
`;

export const BlockDetailsWrapper = styled.div<{
  $mb?: string;
  $width?: string;
  $justifyContent?: string;
}>`
  display: flex;
  justify-content: ${({ $justifyContent }) =>
    $justifyContent || "space-between"};
  margin-bottom: ${({ $mb }) => $mb || "12px"};
  align-items: center;
  width: ${({ $width }) => $width || ""};
`;

export const DetailsTransactionTitle = styled.span<{
  $fw?: string;
  $titleFs?: string;
  $mr?: string;
  $mb?: string;
  $color?: string;
  $controlledLength?: boolean;
  $width?: string;
  $bold?: boolean;
  theme?: {
    subMainInfoTexFontSize?: string;
    subMainInfoTextColor?: string;
    headerTitleColor?: string;
  };
}>`
  font-style: normal;
  font-weight: ${({ $fw }) => $fw || "400"};
  font-size: ${({ $titleFs, theme }) =>
    theme?.subMainInfoTexFontSize || $titleFs || "14px"};
  line-height: 16px;
  margin: ${({ $mb }) => $mb || ""};
  color: ${({ $color, theme }) =>
    $color ||
    theme?.subMainInfoTextColor ||
    theme?.headerTitleColor ||
    COLORS.GRAY};
  margin-bottom: ${({ $mb }) => $mb || ""};
  margin-bottom: ${({ $mr }) => $mr || ""};
  font-family: var(--regularFont);
  text-align: ${({ $controlledLength }) =>
    $controlledLength ? "left" : "right"};
  width: ${({ $controlledLength, $width }) => {
    if ($controlledLength) {
      return "110px";
    }
    if ($width) {
      return $width;
    }
    return "";
  }};
  word-wrap: break-word;

  span {
    font-family: ${({ $bold }) =>
      $bold ? "var(--boldFont)" : "var(--mediumFont)"};
  }
`;

export const DetailsTransactionSubTitle = styled.span<{
  $titleFs?: string;
  $mr?: string;
  $color?: string;
  $controlledLength?: boolean;
  $bold?: boolean;
  theme?: {
    subMainInfoTexFontSize?: string;
    mainTextColor?: string;
    headerTitleColor?: string;
  };
}>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ $titleFs, theme }) =>
    theme?.subMainInfoTexFontSize || $titleFs || "14px"};
  line-height: 16px;
  margin-right: ${({ $mr }) => $mr || ""};
  color: ${({ $color, theme }) =>
    theme?.mainTextColor ||
    $color ||
    theme?.headerTitleColor ||
    COLORS.DEFAULT_BLACK};
  font-family: ${({ $bold }) =>
    $bold ? "var(--boldFont)" : "var(--regularFont)"};
  text-align: ${({ $controlledLength }) =>
    $controlledLength ? "left" : "right"};
  width: ${({ $controlledLength }) => ($controlledLength ? "110px" : "")};
  span {
    font-family: ${({ $bold }) =>
      $bold ? "var(--boldFont)" : "var(--mediumFont)"};
  }
`;
