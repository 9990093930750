import { FunctionComponent } from "preact";
import Trans from "../../../../components/Trans";
import {
  NotificationBodyContainer,
  NotificationButton,
  NotificationCloseIcon,
  NotificationContainer,
  NotificationIcon,
  NotificationText,
} from "./style";
import notificationSuccess from "../../../../assets/icons/notificationSuccess.svg";
import notificationSuccessKombine from "../../../../assets/icons/kombineSuccessIcon.svg";
import notificationError from "../../../../assets/icons/notificationError.svg";
import closeIcon from "../../../../assets/icons/close.svg";

interface NotificationProps {
  status: "success" | "error";
  isOpen: boolean;
  text: string;
  theme?: string;
  hasButton?: boolean;
  buttonText?: string;
  buttonAction?: () => void;
  handleCloseNotification: () => void;
}

const SuccessIconConfig = {
  default: notificationSuccess,
  kombine: notificationSuccessKombine,
};

const NotificationDefault: FunctionComponent<NotificationProps> = ({
  status,
  isOpen,
  text,
  theme = "default",
  hasButton,
  buttonText,
  buttonAction,
  handleCloseNotification,
}) => {
  return (
    <NotificationContainer
      $status={status}
      $isOpen={isOpen}
      data-transaction-name="notification"
    >
      <NotificationIcon
        src={
          status === "success"
            ? SuccessIconConfig[theme as keyof typeof SuccessIconConfig] ||
              SuccessIconConfig["default"]
            : notificationError
        }
        alt="Notification Icon"
      />
      <NotificationBodyContainer data-transaction-name="notification-content">
        <NotificationText>
          <Trans message={text} />
        </NotificationText>
        {hasButton && buttonText && buttonAction && (
          <NotificationButton onClick={buttonAction}>
            <Trans message={buttonText} />
          </NotificationButton>
        )}
      </NotificationBodyContainer>
      <NotificationCloseIcon onClick={handleCloseNotification}>
        <img src={closeIcon} alt="Notification close icon" />
      </NotificationCloseIcon>
    </NotificationContainer>
  );
};

export default NotificationDefault;
