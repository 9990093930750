import styled from "styled-components";

export const CircleWrapper = styled.div`
  position: relative;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SvgCircle = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

export const CircleBg = styled.circle`
  fill: none;
  stroke-width: 5;
`;

export const CircleProgress = styled.circle`
  fill: none;
  stroke-width: 5;
  stroke-linecap: round;
  transition: stroke-dashoffset 1s linear;
`;

export const TimeText = styled.div`
  position: absolute;
  font-family: var(--boldFont);
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #000000;
  opacity: 0.94;
`;
