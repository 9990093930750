import styled from "styled-components";
import { InvalidCardErrorTitle } from "../../../commons/styles/InputStyles";
import { COLORS } from "../../../../constants/themeConstants";

export const FormDescription = styled.span<{
  $margin?: string;
  $focused?: boolean;
  $fs?: string;
  $fw?: string;
}>`
  color: ${({ $focused, theme }) =>
    $focused
      ? theme?.inputColorFocusedPlaceholder
      : theme?.inputColorPlaceholder};
  font-size: ${({ $fs, theme }) => $fs || theme?.inputPlaceholderFontSize};
  margin-left: ${({ theme }) => theme?.inputPlaceHolderMarginLeft};
  margin: ${({ $margin }) => $margin ?? ""};
  font-weight: ${({ $fw }) => $fw ?? ""};
  font-family: var(--mediumFont);
`;

export const FieldLabelWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
`;

export const FormInputContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const InputFormActionWrapper = styled.div<{
  $margin?: string;
  $isNoEditable?: boolean;
  $fieldType?: string;
}>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  width: ${({ $fieldType }) =>
    $fieldType === "small" ? `calc(50% - 12px)` : "100%"};
  margin: ${({ $margin, theme }) => {
    if (theme?.inputGap) {
      return theme.inputGap;
    }

    if ($margin) {
      return $margin;
    }

    return "";
  }};

  ${({ $isNoEditable }) => {
    if ($isNoEditable) {
      return `
          cursor: pointer;
  
          input {
            pointer-events: none;
          }
        `;
    }

    return "";
  }}

  @media (max-height: 768px) {
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    max-width: 408px;
  }
`;

export const BrandIconWrapper = styled.div<{
  $isSmall?: boolean;
}>`
  position: absolute;
  top: 48%;
  right: 56px;
  transform: translateY(-50%);
  right: ${({ $isSmall }) => ($isSmall ? "20px" : "75px")};
`;

export const FormInputIconWrapper = styled.div`
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  margin-top: -3px;
  cursor: pointer;
`;

export const HintCardFieldTitle = styled(InvalidCardErrorTitle)`
  color: ${COLORS.GRAY};
`;
