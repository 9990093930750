import { useEffect, useRef, useContext } from "preact/hooks";
import { state, logger } from "../../services";
import PendingPageComponent from "../../components/PendingPage";
import { Context } from "../../app";
import useApmPageTracking from "../../hooks/useApmPageTracking";

const PendingPage = () => {
  useApmPageTracking("PendingPage");

  const context = useContext(Context);
  const { style, currentState = {}, matchesData } = context;
  const { sessionId, signature } = matchesData;

  const { amount, currency, options, challenge, reference, status } =
    currentState || {};
  const isTestMode = state.isTestMode();
  const isPareq = state.isPareq();
  const isCreq = state.isCreq();
  const isAutoSubmit = state.isAutoSubmit();
  const hasAutoSubmitType = isPareq || isCreq || isAutoSubmit;
  const autoSubmitForm = useRef(null);
  const iframeRef = useRef(null);

  const tryAutosubmitForm = () => {
    if (challenge && autoSubmitForm && autoSubmitForm.current) {
      if (isTestMode) {
        console.log("Try to autosubmit challenge form", challenge);
      } else {
        autoSubmitForm.current.submit();
      }
    } else if (challenge && status === "challenge") {
      logger.error(
        "Can not autosubmit form due to autosubmit form ref missing",
        {
          isPareq,
          isCreq,
          challenge,
          autoSubmitForm,
        },
      );
    }
  };

  useEffect(() => {
    if (!hasAutoSubmitType) {
      return;
    }

    let timeoutId = null;

    if (challenge?.iframe_url) {
      iframeRef.current.onload = () => {
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(tryAutosubmitForm, 2000);
      };
    } else {
      timeoutId = setTimeout(tryAutosubmitForm, 500);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [hasAutoSubmitType, challenge?.iframe_url, iframeRef.current, status]);

  if (!style) {
    return;
  }

  return (
    <>
      <PendingPageComponent
        style={style}
        currency={currency}
        amount={amount}
        options={options}
        reference={reference}
        challenge={challenge}
        status={status}
        cancelUrl={options?.redirect_url}
        currentState={currentState}
        sessionId={sessionId}
        signature={signature}
      />

      {isPareq && challenge && (
        <form ref={autoSubmitForm} action={challenge.url} method="POST">
          <input type="hidden" name="PaReq" value={challenge.pareq} />
          <input type="hidden" name="TermUrl" value={challenge.term_url} />
          <input type="hidden" name="MD" value={challenge.md} />
        </form>
      )}
      {isCreq && challenge && (
        <form ref={autoSubmitForm} action={challenge.url} method="POST">
          <input type="hidden" name="creq" value={challenge.creq} />
        </form>
      )}

      {isAutoSubmit && challenge && (
        <form
          ref={autoSubmitForm}
          action={challenge.url}
          method={challenge.http_method || "POST"}
        >
          {challenge.fields && typeof challenge.fields === "object"
            ? Object.keys(challenge.fields).map((name) => (
                <input
                  type="hidden"
                  key={name}
                  name={name}
                  value={challenge.fields[name]}
                />
              ))
            : null}
        </form>
      )}

      {challenge?.iframe_url && (
        <iframe ref={iframeRef} src={challenge.iframe_url} hidden={true} />
      )}
    </>
  );
};
export default PendingPage;
