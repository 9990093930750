const DropdownIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 19C11.7 19 11.5 18.9 11.3 18.7L7.3 14.7C6.9 14.3 6.9 13.7 7.3 13.3C7.7 12.9 8.3 12.9 8.7 13.3L12 16.6L15.3 13.3C15.7 12.9 16.3 12.9 16.7 13.3C17.1 13.7 17.1 14.3 16.7 14.7L12.7 18.7C12.5 18.9 12.3 19 12 19ZM16.7 9.7C17.1 9.3 17.1 8.7 16.7 8.3L12.7 4.3C12.3 3.9 11.7 3.9 11.3 4.3L7.3 8.3C6.9 8.7 6.9 9.3 7.3 9.7C7.7 10.1 8.3 10.1 8.7 9.7L12 6.4L15.3 9.7C15.7 10.1 16.3 10.1 16.7 9.7Z"
      fill="#7B756B"
    />
  </svg>
);

export default DropdownIcon;
