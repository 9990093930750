import colorSchema from "../../../../colorThemes/colorSchemes";
import Footer from "../../../../components/Footer";
import { LogoBrandHeader } from "../../../../components/Header/style";
import Trans from "../../../../components/Trans";
import { COLORS } from "../../../../constants/themeConstants";
import brand from "../../../../services/brand";
import { BaseButton } from "../../../../themes/commons/styles/ButtonStyles";
import { BrandData } from "../../../commons/interfaces/SchemaInterface";
import {
  PaymentTitle,
  TransactionInfoSecondBlock,
  SuccessPageWrapper,
} from "../../../commons/styles/SuccessPageStyles";
import { SuccessWrapperKombine } from "./style";
import { FunctionComponent } from "preact";

interface SuccessPageProps {
  onButtonClick: () => void;
}

const SuccessPage: FunctionComponent<SuccessPageProps> = ({
  onButtonClick,
}) => {
  const brandData = brand.getBrand() as BrandData;
  const successIcon = colorSchema?.[brandData?.color_scheme]?.successIcon;

  return (
    <>
      <SuccessPageWrapper>
        <SuccessWrapperKombine>
          <div>
            <LogoBrandHeader
              style={{
                width: "40px",
                height: "40px",
              }}
              src={`${successIcon}`}
            />
          </div>

          <PaymentTitle $color={COLORS.DEFAULT_WHITE} $fs="20px" $width="210px">
            <Trans message="paymentSuccessfully" />
          </PaymentTitle>
        </SuccessWrapperKombine>

        <TransactionInfoSecondBlock>
          <BaseButton
            onClick={onButtonClick}
            style={{
              marginTop: "auto",
              background: "linear-gradient(#0629DF, #00AAFF)",
              border: "none",
              height: "48px",
            }}
          >
            <Trans message="PLAY NOW" />
          </BaseButton>
        </TransactionInfoSecondBlock>
        <Footer background="#031938" />
      </SuccessPageWrapper>
    </>
  );
};

export default SuccessPage;
