import { useEffect, useMemo, useState } from "preact/hooks";
import { state, theme } from "../../services";
import { PaymentDetailsContainer, PaymentDetailsWrapper } from "./style";
import PaymentDetailsContent from "./PaymentDetailsContent";
import PaymentDetailsAmount from "./PaymentDetailsAmount";
import FailedRetryInfo from "./FailedRetryInfo";
import PaymentDetailsHead from "./PaymentDetailsHead";
import { ThemeConfig } from "../../themes/commons/interfaces/configInterface";

export interface PaymentDetailItem {
  label: string;
  name?: string;
  value: string;
  condition?: boolean;
}

interface PaymentDetailsProps {
  titleError?: string;
  titleErrorDescription?: string;
  detailedInfo?: PaymentDetailItem[];
  reference?: string;
  description?: string;
  wholeNumber: string;
  decimal: string;
  currency: string;
}

const PaymentDetails = ({
  titleError,
  titleErrorDescription,
  detailedInfo,
  reference,
  description,
  wholeNumber,
  decimal,
  currency,
}: PaymentDetailsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<PaymentDetailItem[]>([]);

  const selectedMethod = state.getSelectedPaymentMethod();
  const canReturnToMethods = state.hasPaymentMethodsToShow();

  // TODO: need fix after move state.js to state.ts
  // @ts-ignore
  const { options } = state.get() || {};

  const currentTheme = theme.getThemeConfig(options?.theme);

  const hasError = !canReturnToMethods && !!titleError;

  const { isHideTransactionId, isHideDescription, hiddenPaymentDetails } =
    (currentTheme as ThemeConfig) || {};

  useEffect(() => {
    setData(() => {
      let result = [...(detailedInfo || [])];

      if (
        !isHideTransactionId &&
        selectedMethod &&
        reference &&
        !result.find((res) => res.value !== reference)
      ) {
        result = [
          {
            label: "transactionIdTitle",
            name: "transactionIdTitle",
            value: reference || "",
          },
          ...result,
        ];
      }

      if (
        !isHideDescription &&
        description &&
        !result.find((res) => res.value !== description)
      ) {
        result = [
          {
            label: "description",
            name: "description",
            value: description || "",
          },
          ...result,
        ];
      }

      return result;
    });
  }, [
    detailedInfo,
    isHideTransactionId,
    isHideDescription,
    selectedMethod,
    reference,
    description,
  ]);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedMethod]);

  const onToggleOpen = (event: Event) => {
    event.preventDefault();

    setIsOpen((prev) => !prev);
  };

  const totalFields = useMemo(
    () => data?.filter((field) => field?.condition !== false && field?.value),
    [data],
  );

  const hasContent = useMemo(() => totalFields.length > 0, [totalFields]);
  const hasAmount = useMemo(
    () => !!(wholeNumber && currency),
    [wholeNumber, currency],
  );

  if (hiddenPaymentDetails) {
    return null;
  }

  return (
    <PaymentDetailsWrapper>
      <PaymentDetailsContainer>
        <PaymentDetailsHead
          isOpen={isOpen}
          onToggleOpen={onToggleOpen}
          hasMoreDetailsButton={hasContent}
          hasUnderline={(isOpen && hasContent) || hasAmount}
        />
        {hasContent ? (
          <PaymentDetailsContent
            isOpen={isOpen}
            data={data}
            hasUnderline={hasAmount}
          />
        ) : null}
        {hasAmount ? (
          <PaymentDetailsAmount
            wholeNumber={wholeNumber}
            decimal={decimal}
            currency={currency}
            hasError={hasError}
          />
        ) : null}
      </PaymentDetailsContainer>
      <FailedRetryInfo
        titleError={titleError}
        titleErrorDescription={titleErrorDescription}
        hasError={hasError}
      />
    </PaymentDetailsWrapper>
  );
};

export default PaymentDetails;
