import InfoIcon from "../../../assets/icons/infoIcon";
import BankInfoDetails from "../../BankInfo/BankInfoDetails";
import Trans from "../../Trans";
import {
  ChallengeInfoWrapper,
  ChallengeMainTitle,
  ChallgeMainSubTitle,
  StyledInfoImage,
} from "../style";
import ExpirationDate from "../../BankInfo/ExpirationDate";
import StepsList from "../ChallengeStepsList/StepsList";
import { InnerHeaderDataWrapper } from "../../../themes/default/components/Instruction/style";
import { TotalInfoWrapper } from "../../Header/style";
import {
  BlockDetailsWrapper,
  ContainerDetailsWrapper,
} from "../../BankInfo/style";

const InfoViewBuilder = ({
  config,
  data,
  expiration_date,
  infoMessage,
  title,
  subTitle,
  handleCopy,
  steps,
  showLine,
}) => {
  return (
    <InnerHeaderDataWrapper $padding="20px">
      <ChallengeMainTitle>
        <Trans message={title} />
        {subTitle && <ChallgeMainSubTitle>{subTitle}?</ChallgeMainSubTitle>}
      </ChallengeMainTitle>
      <TotalInfoWrapper>
        <ContainerDetailsWrapper $mt="16px">
          {steps && <StepsList steps={steps} showLine={showLine} />}
          {config?.map((item, index) => (
            <BankInfoDetails
              key={index}
              title={item?.title}
              value={data[item?.key]}
              copyMessage={item?.copyMessage}
              handleCopy={handleCopy}
              bankNameIcon={
                item?.hasIcon
                  ? `${data[item?.key]?.toLowerCase()?.replace(/\s+/g, "_")}`
                  : ""
              }
            />
          ))}
          {expiration_date && (
            <ExpirationDate expiration_date={expiration_date} />
          )}

          <BlockDetailsWrapper $mb="0" $justifyContent="center">
            <ChallengeInfoWrapper>
              <StyledInfoImage>
                <InfoIcon />
              </StyledInfoImage>
              <ChallengeMainTitle>
                <Trans message={infoMessage} />
              </ChallengeMainTitle>
            </ChallengeInfoWrapper>
          </BlockDetailsWrapper>
        </ContainerDetailsWrapper>
      </TotalInfoWrapper>
    </InnerHeaderDataWrapper>
  );
};

export default InfoViewBuilder;
