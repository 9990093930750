import { api } from "../../../../services";
import { InstructionStepDataSubtext } from "../../components/Instruction/style";
import { IPaymentInstructions } from "./interfaces";

const accountRequisites: IPaymentInstructions = {
  title: "accountRequisitesTitleMakePayment",
  isOrderedList: true,
  instructionList: [
    {
      title: "accountNumber",
      getPaymentData: ({ data = {} }) => data?.account,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.account,
        iconTitle: "Copy",
        text: "accountNumberCopied",
      }),
    },
    {
      title: "bankName",
      getPaymentData: ({ data = {} }) => data?.bank_name,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.bank_name,
        iconTitle: "Copy",
        text: "bankNameCopied",
      }),
    },
    {
      title: "bankCode",
      getPaymentData: ({ data = {} }) => data?.bank_code,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.bank_code,
        iconTitle: "Copy",
        text: "bankCodeCopied",
      }),
    },
    {
      title: "amount",
      getPaymentData: ({ state = {} }) => (
        <>
          <InstructionStepDataSubtext>
            {state?.currency}
          </InstructionStepDataSubtext>{" "}
          {state?.amount}
        </>
      ),
      getCopyInfo: ({ state = {} }) => ({
        copy: state?.amount,
        iconTitle: "Copy",
        text: "amountCopy",
      }),
    },
    {
      title: "accountRequisitesPressButton",
      secondaryTitle: {
        text: "paymentConfirmationSubtitleMadePayment",
        bold: true,
      },
    },
  ],
  getInfo: () => "virtualAccountFooter",
  getExpirationDate: ({ data }) =>
    data?.expiration_date ? data?.expiration_date : null,
  submitAction: api.submitAccountRequisites,
};

export default accountRequisites;
