import { api } from "../../../../services";
import { PMInstructionStepDataSubtext } from "../../components/Instruction/style";
import { IPaymentInstructions } from "./interfaces";

const bankAccountIndonesia: IPaymentInstructions = {
  getCustomInfoBlocks: () => [
    {
      type: "info",
      position: 1,
      condition: () => true,
      content: () => ({
        message: "PMBankTransferChallengeInfoOnTop",
      }),
    },
  ],
  title: "PMChallengeTitle",
  isOrderedList: false,
  isPaymentDataList: true,
  instructionList: [
    {
      dataTitle: "accountNumber",
      getPaymentData: ({ data = {} }) => data?.account,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.account,
        iconTitle: "Copy",
        text: "accountNumberCopied",
      }),
    },
    {
      dataTitle: "bankName",
      getPaymentData: ({ data = {} }) => data?.bank_name,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.bank_name,
        iconTitle: "Copy",
        text: "bankNameCopied",
      }),
    },
    {
      dataTitle: "bankCode",
      getPaymentData: ({ data = {} }) => data?.bank_code,
      getBankInfo: ({ data = {} }) => data?.bankInfo,
      getCopyInfo: ({ data = {} }) => ({
        copy: data?.bank_code,
        iconTitle: "Copy",
        text: "bankCodeCopied",
      }),
    },
    {
      dataTitle: "PMaccountRequisitesChallengeAmountDataTitle",
      getPaymentData: ({ state = {} }) => (
        <>
          {state?.amount}{" "}
          <PMInstructionStepDataSubtext>
            {state?.currency}
          </PMInstructionStepDataSubtext>
        </>
      ),
      getCopyInfo: ({ state = {} }) => ({
        copy: state?.amount,
        iconTitle: "Copy",
        text: "PMaccountRequisitesChallengeAmountDataTitleCopied",
      }),
    },
  ],
  getInfo: () => "virtualAccountFooter",
  getExpirationDate: ({ data }) =>
    data?.expiration_date ? data?.expiration_date : null,
  submitAction: api.submitAccountRequisites,
};

export default bankAccountIndonesia;
