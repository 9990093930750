import { useContext } from "preact/hooks";
import Button from "../../../../components/Button";
import {
  QrCodeWrapper,
  QrCodeButtonWrapper,
} from "../../../../components/Challenge/style";
import { OuterBlockHeader } from "../../../../components/Header/style";
import Trans from "../../../../components/Trans";
import { ContextUI } from "../../../../providers/UIProvider";
import { Context } from "../../../../app";
import { FunctionComponent } from "preact";
import { QrActionsWrapper } from "./style";
import ExpirationDate from "../../../../components/BankInfo/ExpirationDate";
import { InnerLine } from "../../../../styles";
import { ChallengeInfoWrapper } from "../../../../components/Challenge/style";
import { StyledInfoImage } from "../../../../components/Challenge/style";
import { EnumVariant } from "../../../commons/enums/ButtonEnums";
import {
  CryptoInfoBlockWrapper,
  CryptoContentInfoTitle,
} from "../CryptoForm/style";
import InfoIcon from "../../../../assets/icons/infoIcon";
interface ChallengeQrProps {
  challenge: {
    qr?: {
      image?: string;
      code?: string;
      expiration_date?: string;
    };
  };
  cancelUrl?: string;
  infoBlockTitle: Record<string, string>;
  selectedMethod: string;
}

const ChallengeQrDefault: FunctionComponent<ChallengeQrProps> = ({
  challenge,
  cancelUrl,
  infoBlockTitle,
  selectedMethod,
}) => {
  const { handleDownloadImage } = useContext(ContextUI);
  const { onGoBackButtonClick } = useContext(Context);

  return (
    <OuterBlockHeader>
      <QrCodeWrapper>
        <Trans message="scanQrTitleOpay" />
        <img
          style={{ width: "200px" }}
          src={challenge?.qr?.image || ""}
          alt="QR Code"
        />

        <QrCodeButtonWrapper>
          <Button
            textTransform="inherit"
            variant={EnumVariant.SECONDARY}
            onClick={() =>
              handleDownloadImage(challenge?.qr?.image || "", "qr_code.png")
            }
          >
            <Trans message="Download" />
          </Button>
        </QrCodeButtonWrapper>

        <InnerLine />

        {challenge?.qr?.expiration_date && (
          <ExpirationDate expiration_date={challenge?.qr?.expiration_date} />
        )}

        <ChallengeInfoWrapper $mb="16px">
          <StyledInfoImage>
            <InfoIcon />
          </StyledInfoImage>
          <CryptoInfoBlockWrapper>
            <CryptoContentInfoTitle>
              <Trans
                message={
                  infoBlockTitle[selectedMethod] ||
                  "accountRequisitesMadeTransfer"
                }
              />
            </CryptoContentInfoTitle>
          </CryptoInfoBlockWrapper>
        </ChallengeInfoWrapper>
      </QrCodeWrapper>

      <QrActionsWrapper>
        {cancelUrl && (
          <Button
            textTransform="inherit"
            data-role="back_to_store_button"
            onClick={() => onGoBackButtonClick(cancelUrl)}
          >
            <Trans message="iHaveMadePayment" />
          </Button>
        )}
      </QrActionsWrapper>
    </OuterBlockHeader>
  );
};

export default ChallengeQrDefault;
