import styled from "styled-components";
import { EnumSize, EnumVariant } from "../../../commons/enums/ButtonEnums";
import { PM_COLORS } from "../../enums";

interface IPropsButtonStyled {
  $width?: string;
  $mb?: string;
  $textTransform?: string;
  $variant?: EnumVariant;
  $size?: EnumSize;
  $isInIframe?: boolean;
}

interface VariantConfig {
  buttonColor: string;
  buttonTextColor: string;
}

const VARIANT_CONFIG: Record<EnumVariant, VariantConfig> = {
  [EnumVariant.PRIMARY]: {
    buttonColor: PM_COLORS.PRIMARY,
    buttonTextColor: PM_COLORS.GRAY_DARK,
  },
  [EnumVariant.SECONDARY]: {
    buttonColor: PM_COLORS.SECONDARY,
    buttonTextColor: PM_COLORS.GRAY_DARK,
  },
  [EnumVariant.GRAY]: {
    buttonColor: PM_COLORS.GRAY_6,
    buttonTextColor: PM_COLORS.GRAY_DARK,
  },
};

const getCurrentColor = (
  theme: Record<string, string>,
  variant: EnumVariant = EnumVariant.PRIMARY,
) => {
  const currentColor = {
    [EnumVariant.PRIMARY]: theme.primaryButtonColor,
    [EnumVariant.SECONDARY]: theme.secondaryButtonColor,
    [EnumVariant.GRAY]: theme.grayButtonColor,
  };

  return (variant && currentColor[variant]) || null;
};

export const ButtonStyled = styled.button<IPropsButtonStyled>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 18px 32px;
  width: 100%;
  max-width: 100%;
  height: 56px;
  border-radius: 12px;
  background: ${({ $variant, theme }) =>
    getCurrentColor(theme, $variant) ||
    VARIANT_CONFIG[$variant || EnumVariant.PRIMARY].buttonColor};
  color: ${({ $variant }) =>
    VARIANT_CONFIG[$variant || EnumVariant.PRIMARY].buttonTextColor};
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  text-align: center;
  margin: 0 auto;
  font-family: var(--regularFont);
  transition: ease 0.3s;
  cursor: pointer;
  appearance: none;
  outline: 0;
  text-transform: ${({ $textTransform }) => $textTransform || "uppercase"};

  & + div {
    margin-top: 0;
  }

  &:hover {
  }

  & span {
    font-family: var(--regularFont);
    font-size: ${({ theme }) => theme.mainFormFontSize};
  }

  &:disabled {
    background-color: ${PM_COLORS.GRAY};
    color: ${PM_COLORS.GRAY_LIGHT};
    pointer-events: none;
  }
`;
