import { api } from "../../../../services";
import { IPaymentInstructions } from "./interfaces";

const cryptoCurrencyNotActive: IPaymentInstructions = {
  title: "cryptoCurrencyNonActiveTitle",
  instructionList: [
    {
      title: "cryptoCurrencyNonActiveFirstPoint",
    },
    {
      title: "cryptoCurrencyNonActiveSecondPoint",
    },
    {
      title: "cryptoCurrencyNonActiveThirdPoint",
    },
  ],
  infoBlockHasBottomPosition: false,
  getInfo: () => "cryptoCurrencyNonActiveDisclaimer",
  submitAction: api.submitAccountRequisites,
};

export default cryptoCurrencyNotActive;
