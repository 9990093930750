import { FunctionComponent } from "preact";
import { AsquadLoaderContainer } from "./style";
import AsquadLoaderIcon from "../../assets/icons/ui/AsquadLoaderIcon";

interface AsquadLoaderProps {
  size?: "default" | "small";
}

const AsquadLoader: FunctionComponent<AsquadLoaderProps> = ({
  size = "default",
}) => {
  return (
    <AsquadLoaderContainer $size={size}>
      <AsquadLoaderIcon />
    </AsquadLoaderContainer>
  );
};

export default AsquadLoader;
