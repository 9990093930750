import { useContext, useState } from "preact/hooks";
import { state, processing, theme } from "../../services";
import { CONST_ERROR_LIST } from "../../services/constants";
import { ContextUI } from "../../providers/UIProvider";
import ClearFormModal from "../Modals/ClearModalForm/ClearFormModal";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import FieldUIDefault from "../../themes/default/ui/Field";
import FieldUIPM from "../../themes/PM_brand/ui/Field";
import TooltipDateDefault from "../../themes/default/components/TooltipDate";

const InputDate = ({
  values,
  setValues,
  errors,
  setErrors,
  inputRefMonth,
  nextStep,
  checkedCard,
  resetCardForm,
}) => {
  const [focused, setFocused] = useState(false);
  const { setModalData } = useContext(ContextUI);
  const options = state.get().options;
  const currentTheme = theme.getThemeConfig(options?.theme);

  const { showDateTooltip } = currentTheme || {};

  const onDateInput = (e) => {
    let val = e.target.value.replace(/\s/g, "").replace(/[^\d]/g, "");

    if (checkedCard && val !== values.date) {
      if (currentTheme.hasModalAlertForClearCardInfo) {
        setModalData({
          isOpen: true,
          title: "isSureClearCardData",
          content: <ClearFormModal resetCardForm={resetCardForm} />,
        });
      } else {
        resetCardForm(false);
      }

      return;
    }

    const pointer = inputRefMonth.current.selectionStart;

    if (val.length > 4) {
      val = val.replace(/^(\d{2})(\d*)(\d{2})$/g, "$1$3");
    }

    setValues((prev) => ({ ...prev, date: val }));

    setTimeout(() => {
      inputRefMonth.current.selectionStart =
        pointer >= 3 ? pointer + 4 : pointer;
      inputRefMonth.current.selectionEnd = pointer >= 3 ? pointer + 4 : pointer;
    }, 0);

    if (val.length === 4) {
      if (!processing.checkDates(val.slice(0, 2), val.slice(2, 4))) {
        return setErrors((prev) => ({
          ...prev,
          date: CONST_ERROR_LIST.wrong_date,
        }));
      }

      setTimeout(() => {
        nextStep && nextStep.current && nextStep.current.focus();
      }, 0);

      return setErrors((prev) => {
        delete prev.date;

        return prev;
      });
    }
  };
  const onDateBlur = () => {
    setFocused(false);

    if (values.date.length === 0) {
      return setErrors((prev) => ({
        ...prev,
        date: CONST_ERROR_LIST.required,
      }));
    }

    if (values.date.length < 4) {
      return setErrors((prev) => ({
        ...prev,
        date: CONST_ERROR_LIST.wrong_date,
      }));
    }

    if (values.date.length === 4) {
      if (
        !processing.checkDates(values.date.slice(0, 2), values.date.slice(2, 4))
      ) {
        return setErrors((prev) => ({
          ...prev,
          date: CONST_ERROR_LIST.wrong_date,
        }));
      }

      return setErrors((prev) => {
        delete prev.date;

        return prev;
      });
    }
  };

  const errorChecking = () => {
    if (errors.date) {
      if (focused) {
        return values.date.length === 4;
      }

      return true;
    }

    return false;
  };

  const addMask = (value) => {
    let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let matches = v.match(/\d{2,4}/g);
    let match = (matches && matches[0]) || "";
    let parts = [];
    let i;
    let len;

    for (i = 0, len = match.length; i < len; i += 2) {
      parts.push(match.substring(i, i + 2));
    }

    if (parts.length) {
      return parts.join(" / ");
    }

    return value;
  };

  const themeForComponent = useThemeComponent("TooltipDate");

  const TooltipDate = { default: TooltipDateDefault }[themeForComponent];

  const themeForFieldComponent = useThemeComponent("Field");

  const FieldUI = {
    default: FieldUIDefault,
    PM_brand: FieldUIPM,
  }[themeForFieldComponent];

  return (
    <FieldUI
      id="date"
      label={currentTheme?.inputDateLabel || ""}
      value={addMask(values.date)}
      onInput={onDateInput}
      onBlur={onDateBlur}
      setFocused={setFocused}
      focused={focused}
      inputRef={inputRefMonth}
      type="text"
      maxlength={10}
      size={10}
      autocomplete="cc-exp"
      placeholder="MM/YY"
      data-role="card_expire_date"
      data-transaction-name="DateInput"
      error={errors?.date}
      errorChecking={errorChecking}
      extraComponent={showDateTooltip ? <TooltipDate /> : null}
      fieldType="small"
    />
  );
};

export default InputDate;
