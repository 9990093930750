import { useContext, useEffect } from "preact/hooks";
import {
  LoaderButtonsWrapper,
  LoaderExtraContentWrapper,
  LoaderSubtitle,
} from "./style";
import { PAYMENT_METHODS } from "../../constants/paymentMethods";
import { state } from "../../services";
import { Context } from "../../app";
import useTimer from "../../hooks/useTimer";
import { ContextUI } from "../../providers/UIProvider";
import Trans from "../Trans";
import { EnumSize, EnumVariant } from "../../themes/commons/enums/ButtonEnums";
import Button from "../Button";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import StatusLoaderDefault from "../../themes/default/components/StatusLoader";
import StatusLoaderPM from "../../themes/PM_brand/components/StatusLoader";

const StatusLoader = () => {
  const { currentState, onGoBackButtonClick } = useContext(Context);
  const { notificationData, setNotificationData } = useContext(ContextUI);

  const handleTimeout = () => {
    const cancelUrl = state.get().options?.cancel_url;

    state.setLoading({
      extra: () => (
        <LoaderExtraContentWrapper>
          <LoaderSubtitle>
            <Trans message="subtitleLoader" />
          </LoaderSubtitle>
          <LoaderButtonsWrapper>
            <Button
              size={EnumSize.AUTO}
              variant={EnumVariant.SECONDARY}
              onClick={() => onGoBackButtonClick(cancelUrl)}
            >
              <Trans message="Back" />
            </Button>
          </LoaderButtonsWrapper>
        </LoaderExtraContentWrapper>
      ),
    });
  };

  useEffect(() => {
    if (
      !currentState?.loadingData?.status &&
      notificationData.isCloseAutomatically === false
    ) {
      setNotificationData((prev) => ({
        ...prev,
        isOpen: false,
        isCloseAutomatically: true,
      }));
    }
  }, [currentState?.loadingData?.status]);

  useTimer({
    status: currentState?.loadingData?.status,
    action: handleTimeout,
    repeatTrigger: !notificationData?.isOpen,
  });

  const selectedMethod = state.getSelectedPaymentMethod();

  useEffect(() => {
    if (!currentState || !currentState.appIsMounted) {
      const selectedCrypto = currentState?.crypto?.find(
        (item) => item.currency === selectedMethod?.currency,
      );

      const cryptoMethodLoaded = !!(
        selectedMethod?.method === PAYMENT_METHODS.CRYPTO &&
        !selectedCrypto?.error_code &&
        !selectedCrypto?.address
      );

      const anotherMethodsLoaded = !!(
        currentState?.status !== "new" &&
        (currentState?.identifier || currentState?.error)
      );

      const isTestMode = state.isTestMode();

      if (
        currentState?.loadingData?.status &&
        (anotherMethodsLoaded || cryptoMethodLoaded || isTestMode)
      ) {
        state.set({ appIsMounted: true });

        state.setLoading({ status: false, extra: null, eventName: "app_init" });
      }
    }
  }, [
    currentState?.identifier,
    currentState?.loadingData?.status,
    currentState?.status,
    currentState?.selected_method_id,
    selectedMethod,
  ]);

  useEffect(() => {
    if (currentState?.status === "form_submitted") {
      state.setLoading({ status: false, extra: null, eventName: "submit" });
    }
  }, [currentState?.status]);

  useEffect(() => {
    if (
      !["challenge_accepted", "challenge_succeeded"].includes(
        currentState?.status,
      )
    ) {
      state.setLoading({
        status: false,
        extra: null,
        eventName: "challengeSubmit",
      });
    }
  }, [currentState?.status]);

  const themeForComponent = useThemeComponent("StatusLoader");

  const StatusLoader = {
    default: StatusLoaderDefault,
    PM_brand: StatusLoaderPM,
  }[themeForComponent];

  return currentState?.loadingData?.status ? (
    <StatusLoader currentState={currentState} />
  ) : null;
};

export default StatusLoader;
