import { useState } from "preact/hooks";
import { GoBackButtonWrapper } from "./style";
import Trans from "../Trans";
import Loader from "../Loaders";
import ChallengeComplete from "./ChallengeComplete";
import useApmTransaction from "../../hooks/useApmTransaction";
import { FormLoaderWrapper } from "../Loaders/style";
import Instruction from "../Instruction";
import Button from "../Button";

const ChallengeBankTransferAfrica = ({ bankTransferData }) => {
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const { createSpan } = useApmTransaction("PaymentFlow");

  const handlePaymentMade = () => {
    const handlePaymentSpan = createSpan("MadePayment");

    setIsPaymentCompleted(true);

    setTimeout(() => {
      handlePaymentSpan?.end();
    }, 0);
  };

  return bankTransferData ? (
    <>
      {isPaymentCompleted ? (
        <ChallengeComplete />
      ) : (
        <>
          <Instruction
            configName="bank_transfer_africa"
            instructionData={bankTransferData}
          />
          <GoBackButtonWrapper>
            <Button
              onClick={handlePaymentMade}
              data-transaction-name="made-payment"
            >
              <Trans message="madePayment" />
            </Button>
          </GoBackButtonWrapper>
        </>
      )}
    </>
  ) : (
    <FormLoaderWrapper data-transaction-name="challenge-bank-transfer-africa-loader">
      <Loader />
    </FormLoaderWrapper>
  );
};

export default ChallengeBankTransferAfrica;
