import styled from "styled-components";

export const SuccessPageWrapper = styled.div`
  margin: 0 auto;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 440px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PaymentTitle = styled.p<{
  $fs?: string;
  $mb?: string;
  $color?: string;
  $width?: string;
  theme?: {
    successTitleColor?: string;
  };
}>`
  @media (min-width: 320px) {
    font-family: var(--boldFont);
    font-style: normal;
    font-weight: 500;
    font-size: ${({ $fs }) => $fs || "25px"};
    line-height: 30px;
    text-align: center;
    color: ${({ theme }) => theme?.successTitleColor};
    margin-bottom: ${({ $mb }) => $mb || "28px"};
  }
  margin-top: 21px;
  font-weight: bold;
  margin-bottom: ${({ $mb }) => $mb || "16px"};
  color: ${({ $color }) => $color || "#101820"};
  width: ${({ $width }) => $width || ""};
`;

export const TransactionInfoSecondBlock = styled.div<{
  $background?: string;
  $height?: string;
  $padding?: string;
  $margin?: string;
  $borderRadius?: string;
  theme?: {
    managePopupBackgroundColor?: string;
  };
}>`
  background-color: ${({ theme, $background }) =>
    $background || theme?.managePopupBackgroundColor};
  height: ${({ $height }) => $height || "100%"};
  padding: ${({ $padding }) => $padding || "27px 12px 20px"};
  margin: ${({ $margin }) => $margin || ""};
  border-radius: ${({ $borderRadius }) => $borderRadius || ""};
  display: flex;
  flex-direction: column;
`;

export const SuccessPageBlockWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const TransactionInfoFirstBlock = styled.div<{
  $padding?: string;
  theme?: {
    successPageBackground?: string;
  };
}>`
  background-color: ${({ theme }) => theme?.successPageBackground || "#f0f4f5"};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: ${({ $padding }) => $padding || "40px 24px 24px"};
  text-align: center;
`;
