import { FunctionComponent } from "preact";
import Footer from "../../../../components/Footer";
import Loader from "../../../../components/Loaders";
import {
  LoaderContainer,
  LoaderPositionControl,
  LoaderTitle,
  LoaderWrapper,
} from "../../../../components/Loaders/style";
import Trans from "../../../../components/Trans";

interface StatusLoaderDefaultProps {
  currentState: {
    loadingData?: {
      hasBlur?: boolean;
      text?: string;
      extra?: () => string;
    };
  };
}

const StatusLoaderDefault: FunctionComponent<StatusLoaderDefaultProps> = ({
  currentState,
}) => {
  return (
    <LoaderContainer
      $hasBlur={currentState?.loadingData?.hasBlur}
      data-transaction-name="loader"
    >
      <LoaderPositionControl>
        <LoaderWrapper>
          <Loader />
          {currentState?.loadingData?.text ? (
            <LoaderTitle>
              <Trans message={currentState?.loadingData?.text} withoutWrapper />
            </LoaderTitle>
          ) : null}
          {currentState?.loadingData?.extra ? (
            <>{currentState?.loadingData?.extra()}</>
          ) : null}
          <Footer />
        </LoaderWrapper>
      </LoaderPositionControl>
    </LoaderContainer>
  );
};

export default StatusLoaderDefault;
