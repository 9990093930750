import { useState, useEffect, useMemo } from "preact/hooks";
import { state } from "../../services";
import { CONST_ERROR_LIST } from "../../services/constants";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import FieldUIDefault from "../../themes/default/ui/Field";
import FieldUIPM from "../../themes/PM_brand/ui/Field";
import { FunctionComponent, RefObject } from "preact";

interface CustomerInputProps {
  nameKey: string;
  label: string;
  autocomplete?: string;
  prefix?: string | ((params: { method?: any }) => string);
  filterPattern?: RegExp;
  pattern?: RegExp | ((params: { method?: any }) => RegExp);
  validationMessage?: string;
  nonRequired?: boolean;
  autofocus?: boolean;
  values: Record<string, string>;
  setValues: (
    values: (prev: Record<string, string>) => Record<string, string>,
  ) => void;
  errors: Record<string, string>;
  setErrors: (
    errors: (prev: Record<string, string>) => Record<string, string>,
  ) => void;
  inputRef?: RefObject<HTMLInputElement>;
  type?: string;
  placeholder?: string;
  onClick?: () => void;
  inputDisplay?: (value: string) => string;
  isDropdown?: boolean;
  validationHint?: string;
}

const CustomerInput: FunctionComponent<CustomerInputProps> = ({
  nameKey,
  label,
  autocomplete,
  prefix,
  filterPattern,
  pattern,
  validationMessage,
  nonRequired,
  values,
  setValues,
  errors,
  setErrors,
  inputRef,
  type = "text",
  placeholder,
  onClick,
  inputDisplay,
  isDropdown,
  validationHint,
}) => {
  const [focused, setFocused] = useState(false);

  const selectedMethod = state.getSelectedPaymentMethod();

  const currentPrefix = useMemo(() => {
    if (prefix && typeof prefix === "function") {
      return prefix({ method: selectedMethod });
    }

    return prefix;
  }, [prefix, selectedMethod]);

  const isValid = (value: string) => {
    if (!nonRequired && !value) {
      return false;
    }

    if (!!value && pattern) {
      const regex =
        typeof pattern === "function"
          ? pattern({ method: selectedMethod })
          : pattern;

      return regex.test(value);
    }

    return true;
  };

  const clearErrors = () => {
    setErrors((prev) => {
      delete prev[nameKey];

      return prev;
    });
  };

  const onInput = (e: Event) => {
    const target = e.target as HTMLInputElement;
    let val = target.value || "";

    if (currentPrefix && typeof currentPrefix === "string") {
      if (
        val.startsWith(currentPrefix) &&
        val.slice(currentPrefix.length).startsWith(currentPrefix)
      ) {
        val = val.slice(currentPrefix.length);
      } else if (val.length <= currentPrefix.length) {
        val = currentPrefix;
      } else if (!val?.startsWith(currentPrefix)) {
        const inputPrefix = val.slice(0, currentPrefix.length);

        let matchingLength = 0;
        let usedIndexes = new Set();

        for (let i = 0; i < currentPrefix.length; i++) {
          for (let j = 0; j < inputPrefix.length; j++) {
            if (currentPrefix[i] === inputPrefix[j] && !usedIndexes.has(j)) {
              matchingLength++;
              usedIndexes.add(j);
              break;
            }
          }
        }

        const inputValue = val.slice(matchingLength);

        val = currentPrefix + inputValue;
      }
    }

    if (filterPattern) {
      val = val?.match(filterPattern)?.join("") || "";
    }

    if (isValid(val)) {
      clearErrors();
    }

    setValues((prev) => ({ ...prev, [nameKey]: val }));
  };

  const onBlur = () => {
    setFocused(false);
    const toValidate = (values[nameKey] || "").trim();

    setValues((prev) => ({ ...prev, [nameKey]: toValidate }));

    if (isValid(toValidate) || isDropdown) {
      return clearErrors();
    }

    return setErrors((prev) => ({
      ...prev,
      [nameKey]: toValidate
        ? validationMessage || CONST_ERROR_LIST.pattern
        : CONST_ERROR_LIST.required,
    }));
  };

  const errorChecking = () => {
    if (!errors[nameKey]) {
      return false;
    }

    return !focused;
  };

  const validationValue = () => {
    if (
      ((state.get() as { status: string })?.status === "failed_retry" ||
        values[nameKey] === undefined ||
        values[nameKey] === "") &&
      currentPrefix &&
      pattern
    ) {
      let needClean = false;

      const regex =
        typeof pattern === "function"
          ? pattern({ method: selectedMethod })
          : pattern;

      needClean = !regex.test(values[nameKey]);

      if (needClean) {
        onInput({ target: { value: "" } } as unknown as Event);
      }
    }
  };

  useEffect(() => {
    validationValue();
  }, []);

  const themeForComponent = useThemeComponent("Field");

  const FieldUI = {
    default: FieldUIDefault,
    PM_brand: FieldUIPM,
  }[themeForComponent];

  if (!FieldUI) return null;

  return (
    <FieldUI
      nameKey={nameKey}
      value={
        inputDisplay && typeof inputDisplay === "function"
          ? inputDisplay(values[nameKey])
          : values[nameKey]
      }
      type={type}
      autocomplete={autocomplete}
      placeholder={placeholder || label}
      onInput={onInput}
      onBlur={onBlur}
      focused={focused}
      setFocused={setFocused}
      error={errors[nameKey]}
      errorChecking={errorChecking}
      inputRef={inputRef}
      isDropdown={isDropdown}
      validationHint={validationHint}
      data-transaction-name={`field-${nameKey}`}
      label={label}
      onClick={onClick}
    />
  );
};

export default CustomerInput;
