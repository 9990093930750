import { useContext } from "preact/hooks";
import { Context } from "../../../../app";
import { ContextUI } from "../../../../providers/UIProvider";

import {
  CryptoContentInfoTitle,
  CryptoCurrencyNotActiveWrapper,
  CryptoFormContainer,
  CryptoHelperBlock,
  CryptoHelperBlockDescription,
  CryptoInfoBlockWrapper,
  CryptoMinMaxTitle,
  CryptoPaymentProgress,
  CryptoPaymentProgressDescription,
} from "../CryptoForm/style";
import Trans from "../../../../components/Trans";
import { Regular } from "../../../../ui/GeneralStyles/style";
import {
  BaseButton,
  ButtonWrapper,
} from "../../../../themes/commons/styles/ButtonStyles";
import Instruction from "../../../../components/Instruction";
import { FormLoaderWrapper } from "../../../../components/Loaders/style";
import Loader from "../../../../components/Loaders";
import InfoIcon from "../../../../assets/icons/infoIcon";
import {
  useIsAmountBelowMin,
  useSumMinValue,
} from "../../../../components/CryptoWidgetForm/cryptoUtils";
import CryptoForm from "../../../../components/CryptoWidgetForm/CryptoForm";
import { InnerHeaderDataWrapper } from "../Instruction/style";
import CryptoDisclaimer from "../../../../components/CryptoWidgetForm/CryptoDisclaimer";
import { formatCryptoCurrency } from "../../../../constants/CryptoCurrencyIcon";
import {
  ChallengeInfoWrapper,
  StyledInfoImage,
} from "../../../../components/Challenge/style";
import Qr from "../../../../components/CryptoWidgetForm/Qr";
interface CryptoInputData {
  currency_amount?: string;
  pay_amount?: string;
}

interface CryptoContentProps {
  onAmountChange: (data: CryptoInputData) => Promise<void>;
  isCryptoCalculated?: boolean;
  isMinNotificationEnabled?: boolean;
  currentState: {
    currency_amount?: string;
    pay_amount?: string;
    pay_currency?: string;
    network?: string;
    address?: string;
    address_tag?: string;
    address_qr_code_image?: string;
    currency?: string;
  };
  isCryptoFormVisible: boolean;
  redirectUrl: string;
  networkFeeField?: string;
  exchangeData: {
    info?: {
      active: boolean;
      amount: { min: number };
      waiting_time_minutes: number;
      network_fee: number;
    };
    data?: {
      info?: {
        active: boolean;
        amount: { min: number };
        waiting_time_minutes: number;
        network_fee: number;
      };
    };
  };
}

const CryptoContent = ({
  onAmountChange,
  currentState,
  isCryptoFormVisible,
  redirectUrl,
  exchangeData,
  networkFeeField,
  isCryptoCalculated,
  isMinNotificationEnabled,
}: CryptoContentProps) => {
  const { onGoBackButtonClick } = useContext(Context);
  const { setModalData, handleCopy } = useContext(ContextUI);
  const {
    active,
    amount,
    waiting_time_minutes = 5,
    network_fee,
  } = exchangeData?.data?.info || exchangeData?.info || {};

  const { min } = amount || {};
  const hasMinValue = !!min && !isNaN(+min) && +min !== 0;
  const sumMinValue = useSumMinValue(min ?? 0, network_fee ?? 0);
  const isAmountBelowMin = useIsAmountBelowMin(
    currentState.pay_amount ?? "",
    sumMinValue,
  );

  const handleGoBack = () => {
    setModalData({
      isOpen: false,
      title: "",
      content: null,
    });

    onGoBackButtonClick(redirectUrl);
  };

  const handlePaymentClick = () => {
    setModalData({
      isOpen: true,
      title: "paymentInProgress",
      content: (
        <CryptoPaymentProgress>
          <CryptoPaymentProgressDescription>
            <Trans
              message="paymentInProgressDescription"
              params={{ minutes: waiting_time_minutes }}
              components={[Regular]}
              withoutWrapper
            />
          </CryptoPaymentProgressDescription>
          <BaseButton
            data-role="back_to_store_button"
            onClick={handleGoBack}
            style={{ marginTop: "16px" }}
          >
            <Trans message="backToWebsite" />
          </BaseButton>
        </CryptoPaymentProgress>
      ),
    });
  };

  if (active === false) {
    return (
      <CryptoCurrencyNotActiveWrapper>
        <Instruction
          configName="crypto_currency_not_active"
          instructionData={{}}
        />
        <ButtonWrapper style={{ marginTop: "auto" }}>
          <BaseButton
            $textTransform="initial"
            onClick={() =>
              onGoBackButtonClick("", { canReturnToMethods: true })
            }
          >
            <Trans message="backToMethods" />
          </BaseButton>
        </ButtonWrapper>
      </CryptoCurrencyNotActiveWrapper>
    );
  }

  return !isCryptoFormVisible ||
    !currentState?.pay_currency ||
    !currentState?.network ||
    !currentState?.address ? (
    <FormLoaderWrapper height="100%">
      <Loader />
    </FormLoaderWrapper>
  ) : (
    <>
      <CryptoFormContainer>
        {isCryptoCalculated && (
          <ChallengeInfoWrapper $mb="16px">
            <StyledInfoImage>
              <InfoIcon />
            </StyledInfoImage>
            <CryptoInfoBlockWrapper>
              <CryptoContentInfoTitle>
                <Trans message="cryptoCalculatedInfoTitle" />
              </CryptoContentInfoTitle>
            </CryptoInfoBlockWrapper>
          </ChallengeInfoWrapper>
        )}
        {!["on_project", "in_project_fee"].includes(networkFeeField || "") && (
          <ChallengeInfoWrapper
            $mb={hasMinValue || isCryptoCalculated ? "16px" : ""}
          >
            <StyledInfoImage>
              <InfoIcon />
            </StyledInfoImage>
            <CryptoInfoBlockWrapper>
              <CryptoContentInfoTitle>
                <Trans
                  message="cryptoFeeTitle"
                  params={{
                    currency: currentState?.currency || "",
                    cryptoCurrency:
                      formatCryptoCurrency(currentState?.pay_currency, true) ||
                      "",
                  }}
                  components={[Regular]}
                />
              </CryptoContentInfoTitle>
            </CryptoInfoBlockWrapper>
          </ChallengeInfoWrapper>
        )}

        {(hasMinValue || isCryptoCalculated) && (
          <CryptoHelperBlock $mb="12px">
            <CryptoHelperBlockDescription>
              {hasMinValue && isMinNotificationEnabled && (
                <>
                  <Trans
                    message="cryptoTopup"
                    withoutWrapper
                    components={[CryptoMinMaxTitle]}
                    componentProps={[{ $isAmountBelowMin: isAmountBelowMin }]}
                    params={{
                      min: sumMinValue,
                      value: currentState?.pay_currency || "",
                    }}
                  />
                  <Trans message="paymentMinimumLimit" withoutWrapper />
                </>
              )}
            </CryptoHelperBlockDescription>
          </CryptoHelperBlock>
        )}

        {isCryptoFormVisible && isCryptoCalculated ? (
          <>
            <CryptoForm
              currentState={currentState}
              onAmountChange={onAmountChange}
              exchangeData={{ network_fee, min }}
              isMinNotificationEnabled={isMinNotificationEnabled}
              options={{
                copySendAmountEnabled: true,
              }}
            />
          </>
        ) : null}
      </CryptoFormContainer>
      <InnerHeaderDataWrapper $noShadow>
        {currentState?.pay_currency && currentState?.network ? (
          <CryptoDisclaimer currency={currentState?.pay_currency} />
        ) : null}

        {currentState?.address ? (
          <Qr
            address={currentState?.address}
            address_tag={currentState?.address_tag}
            address_qr_code_image={currentState?.address_qr_code_image}
            handleCopy={handleCopy}
          />
        ) : null}

        <ChallengeInfoWrapper>
          <StyledInfoImage>
            <InfoIcon />
          </StyledInfoImage>
          <CryptoInfoBlockWrapper>
            <CryptoContentInfoTitle>
              <Trans message="correctNetworkInfoTitle" />
            </CryptoContentInfoTitle>

            <CryptoContentInfoTitle $bold>
              <Trans message="mistakeLostCryptoFound" />
            </CryptoContentInfoTitle>
          </CryptoInfoBlockWrapper>
        </ChallengeInfoWrapper>
      </InnerHeaderDataWrapper>

      <ButtonWrapper>
        <BaseButton
          $textTransform="initial"
          onClick={handlePaymentClick}
          $mb="22px"
        >
          <Trans message="iHaveMadePayment" />
        </BaseButton>
      </ButtonWrapper>
    </>
  );
};

export default CryptoContent;
