import { useEffect, useState, useContext } from "preact/hooks";
import { state, logger, localStorageService } from "../../services";
import { Context } from "../../app";
import Scroll from "../../components/Scroll";
import useApmPageTracking from "../../hooks/useApmPageTracking";
// import AsyncComponent from "../../components/AsyncComponent";
import { useThemeComponent } from "../../hooks/useThemeComponent";
import SuccessDefault from "../../themes/default/components/SuccessPage";
import SuccessKombine from "../../themes/kombine/components/SuccessPage";
import SuccessPM from "../../themes/PM_brand/components/SuccessPage";

const SuccessPage = () => {
  useApmPageTracking("SuccessPage");

  const context = useContext(Context);
  const { style, currentState, onGoBackButtonClick, matchesData } = context;
  const { sessionId } = matchesData?.matches || {};
  const { options } = currentState || {};
  const { status_page, redirect_url } = options || {};
  const { redirect_after_sec, enabled } = status_page || {};
  const [countdown, setCountdown] = useState(redirect_after_sec);

  const sessionData = localStorageService.getSessionData(
    "localStorage",
    sessionId,
  );
  const { storageReferrer } = sessionData;

  const currentSessionReferrer = storageReferrer || null;

  const onButtonClick = () => {
    onGoBackButtonClick(redirect_url);
  };

  useEffect(() => {
    const targetUrl = redirect_url || currentSessionReferrer;

    if (!targetUrl) {
      logger.error("Target success page redirect URL not fount");

      return;
    }

    if (countdown === 0 || !enabled) {
      state.changeLocation(targetUrl, true);
    }

    let timer;

    if (countdown) {
      timer = setInterval(
        () => setCountdown(countdown > 0 ? countdown - 1 : countdown),
        1000,
      );
    }

    return () => clearInterval(timer);
  }, [
    countdown,
    redirect_url,
    redirect_after_sec,
    enabled,
    currentSessionReferrer,
  ]);

  const themeForComponent = useThemeComponent("SuccessPage");

  const Success = {
    default: SuccessDefault,
    kombine: SuccessKombine,
    PM_brand: SuccessPM,
  }[themeForComponent];

  if (!style || !enabled) {
    return;
  }

  return (
    <Scroll>
      <Success countdown={countdown} onButtonClick={onButtonClick} />
      {/* <AsyncComponent
        importFn={(t) =>
          import(
            `../../themes/${themeForComponent}/components/SuccessPage/index.tsx${t}`
          )
        }
        name="Success"
      >
        {(Success) => (
          <Success countdown={countdown} onButtonClick={onButtonClick} />
        )}
      </AsyncComponent> */}
    </Scroll>
  );
};

export default SuccessPage;
