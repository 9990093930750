import { FunctionComponent } from "preact";
import Trans from "../../../../components/Trans";
import {
  InfoBlockContainer,
  InfoBlockWrapper,
  InfoBlockImage,
  InfoBlockMainTitle,
  FormFieldsTitle,
} from "./style";
import { InnerHeaderDataWrapper } from "../Instruction/style";
import InfoIcon from "../../../../assets/icons/infoIcon";
import { PaymentMethodInfo } from "../../../../constants/paymentMethodsInfo";

interface FormInfoBlockDefaultProps {
  currentInfo?: PaymentMethodInfo;
}

const FormInfoBlockDefault: FunctionComponent<FormInfoBlockDefaultProps> = ({
  currentInfo,
}) => {
  const { content, formFieldsTitle, link } = currentInfo || {};

  if (!content) return null;

  return (
    <>
      <InnerHeaderDataWrapper $padding="0">
        <InfoBlockContainer>
          <InfoBlockWrapper>
            <InfoBlockImage>
              <InfoIcon />
            </InfoBlockImage>
            <InfoBlockMainTitle>
              {typeof content === "function" ? content() : content}
            </InfoBlockMainTitle>
          </InfoBlockWrapper>
          {link && (
            <a href={link.href} target={link.target}>
              <Trans message={link.message} withoutWrapper />
            </a>
          )}
        </InfoBlockContainer>
      </InnerHeaderDataWrapper>

      {formFieldsTitle && (
        <FormFieldsTitle>
          {typeof formFieldsTitle === "function"
            ? formFieldsTitle()
            : formFieldsTitle}
        </FormFieldsTitle>
      )}
    </>
  );
};

export default FormInfoBlockDefault;
