import { useContext, useEffect } from "preact/hooks";
import StepsList from "../ChallengeStepsList/StepsList";
import DetailsList from "../ChallengeDetailsList/DetailsList";
import { InnerLine } from "../../../styles";
import Trans from "../../Trans";
// import iconNotification from "../../../assets/icons/notificationSuccess.svg";
// import iconNotificationKombine from "../../../assets/icons/kombineSuccessIcon.svg";
import ExpirationDate from "../../BankInfo/ExpirationDate";
import {
  ChallengeMainTitle,
  StyledInfoImage,
  ChallengeInfoWrapper,
  ChallengeInfoMainTitle,
} from "../style";
import InfoIcon from "../../../assets/icons/infoIcon";
import { Context } from "../../../app";
import { InnerHeaderDataWrapper } from "../../../themes/default/components/Instruction/style";
import {
  BaseButton,
  ButtonWrapper,
} from "../../../themes/commons/styles/ButtonStyles";

const InteracEtransferInfo = ({
  challenge,
  transactionIdRef,
  handleCopy,
  cancelUrl,
  theme,
}) => {
  const { onGoBackButtonClick } = useContext(Context);

  const { name, secret_answer, secret_question, email } = challenge;

  useEffect(() => {
    return () => {
      localStorage.removeItem("expirationDate");
    };
  }, []);

  const storageKey = "expirationDate";
  let expirationDate;

  const savedExpirationDate = localStorage.getItem(storageKey);

  if (savedExpirationDate) {
    expirationDate = new Date(savedExpirationDate);
  } else {
    expirationDate = new Date(new Date().getTime() + 60 * 60 * 1000);
    localStorage.setItem(storageKey, expirationDate.toString());
  }

  // TODO need refator
  const kombineButtonStyle = {
    marginTop: "auto",
    background: "linear-gradient(#0629DF, #00AAFF)",
    border: "none",
    height: "48px",
  };

  const details = [
    { title: "recipientName", value: name, copyTitle: "nameCopy", bold: true },
    {
      title: "recipientEmail",
      value: email,
      copyTitle: "emailCopy",
      bold: true,
    },
    { title: "secretAnswer", value: secret_answer, copyTitle: "answerCopy" },
    {
      title: "secretQuestion",
      value: secret_question,
      copyTitle: "questionCopy",
    },
  ].filter((detail) => detail?.value);

  const steps = [
    { number: 1, title: "openYourBankingAccount" },
    { number: 2, title: "chooseInteracAmount" },
    { number: 3, title: "fillRecipEmailName" },
    { number: 4, title: "completeSecret" },
    { number: 5, title: "confirmPayment" },
  ];

  // MERGE TODO: notification icon
  // const notificationIconConfig = {
  //   default: iconNotification,
  //   kombine: iconNotificationKombine,
  // };

  return (
    <>
      <InnerHeaderDataWrapper $pt="20px">
        <ChallengeMainTitle $mt="16px" $mb="16px">
          <Trans message="howToCompletePayment" />
        </ChallengeMainTitle>

        <StepsList steps={steps} />
        <InnerLine themeBackground />
        <DetailsList
          theme={theme}
          details={details}
          handleCopy={handleCopy}
          transactionIdRef={transactionIdRef}
        />
        <ExpirationDate expiration_date={expirationDate} />

        <ChallengeInfoWrapper>
          <StyledInfoImage>
            <InfoIcon />
          </StyledInfoImage>
          <ChallengeInfoMainTitle>
            <Trans message="interacInfoTitle" />
          </ChallengeInfoMainTitle>
        </ChallengeInfoWrapper>
      </InnerHeaderDataWrapper>
      {cancelUrl ? (
        <ButtonWrapper>
          <BaseButton
            $textTransform="initial"
            onClick={() => onGoBackButtonClick(cancelUrl)}
            $mb="22px"
            style={theme === "kombine" && kombineButtonStyle}
          >
            <Trans message="iHaveMadePayment" />
          </BaseButton>
        </ButtonWrapper>
      ) : null}
    </>
  );
};

export default InteracEtransferInfo;
