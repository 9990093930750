import styled from "styled-components";
import { COLORS } from "../../../../constants/themeConstants";

export const InfoBlockContainer = styled.div`
  background-color: ${COLORS.BlUE_LIGHT};
  padding: 12px 12px 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    text-align: center;
    font-weight: 700;
    font-family: var(--boldFont);
    color: ${({ theme }) => theme?.mainColor};
  }
`;

export const InfoBlockWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

export const InfoBlockImage = styled.div`
  & svg {
    circle {
      fill: ${({ theme }) => theme.mainColor};
    }
  }
`;

export const InfoBlockMainTitle = styled.span`
  width: 100%;
  font-family: var(--mediumFont);
  color: ${COLORS.DEFAULT_BLACK};
  font-size: 14px;
  font-weight: 400;
  line-height: 16.3px;
  letter-spacing: 0.01px;

  span {
    font-family: var(--boldFont);
    color: ${COLORS.DEFAULT_BLACK};
    font-size: 14px;
    font-weight: 500;
    line-height: 16.3px;
    letter-spacing: 0.01px;
  }
`;

export const FormFieldsTitle = styled.div`
  font-family: var(--mediumFont);
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  padding: 0 16px 16px;
  margin-right: 16px;
  margin-right: 16px;

  @media (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;
