import styled from "styled-components";
import { PM_COLORS } from "../../enums";

export const CustomCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme?.rememberMeCheckboxMargin};
  padding: ${({ theme }) => theme?.rememberMeCheckboxPadding};
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxLabel = styled.label`
  color: ${({ theme }) => theme?.rememberMeCheckboxTitleColor};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  width: 100%;
  flex-shrink: 1;
`;

export const PmCheckboxWrapper = styled.div`
  background-color: ${PM_COLORS.GRAY_DARK_2};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 24px;
  width: 100%;
`;

export const PMdCheckbox = styled.div<{
  $checked?: boolean;
}>`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${({ theme }) =>
    theme.primaryButtonColor || PM_COLORS.PRIMARY};
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke-width: 3px;
    visibility: ${({ $checked }) => ($checked ? "visible" : "hidden")};
  }
`;
