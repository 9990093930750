import styled from "styled-components";

export const AltMethodsWrapper = styled.div<{
  $hasAltMethod?: boolean;
}>`
  display: flex;
  flex-direction: ${({ $hasAltMethod }) => ($hasAltMethod ? "" : "column")};
  max-width: 400px;
  width: 100%;
  margin: ${({ $hasAltMethod }) =>
    $hasAltMethod ? "20px auto 12px" : "0 auto"};
  gap: 15px;
  padding: 0 12px;
  & > div {
    flex: ${({ $hasAltMethod }) => ($hasAltMethod ? "1" : "")};
  }

  @media (max-height: 768px) {
    margin: ${({ $hasAltMethod }) => ($hasAltMethod ? "8px auto" : "0 auto")};
  }
`;
