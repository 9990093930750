import { useEffect, useState, useCallback, useRef } from "preact/hooks";
import {
  logger,
  state,
  theme,
  testData,
  processing,
  api,
  localStorageService,
} from "./services";
import { useData, useColorScheme, useAntifraud } from "./services/hooks";
import { route } from "preact-router";
import {
  AUTO_PROCESSED_PAYMENT_METHODS,
  METHODS_WITH_TRANSACTION_ON_SELECT,
} from "./constants/paymentMethods";
import { GlobalStyles, WhiteOverlay } from "./styles";
import { ThemeProvider } from "styled-components";
import Routes from "./components/Routes";
import brand from "./services/brand";
import LoaderLine from "./components/Loaders/LoaderLine";
import StatusLoader from "./components/Loaders/StatusLoader";
import useApmTransaction from "./hooks/useApmTransaction";
import ConnectionProvider from "./providers/ConnectionProvider";
import UIProvider from "./providers/UIProvider";
import useHotjar from "./hooks/useHotjar";
import useInitApp from "./hooks/useInitApp";
import useLocaleManager from "./hooks/useLocaleManager";
import useConnectionManager from "./hooks/useConnectionManager";
import { AppContext } from "./Context";
import { getSessionFromUrl } from "./services/utils";
const MOUNT_TIME = 3000;

export const Context = AppContext;

const App = () => {
  const initialSessionData = getSessionFromUrl();
  const [isBrandLoading, setIsBrandLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [matchesData, setMatchesData] = useState({
    matches: {
      sessionId: initialSessionData.sessionId,
      signature: initialSessionData.signature,
      type: initialSessionData.type,
    },
  });
  const [style, setStyle] = useState(null);
  const [themeConfig, setThemeConfig] = useState(null);
  const [currentState, setCurrentState] = useState({});
  const [currentCryptoState, setCurrentCryptoState] = useState({});
  const lastStatus = useRef(null);
  const brandData = brand.getBrand();
  const { type, signature, sessionId, url } = matchesData?.matches || {};
  const isTestMode = testData.isTestMode(sessionId, signature);

  const { status, actualRoute, options } = currentState || {};
  const { colorTheme } = useColorScheme(options?.theme);

  const {
    createSpan: createPaymentSpan,
    endTransaction: endPaymentTransaction,
  } = useApmTransaction("PaymentFlow");

  const { createSpan: createCancelSpan, endTransaction: endCancelTransaction } =
    useApmTransaction("CancelFlow");

  useInitApp();
  useHotjar(brandData?.hotjar_site_id, sessionId);
  useConnectionManager({
    matches: matchesData?.matches,
    setters: {
      setStyle,
      setThemeConfig,
      setCurrentCryptoState,
      setCurrentState,
    },
  });

  useLocaleManager();

  const onGoBackButtonClick = (cancelUrl, options) => {
    if (options?.canReturnToMethods) {
      const clearSelectedMethodSpan = createPaymentSpan("ClearSelectedMethod");

      state.onPaymentMethodSelected({ id: null }).finally(() => {
        clearSelectedMethodSpan?.end();
      });

      state.set({ cryptoConverted: null });

      return;
    }

    if (isTestMode) {
      console.log(`REDIRECT TO: ${cancelUrl}`);
      testData.reset();
      state.reset();

      return;
    }

    if (typeof window === "undefined") {
      return;
    }

    const cancelUrlSpan = createPaymentSpan("CancelUrl");

    setTimeout(() => {
      cancelUrlSpan?.end();
      endPaymentTransaction();

      const sessionData = localStorageService.getSessionData(
        "localStorage",
        sessionId,
      );

      if (!sessionData) {
        logger.error("Session data is missing!", { sessionData });
      }

      const { storageReferrer, storageRedirectUrl } = sessionData || {};

      const changeLocationWithSpan = (url, labelKey, spanName) => {
        try {
          const span = createCancelSpan(spanName);
          logger.addLabels({ [labelKey]: url });
          state.changeLocation(url, true);
          span?.end();
          endCancelTransaction();
        } catch (error) {
          logger.error(`Error during changeLocationWithSpan for ${spanName}`, {
            url,
            labelKey,
            error,
          });
        }
      };

      const SESSION_CANCEL_URL_KEY = "session.sessionCancelUrl";
      const SESSION_REDIRECT_URL_KEY = "session.sessionRedirectUrl";
      const SESSION_REFERRER_URL_KEY = "session.sessionReferrerUrl";

      const redirectWithStorageReferrer = (spanName) => {
        changeLocationWithSpan(
          storageReferrer,
          SESSION_REFERRER_URL_KEY,
          spanName,
        );
      };

      try {
        if (cancelUrl) {
          changeLocationWithSpan(
            cancelUrl,
            SESSION_CANCEL_URL_KEY,
            "ChangeLocation_CancelUrl",
          );

          return;
        }

        if (storageRedirectUrl) {
          changeLocationWithSpan(
            storageRedirectUrl,
            SESSION_REDIRECT_URL_KEY,
            "ChangeLocation_StorageRedirectUrl",
          );
        } else if (storageReferrer) {
          redirectWithStorageReferrer("ChangeLocation_CurrentSessionReferrer");
        }
      } catch (error) {
        logger.error("Go back redirect failed!", { error });

        if (storageReferrer) {
          redirectWithStorageReferrer("ChangeLocation_CurrentSessionReferrer");
        } else {
          logger.error("No storageReferrer available for redirect.");
        }
      }
    }, 1000);
  };

  const getFormattedAmountParts = (amount) => {
    if (amount == null) {
      return {
        wholeNumber: "",
        decimal: "",
      };
    }

    const formattedAmount = amount?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    const parts = formattedAmount.toString().split(".");
    const wholeNumber = parts[0];
    const decimal = parts[1] || "";

    return {
      wholeNumber,
      decimal,
    };
  };

  const newState = useData(state.get());

  useEffect(() => {
    if (newState) {
      let currentTheme = "default";

      try {
        currentTheme = localStorageService.getSessionData(
          "sessionStorage",
          sessionId,
        )?.currentTheme;
      } catch (error) {
        logger.warn("Access to sessionStorage is denied", { error });
      }

      const newStateData = {};

      if (
        currentTheme !== "undefined" &&
        currentTheme !== newState?.options?.theme
      ) {
        newStateData.options = {
          ...(newState?.options || {}),
        };

        setCurrentState({
          ...newState,
          ...newStateData,
        });
      } else {
        setCurrentState(newState);
      }

      if (newState?.options?.theme) {
        try {
          localStorageService.setSessionData("sessionStorage", sessionId, {
            currentTheme: newState?.options?.theme || {},
          });
        } catch (error) {
          logger.warn("Failed to write to sessionStorage", { error });
        }
      }
    }
  }, [newState]);

  useEffect(() => {
    if (sessionId && signature) {
      api
        .getBrandInfo(sessionId, signature)
        .then((data) => {
          if (data) {
            brand.setBrand(data);
          }
        })
        .finally(() => {
          console.log("Current env:", import.meta.env.VITE_NAME_ENV);
          setIsBrandLoading(false);
        });
    }
  }, [sessionId, signature]);

  useEffect(() => {
    if (sessionId && signature) {
      const { storageReferrer, storageSessionId } =
        localStorageService.getSessionData("localStorage", sessionId);
      const referrer = document?.referrer;

      const shouldUpdateReferrer =
        referrer &&
        referrer !== window?.location?.href &&
        storageReferrer !== referrer &&
        storageSessionId !== sessionId;

      const shouldUpdateRedirectUrl = options?.redirect_url;

      localStorageService.setSessionData("localStorage", sessionId, {
        ...(shouldUpdateReferrer ? { storageReferrer: referrer } : {}),
        ...(shouldUpdateRedirectUrl
          ? { storageRedirectUrl: options?.redirect_url }
          : {}),
      });
    }
  }, [sessionId, signature, options?.redirect_url]);

  useEffect(() => {
    if (
      (!type || !sessionId || !signature) &&
      Object.keys(matchesData).length > 1
    ) {
      if (isMounted) {
        new Promise((resolve) => {
          state.setCurrentSession({ type, sessionId, signature, resolve });
        });
      } else {
        setTimeout(() => {
          setIsMounted(true);
        }, MOUNT_TIME);
      }
    }
  }, [matchesData, isMounted]);

  useEffect(() => {
    const data = state.get();
    const selectedMethod = state.getSelectedPaymentMethod();

    if (data.status === "new" && lastStatus.current !== "new") {
      const trackOpenSpan = createPaymentSpan("TrackOpen");

      state.trackOpen().finally(() => {
        trackOpenSpan?.end();
      });
    }

    if (
      ["opened", "failed_retry"].includes(data.status) &&
      AUTO_PROCESSED_PAYMENT_METHODS.includes(selectedMethod?.method)
    ) {
      processing.processFormData({
        method: selectedMethod?.method,
      });
    }

    if (
      data.status === "expired" &&
      (!data.error || (data.error && Object.keys(data.error).length === 0))
    ) {
      state.expiredStatus();
    }

    if (["success", "failed"].includes(data.status)) {
      if (!data?.identifier && !isTestMode) {
        state.setLoading({ status: true, eventName: "no_identifier" });
      }
    }

    lastStatus.current = data.status;
  }, [status, actualRoute?.url]);

  useEffect(() => {
    const data = state.get();

    if (data.status) {
      Promise.resolve()
        .then(() =>
          theme.getStyle(state.getTheme(), sessionId, signature, brandData),
        )
        .then((themeStyle) => setStyle(themeStyle))
        .then(() => theme.getThemeConfig(state.getTheme()))
        .then((themeConfig) => setThemeConfig(themeConfig));
    }
  }, [status, actualRoute?.url, options?.theme]);

  useEffect(() => {
    if (actualRoute && actualRoute.url !== url) {
      route(actualRoute.url);
    }
  }, [actualRoute, url]);

  /*
    when status is opened automatically selects payment method
    based on options.method field value if method is not auto
    or even for auto method based on only available payment method if available_methods.length is 1
    in test mode also listens options.method changes and mocks all available methods
  */
  useEffect(() => {
    const { status, selected_method_id, options } = newState || {};
    const { method } = options || {};
    const isReadyToRun =
      ["opened", "failed_retry"].includes(status) &&
      (isTestMode || !selected_method_id);
    const methods = state.getAvailablePaymentMethods();
    const nonErrorMethods = methods.filter((availableMethod) => {
      return !METHODS_WITH_TRANSACTION_ON_SELECT.find((methodConfig) => {
        const dataArray = newState?.[methodConfig.dataArrayName];
        const currentMethod = dataArray?.find((item) => {
          if (!item.payment_method_id) {
            return methodConfig.name === availableMethod.method;
          }

          return item.payment_method_id === availableMethod.id;
        });

        return currentMethod?.error_code;
      });
    });
    const onlyAvailableMethod =
      nonErrorMethods.length === 1 ? nonErrorMethods[0] : null;

    const testAutoMethod =
      isTestMode && selected_method_id && method === "auto"
        ? { id: null }
        : null;
    const methodToSelect = onlyAvailableMethod || testAutoMethod;

    if (isReadyToRun && methodToSelect) {
      const methodSelectSpan = createPaymentSpan("AutoSelectMethod");

      state
        .onPaymentMethodSelected({
          id: methodToSelect.id,
        })
        .finally(() => {
          methodSelectSpan?.end();
        });
    }
  }, [newState?.options?.method, newState?.status]);

  logger.initApm();

  const [preloaded, setPreloaded] = useState(true);

  const onPreloadDefaultRoute = useCallback(
    (routesConfig) => {
      if (
        preloaded &&
        routesConfig?.active?.[routesConfig?.active.length - 1]?.type?.preload
      ) {
        routesConfig.active[routesConfig.active.length - 1].type.preload?.();
        setPreloaded(false);
      }
    },
    [preloaded],
  );

  const handleRoute = useCallback(
    (routesConfig) => {
      onPreloadDefaultRoute(routesConfig);
      setMatchesData(routesConfig?.active[0]?.props);
    },
    [onPreloadDefaultRoute],
  );

  const value = {
    matchesData,
    style,
    themeConfig,
    currentState,
    isTestMode,
    handleRoute,
    getFormattedAmountParts,
    onGoBackButtonClick,
    currentCryptoState,
    setCurrentCryptoState,
  };

  useAntifraud(brandData);

  return (
    <ThemeProvider theme={colorTheme}>
      <UIProvider>
        {isBrandLoading ? (
          <WhiteOverlay>
            <LoaderLine />
          </WhiteOverlay>
        ) : null}
        <GlobalStyles />
        <Context.Provider value={value}>
          <StatusLoader />
          <ConnectionProvider>
            <Routes />
          </ConnectionProvider>
        </Context.Provider>
      </UIProvider>
    </ThemeProvider>
  );
};

export default App;
