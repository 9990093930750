import styled from "styled-components";

export const TransactionInfoErrorBlockKomb = styled.div<{
  $mb?: string;
  $background?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  margin: 0 16px;
  border-radius: 8px;
  margin-bottom: ${({ $mb }) => $mb || ""};
  background: ${({ $background }) => $background};
  & svg {
    g {
      circle {
        fill: #e53956;
      }
    }
  }
`;
