import styled from "styled-components";

export const TimeRemainingWrapper = styled.div<{
  timeRemaining?: boolean;
  theme?: {
    remainingTimeBackground?: string;
  };
}>`
  background-color: ${({ timeRemaining, theme }) =>
    timeRemaining ? "#FFE0EB" : theme?.remainingTimeBackground};
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
`;

export const TimeRemainingTitle = styled.div`
  color: ${({ theme }) => theme?.remainingTimeTitleColor || ""};
  font-size: 14px;
`;

export const RemainingTimeTitle = styled.span`
  font-family: var(--boldFont);
  color: ${({ theme }) => theme?.remainingTimeTimesColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 16.3px;
  letter-spacing: 0.5px;
`;

export const ExpirationDateInfoMainWrapper = styled.div`
  margin: 0 16px 24px;

  @media (min-width: 768px) {
    margin: 0 16px 24px;
    margin: ${({ theme }) => `0 ${theme?.infoBlockMargin} 24px`};
  }

  @media (max-height: 768px) {
    margin-bottom: 8px;
  }
`;

export const ExpirationDateInfoContentWrapper = styled.div`
  background-color: #ffe0eb;
  max-width: 376px;
  margin: 0 auto;
  padding: 12px;
  display: flex;
  opacity: 0.8;
  border-radius: 15px;
  margin-bottom: 24px;

  @media (min-width: 1440px) {
    margin-top: 0px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  margin-left: 8px;
`;

export const ExpiredInfoTitleWrapper = styled.div`
  span {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
  }
`;

export const ExpiredInfoSubtitle = styled.p`
  margin-top: 8px;
  font-size: 16px;
`;
