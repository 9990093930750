import styled from "styled-components";
import { COLORS } from "../../../../constants/themeConstants";

interface InstructionStepSecondaryTitleProps {
  bold?: boolean;
}

export const InstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const InstructionStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

export const InstructionStepTitleWrapper = styled.div`
  display: flex;
  margin-right: auto;
  align-items: baseline;
`;

export const InstructionStepNumber = styled.p`
  color: ${(props) => props.theme?.mainTextColor || props.theme.mainColor};
  font-size: 14px;
  font-family: var(--boldFont);
  margin: 0 12px 0 0;
  width: 9px;
  flex-shrink: 0;
`;

export const InstructionStepTitle = styled.span`
  font-size: 14px;
  font-family: var(--regularFont);
  color: ${({ theme }) => theme?.mainTextColor || "#5a747a"};
  letter-spacing: 0px;
`;

export const InstructionStepSecondaryTitle = styled(
  InstructionStepTitle,
)<InstructionStepSecondaryTitleProps>`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;

export const InstructionStepDataWrapper = styled.div`
  background-color: ${COLORS.GREY_WHITE};
  width: 100%;
  height: 32px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  border-radius: 8px;

  svg {
    max-width: 16px;
    max-height: 16px;
    width: auto;
    height: auto;
  }
`;

export const InstructionStepDataText = styled.span`
  font-family: var(--boldFont);
  font-size: 14px;
  font-weight: 500;
  line-height: 16.03px;
`;

export const InstructionStepDataSubtext = styled.span`
  color: ${COLORS.GRAY};
  font-family: var(--regularFont);
  font-weight: 400;
`;

export const InstructionStepCopyIconWrapper = styled.div`
  max-width: 96px;
  max-height: 16px;
  width: auto;
  height: auto;
`;

export const InfoMainTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-family: var(--boldFont);
    color: #101820;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.3px;
    letter-spacing: 0.01px;
  }
`;

export const InnerHeaderDataWrapper = styled.div<{
  $padding?: string;
  $noShadow?: boolean;
  $gap?: string;
  $pt?: string;
  $alignItems?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $alignItems }) => $alignItems || "center"};
  padding: ${({ $padding }) => $padding || "0px 16px 16px"};
  margin-top: auto;
  background: ${({ theme }) => theme?.infoBlockBackground};
  box-shadow: ${({ theme, $noShadow }) => ($noShadow ? "" : theme?.boxShadow)};
  border-radius: 15px;
  margin: 0 auto;
  padding-top: ${({ $pt }) => $pt || ""};
  margin-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;
  z-index: 30;
  gap: ${({ $gap }) => $gap || ""};

  @media (min-width: 768px) {
    margin-left: ${({ theme }) => theme?.infoBlockMargin};
    margin-right: ${({ theme }) => theme?.infoBlockMargin};
  }

  @media (max-height: 768px) {
    margin-bottom: 8px;
  }
`;

export const NonOrderListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

export const NonOrderListItemTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.GRAY};
  font-family: var(--regularFont);
  text-align: right;
  word-wrap: break-word;
`;

export const NonOrderListItemValue = styled.span`
  font-family: var(--regularFont);
  font-weight: 500;
  font-size: 14px;
  line-height: 16.03px;
  letter-spacing: 0%;
  color: ${COLORS.DEFAULT_BLACK};
  margin: 0 0 0 auto;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  * {
    max-height: 12px;
    width: auto;
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 16px 0;
  width: 100%;
`;
