/* eslint-disable no-unused-vars */
import { useState, useEffect } from "preact/hooks";
import { api } from "../../../services";
import Trans from "../../Trans";
import { ChallengeTimerTitle, ResendSpan, ResendTimeTitle } from "./style";

const Timer = ({ initialSeconds, sessionId, signature }) => {
  const [startTime, setStartTime] = useState(() => {
    const savedStartTime = parseInt(localStorage.getItem("timerStartTime"), 10);
    const now = new Date().getTime();

    return isNaN(savedStartTime) || savedStartTime < now - initialSeconds
      ? now
      : savedStartTime;
  });

  const [remainingTime, setRemainingTime] = useState(initialSeconds);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isResendClicked, setResendClicked] = useState(false);
  let interval;

  const calculateRemainingTime = () => {
    const currentTime = new Date().getTime();
    const elapsedTime = Math.floor((currentTime - startTime) / 1000);
    const updatedRemainingTime = Math.max(initialSeconds - elapsedTime, 0);

    setRemainingTime(updatedRemainingTime);

    if (updatedRemainingTime === 0) {
      setButtonDisabled(false);
      clearInterval(interval);
      localStorage.removeItem("timerStartTime");
    }
  };

  useEffect(() => {
    setButtonDisabled(true);

    interval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [initialSeconds]);

  const handleResendClick = () => {
    if (!isResendClicked) {
      setButtonDisabled(true);
      setResendClicked(true);
      api.resendChallenge({
        sessionId,
        signature,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("timerStartTime", startTime);
  }, [startTime]);

  return (
    <ChallengeTimerTitle>
      <ResendTimeTitle>
        <Trans message="time_remaining" /> {remainingTime ? remainingTime : ""}
      </ResendTimeTitle>{" "}
      <ResendSpan
        $isButtonDisabled={isButtonDisabled}
        $isResendClicked={isResendClicked}
        onClick={isButtonDisabled || isResendClicked ? null : handleResendClick}
      >
        <Trans message="resend_otp" />
      </ResendSpan>
    </ChallengeTimerTitle>
  );
};

export default Timer;
