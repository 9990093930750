import {
  ChallengeMainTitle,
  StkChallengeSubTitle,
  StyledInfoImage,
  ChallengeInfoWrapper,
} from "./style";
import InfoIcon from "../../assets/icons/infoIcon";
import Trans from "../Trans";
import { InnerHeaderDataWrapper } from "../../themes/default/components/Instruction/style";
import { TotalInfoWrapper } from "../Header/style";

const ChallengeStk = () => {
  return (
    <InnerHeaderDataWrapper $alignItems="start" $padding="20px">
      <ChallengeMainTitle $mb="16px">
        <Trans message="mobileMoneyMainTitle" />
      </ChallengeMainTitle>

      <TotalInfoWrapper>
        <StkChallengeSubTitle>
          <Trans message="mobileMoneySubTitle" />
        </StkChallengeSubTitle>
      </TotalInfoWrapper>
      <ChallengeInfoWrapper>
        <StyledInfoImage>
          <InfoIcon />
        </StyledInfoImage>
        <ChallengeMainTitle>
          <Trans message="mobileMoneyInfoTitle" />
        </ChallengeMainTitle>
      </ChallengeInfoWrapper>
    </InnerHeaderDataWrapper>
  );
};

export default ChallengeStk;
