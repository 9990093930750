import styled, { keyframes, css } from "styled-components";

const commonStyles = css`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.mainColor};
  position: absolute;
  content: "";
`;

const firstDotAnimation = keyframes`
  0%, 50% {
    transform-origin: 15px center;
  }
  50% {
    transform: rotateZ(180deg);
  }
  51%, 100% {
    transform-origin: center;
    transform: rotateZ(180deg) translateX(-22px);
  }
  100% {
    transform: rotateZ(180deg) translateX(0);
  }
`;

const secondDotAnimation = keyframes`
  0%, 50% {
    transform: rotateZ(-180deg);
  }
  51%, 100% {
    transform-origin: -8px center;
  }
`;

const Loader4Wrapper = styled.div`
  position: relative;
  width: 15px;
  height: 30px;
  margin: auto;
`;

const Loader4Dot = styled.div`
  ${commonStyles}
  ${({ isSecond }) => (isSecond ? "left: 0" : "right: 0")};
  opacity: ${({ isSecond }) => (isSecond ? "0.8" : "1")};
  animation: ${({ isSecond }) =>
      isSecond ? secondDotAnimation : firstDotAnimation}
    1s linear infinite;
`;

const StepLoader = ({ ...props }) => {
  return (
    <Loader4Wrapper>
      <Loader4Dot />
      <Loader4Dot isSecond {...props} />
    </Loader4Wrapper>
  );
};

export default StepLoader;
