import { createContext } from "preact";

export interface CryptoState {
  currency_amount?: string;
  pay_amount?: string;
  pay_currency?: string;
  network?: string;
  address?: string;
  address_tag?: string;
  address_qr_code_image?: string;
  exchange_allow_calculator?: boolean;
  currency?: string;
}

interface AppContextType {
  matchesData: any | null;
  style: any | null;
  themeConfig: any | null;
  currentState: any | null;
  currentCryptoState: CryptoState | null;
  isTestMode: boolean;
  handleRoute: () => void;
  onGoBackButtonClick: (
    cancelUrl: string,
    options?: { canReturnToMethods?: boolean },
  ) => void;
}

const defaultContext: AppContextType = {
  matchesData: null,
  style: null,
  themeConfig: null,
  currentState: null,
  currentCryptoState: null,
  isTestMode: false,
  handleRoute: () => {},
  onGoBackButtonClick: (_cancelUrl, _options) => {},
};

export const AppContext = createContext<AppContextType>(defaultContext);
